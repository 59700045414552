import { createSlice } from "@reduxjs/toolkit";
import { acceptInviteThunk, getInvitationMethodThunk, getMembersThunk } from "../thunks/members.thunk";

export type TAPIKey = {
  name: string;
  secret: string;
  show: boolean;
};

export type TTeamMember = {
  email: string;
  is_owner: boolean;
  status: "PENDING" | "ACTIVE";
};

type InitialStateUser = {
  apiKeys: {
    keyList: TAPIKey[];
    apiKeyReqErr: string;
    isFetching: boolean;
  };
  teamMembers: {
    list: TTeamMember[];
    isFetching: boolean;
    isAccepted: boolean;
    acceptInviteLoading: boolean;
  };
  invitation: {
    method: string | null;
    isValid: boolean;
    isLoading: boolean;
  };
};

const initialState: InitialStateUser = {
  apiKeys: {
    keyList: [],
    apiKeyReqErr: "",
    isFetching: false,
  },
  teamMembers: {
    list: [],
    isFetching: false,
    isAccepted: null,
    acceptInviteLoading: false,
  },
  invitation: {
    method: null,
    isValid: false,
    isLoading: false,
  },
};

const slice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    /**
     * API Keys
     *
     */
    setAPIKeys: (state, { payload }) => {
      // add show key for all API keys
      state.apiKeys.keyList = payload.map((_apiKey: TAPIKey) => {
        return {
          ..._apiKey,
          show: false,
        };
      });
      state.apiKeys.isFetching = false;
    },
    clearAPIKeys: (state) => {
      state.apiKeys.keyList = [];
      state.apiKeys.apiKeyReqErr = "";
      state.apiKeys.isFetching = false;
    },
    setShowAPISecret: (state, { payload }) => {
      state.apiKeys.keyList = state.apiKeys.keyList.map((_apiKey) => {
        if (payload.secret === _apiKey.secret) {
          return { ..._apiKey, show: payload.show };
        }

        return { ..._apiKey };
      });
    },
    setAPIKeyReqError: (state, { payload }) => {
      state.apiKeys.apiKeyReqErr = payload;
    },
    setAPIKeyIsFetching: (state, { payload }) => {
      state.apiKeys.isFetching = payload;
    },

    /**
     * Users
     */

    setTeamMemberList: (state, { payload }) => {
      state.teamMembers.list = payload?.detail?.members || [];
      state.teamMembers.isFetching = false;
    },
    clearTeamMemberList: (state) => {
      state.teamMembers.list = [];
      state.teamMembers.isFetching = false;
    },
    setAcceptInviteLoading: (state, { payload }) => {
      state.teamMembers.acceptInviteLoading = payload;
    },
    setFetchingTeamMembers: (state, { payload }) => {
      state.teamMembers.isFetching = payload;
    },
    setInvitation: (state, { payload }) => {
      state.invitation = payload;
    },
    setInvitationLoading: (state, { payload }) => {
      state.invitation.isLoading = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMembersThunk.fulfilled, (state, { payload }) => {
        state.teamMembers.isFetching = false;
      })
      .addCase(getMembersThunk.rejected, (state, { payload }) => {
        state.teamMembers.isFetching = false;
      })
      .addCase(acceptInviteThunk.fulfilled, (state, { payload }) => {
        state.teamMembers.acceptInviteLoading = false;
        state.teamMembers.isAccepted = true;
        state.invitation.method = null;
        state.invitation.isValid = false;
        state.invitation.isLoading = false;
      })
      .addCase(acceptInviteThunk.rejected, (state, { payload }) => {
        state.teamMembers.isAccepted = false;
        state.teamMembers.acceptInviteLoading = false;
        state.invitation.method = null;
        state.invitation.isValid = false;
        state.invitation.isLoading = false;
      })
      .addCase(getInvitationMethodThunk.fulfilled, (state, { payload }) => {
        const invitationMethod = !!payload?.invitation_method ? payload.invitation_method : "UNINVITED";
        state.invitation.method = invitationMethod;
        state.invitation.isLoading = false;
        state.invitation.isValid = ["GOOGLE_SSO", "EMAIL_PW"].includes(invitationMethod);
      })
      .addCase(getInvitationMethodThunk.rejected, (state, { payload }) => {
        state.invitation.method = "UNINVITED";
        state.invitation.isValid = false;
        state.invitation.isLoading = false;
      });
  },
});

export const {
  // apiKeys
  setAPIKeys,
  clearAPIKeys,
  setShowAPISecret,
  setAPIKeyReqError,
  setAPIKeyIsFetching,
  // teamMembers
  setTeamMemberList,
  clearTeamMemberList,
  setFetchingTeamMembers,
  setAcceptInviteLoading,
  setInvitation,
  setInvitationLoading,
} = slice.actions;
export default slice.reducer;
