import { useEffect, useState } from "react";
import { getRemainingCountdown } from "../utils/countdown";

type Props = {
  timeOfOperation: string;
  countdownDelayInMs: number;
  countdownDurationInMin: number;
};

const useCountdown = ({ timeOfOperation, countdownDurationInMin, countdownDelayInMs }: Props) => {
  const [countdown, setCountdown] = useState(
    getRemainingCountdown(timeOfOperation, countdownDurationInMin, countdownDelayInMs),
  );

  useEffect(() => {
    let timerId: NodeJS.Timer;

    if (countdown > 0) {
      timerId = setInterval(() => {
        setCountdown((prevCountdown) => prevCountdown - 1);
      }, countdownDelayInMs);
    }

    return () => {
      clearInterval(timerId);
    };
  }, [countdown]);

  return countdown;
};

export default useCountdown;
