import axios, { AxiosPromise } from "axios";
import config from "../../config";
import { LogsState } from "../../slices/marqtune";
import { AccountId } from "../api.types";
import {
  MarqtuneDatasetDetailsResponse,
  MarqtuneDatasetListResponse,
  MarqtuneDeleteResourceResponse,
  MarqtuneEvaluationDetailsResponse,
  MarqtuneEvaluationListResponse,
  MarqtuneGetDownloadLinkResponse,
  MarqtuneModelDetailsResponse,
  MarqtuneModelListResponse,
} from "./types";

const requestAccess = (accountId: AccountId): AxiosPromise => {
  return axios({
    method: "POST",
    baseURL: config.baseURL,
    url: config.endpoints.requestMarqtuneAccess,
    params: { accountId },
  });
};

/***
 * DATASETS
 */

const listDatasets = (accountId: AccountId): AxiosPromise<MarqtuneDatasetListResponse> => {
  return axios({
    method: "GET",
    baseURL: config.marqtuneBaseURL,
    url: config.endpoints.marqtune.datasets,
    params: { accountId },
  });
};

const deleteDataset = (accountId: AccountId, datasetId: string): AxiosPromise<MarqtuneDeleteResourceResponse> => {
  return axios({
    method: "DELETE",
    baseURL: config.marqtuneBaseURL,
    url: `${config.endpoints.marqtune.datasets}/${datasetId}`,
    params: { accountId },
  });
};

const getDatasetDetails = (accountId: AccountId, datasetId: string): AxiosPromise<MarqtuneDatasetDetailsResponse> => {
  return axios({
    method: "GET",
    baseURL: config.marqtuneBaseURL,
    url: `${config.endpoints.marqtune.datasets}/${datasetId}`,
    params: { accountId },
  });
};

const getDatasetLogs = (accountId: AccountId, datasetId: string): AxiosPromise<LogsState["logs"]> => {
  return axios({
    method: "GET",
    baseURL: config.marqtuneBaseURL,
    url: `${config.endpoints.marqtune.datasets}/${datasetId}/logs`,
    params: { accountId },
  });
};

const getDatasetLogsUrl = (accountId: AccountId, datasetId: string): AxiosPromise<MarqtuneGetDownloadLinkResponse> => {
  return axios({
    method: "GET",
    baseURL: config.marqtuneBaseURL,
    url: `${config.endpoints.marqtune.datasets}/${datasetId}/logs/url`,
    params: { accountId },
  });
};

/***
 * MODELS
 */

const listModels = (accountId: AccountId): AxiosPromise<MarqtuneModelListResponse> => {
  return axios({
    method: "GET",
    baseURL: config.marqtuneBaseURL,
    url: config.endpoints.marqtune.models,
    params: { accountId },
  });
};

const getModelDetails = (accountId: AccountId, modelId: string): AxiosPromise<MarqtuneModelDetailsResponse> => {
  return axios({
    method: "GET",
    baseURL: config.marqtuneBaseURL,
    url: `${config.endpoints.marqtune.models}/${modelId}`,
    params: { accountId },
  });
};

const releaseCheckpoint = (
  accountId: AccountId,
  modelId: string,
  releasedCheckpoint: string,
): AxiosPromise<MarqtuneModelDetailsResponse> => {
  return axios({
    method: "POST",
    baseURL: config.marqtuneBaseURL,
    url: `${config.endpoints.marqtune.models}/${modelId}/released-checkpoints/${releasedCheckpoint}`,
    params: { accountId },
  });
};

const getModelCheckpointUrl = (
  accountId: AccountId,
  modelId: string,
  checkpoint: string,
): AxiosPromise<MarqtuneGetDownloadLinkResponse> => {
  return axios({
    method: "GET",
    baseURL: config.marqtuneBaseURL,
    url: `${config.endpoints.marqtune.models}/${modelId}/download/url?checkpoint=${checkpoint}`,
    params: { accountId },
  });
};

const getModelLogs = (accountId: AccountId, modelId: string): AxiosPromise<LogsState["logs"]> => {
  return axios({
    method: "GET",
    baseURL: config.marqtuneBaseURL,
    url: `${config.endpoints.marqtune.models}/${modelId}/logs`,
    params: { accountId },
  });
};

const getModelLogsUrl = (accountId: AccountId, modelId: string): AxiosPromise<MarqtuneGetDownloadLinkResponse> => {
  return axios({
    method: "GET",
    baseURL: config.marqtuneBaseURL,
    url: `${config.endpoints.marqtune.models}/${modelId}/logs/url`,
    params: { accountId },
  });
};

const deleteModel = (accountId: AccountId, modelId: string): AxiosPromise<MarqtuneDeleteResourceResponse> => {
  return axios({
    method: "DELETE",
    baseURL: config.marqtuneBaseURL,
    url: `${config.endpoints.marqtune.models}/${modelId}`,
    params: { accountId },
  });
};

/***
 * EVALUATIONS
 */

const listEvaluations = (accountId: AccountId): AxiosPromise<MarqtuneEvaluationListResponse> => {
  return axios({
    method: "GET",
    baseURL: config.marqtuneBaseURL,
    url: config.endpoints.marqtune.evaluations,
    params: { accountId },
  });
};

const getEvaluationDetails = (
  accountId: AccountId,
  evaluationId: string,
): AxiosPromise<MarqtuneEvaluationDetailsResponse> => {
  return axios({
    method: "GET",
    baseURL: config.marqtuneBaseURL,
    url: `${config.endpoints.marqtune.evaluations}/${evaluationId}`,
    params: { accountId },
  });
};

const getEvaluationLogs = (accountId: AccountId, evaluationId: string): AxiosPromise<LogsState["logs"]> => {
  return axios({
    method: "GET",
    baseURL: config.marqtuneBaseURL,
    url: `${config.endpoints.marqtune.evaluations}/${evaluationId}/logs`,
    params: { accountId },
  });
};

const getEvaluationLogsUrl = (
  accountId: AccountId,
  evaluationId: string,
): AxiosPromise<MarqtuneGetDownloadLinkResponse> => {
  return axios({
    method: "GET",
    baseURL: config.marqtuneBaseURL,
    url: `${config.endpoints.marqtune.evaluations}/${evaluationId}/logs/url`,
    params: { accountId },
  });
};

const deleteEvaluation = (accountId: AccountId, evaluationId: string): AxiosPromise<MarqtuneDeleteResourceResponse> => {
  return axios({
    method: "DELETE",
    baseURL: config.marqtuneBaseURL,
    url: `${config.endpoints.marqtune.evaluations}/${evaluationId}`,
    params: { accountId },
  });
};

const MarqtuneApi = {
  requestAccess,
  datasets: {
    all: listDatasets,
    details: getDatasetDetails,
    logs: getDatasetLogs,
    logsUrl: getDatasetLogsUrl,
    deleteDataset,
  },
  models: {
    all: listModels,
    details: getModelDetails,
    logs: getModelLogs,
    logsUrl: getModelLogsUrl,
    checkpointUrl: getModelCheckpointUrl,
    deleteModel,
    releaseCheckpoint,
  },
  evaluations: {
    all: listEvaluations,
    details: getEvaluationDetails,
    logs: getEvaluationLogs,
    logsUrl: getEvaluationLogsUrl,
    deleteEvaluation,
  },
};

export default MarqtuneApi;
