import { IndexItemRes } from "../../../api/indexes/indexes.types";

export type IndexStatsType = {
  dataSize: number;
  docCount: number;
  indexPercentage: number;
  vectorCount: number;
};

export type IndexItem = IndexItemRes & {
  // stats
  isFetchingStats: boolean;
  stats: IndexStatsType | null;
};

export enum TitleCategories {
  GEN_INFO = "General Information",
  STORAGE = "Storage",
  INFERENCE = "Inference",
}

export type IndexOverviewPropType = {
  id: string;
  value: string;
  title: string;
  category?: TitleCategories;
};

export const ACCOUNT_LEVEL_METRICS_TAB = "accountLevelMetrics";
export const OVERVIEW_TAB = "overview";
export const MONITOR_TAB = "monitor";
export type IndexOverviewTabValueType = typeof OVERVIEW_TAB | typeof MONITOR_TAB | typeof ACCOUNT_LEVEL_METRICS_TAB;

export enum MetricOperationTypes {
  primaryShardCount = "PRIMARY_SHARD_COUNT",
  replicaShardCount = "REPLICA_SHARD_COUNT",
  docCount = "DOC_COUNT",
  dataSize = "DATA_SIZE",
  totalSearchCount = "TOTAL_SEARCH_COUNT",
  doc = "doc",
  searchLatency = "search_processing_time",
  indexingLatency = "index_processing_time",
  // account level metrics
  error400 = "4XX",
  error500 = "5XX",
  rps = "RequestCount",
  latency = "TargetResponseTime",
}

export type MetricItemType = {
  Timestamp: string;
  SampleCount?: number;
  Average?: number;
  Sum?: number;
  Minimum?: number;
  Maximum?: number;
  Unit?: string;
};

export type GetMetricsBatchType = {
  indexName: string;
  dataTypes: MetricOperationTypes[];
  dateStart: string;
  dateEnd: string;
};

export type StatParamFormatter = (val: number, version?: string) => string;

export type StatsParamUtilProps = {
  [statParam: string]: {
    displayName: string;
    formatter: StatParamFormatter;
  };
};
