import { Box, Container, Grid, Theme, Typography, useMediaQuery } from "@mui/material";
import classNames from "classnames";
import React from "react";
import { Helmet } from "react-helmet-async";
import { FontClasses } from "../../constants/styles";
import MarqoLogo, { LogoVariant } from "../logo/marqo-logo";
import { AuthBaseClasses } from "./auth.styles";

type AuthBaseProps = {
  formDisplayTitle: string;
  pageTitle?: string;
  wrapperClassName?: string;
  extraWrapperClasses?: string;
  customMdSize?: number;
  disableMarqoLink?: boolean;
};

const AuthBase: React.FC<AuthBaseProps> = (props): JSX.Element => {
  const { children, formDisplayTitle } = props;
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"), { noSsr: true });
  const classes = AuthBaseClasses();
  const fontClasses = FontClasses();

  const getInlineStyles = () => {
    return !mdUp ? { borderRadius: "10px" } : {};
  };

  return (
    <Box>
      <Helmet>
        <title>{!!props?.pageTitle ? props.pageTitle : formDisplayTitle} | Marqo </title>
      </Helmet>
      <Box className={classes.authBoxMain} component="main">
        <Container className={classes.authContainer}>
          <Grid
            container
            item
            xs={12}
            sm={10}
            md={9}
            lg={9}
            xl={9}
            flexDirection={"row"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            {/* auth main section */}

            <Grid
              container
              item
              className={classNames(
                classes.authMainSectionWrapper,
                !!props?.extraWrapperClasses ? props.extraWrapperClasses : "",
              )}
              flexGrow={1}
              pt={4}
              justifyContent={"center"}
              sx={getInlineStyles()}
              xs={12}
              sm={9}
              md={6}
              lg={6}
            >
              <Grid container item justifyContent={"center"}>
                <MarqoLogo variant={LogoVariant.ALL_MIDNIGHT_BLUE} />
              </Grid>

              {!!formDisplayTitle && (
                <Grid container item flexDirection={"row"} alignItems={"center"} justifyContent={"center"} p={6} pb={0}>
                  <Typography
                    color="neutral.900"
                    textAlign={"center"}
                    sx={{ fontWeight: "400", fontSize: "1.2em" }}
                    className={fontClasses.poppins}
                  >
                    {formDisplayTitle}
                  </Typography>
                </Grid>
              )}

              {/* form */}

              <Box sx={{ flexGrow: 1 }} className={classes.authForm}>
                {children}
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};

export default AuthBase;
