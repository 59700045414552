import PropTypes from "prop-types";
import { createContext, useEffect, useState } from "react";
import React from "react";

export type TSettings = {
  direction: string;
  responsiveFontSizes: boolean;
  theme: "dark" | "light";
};

type TSettingsContextDefault = {
  settings: TSettings;
  saveSettings: (settings: TSettings) => void;
};

const initialSettings: TSettings = {
  direction: "ltr",
  responsiveFontSizes: true,
  theme: "dark",
};

export const restoreSettings = (): TSettings => {
  let settings: TSettings | null = null;
  try {
    const storedData: string = window.localStorage.getItem("settings");

    if (storedData) {
      settings = JSON.parse(storedData);
    } else {
      settings = {
        direction: "ltr",
        responsiveFontSizes: true,
        theme: "dark",
      };
    }
  } catch (err) {
    // TODO: show err on errortoast
  }

  return settings;
};

export const storeSettings = (settings: TSettings): void => {
  window.localStorage.setItem("settings", JSON.stringify(settings));
};

export const SettingsContext = createContext<TSettingsContextDefault>({
  settings: initialSettings,
  saveSettings: () => {},
});

export const SettingsProvider: React.FC = (props): JSX.Element => {
  const { children } = props;
  const [settings, setSettings] = useState<TSettings>(initialSettings);

  useEffect(() => {
    const restoredSettings = restoreSettings();
    if (restoredSettings) {
      setSettings(restoredSettings);
    }
  }, []);

  const saveSettings = (updatedSettings: TSettings): void => {
    setSettings(updatedSettings);
    storeSettings(updatedSettings);
  };

  return (
    <SettingsContext.Provider
      value={{
        settings,
        saveSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SettingsConsumer = SettingsContext.Consumer;
