import { Fragment, useCallback, useEffect } from "react";
import { batch } from "react-redux";
import ContentLayout from "../../components/content-layout/content-layout.component";
import MarqtuneDatasetsSection from "../../components/page-parts/marqtune/root/datasets-section.component";
import MarqtuneEvaluationsSection from "../../components/page-parts/marqtune/root/evaluations-section.component";
import MarqtuneModelsSection from "../../components/page-parts/marqtune/root/models-section.component";
import RequestMarqtuneAccess from "../../components/page-parts/marqtune/root/request-access.component";
import { useDispatch, useSelector } from "../../store";
import {
  listMarqtuneDatasetsThunk,
  listMarqtuneEvaluationsThunk,
  listMarqtuneModelsThunk,
} from "../../thunks/marqtune.thunk";

const MarqtunePage = () => {
  const dispatch = useDispatch();
  const { withMarqtune, withMarqtuneUI, isGuest } = useSelector(({ user }) => user.data);

  const initRootPage = useCallback(() => {
    if (withMarqtune && withMarqtuneUI) {
      batch(async () => {
        dispatch(listMarqtuneDatasetsThunk());
        dispatch(listMarqtuneEvaluationsThunk());
        dispatch(listMarqtuneModelsThunk());
      });
    }
  }, [withMarqtune, withMarqtuneUI, dispatch]);

  useEffect(initRootPage, [initRootPage]);

  return (
    <ContentLayout pageTitle={"Marqtune"} headTitle={"Marqtune"}>
      {withMarqtune && withMarqtuneUI && (
        <Fragment>
          <MarqtuneDatasetsSection />
          <MarqtuneModelsSection />
          <MarqtuneEvaluationsSection />
        </Fragment>
      )}
      {!withMarqtune && <RequestMarqtuneAccess />}
    </ContentLayout>
  );
};

export default MarqtunePage;
