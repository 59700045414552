import * as Yup from "yup";
import { FormConfigType } from "../../components/form/form.types";
import {
  getAccountLimitReachedMsg,
  inferencePodsTooltipTitle,
  replicasTooltipTitle,
  shardsTooltipTitle,
} from "../../components/page-parts/indexes/index-form-tooltips";
import { DISALLOW_GT2_GPU_CPU_LARGE_BASIC_MSG } from "../../constants";
import { CPU_SMALL, IndexWorkflowsInferenceMapping } from "../../constants/enums";
import { constructRequiredNumberValidation } from "../../constants/validation";
import { AccountLimits } from "../../utils/indices";
import { FormValuesExtended } from "../types";
import { commonInputPropValues, defaultCardSizes } from "./common";
import { getInferencePodTypeOptions, getStorageShardTypeOptions } from "./options";
import { ModifyIndexFormProps } from "./types";
import IndexFormValidators from "./validators";

export type ModifyIndexFormConfigProps = {
  canResize: boolean;
  disableReplicas: boolean;
  mappedStorage: string;
  currNumShards: number;
  currNumReplicas: number;
  currNumInfPods: number;
  currInferenceType: string;
  accountLimits: AccountLimits;
  storagePrices: any;
  inferencePrices: any;
};

export const editIndexFormConfig = ({
  canResize,
  disableReplicas,
  mappedStorage,
  currNumShards,
  currNumReplicas,
  currNumInfPods,
  currInferenceType,
  accountLimits,
  storagePrices,
  inferencePrices,
}: ModifyIndexFormConfigProps): FormConfigType => {
  const storageOptions = getStorageShardTypeOptions(storagePrices);
  const mappedInference =
    IndexWorkflowsInferenceMapping[currInferenceType as keyof typeof IndexWorkflowsInferenceMapping];
  const inferenceOptions = getInferencePodTypeOptions(inferencePrices);

  return {
    fields: {
      inferencePodType: {
        label: "Inference pod type",
        type: "card-select",
        options: inferenceOptions,
        initialValue: mappedInference || inferenceOptions[0].value,
        ...defaultCardSizes,
        validation: {
          rules: {},
          validate: Yup.string()
            .test(
              "selected-option",
              "Option selected is not valid",
              (val) => val === CPU_SMALL || inferenceOptions.some(({ value }) => value === val),
            )
            .required("Required"),
        },
      },
      storageShardType: {
        label: `Storage shard type${canResize && " (editing available soon)"}`,
        type: "card-select",
        options: storageOptions,
        initialValue: mappedStorage, // marqo.basic
        ...defaultCardSizes,
        componentProps: {
          fullWidth: false,
          disabled: true,
          editable: false,
        },
        hidden: !canResize,
        validation: {
          rules: {},
          validate: Yup.string()
            .test("selected-option", "Option selected is not valid", (val) =>
              storageOptions.some(({ value }) => value === val),
            )
            .test("storage-config", DISALLOW_GT2_GPU_CPU_LARGE_BASIC_MSG, (val, formValues) =>
              IndexFormValidators.storageShardType.validator(
                val,
                (formValues as FormValuesExtended<ModifyIndexFormProps>).from[0].value,
              ),
            )
            .required("Required"),
        },
        disableToastMsg: "Editing storage shard type will be available soon",
      },
      numberOfShards: {
        label: "Number of shards",
        initialValue: 0,
        validation: {
          rules: {},
          validate: constructRequiredNumberValidation(
            "Number of shards",
            Math.max(currNumShards, accountLimits.shard.min),
            accountLimits.shard.max,
          ),
        },
        ...commonInputPropValues(currNumShards),
        hidden: !canResize,
        extendedErrorMsg: getAccountLimitReachedMsg("shard", accountLimits.shard.max),
        tooltipTitle: shardsTooltipTitle,
      },
      numberOfReplicas: {
        label: "Number of replicas",
        initialValue: currNumReplicas,
        validation: {
          rules: {},
          validate: constructRequiredNumberValidation(
            "Number of replicas",
            Math.max(currNumReplicas, accountLimits.replica.min),
            accountLimits.replica.max,
          ).test("must-be-zero-for-basic-storage", "Replicas must be 0 for basic storage", (val, formValues) =>
            IndexFormValidators.numberOfReplicas.isReplicaZeroForBasicStorage(
              val,
              (formValues as FormValuesExtended<ModifyIndexFormProps>).from[0].value.storageShardType,
            ),
          ),
        },
        ...commonInputPropValues(currNumReplicas, disableReplicas),
        hidden: !canResize,
        extendedErrorMsg: getAccountLimitReachedMsg("replica", accountLimits.replica.max),
        tooltipTitle: replicasTooltipTitle,
      },
      numberOfInferencePods: {
        label: "Number of inference pods",
        initialValue: currNumInfPods,
        validation: {
          rules: {},
          validate: constructRequiredNumberValidation(
            "Number of inference pods",
            accountLimits.inference.min,
            accountLimits.inference.max,
          ),
        },
        ...commonInputPropValues(accountLimits.inference.min),
        extendedErrorMsg: getAccountLimitReachedMsg("inference pod", accountLimits.inference.max),
        tooltipTitle: inferencePodsTooltipTitle,
      },
      // action btn
      submit: {
        hidden: true,
        initialValue: false,
      },
    },
  };
};
