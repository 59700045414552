import { Box, Container, Link, SxProps, Theme } from "@mui/material";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import Config from "../../config";
import { useBreadcrumbs } from "../../hooks/use-breadcrumbs";
import { useSelector } from "../../store";
import { selectCurrentAccountId } from "../../store/selectors";
import InvitationBanner from "../invitation-banner/invitation-banner.component";
import GuestBanner from "../page-parts/billing/guest-banner.component";

export const LinkRouter: React.FC<any> = ({ variant = "h6", to, ...props }): JSX.Element => (
  <Link {...props} component={NavLink} to={to} variant={variant} />
);

type ContentLayoutProps = {
  headTitle?: string;
  pageTitle?: string;
  rootSx?: SxProps<Theme>;
  containerSx?: SxProps<Theme>;
  hideGuestBanner?: boolean;
};

const ContentLayout: React.FC<ContentLayoutProps> = (props): JSX.Element => {
  const { setPageTitle } = useBreadcrumbs();
  const location = useLocation();
  const { headTitle, pageTitle, containerSx, hideGuestBanner, children } = props;
  const currentAccountId = useSelector(selectCurrentAccountId);
  const showBanner = !hideGuestBanner && !currentAccountId;

  useEffect(() => {
    setPageTitle(pageTitle);
  }, [pageTitle]);

  return (
    <Box
      component="main"
      sx={(theme) => ({
        flexGrow: 1,
        pt: 6,
        px: 4,
        mb: 2,
        [theme.breakpoints.down("lg")]: {
          px: 0,
          my: 2,
          mx: 0,
          pt: 2,
        },
      })}
    >
      {headTitle && (
        <Helmet>
          <title>{headTitle} | Marqo</title>
        </Helmet>
      )}

      <Container maxWidth="xl" sx={containerSx}>
        {showBanner && <GuestBanner />}
        {location.pathname !== Config.authenticatedPaths.accountInvitations && <InvitationBanner />}
        {children}
      </Container>
    </Box>
  );
};

export default ContentLayout;
