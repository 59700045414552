import { EmailRounded, LockRounded } from "@mui/icons-material";
import { renderInputStartAdornment } from "../../components/form/form.component";

export const emailComponentProps = (disabled = false, hidden = false) => {
  return {
    componentProps: {
      placeholder: "Email",
      autoComplete: "username email",
      disabled,
      hidden,
      ...renderInputStartAdornment(<EmailRounded fontSize={"small"} />),
    },
  };
};
export const passwordComponentProps = (placeholder = "Password", disabled = false) => {
  return {
    componentProps: {
      placeholder: placeholder,
      autoComplete: "current-password",
      disabled,
      ...renderInputStartAdornment(<LockRounded fontSize={"small"} />),
    },
  };
};
