import { Box, CircularProgress, Grid } from "@mui/material";
import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { AcceptInviteFormData } from "../../../api/settings/settings.types";
import AuthBase from "../../../components/authentication/auth-base.component";
import { AuthBaseClasses } from "../../../components/authentication/auth.styles";
import { AuthButton } from "../../../components/button/auth-button.component";
import Form, { getFormConfig } from "../../../components/form/form.component";
import { AlreadyHaveAnAccountQuickLink } from "../../../components/page-parts/quick-links/quick-links";
import { GoogleButton } from "../../../components/social-buttons";
import config from "../../../config";
import { acceptInviteFormConfig } from "../../../form-configs/members.forms";
import { useAuth } from "../../../hooks/use-auth";
import { setToast } from "../../../slices/app";
import { setInvitation } from "../../../slices/settings";
import { useDispatch, useSelector } from "../../../store";
import { acceptInviteThunk, getInvitationMethodThunk } from "../../../thunks/members.thunk";
import { generateRandomKey } from "../../../utils";
import { getQueryParam } from "../../../utils/locationUtils";
import { JSONCookieStorage } from "../../../utils/services/storage";

type InviteContextProps = {
  method: string;
  isValid: boolean;
  email: string;
};
const InviteContextStorage = new JSONCookieStorage<InviteContextProps>(config.inviteCookieName);

const AcceptInvitePage = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();
  const classes = AuthBaseClasses();
  const { isAccepted, acceptInviteLoading } = useSelector((state) => state.settings.teamMembers);
  const {
    isValid,
    method: invitationMethod,
    isLoading: isLoadingInvitation,
  } = useSelector((state) => state.settings.invitation);
  const { isAuthenticated, authChecking } = useAuth();
  const [hidePwFields, setHidePwFields] = useState(true);

  const invalidHandler = (msg = "Invitation is invalid") => {
    dispatch(
      setToast({
        msg,
        type: "error",
        hash: generateRandomKey(6),
      }),
    );
    dispatch(
      setInvitation({
        method: null,
        isValid: false,
      }),
    );
    InviteContextStorage.remove();
    navigate(config.publicPaths.login);
  };

  const formik = useFormik({
    ...getFormConfig<AcceptInviteFormData>(acceptInviteFormConfig(hidePwFields)),
    onSubmit: async (formData, _) => {
      dispatch(acceptInviteThunk(formData));
    },
  });

  const searchChangeHandler = (code: string): void => {
    try {
      const ctx = InviteContextStorage.get();
      const { email: ctxEmail, method: ctxMethod } = ctx;
      if (!!ctxEmail && ctxMethod === "GOOGLE_SSO" && code) {
        formik.setFieldValue("email", ctx.email);
        formik.setFieldValue("credentials", code);
        formik.submitForm();
      } else {
        invalidHandler();
      }
    } catch (e) {
      // cannot destructure property email of ctx as it is null
      // TODO: This is hacky; Should restructure App.tsx
      location.search = "";
      location.pathname = config.publicPaths.login;
      navigate("/");
      invalidHandler();
    }
  };

  useEffect(() => {
    if (formik.submitCount && !acceptInviteLoading) {
      InviteContextStorage.remove();

      if (isAccepted !== null) {
        navigate(config.publicPaths.login);
      }
    }
    // eslint-disable-next-line
  }, [formik.submitCount, acceptInviteLoading, isAccepted]);

  useEffect(() => {
    if (!isAuthenticated && !authChecking && !acceptInviteLoading && !isLoadingInvitation && !!params?.email) {
      const email = params?.email || "";
      formik.setFieldValue("email", email);
      dispatch(getInvitationMethodThunk(email));
    }
    // eslint-disable-next-line
  }, [params?.email]);

  useEffect(() => {
    if (invitationMethod === "UNINVITED") {
      invalidHandler();
    } else if (invitationMethod === "GOOGLE_SSO") {
      InviteContextStorage.set({
        method: invitationMethod,
        email: formik.values.email,
        isValid,
      });
      setHidePwFields(true);
    } else if (invitationMethod === "EMAIL_PW") {
      InviteContextStorage.remove();
      setHidePwFields(false);
    }
    // eslint-disable-next-line
  }, [invitationMethod]);

  useEffect(() => {
    if (!!location?.search) {
      const code = getQueryParam(location.search, "code");
      if (!!code) searchChangeHandler(code);
    }
    // eslint-disable-next-line
  }, [location?.search]);

  return (
    <AuthBase formDisplayTitle="" pageTitle={"Accept Invite"} extraWrapperClasses={"borderRadiusAll"}>
      <Form formik={formik} spacing={2} formConfig={acceptInviteFormConfig(hidePwFields)}>
        <Box className={classes.customFormElementsWrapper}>
          {isLoadingInvitation || !isValid ? (
            <Grid container height={"30vh"} justifyContent={"center"} alignContent={"center"}>
              <CircularProgress />
            </Grid>
          ) : (
            <Fragment>
              {hidePwFields ? (
                <Box mb={4}>
                  <GoogleButton
                    authPath={config.publicPaths.acceptInvite}
                    btnTextPrefix={"Join"}
                    intent={"accept_invite"}
                  />
                </Box>
              ) : (
                <Fragment>
                  {AlreadyHaveAnAccountQuickLink}
                  <AuthButton
                    isDisabled={formik.isSubmitting || !!Object.keys(formik.errors).length}
                    label={"Accept invite"}
                  />
                </Fragment>
              )}
            </Fragment>
          )}
        </Box>
      </Form>
    </AuthBase>
  );
};

export default AcceptInvitePage;
