import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getNeutralRgb } from "../theme/dark-theme-options";

export const ellipsisStyles = {
  textOverflow: "ellipsis",
  overflow: "hidden",
  whiteSpace: "nowrap",
};

export const FontClasses = makeStyles((theme: Theme) => ({
  poppins: {
    fontFamily: '"Poppins", sans-serif !important',
  },
}));

export const leanScrollbarStyles = (appliedToSnippets: boolean) => {
  const scrollbarBgColor = getNeutralRgb(appliedToSnippets ? "900" : "800");
  return {
    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
      backgroundColor: scrollbarBgColor,
    },
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
      borderRadius: 8,
      backgroundColor: getNeutralRgb("600"),
      minHeight: 24,
      border: `5px solid ${scrollbarBgColor}`,
    },
    "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
      backgroundColor: getNeutralRgb("300"),
    },
    "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
      backgroundColor: getNeutralRgb("300"),
    },
    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
      backgroundColor: getNeutralRgb("300"),
    },
    "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
      backgroundColor: getNeutralRgb("800"),
    },
  };
};

export const WrapperClasses = makeStyles((theme: Theme) => ({
  leanSnippetScrollbar: {
    "& .MuiTabs-scroller, & pre": {
      overflowX: "auto !important",
      width: "100%",

      ...leanScrollbarStyles(true),
    },
  },
  directLeanScrollbar: {
    overflowX: "auto",
    ...leanScrollbarStyles(false),
  },
}));

export const TextTransformClasses = makeStyles((theme: Theme) => ({
  capitalize: {
    textTransform: "capitalize",
  },
}));

export const PageStyles = makeStyles((theme: Theme) => ({
  tableCell: {
    "&.MuiTableCell-root": {
      verticalAlign: "middle",
      overflowX: "hidden",
      maxWidth: "9rem",
      minWidth: "9rem",
    },

    "& .MuiTypography-root": {
      fontSize: "0.875rem",
    },

    "& .MuiGrid-root.noWrap": {
      flexWrap: "nowrap",
    },

    "&.TableCell-Wide": {
      minWidth: "12rem",
      maxWidth: "12rem",
    },

    "&.TableCell-Narrow": {
      minWidth: "7em",
      maxWidth: "7em",
    },
  },
}));
