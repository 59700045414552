import { Button, DialogActions, DialogContent, Grid, TextField, Theme, Typography, useMediaQuery } from "@mui/material";
import React, { useState } from "react";
import { buttonClasses } from "../button/button.styles";
import { StyledDialog } from "./prompt-dialog.styles";

type Props = {
  resourceName: string;
  accountName: string;
  agreeHandler: () => void;
  disagreeHandler: () => void;
  openDeletePrompt: boolean;
};

const DeletePrompt: React.FC<Props> = (props): JSX.Element => {
  const { resourceName, accountName, agreeHandler, openDeletePrompt, disagreeHandler } = props;
  const btnClasses = buttonClasses();
  const xsOnly = useMediaQuery((theme: Theme) => theme.breakpoints.only("xs"), { noSsr: true });
  const [confirmInput, setConfirmInput] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmInput(e.target.value);
  };

  const handleOnCancel = () => {
    setConfirmInput("");
    disagreeHandler();
  };

  const handleOnAgree = () => {
    setConfirmInput("");
    agreeHandler();
  };

  const promptDialogMsg = (
    <Grid container spacing={2} gap={2} {...(xsOnly ? { px: 2, pt: 2 } : { px: 4, pt: 4 })}>
      <Typography>
        You are about to permanently delete{" "}
        <span style={{ color: "neutral.100", fontWeight: "bold" }}>{resourceName}</span>
        {!accountName ? null : (
          <>
            {" "}
            from account{" "}
            <span style={{ color: "neutral.100", fontWeight: "bold" }}>{accountName || "<unnamed account>"}</span>
          </>
        )}
        .
      </Typography>
      <Typography>
        Type <span style={{ color: "neutral.100", fontWeight: "bold" }}>{resourceName}</span> to confirm.
      </Typography>
      <TextField value={confirmInput} onChange={handleChange} size="small" fullWidth />
    </Grid>
  );

  return (
    <StyledDialog
      open={openDeletePrompt}
      id={`delete-${resourceName}-prompt-dialog`}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent sx={{ marginLeft: "10px" }}>{promptDialogMsg}</DialogContent>
      <DialogActions>
        <Grid container justifyContent={"flex-end"} columnGap={2} {...(xsOnly ? { pr: 4, pb: 2 } : { pr: 6, pb: 4 })}>
          <Button variant={"outlined"} color={"inherit"} onClick={handleOnCancel} id={`prompt-dialog-no`} autoFocus>
            Cancel
          </Button>
          <Button
            variant={"contained"}
            disabled={confirmInput !== resourceName}
            className={`${btnClasses.dangerButton} ${btnClasses.deleteButton}`}
            onClick={handleOnAgree}
            id={`prompt-dialog-yes`}
          >
            Submit
          </Button>
        </Grid>
      </DialogActions>
    </StyledDialog>
  );
};

export default DeletePrompt;
