import { MoreVertRounded } from "@mui/icons-material";
import { Grid, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import { maxWidth, padding } from "@mui/system";
import classNames from "classnames";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { MarqtuneModel } from "../../../../api/marqtune/types";
import config from "../../../../config";
import { ellipsisStyles } from "../../../../constants/styles";
import { setMarqtuneModelsPage } from "../../../../slices/marqtune";
import { useSelector } from "../../../../store";
import { toHrMinConvention, toMonthDYr } from "../../../../utils/time-formatter";
import { LinkRouter } from "../../../content-layout/content-layout.component";
import CopyButton from "../../../copy-button/copy-button.component";
import { StatusChip } from "../chip.component";
import ReleaseCheckpointDialog from "../model-details/release-checkpoint.component";
import { MarqtuneModelActionPrompt } from "../prompts.component";
import { MarqtuneStyleClasses } from "../styles";
import { isReady } from "../utils";
import MarqtuneRootSection from "./marqtune-root-section.component";

const MarqtuneModelsSection = () => {
  const navigate = useNavigate();
  const classes = MarqtuneStyleClasses();
  const { list: data, isFetching, itemsPerPage, currentPage } = useSelector(({ marqtune }) => marqtune.models);
  const [selectedModel, setSelectedModel] = useState<MarqtuneModel>(null);
  const [actionsMenuAnchor, setActionsMenuAnchor] = useState(null);
  const [checkpointDialogOpen, setCheckpointDialogOpen] = useState(false);

  const handleOnClickCell = (modelId: string) => {
    navigate(`/marqtune/models/${modelId}`);
  };

  const handleActionsMenuClose = () => {
    setActionsMenuAnchor(null);
    setSelectedModel(null);
  };
  const handleReleaseClick = (item: MarqtuneModel) => {
    setSelectedModel(item);
    setCheckpointDialogOpen(true);
  };

  const handleOnClickActionMenu = (e: React.MouseEvent, item: MarqtuneModel) => {
    e.stopPropagation();
    setSelectedModel(item);
    setActionsMenuAnchor(e.currentTarget);
  };

  const handleDialogClose = () => {
    setSelectedModel(null);
    setCheckpointDialogOpen(false);
  };

  const renderTableRow = (rowItem: MarqtuneModel, index: number) => {
    const { modelName, modelId, baseModel, checkpoints, releasedCheckpoints, status, startedAt } = rowItem;
    const isReleased = releasedCheckpoints?.length > 0;
    const isReleaseAllowed = status === "Ready" && checkpoints?.length > 0 && !isReleased;

    return (
      <TableRow
        key={index}
        className={classNames(classes.centeredTableRowCells, classes.rootSectionTableRow, "ModelsSection-TableRow")}
      >
        {/* NAME */}
        <TableCell onClick={() => handleOnClickCell(modelId)}>
          <Grid container>
            <Typography variant={"subtitle2"} sx={ellipsisStyles}>
              {modelName}
            </Typography>
          </Grid>
        </TableCell>

        {/* ID */}
        <TableCell onClick={() => handleOnClickCell(modelId)}>
          <Grid container justifyContent={"center"} flexWrap={"nowrap"} gap={1} overflow={"hidden"}>
            <Typography variant={"subtitle2"} sx={ellipsisStyles}>
              {modelId}
            </Typography>
            <CopyButton text={modelId} label={"Model ID"} />
          </Grid>
        </TableCell>

        {/* BASE MODEL */}
        <TableCell onClick={() => handleOnClickCell(modelId)}>
          <Grid container justifyContent={"center"} flexWrap={"nowrap"} gap={1} overflow={"hidden"}>
            <Typography variant={"subtitle2"} sx={ellipsisStyles}>
              {baseModel}
            </Typography>
          </Grid>
        </TableCell>

        {/* released */}
        <TableCell onClick={!isReleaseAllowed ? () => handleOnClickCell(modelId) : undefined}>
          <Grid container justifyContent="center" flexWrap="nowrap" gap={1} overflow="hidden">
            {isReleaseAllowed ? (
              <LinkRouter to="#" className={classes.releaseLink} onClick={() => handleReleaseClick(rowItem)}>
                Release
              </LinkRouter>
            ) : (
              <Typography variant="subtitle2" sx={ellipsisStyles}>
                {isReleased ? "Yes" : "No"}
              </Typography>
            )}
          </Grid>
        </TableCell>

        {/* STARTED AT */}
        <TableCell onClick={() => handleOnClickCell(modelId)}>
          <Grid container justifyContent={"center"} flexDirection="column" gap={1} overflow={"hidden"}>
            <Typography textAlign={"center"}>{toMonthDYr(startedAt)}</Typography>
            <Typography color={"textSecondary"} textAlign={"center"}>
              {toHrMinConvention(startedAt)}
            </Typography>
          </Grid>
        </TableCell>

        {/* STATUS */}
        <TableCell onClick={() => handleOnClickCell(modelId)}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className="RowEndActionsWrapper"
            flexWrap="nowrap"
          >
            <Grid item>
              <StatusChip isReady={isReady(rowItem)} status={status} />
            </Grid>
            <Grid item>
              <IconButton
                sx={{ padding: "2px" }}
                onClick={(e: React.MouseEvent) => handleOnClickActionMenu(e, rowItem)}
              >
                <MoreVertRounded />
              </IconButton>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <MarqtuneRootSection
      tableHeaders={[
        { title: "MODEL NAME", id: "model-name" },
        { title: "MODEL ID", id: "model-id" },
        { title: "BASE MODEL", id: "model-base-model" },
        { title: "AVAILABLE FOR INDEX CREATION", id: "model-secondary-released" },
        { title: "DATE STARTED (UTC)", id: "model-started-at", order: { key: "startedAt", type: "date" } },
        { title: "STATUS", id: "model-secondary-status" },
      ]}
      setPageChangeAction={setMarqtuneModelsPage}
      data={data}
      isFetching={isFetching}
      itemsPerPage={itemsPerPage}
      currentPage={currentPage}
      confirmPopupConfigLabel={"model"}
      cardTitle={"Models"}
      contentSectionIdPrefix={"MarqtuneModels"}
      emptyListMsg={"No trained model found."}
      tableCellKeyPrefix={"models-section-cell"}
      topRightActionBtnConfig={{
        label: "Train Model",
        href: config.authenticatedPaths.marqtune.trainModel,
      }}
      filterPaths={["modelName", "modelId", "status", "secondaryStatus"]}
      setCurrentPage={setMarqtuneModelsPage}
      renderTableRow={renderTableRow}
    >
      <MarqtuneModelActionPrompt
        actionsMenuAnchor={actionsMenuAnchor}
        resourceName={selectedModel?.modelName}
        resourceId={selectedModel?.modelId}
        agreeHandler={handleActionsMenuClose}
        disagreeHandler={handleActionsMenuClose}
      />
      {selectedModel && (
        <ReleaseCheckpointDialog
          open={checkpointDialogOpen}
          modelName={selectedModel.modelName}
          modelId={selectedModel.modelId}
          checkpointList={selectedModel.checkpoints || []}
          onClose={handleDialogClose}
        />
      )}
    </MarqtuneRootSection>
  );
};

export default MarqtuneModelsSection;
