import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const dateTimePickerStyles = makeStyles((theme: Theme) => ({
  dateTimePicker: {
    "& .MuiPaper-root": {
      backgroundColor: theme.palette.background.default,
      color: theme.palette.primary.contrastText,
    },
    "& .MuiTabs-flexContainer": {
      backgroundColor: theme.palette.background.default,
    },
  },
}));
