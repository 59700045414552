import { AccountBalanceWallet, Sync } from "@mui/icons-material";
import { Button, Grid, Theme, Tooltip, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import { buttonClasses } from "../../components/button/button.styles";
import CardTitle from "../../components/card-title/card-title.component";
import ContentLayout from "../../components/content-layout/content-layout.component";
import ContentSection from "../../components/content-layout/content-section.component";
import BillingSummaryInfo from "../../components/page-parts/billing/billing-summary-section/billing-summary-main.component";
import UsageDetails from "../../components/page-parts/billing/billing-summary-section/usage-details.component";
import { BILLING_PORTAL_SECTION_INFO } from "../../constants";
import { useDispatch, useSelector } from "../../store";
import { hasStripeCardThunk } from "../../thunks/billing.thunk";
import { getBillingPortalURLThunk, getMonthToDateUsageThunk } from "../../thunks/stripe-integration.thunk";
import GuestBillingPage from "./billing-guest.page";

const Billing = () => {
  const [isPortalBtnClicked, setIsPortalBtnClicked] = useState(false);

  const { billingPortal } = useSelector(({ billing }) => billing.stripeIntegration);
  const { isGuest } = useSelector(({ user }) => user.data);
  const dispatch = useDispatch();

  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"), { noSsr: true });
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"), { noSsr: true });
  const btnClasses = buttonClasses();

  const handleViewBillingPortal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setIsPortalBtnClicked(true);
    dispatch(getBillingPortalURLThunk());
  };

  useEffect(() => {
    !isGuest && dispatch(getMonthToDateUsageThunk());
  }, []);

  useEffect(() => {
    if (billingPortal.url && isPortalBtnClicked) {
      window.open(billingPortal.url, "_blank");
      setIsPortalBtnClicked(false);
    }
  }, [billingPortal.url, isPortalBtnClicked]);

  if (isGuest) return <GuestBillingPage />;

  return (
    <ContentLayout pageTitle={"Billing"}>
      <ContentSection id={"BillingSummary-Section"}>
        <CardTitle noDivider title={"Billing Summary"} rootProps={{ justifyContent: "center" }} />
        <BillingSummaryInfo />
      </ContentSection>

      <ContentSection id={"UsageBreakdown-Section"}>
        <Grid container mb={2} alignItems={"center"} justifyContent={"space-between"} sx={mdUp ? {} : { gap: 2 }}>
          <Grid item>
            <CardTitle noDivider title={"Usage Breakdown"} rootProps={{ justifyContent: "center" }} />
          </Grid>

          <Grid item>
            <Tooltip title="Refresh billing summaries">
              <Button
                color={"secondary"}
                variant={"contained"}
                fullWidth={false}
                onClick={() => {
                  dispatch(getMonthToDateUsageThunk());
                  dispatch(hasStripeCardThunk());
                }}
              >
                <Sync />
              </Button>
            </Tooltip>
          </Grid>
        </Grid>

        <Grid container flexDirection={"row"} spacing={4}>
          <UsageDetails />
        </Grid>
      </ContentSection>

      <ContentSection id={"BillingPortal-Section"}>
        <Grid container mb={2} alignItems={"center"} justifyContent={"space-between"} sx={{ gap: 2 }}>
          <Grid container item xs={6}>
            <CardTitle noDivider title={"Billing Portal"} rootProps={{ justifyContent: "center" }} />
          </Grid>
          <Grid container item xs={5} gap={2} justifyContent={"flex-end"}>
            <Button
              onClick={handleViewBillingPortal}
              variant="contained"
              color="secondary"
              className={btnClasses.iconTextButton}
            >
              <AccountBalanceWallet />
              {smUp && (
                <span
                  style={{
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  View Billing Portal
                </span>
              )}
            </Button>
          </Grid>
        </Grid>

        <Grid container item>
          <Grid item mt={2}>
            <Typography variant={"body1"}>{BILLING_PORTAL_SECTION_INFO}</Typography>
          </Grid>
        </Grid>
      </ContentSection>
    </ContentLayout>
  );
};

export default Billing;
