import { Help } from "@mui/icons-material";
import {
  Alert,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeError, Token } from "@stripe/stripe-js";
import classNames from "classnames";
import { FormikProps } from "formik";
import React, { useState } from "react";
import { SignupByEmailForm } from "../../../../api/auth/auth.types";
import { useStripeOptions } from "../../../../hooks/use-stripeOptions";
import { buttonClasses } from "../../../button/button.styles";
import StripeLogo from "../../../logo/stripe-logo";
import { pricingCalculator, termsAndConditions } from "../../quick-links/quick-links";
import { stripeClasses } from "./stripe.styles";

type Props = {
  showTermsConditions?: boolean;
  showSkipButton?: boolean;
  showBackButton?: boolean;
  submittingMessage?: string;
  formik?: FormikProps<SignupByEmailForm>;
  backHandler?: () => void;
  submitHandler?: (token: Token) => void | Promise<void>;
  submitErrorHandler?: (tokenError: StripeError) => void;
};

const StripeBillingDetails: React.FC<Props> = (props) => {
  const showTermsConditions = props?.showTermsConditions ?? true;

  const stripe = useStripe();
  const elements = useElements();
  const stripeOptions = useStripeOptions();
  const stripeStyles = stripeClasses();
  const buttonStyles = buttonClasses();
  // local states
  const [cardError, setCardError] = useState("");
  const [showProgressLoader, setShowProgressLoader] = useState(true);
  const [hasAgreed, setHasAgreed] = useState(!(showTermsConditions ?? true));
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [isTouched, setIsTouched] = useState(false);

  // constants
  const NAME_REQUIRED = "Name is required.";
  const xsOnly = useMediaQuery((theme: Theme) => theme.breakpoints.only("xs"), { noSsr: true });
  const tcErrorMsg = "Please read and accept the terms and conditions.";

  const handleNameOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nameValue = e.target.value;
    setIsTouched(true);
    setName(nameValue);

    if (!!nameValue) {
      setNameError("");
    } else {
      setNameError(NAME_REQUIRED);
    }
  };

  const handleCheckboxToggle = () => {
    if (!hasAgreed && cardError === tcErrorMsg) {
      setCardError("");
    } else if (hasAgreed && cardError !== tcErrorMsg) {
      setCardError(tcErrorMsg);
    }
    setHasAgreed(!hasAgreed);
  };

  const handleSubmit = async (event: React.MouseEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    if (!hasAgreed) {
      setCardError(tcErrorMsg);
    }

    if (!stripe || !elements || !name || !hasAgreed) {
      return;
    }

    setShowProgressLoader(true);
    const cardElement = elements.getElement(CardNumberElement);
    const { error: tokenError, token } = await stripe.createToken(cardElement, { name });

    if (tokenError) {
      setCardError(tokenError.message);
      props.submitErrorHandler?.(tokenError);
      return;
    }

    setCardError("");
    try {
      await props.submitHandler?.(token);
    } catch (error) {
      // Ignore
    }
    setShowProgressLoader(false);
  };

  const handleSkip = async (): Promise<void> => {
    if (!hasAgreed) {
      setCardError(tcErrorMsg);
      return;
    }
    setShowProgressLoader(true);
    await props.formik.submitForm();
    setShowProgressLoader(false);
  };

  const handleCardChange = async (): Promise<void> => {
    setIsTouched(true);

    if (!stripe || !elements || !name || !hasAgreed) {
      return null;
    }

    const cardElement = elements.getElement(CardNumberElement);
    const { error: tokenError, token } = await stripe.createToken(cardElement, { name });

    setCardError(tokenError?.message ?? "");
  };

  const getSubmitBtnAddlClass = () => {
    return isTouched || !!nameError || !!cardError ? "touched" : "untouched";
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid container justifyContent={"center"} alignItems={"center"}>
        <Grid
          container
          item
          xs={12}
          md={10}
          p={3}
          rowGap={2}
          justifyContent={"center"}
          alignItems={"center"}
          // MUI makes this 83.3% on larger screens, messing up padding on smaller screens.
          style={{ maxWidth: "100%", flexBasis: "100%" }}
        >
          <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "0.5em" }}>
            <Typography variant={"h5"} sx={{ color: "neutral.950" }}>
              Enter your billing details
            </Typography>
            <Typography className={"form-subtitle"}>
              You will only be charged when you create an index or train a model with Marqtune.
            </Typography>
            <Typography className={"form-subtitle"}>
              Marqo Cloud is billed for what you use. Payments are processed monthly.
            </Typography>
            <Typography className={"form-subtitle"}>
              See the {pricingCalculator} and {termsAndConditions} for more information.
            </Typography>
            {props.showSkipButton && (
              <Typography className={"form-subtitle"}>
                You can skip adding your billing details for guest access to the console.{" "}
                <Tooltip
                  sx={{ width: "0.8em", height: "0.8em", margin: "-3px" }}
                  title="Billing details are required to create indexes on Marqo Cloud. Billing details can be added at any time after signing in."
                >
                  <Help />
                </Tooltip>
              </Typography>
            )}
          </div>

          <Grid container item rowGap={2}>
            <Grid item xs={12} className={stripeStyles.stripeCustomElementStyles}>
              <TextField
                fullWidth
                name={"nameOnCard"}
                placeholder={"Name on the card"}
                value={name}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNameOnChange(e)}
              />
              {!!nameError && <Typography className={"billing-form-error"}>{nameError}</Typography>}
            </Grid>
            <Grid item xs={12} className={stripeStyles.stripeCustomElementStyles}>
              <CardNumberElement
                onChange={handleCardChange}
                options={{ ...stripeOptions, placeholder: "Card number" }}
              />
            </Grid>
            <Grid container item xs={12} columnSpacing={3} rowSpacing={3}>
              <Grid item xs={12} sm={6} className={stripeStyles.stripeCustomElementStyles}>
                <CardExpiryElement
                  id={"cardExpiry"}
                  onChange={handleCardChange}
                  options={{ ...stripeOptions, placeholder: "Expiration" }}
                />
              </Grid>
              <Grid item xs={12} sm={6} className={stripeStyles.stripeCustomElementStyles}>
                {/* last one to load */}
                <CardCvcElement
                  onChange={handleCardChange}
                  onReady={() => setShowProgressLoader(false)}
                  options={stripeOptions}
                />
              </Grid>
            </Grid>

            <Grid container item alignItems={"center"} sx={{ marginTop: "-14px" }}>
              <Typography sx={{ color: "neutral.600", fontSize: "0.875rem" }}>Powered by </Typography>
              <StripeLogo />
            </Grid>

            {showTermsConditions && (
              <Grid container item xs={12} mb={2} alignItems={"baseline"} gap={xsOnly ? 2 : 0}>
                <Grid item xs={1}>
                  <Checkbox
                    size={"small"}
                    sx={{ padding: 0 }}
                    inputProps={{ "aria-label": "Terms and Conditions" }}
                    onChange={() => handleCheckboxToggle()}
                  />
                </Grid>
                <Grid item xs={10}>
                  <Typography className={"form-subtitle checkbox-msg"} display={"flex"} sx={{ alignSelf: "center" }}>
                    I agree to Marqo's {termsAndConditions}.
                  </Typography>
                </Grid>
              </Grid>
            )}
          </Grid>

          {showProgressLoader && (
            <Grid container item xs={12} justifyContent={"center"}>
              {props.submittingMessage && (
                <Grid container item xs={12} justifyContent={"center"} mb={2}>
                  <Typography sx={{ color: "neutral.500", textAlign: "center" }}>{props.submittingMessage}</Typography>
                </Grid>
              )}
              <Grid
                container
                item
                xs={12}
                height={"50px"}
                width={"200px"}
                flexDirection={"column"}
                justifyContent={"center"}
                alignSelf={"center"}
                alignItems={"center"}
              >
                <CircularProgress />
              </Grid>
            </Grid>
          )}

          {cardError && (
            <Grid item xs={12} sx={{ marginTop: "-1em", marginBottom: "0.5em" }}>
              <Alert severity={"error"}> {cardError} </Alert>
            </Grid>
          )}

          {!showProgressLoader && (
            <Grid container item xs={12} justifyContent={"flex-end"} gap={2}>
              {(props.showBackButton ?? true) && (
                <Button
                  className={buttonStyles.backButton}
                  disabled={!stripe || !elements}
                  onClick={props.backHandler}
                  variant={"outlined"}
                >
                  Back
                </Button>
              )}
              {(props.showSkipButton ?? true) && (
                <Button onClick={handleSkip} className={buttonStyles.skipButton}>
                  Skip
                </Button>
              )}
              <Button
                type="submit"
                color={"secondary"}
                className={classNames(getSubmitBtnAddlClass(), buttonStyles.submitButton)}
                disabled={!stripe || !elements || !hasAgreed || !!nameError || !!cardError}
                variant={"contained"}
              >
                Confirm
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </form>
  );
};
export default StripeBillingDetails;
