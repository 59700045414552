import React from "react";
import { StyledContentSection } from "./content-layout.styles";

type ContentSectionProps = {
  id: string;
  fullHeight?: boolean;
  mb?: number;
  sx?: Record<string, unknown>;
};

const ContentSection: React.FC<ContentSectionProps> = ({ id, children, sx, fullHeight, mb = 3 }): JSX.Element => {
  return (
    <StyledContentSection
      id={id}
      elevation={4}
      sx={{
        py: 3,
        px: 4,
        mb,
        backgroundColor: "background.contentCard",
        ...(fullHeight ? { height: "100%" } : {}),
        ...sx,
      }}
    >
      {children}
    </StyledContentSection>
  );
};

export default ContentSection;
