import { Grid } from "@mui/material";
import IndexDetailsUtils from "../../../../utils/indices/index-details-utils";
import CardTitle from "../../../card-title/card-title.component";
import ContentSection from "../../../content-layout/content-section.component";
import { DetailGridDisplay } from "../../marqtune/detail-grid-display.component";
import { IndexItem } from "../indexes.types";

type Props = {
  indexObj: IndexItem;
};

const IndexStatsSection = ({ indexObj }: Props) => {
  return (
    <ContentSection id={"IndexStats-Section"}>
      <Grid container flexDirection={"column"}>
        <CardTitle title={"Index Stats"} noDivider rootProps={{ justifyContent: "center" }} />
      </Grid>
      <Grid item container rowGap={2} mt={2}>
        {Object.entries(IndexDetailsUtils.indexStatsGridConfig(indexObj)).map(([_, { title, text, copyTitle }]) => {
          return (
            <DetailGridDisplay
              key={title}
              label={title}
              value={text}
              sizes={{ xs: 12, sm: 6, md: 3, xl: 2 }}
              withCopy={Boolean(copyTitle)}
            />
          );
        })}
      </Grid>
    </ContentSection>
  );
};

export default IndexStatsSection;
