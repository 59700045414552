import { ModelInfo } from "../../api/indexes/indexes.types";
import { Option, RadioGroupFieldProps } from "../../components/form-fields/select-field";
import { IndexWorkflowsInferenceMapping, IndexingMode, MarqoVersion, ModelModality } from "../../constants/enums";
import { getFormattedAmount } from "../../utils/billing";

export const INDEXING_MODE_OPTIONS: RadioGroupFieldProps["options"] = [
  { value: IndexingMode.TEXT, label: "Text-optimised" },
  { value: IndexingMode.IMAGE, label: "Image-compatible" },
  { value: IndexingMode.AUDIO_VIDEO, label: "Audio/Video-compatible" },
];

export const INDEX_TYPE_OPTIONS: RadioGroupFieldProps["options"] = [
  { value: "unstructured", label: "Unstructured" },
  { value: "structured", label: "Structured" },
];

function isAudioVideoRecommended(model: ModelInfo): boolean {
  return model.modality.includes(ModelModality.AUDIO) || model.modality.includes(ModelModality.VIDEO);
}
function isImageRecommended(model: ModelInfo): boolean {
  return (
    model.modality.includes(ModelModality.IMAGE) &&
    !model.modality.includes(ModelModality.AUDIO) &&
    !model.modality.includes(ModelModality.VIDEO)
  );
}
function isTextRecommended(model: ModelInfo): boolean {
  return (
    !model.modality.includes(ModelModality.IMAGE) &&
    !model.modality.includes(ModelModality.AUDIO) &&
    !model.modality.includes(ModelModality.VIDEO)
  );
}

export const getStorageShardTypeOptions = (storagePrices: any): Option[] => {
  return [
    {
      id: "marqo.basic",
      value: "marqo.basic",
      title: "marqo.basic",
      description: "Best for development and testing",
      subDescription: `${getFormattedAmount(storagePrices["marqo.basic"])} / hr`,
    },
    {
      id: "marqo.balanced",
      value: "marqo.balanced",
      title: "marqo.balanced",
      description: "Storage optimised",
      subDescription: `${getFormattedAmount(storagePrices["marqo.balanced"])} / hr`,
    },
    {
      id: "marqo.performance",
      value: "marqo.performance",
      title: "marqo.performance",
      description: "Performance optimised",
      subDescription: `${getFormattedAmount(storagePrices["marqo.performance"])} / hr`,
    },
  ];
};

const { GPU, CPU } = IndexWorkflowsInferenceMapping;

export const getInferencePodTypeOptions = (inferencePrices: any): Option[] => {
  return [
    {
      id: CPU,
      value: CPU,
      title: CPU,
      description: "Best for text data",
      subDescription: `${getFormattedAmount(inferencePrices[CPU])} / hr`,
    },
    {
      id: GPU,
      value: GPU,
      title: GPU,
      description: "Best for image data, highest throughput",
      subDescription: `${getFormattedAmount(inferencePrices[GPU])} / hr`,
    },
  ];
};
export const getModelOptions = (
  indexingMode: IndexingMode,
  modelList: ModelInfo[],
  version: MarqoVersion,
  classes: any,
): (Option & { isDivider?: boolean })[] => {
  if (!modelList || modelList.length === 0) {
    return [];
  }
  const createOptions = (type: string, label: string, addDivider: boolean): (Option & { isDivider?: boolean })[] => {
    const filteredModels =
      modelList
        .filter((model) => {
          if (!model.show) return false;
          if (model.type !== type) return false;
          if (indexingMode === IndexingMode.AUDIO_VIDEO) {
            return isAudioVideoRecommended(model);
          } else if (indexingMode === IndexingMode.IMAGE) {
            return isImageRecommended(model);
          } else {
            return isTextRecommended(model);
          }
        })
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((model) => ({
          id: model.name,
          value: model.name,
          title: model.name,
        })) || [];

    return filteredModels.length > 0
      ? [
          ...(addDivider ? [{ id: `${type}-divider`, title: label, isDivider: true, className: classes.divider }] : []),
          ...filteredModels,
        ]
      : [];
  };

  // Show Marqtuned models only if the index version is V2
  const marqtunedOptions = version === MarqoVersion.V2 ? createOptions("marqtuned", "Marqtuned models", true) : [];

  // Show Open Source Models, with a divider if Marqtuned models exist, without if they don't
  const opensourceOptions = createOptions("opensource", "Open source models", marqtunedOptions.length > 0);

  const spacer =
    marqtunedOptions.length > 0 ? [{ id: "spacer", title: "", isDivider: true, className: classes.dividerSpacer }] : [];

  return [...marqtunedOptions, ...spacer, ...opensourceOptions];
};

export const getMarqoVersionOptions = (withV1Indexes: boolean): Option[] => {
  if (withV1Indexes) {
    return Object.entries(MarqoVersion).map(([marqoVersionKey, marqoVersionValue]) => {
      return {
        id: marqoVersionKey,
        value: marqoVersionValue,
        title: marqoVersionValue,
      };
    });
  }

  return [{ id: "V1", value: MarqoVersion.V1, title: MarqoVersion.V1 }];
};
