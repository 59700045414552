import { MoreVertRounded } from "@mui/icons-material";
import { Box, Grid, IconButton, Theme, Typography, useMediaQuery } from "@mui/material";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { MarqtuneEvaluation } from "../../api/marqtune/types";
import CardTitle from "../../components/card-title/card-title.component";
import ContentLayout from "../../components/content-layout/content-layout.component";
import ContentSection from "../../components/content-layout/content-section.component";
import CopyButton from "../../components/copy-button/copy-button.component";
import FetchingDisplay from "../../components/fetching-display/fetching-display.component";
import { DetailGridDisplay } from "../../components/page-parts/marqtune/detail-grid-display.component";
import LogsViewer from "../../components/page-parts/marqtune/logs-viewer/logs-viewer.component";
import { liveLogsClasses } from "../../components/page-parts/marqtune/logs-viewer/logs.styles";
import { MarqtuneEvaluationActionPrompt } from "../../components/page-parts/marqtune/prompts.component";
import { DetailsConf } from "../../components/page-parts/marqtune/types";
import { getDisplayableStatus, isFinished } from "../../components/page-parts/marqtune/utils";
import config from "../../config";
import { WrapperClasses } from "../../constants/styles";
import { useBreadcrumbs } from "../../hooks/use-breadcrumbs";
import { ChevronDown } from "../../icons/chevron-down";
import { ChevronUp } from "../../icons/chevron-up";
import { useDispatch, useSelector } from "../../store";
import { selectEvaluationByEvaluationId } from "../../store/selectors";
import {
  downloadMarqtuneEvaluationLogsThunk,
  listMarqtuneEvaluationLogsThunk,
  listMarqtuneEvaluationsThunk,
} from "../../thunks/marqtune.thunk";
import { stringifyDictOrEmpty } from "../../utils/formatters";

const initialBreadcrumbs = [{ title: "Marqtune", link: "/marqtune" }];

type EvaluationParamsProps = {
  hyperparameters: MarqtuneEvaluation["hyperparameters"];
};

export const EvaluationParameters = ({ hyperparameters }: EvaluationParamsProps) => {
  const [expand, setExpand] = useState(true);
  const classes = liveLogsClasses();
  const wrapperClasses = WrapperClasses();
  const copiableParams = stringifyDictOrEmpty(hyperparameters);

  return (
    <Grid container gap={2} mt={4}>
      <Grid container item onClick={() => setExpand(!expand)} alignItems={"center"} gap={2}>
        <Typography variant="subtitle2">Evaluation Parameters</Typography>

        <Grid item>
          <IconButton sx={{ minWidth: "0", width: "min-content", padding: 0 }}>
            {expand ? <ChevronDown /> : <ChevronUp />}
          </IconButton>
        </Grid>
      </Grid>

      {expand && (
        <Grid
          container
          item
          data-testid={"EvaluationParameters-wrapper"}
          className={classNames(classes.wrapper, wrapperClasses.directLeanScrollbar)}
        >
          <span style={{ position: "relative", width: "100%" }}>
            <span style={{ position: "absolute", right: "0.5rem", top: "0.5rem" }}>
              <CopyButton text={copiableParams} label="Evaluation parameters" />
            </span>
          </span>
          <pre>{copiableParams}</pre>
        </Grid>
      )}
    </Grid>
  );
};

const evalDetailsConfig = (evaluationDetails: MarqtuneEvaluation): DetailsConf => ({
  evaluationId: {
    label: "Evaluation ID",
    value: evaluationDetails.evaluationId,
    sizes: { xs: 12, sm: 4, xl: 3 },
    withCopy: true,
  },
  model: {
    label: "Model",
    value: evaluationDetails.model,
    sizes: { xs: 12, sm: 4, xl: 3 },
    withCopy: true,
  },
  secondaryStatus: {
    label: "Status",
    value: getDisplayableStatus(evaluationDetails),
    sizes: { xs: 12, sm: 4, xl: 3 },
  },
  ...(evaluationDetails?.failureReason
    ? {
        failureReason: {
          label: "Failure reason",
          value: evaluationDetails.failureReason,
          sizes: { xs: 12, sm: 4, xl: 3 },
        },
      }
    : {}),
});

const MarqtuneEvaluationDetailsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"), { noSsr: true });
  const { evaluationId } = useParams();
  const { isFetching } = useSelector(({ marqtune }) => marqtune.evaluations);
  const { setBreadcrumbs, clearBreadcrumbs } = useBreadcrumbs();
  const evaluationDetails = useSelector(selectEvaluationByEvaluationId(evaluationId));
  const [actionsMenuAnchor, setActionsMenuAnchor] = useState(null);
  const matchingEvalId = evaluationDetails?.evaluationId;
  const pageTitle = matchingEvalId || "-";

  const handleEvaluationActions = (e: React.MouseEvent) => {
    e.stopPropagation();
    setActionsMenuAnchor(e.currentTarget);
  };

  const handleMenuClose = () => {
    setActionsMenuAnchor(null);
  };

  useEffect(() => {
    dispatch(listMarqtuneEvaluationsThunk());
    setBreadcrumbs(initialBreadcrumbs);
    return () => clearBreadcrumbs();
  }, []);

  useEffect(() => {
    if (!evaluationDetails && !isFetching) {
      navigate(config.authenticatedPaths.marqtune.root);
    }

    if (matchingEvalId) {
      dispatch(listMarqtuneEvaluationLogsThunk(matchingEvalId));
    }
  }, [matchingEvalId, isFetching]);

  return (
    <ContentLayout pageTitle={pageTitle} headTitle={pageTitle}>
      {isFetching || !matchingEvalId ? (
        <FetchingDisplay />
      ) : (
        <ContentSection id={`MarqtuneEvaluation-summary`}>
          <Grid container flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} mb={2}>
            <Grid item>
              <CardTitle title={matchingEvalId} noDivider typographyProps={{ variant: "h6" }} />
            </Grid>
            <Grid item>
              <IconButton onClick={handleEvaluationActions}>
                <MoreVertRounded />
              </IconButton>
            </Grid>
          </Grid>

          {/* DETAILS SUMMARY */}

          <MarqtuneEvaluationActionPrompt
            resourceName={evaluationId}
            resourceId={evaluationId}
            actionsMenuAnchor={actionsMenuAnchor}
            agreeHandler={handleMenuClose}
            disagreeHandler={handleMenuClose}
          />

          <Grid container flexDirection={"row"} spacing={1} mt={2} gap={lgUp ? 0 : 2}>
            <Grid container item xs={12} lg={8} pr={2} rowGap={2}>
              {Object.entries(evalDetailsConfig(evaluationDetails)).map(([param, restOfConfig]) => {
                return <DetailGridDisplay key={`evaluation-details-grid-${restOfConfig.label}`} {...restOfConfig} />;
              })}
            </Grid>
          </Grid>

          <EvaluationParameters hyperparameters={evaluationDetails?.hyperparameters} />

          <Box mt={3}>
            <LogsViewer
              isResourceTaskFinished={isFinished(evaluationDetails)}
              sectionId={"EvaluationDetails"}
              resourceId={matchingEvalId}
              dispatchIntervalInSec={30}
              dispatchAction={listMarqtuneEvaluationLogsThunk}
              downloadLogsDispatchAction={downloadMarqtuneEvaluationLogsThunk}
              resourceLogs={evaluationDetails?.resourceLogs}
            />
          </Box>
        </ContentSection>
      )}
    </ContentLayout>
  );
};

export default MarqtuneEvaluationDetailsPage;
