import { Alert, Grid, Link } from "@mui/material";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import ContentLayout from "../../../components/content-layout/content-layout.component";
import { BreadCrumbItem } from "../../../components/content-layout/content-layout.types";
import ContentSection from "../../../components/content-layout/content-section.component";
import { getFormConfig } from "../../../components/form/form.component";
import { StandardForm } from "../../../components/form/standard-form.component";
import config from "../../../config";
import { EMAIL_IS_ALREADY_REGISTERED } from "../../../constants";
import QuickLinks from "../../../constants/links";
import { AddMemberForm, addMemberFormConfig } from "../../../form-configs/members.forms";
import { useBreadcrumbs } from "../../../hooks/use-breadcrumbs";
import { setShowTip } from "../../../slices/user";
import { useDispatch, useSelector } from "../../../store";
import { addMemberThunk } from "../../../thunks/members.thunk";

const breadcrumbsList: BreadCrumbItem[] = [{ title: "Team Members", link: "/settings/team-members" }];

const AddMember = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setBreadcrumbs, clearBreadcrumbs } = useBreadcrumbs();
  const { showTip, data: userData } = useSelector(({ user }) => user);

  const formik = useFormik({
    ...getFormConfig<AddMemberForm>(addMemberFormConfig),
    onSubmit: async (data) => {
      await dispatch(
        addMemberThunk({
          formData: data,
          onSuccess: () => {
            dispatch(setShowTip(false));
            navigate(config.authenticatedPaths.members);
          },
        }),
      );
    },
  });

  useEffect(() => {
    setBreadcrumbs(breadcrumbsList);
    return () => {
      dispatch(setShowTip(false));
      clearBreadcrumbs();
    };
  }, []);

  return (
    <ContentLayout pageTitle="Add Team Member">
      <ContentSection id={"AddMember-Section"}>
        <StandardForm
          formik={formik}
          formConfig={addMemberFormConfig}
          promptTitle={""}
          submitBtnLabel={"Add team member"}
          onPreSubmitHandler={() => {
            if (userData.isGuest) {
              navigate(config.authenticatedPaths.billing);
              return false;
            }
          }}
          postSendNavigateTo={"/settings/team-members"}
        >
          {showTip && (
            <Grid item xs={12}>
              <Alert severity={"info"}>
                {EMAIL_IS_ALREADY_REGISTERED}{" "}
                <Link
                  sx={{ color: "neutral.100", textDecoration: "underline", fontWeight: "bold" }}
                  href={QuickLinks.adding_team_members}
                  target={"_blank"}
                >
                  Learn More
                </Link>
                .
              </Alert>
            </Grid>
          )}
        </StandardForm>
      </ContentSection>
    </ContentLayout>
  );
};

export default AddMember;
