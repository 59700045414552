import { Box, Button, Typography } from "@mui/material";
import classNames from "classnames";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router";
import AuthBase from "../../components/authentication/auth-base.component";
import { AuthBaseClasses } from "../../components/authentication/auth.styles";
import { AuthButton } from "../../components/button/auth-button.component";
import { buttonClasses } from "../../components/button/button.styles";
import Form, { getFormConfig } from "../../components/form/form.component";
import { AlreadyHaveAnAccountQuickLink } from "../../components/page-parts/quick-links/quick-links";
import config from "../../config";
import { confirmSignupFormConfig } from "../../form-configs/auth/signup.forms";
import { useAuth } from "../../hooks/use-auth";

const ConfirmSignup = () => {
  const { confirmSignup, resendConfirmationCode } = useAuth();
  const params = useParams();
  const navigate = useNavigate();
  const classes = AuthBaseClasses();
  const buttonClss = buttonClasses();

  const formik = useFormik({
    ...getFormConfig<{
      email: string;
      verificationCode: string;
      submit: string | boolean;
    }>(confirmSignupFormConfig(params?.email)),
    onSubmit: async (values, helpers) => {
      try {
        // TODO: authenticate using token returned by confirm signup
        // review if this is a security concern
        await confirmSignup(values.email, values.verificationCode);

        // cannot redirect to indexes without the token
        navigate(config.authenticatedPaths.home);
      } catch (e) {
        helpers.setErrors({
          submit: "Invalid request.",
        });
      }
    },
  });

  const handleResend = async () => {
    await resendConfirmationCode(formik.values.email);
  };

  return (
    <AuthBase
      disableMarqoLink={true}
      extraWrapperClasses={"borderRadiusAll"}
      formDisplayTitle=""
      pageTitle="Verify your email"
    >
      {params?.email && (
        <Typography sx={{ color: "neutral.900", marginTop: "1em" }} align="center">
          We've sent a code to your email
          <Typography sx={{ color: "blue", textDecoration: "underline" }}>{params?.email}</Typography>
          Paste it below to continue.
        </Typography>
      )}
      <Form formik={formik} spacing={2} formConfig={confirmSignupFormConfig(params?.email)}>
        <Box className={classes.customFormElementsWrapper}>
          <AuthButton isDisabled={formik.isSubmitting} label={"Verify"} />
          <Box mt={1} sx={{ display: "flex", justifyContent: "center" }}>
            <Button
              onClick={handleResend}
              className={classNames(buttonClss.leanTextButton, classes.resendConfirmationCode)}
            >
              Resend confirmation code
            </Button>
          </Box>
        </Box>
      </Form>
    </AuthBase>
  );
};

export default ConfirmSignup;
