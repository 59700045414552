export const endpoints = {
  // ACCOUNTS
  createAccount: "/account/create",
  // AUTH
  createStripeCustomer: "/account/create_customer",
  ssoAuth: "/account/sso",
  preSignup: "/account/pre_register",
  signup: "/account/signup",
  confirmSignup: "/account/confirm_signup",
  resendConfirmation: "/account/resend_confirmation",
  getSocialUserData: "/account/get_social_user_data",
  signin: "/account/signin",
  forgotPassword: "/account/forgot_password",
  resetPassword: "/account/reset_password",
  changePassword: "/account/change_password",
  account: "/account/get_account_data",
  logout: "/account/logout",
  getMarqoEndpoint: "/account/get_endpoint_url",
  getAccountLimits: "/account/limits",
  setLastSelectedAccount: "/account/selected",
  // INDEXES
  v1IndexWorkflows: "/indexes",
  v2IndexWorkflows: "/v2/indexes",
  getModelsEndpoint: "/v2/indexes/get_models",
  // INTEGRATIONS
  integrations: "/integrations",
  // METRICS
  index_metrics: "/metrics/index",
  rp_metrics: "/metrics/rp",
  // BILLING
  getPrices: "/billing/get_prices",
  hasStripeCard: "/billing/has_stripe_card",
  billingSummary: "/billing/get_billing_summary",
  billingHistory: "/billing/get_billing_history",
  getBillingPortalURL: "/billing/create_billing_portal_url",
  getMonthToDateUsage: "/billing/get_month_to_date_usage",
  // API KEY
  apiKeys: "/api_keys",
  deleteAPIKey: "/api_keys", // TODO: review api_keys urls
  // TEAM MEMBERS
  members: "/account/members",
  resendInvite: "/account/resend_invite",
  acceptInvite: "/account/accept_invite",
  invitationMethod: "/account/members/pending",
  // MARQTUNE ACCESS
  requestMarqtuneAccess: "/marqtune/request_access",
  // MARQTUNE
  marqtune: {
    datasets: "/datasets",
    models: "/models",
    evaluations: "/evaluation", // not plural in the API
  },
};

export const publicPaths = {
  login: "/authentication/login/",
  register: "/authentication/register/",
  confirmSignup: "/authentication/confirm-signup/",
  acceptInvite: "/authentication/accept-invite/",
  forgotPassword: "/authentication/forgot-password/",
  passwordReset: "/authentication/password-reset/",
  setPassword: "/authentication/set-password/",
};

export const authenticatedPaths = {
  home: "/",
  // INDEXES
  indexes: "/indexes/",
  createIndex: "/indexes/create",
  // ACCOUNTS
  accounts: "/accounts/",
  createAccount: "/accounts/create/",
  accountInvitations: "/accounts/invitations/",
  // BILLING
  billing: "/billing/",
  // API KEYS
  apiKeys: "/settings/api-keys/",
  createApiKey: "/settings/api-keys/create/",
  // MEMBERS
  members: "/settings/team-members/",
  addMember: "/settings/team-members/add/",
  // INTEGRATIONS
  integrations: "/integrations/",
  // MARQTUNE
  marqtune: {
    root: "/marqtune/",
    datasets: {
      create: "/marqtune/datasets/create/",
      detail: "/marqtune/datasets/:name/",
    },
    trainModel: "/marqtune/train-model/",
    models: {
      create: "/marqtune/models/create/",
      detail: "/marqtune/models/:name/",
    },
    evaluations: {
      all: "/marqtune/evaluations/",
      detail: "/marqtune/evaluations/:id/",
    },
    evaluationModel: "/marqtune/evaluation-model/",
  },
};
