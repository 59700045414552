import { createSlice } from "@reduxjs/toolkit";
import { ResGetUserData } from "../api/user/user.types";
import { createAccount } from "../thunks/account.thunk";
import { getAccountLimitsThunk, getMarqoEndpointThunk, getUserData } from "../thunks/user.thunk";

export type InitialStateUser = {
  limits: {
    index: null | number;
    inference: null | number;
    replica: null | number;
    shard: null | number;
  };
  data: {
    email: string;
    organization: string;
    country?: string;
    isGuest: boolean;
    withBilling: boolean;
    withIntegrations: boolean;
    withV1Indexes: boolean;
    withCloudSelector: boolean;
    withMarqtune: boolean;
    withMarqtuneUI: boolean;
    withStorageResizing: boolean;
    withAudioVideoIndexingMode: boolean;
  };
  integrations: {
    isFetching: boolean;
    datadog: {
      key: string | null;
    };
  };
  s2config: {
    s2CustomerId: string;
    isAdmin: boolean;
  };
  marqoEndpoint: string;
  isAccountDataSet: boolean;
  showTip: boolean;
};

export const UserInitialState: InitialStateUser = {
  limits: {
    index: null,
    inference: null,
    replica: null,
    shard: null,
  },
  data: {
    email: null,
    organization: null,
    country: null,
    isGuest: false,
    withBilling: null,
    withIntegrations: null,
    withV1Indexes: null,
    withCloudSelector: false,
    withMarqtune: null,
    withMarqtuneUI: null,
    withStorageResizing: null,
    withAudioVideoIndexingMode: null,
  },
  integrations: {
    isFetching: false,
    datadog: {
      key: null,
    },
  },
  s2config: {
    s2CustomerId: null,
    isAdmin: null,
  },
  marqoEndpoint: null,
  isAccountDataSet: false,
  showTip: null,
};

/** Updates `state` in-place with the contents of `userData`. */
function updateWithUserData(state: InitialStateUser, userData: ResGetUserData): void {
  const {
    organization,
    country,
    customer_visible_account_id,
    is_admin,
    is_guest,
    with_billing,
    with_integrations,
    with_v1_indexes,
    with_cloud_selector,
    with_marqtune,
    with_marqtune_ui,
    with_storage_resizing,
    with_av_indexing_mode,
    email,
  } = userData;

  state.data = {
    organization,
    country,
    email,
    isGuest: is_guest,
    withBilling: with_billing,
    withIntegrations: with_integrations,
    withV1Indexes: with_v1_indexes,
    withCloudSelector: with_cloud_selector,
    withMarqtune: with_marqtune,
    withMarqtuneUI: with_marqtune_ui,
    withStorageResizing: with_storage_resizing,
    withAudioVideoIndexingMode: with_av_indexing_mode,
  };

  state.s2config = {
    s2CustomerId: customer_visible_account_id,
    isAdmin: is_admin,
  };
  state.isAccountDataSet = true;
}

const slice = createSlice({
  name: "user",
  initialState: UserInitialState,
  reducers: {
    resetUserState: (state) => {
      state.limits = { ...UserInitialState.limits };
      state.data = { ...UserInitialState.data };
      state.s2config = { ...UserInitialState.s2config };
      state.marqoEndpoint = UserInitialState.marqoEndpoint;
      state.isAccountDataSet = false;
    },
    setMarqoEndpoint: (state, { payload }) => {
      state.marqoEndpoint = payload;
    },
    setShowTip: (state, { payload }) => {
      state.showTip = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserData.fulfilled, (state, action) => {
        updateWithUserData(state, action.payload);
      })
      .addCase(getUserData.rejected, (state, _) => {
        state.isAccountDataSet = true;
      })
      .addCase(createAccount.fulfilled, (state, action) => {
        updateWithUserData(state, action.payload.account_data);
      })
      .addCase(getAccountLimitsThunk.rejected, (state, _) => {
        state.limits = { ...UserInitialState.limits };
      })
      .addCase(getMarqoEndpointThunk.fulfilled, (state, { payload }) => {
        state.marqoEndpoint = payload;
      })
      .addCase(getAccountLimitsThunk.fulfilled, (state, { payload }) => {
        const { index_limit, inference_limit, shard_limit, replica_limit } = payload;
        state.limits = {
          inference: inference_limit,
          shard: shard_limit,
          replica: replica_limit,
          index: index_limit,
        };
      });
  },
});

export const { resetUserState, setMarqoEndpoint, setShowTip } = slice.actions;
export default slice.reducer;
