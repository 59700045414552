import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import React from "react";
import { DEFAULT_PROMPT_DIALOG_MSG } from "../../constants";
import { buttonClasses } from "../button/button.styles";
import { StyledDialog } from "./prompt-dialog.styles";

type PromptDialogProps = {
  agreeHandler: () => void;
  disagreeHandler: () => void;
  promptDialogMsg?: string | React.ReactNode;
  openDialog: boolean;
  title: string;
};

const PromptDialog: React.FC<PromptDialogProps> = ({
  agreeHandler,
  disagreeHandler,
  openDialog,
  title,
  promptDialogMsg = DEFAULT_PROMPT_DIALOG_MSG,
}): JSX.Element => {
  const btnClasses = buttonClasses();

  return (
    <StyledDialog
      open={openDialog}
      id={"prompt-dialog"}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{promptDialogMsg}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={"center"} columnGap={2} mb={2}>
          <Button
            className={`${btnClasses.dangerButton} ${btnClasses.dialogActionButton}`}
            onClick={agreeHandler}
            id={`prompt-dialog-yes`}
          >
            Yes
          </Button>
          <Button
            className={`${btnClasses.noButton} ${btnClasses.dialogActionButton}`}
            onClick={disagreeHandler}
            autoFocus
            id={`prompt-dialog-no`}
          >
            No
          </Button>
        </Grid>
      </DialogActions>
    </StyledDialog>
  );
};

export default PromptDialog;
