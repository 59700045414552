import ReactGA from "react-ga4";

export type Ga4Event = {
  category: string;
  event_name: string;
  label: string;
};

export const TrackGoogleAnalyticsEvent = (event: Ga4Event, data: any) => {
  const { category, event_name, label } = event;

  const event_params = {
    category,
    label,
    ...data,
  };
  ReactGA.event(event_name, event_params);
};
