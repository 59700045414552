import { Box, Button, ButtonGroup, InputLabel, Popover } from "@mui/material";
import moment, { Moment } from "moment";
import React, { useState } from "react";
import DateRangePicker from "../date-range-picker/date-range-picker.component";
import { DateRangeSelectorState } from "../date-range-picker/date-range-picker.types";
import { RangeButtonsProps, RangeItemType } from "./range-buttons.types";

export const timePeriods: Record<RangeItemType["value"], Moment | null> = {
  "1h": moment().subtract(1, "hours"),
  "3h": moment().subtract(3, "hours"),
  "12h": moment().subtract(12, "hours"),
  day: moment().subtract(1, "days"),
  week: moment().subtract(1, "weeks"),
  month: moment().subtract(1, "month"),
  custom: undefined,
};

export const defaultButtonRanges: RangeItemType[] = [
  { label: "1h", value: "1h", date: timePeriods["1h"] },
  { label: "3h", value: "3h", date: timePeriods["3h"] },
  { label: "12h", value: "12h", date: timePeriods["12h"] },
  { label: "1d", value: "day", date: timePeriods["day"] },
  { label: "1w", value: "week", date: timePeriods["week"] },
  { label: "1m", value: "month", date: timePeriods["month"] },
  { label: "Custom", value: "custom" },
];

const ChartRangeButtons: React.FC<RangeButtonsProps> = (props): JSX.Element => {
  const { selectedRange, onRangeButtonClick, dateRange, setDateRange } = props;
  const [popoverAnchor, setPopoverAnchor] = useState(null);
  const handleRangeChange = (e: React.MouseEvent<HTMLButtonElement>, rangeItem: RangeItemType): void => {
    if (rangeItem.value === "custom") {
      return setPopoverAnchor(e.currentTarget);
    }
    onRangeButtonClick(rangeItem);
  };

  const handleSubmit = (): void => {
    const range: RangeItemType = {
      label: "Custom",
      value: "custom",
    };
    const date: DateRangeSelectorState = {
      dateFrom: dateRange.dateFrom,
      dateTo: dateRange.dateTo,
    };
    onRangeButtonClick(range, date);
    setPopoverAnchor(null);
  };

  const handleClosePopover = (): void => {
    setPopoverAnchor(null);
  };

  const validateDates = () => {
    const { dateFrom, dateTo } = dateRange;
    return dateFrom.isBefore(dateTo);
  };
  return (
    <Box
      display={"flex"}
      justifyContent={"flex-end"}
      m={2}
      sx={(theme) => ({
        [theme.breakpoints.down("sm")]: {
          mx: 0,
        },
      })}
    >
      <ButtonGroup size="small" variant="outlined" color="info">
        {defaultButtonRanges.map((rangeItem) => (
          <Button
            key={rangeItem.value}
            sx={{ py: "3px", lineHeight: 1.2 }}
            onClick={(e) => handleRangeChange(e, rangeItem)}
            variant={rangeItem.value === selectedRange ? "contained" : "outlined"}
          >
            {rangeItem.label}
          </Button>
        ))}
      </ButtonGroup>

      {dateRange && setDateRange && (
        <Popover
          open={Boolean(popoverAnchor)}
          anchorEl={popoverAnchor}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          onClose={handleClosePopover}
        >
          <Box sx={{ p: 2, flexDirection: "column" }}>
            <DateRangePicker dateFrom={dateRange.dateFrom} dateTo={dateRange.dateTo} onChange={setDateRange} />
            {!validateDates() && (
              <InputLabel error sx={{ mt: 1 }}>
                End date can't be before start
              </InputLabel>
            )}
            <Box sx={{ mt: 1 }}>
              <Button
                variant={"contained"}
                sx={{ mr: 2 }}
                onClick={handleSubmit}
                color={"secondary"}
                disabled={!validateDates()}
              >
                Submit
              </Button>
              <Button variant={"text"} color={"error"} onClick={handleClosePopover}>
                Cancel
              </Button>
            </Box>
          </Box>
        </Popover>
      )}
    </Box>
  );
};

export default ChartRangeButtons;
