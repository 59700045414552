import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { leanScrollbarStyles } from "../../../../constants/styles";

export const liveLogsClasses = makeStyles((theme: Theme) => ({
  wrapper: {
    backgroundColor: theme.palette.background.default,
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
    paddingBottom: theme.spacing(4),
    borderRadius: theme.spacing(0.5),
    fontFamily: "Fira Code",
    fontSize: "0.8rem",
    overflow: "hidden",
    maxHeight: "50vh",

    "& pre": {
      overflow: "auto",
      height: "100%",
      width: "100%",

      "&:focus, &:hover": {
        ...leanScrollbarStyles(true),
      },
    },
  },
  clickToExpand: {
    cursor: "pointer",
  },
}));
