import { NavigateNext } from "@mui/icons-material";
import { Box, Breadcrumbs, IconButton, SxProps, Theme, Toolbar, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { useBreadcrumbs } from "../../hooks/use-breadcrumbs";
import { Menu as MenuIcon } from "../../icons/menu";
import AccountButton from "../account-button/account-button.component";
import { LinkRouter } from "../content-layout/content-layout.component";
import { DashboardNavbarRoot } from "./dashboard-navbar.styles";

type DashboardNavbarProps = {
  onOpenSidebar: () => void;
  rootSx?: SxProps<Theme>;
  breadcrumbsRootSx?: SxProps<Theme>;
  breadcrumbItemSx?: SxProps<Theme>;
  containerSx?: SxProps<Theme>;
};

export const DashboardNavbar: React.FC<DashboardNavbarProps> = (props): JSX.Element => {
  const { breadcrumbs, pageTitle } = useBreadcrumbs();
  const { onOpenSidebar, breadcrumbsRootSx, breadcrumbItemSx, ...other } = props;

  return (
    <DashboardNavbarRoot
      sx={{
        left: { lg: 280 },
        width: { lg: "calc(100% - 280px)" },
      }}
      {...other}
    >
      <Toolbar disableGutters sx={{ minHeight: 64, left: 0, px: 2 }}>
        {/* hamburger */}

        <IconButton onClick={onOpenSidebar} sx={{ display: { xs: "inline-flex", lg: "none" } }}>
          <MenuIcon fontSize="small" />
        </IconButton>

        {/* breadcrumbs */}

        {pageTitle && !breadcrumbs && (
          <Typography fontWeight={"bold"} color={"textPrimary"} sx={{ py: 2 }}>
            {pageTitle}
          </Typography>
        )}

        {breadcrumbs && (
          <Breadcrumbs sx={{ py: 2, ...breadcrumbsRootSx }} separator={<NavigateNext fontSize={"small"} />}>
            {breadcrumbs.map((breadcrumb) => (
              <LinkRouter
                to={breadcrumb.link}
                underline="hover"
                color="inherit"
                fontWeight={"bold"}
                key={breadcrumb.link}
                sx={breadcrumbItemSx}
                variant={"body1"}
              >
                {breadcrumb.title}
              </LinkRouter>
            ))}
            <Typography variant={"body1"} fontWeight={"bold"} sx={breadcrumbItemSx}>
              {pageTitle}
            </Typography>
          </Breadcrumbs>
        )}

        {/* right popover for account settings */}

        <Box sx={{ flexGrow: 1 }} />
        <AccountButton />
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};
