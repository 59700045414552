import { Alert, CircularProgress, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CardCvcElement, CardExpiryElement, CardNumberElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeElementChangeEvent } from "@stripe/stripe-js";
import { useState } from "react";
import { useStripeOptions } from "../../hooks/use-stripeOptions";
import { getNeutralRgb } from "../../theme/dark-theme-options";
import StripeLogo from "../logo/stripe-logo";
import { pricingCalculator, termsAndConditions } from "../page-parts/quick-links/quick-links";

export const stripeClasses = makeStyles(() => ({
  stripeBillingCardForm: {
    "& input": {
      fontSize: "1rem",
      fontWeight: "300",
      fontFamily: "'Poppins', sans-serif",
      backgroundColor: "white",
      color: "#0D0F35",
      border: `1px solid ${getNeutralRgb("300")}`,
      borderRadius: "7px",
    },
    // TODO: Fix buggy fieldset rendering and remove this.
    "& fieldset": {
      display: "none",
    },
  },
  stripeCustomElementStyles: {
    marginTop: "0.5em",
    flexGrow: 1,
    "& .StripeElement": {
      padding: "15px 20px",
      border: `1px solid ${getNeutralRgb("300")}`,
      borderRadius: "7px",
    },
  },
}));

export type Props = {
  submittingMessage?: string;
  error?: any;
  style?: React.CSSProperties;
  onChange?: (tokenId: string) => void;
  onError?: (error: any) => void;
};

export default function StripeCardField({ error, onChange, onError, submittingMessage, style }: Props) {
  const elements = useElements();
  const stripe = useStripe();
  const stripeOptions = useStripeOptions();
  const stripeStyles = stripeClasses();

  const [showProgressLoader, setShowProgressLoader] = useState(true);
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");

  submittingMessage ??= "Submitting your payment details. Please wait...";

  const handleNameOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const nameValue = e.target.value;
    setName(nameValue);
    setNameError(nameValue ? "" : "Name is required");
  };

  const handleCardChange = async (e: StripeElementChangeEvent) => {
    if (!stripe || !elements || !name) return;

    const cardElement = elements.getElement(CardNumberElement);
    const { error: tokenError, token } = await stripe.createToken(cardElement, { name });

    // TODO: Only set error on blur.
    if (tokenError) return onError?.(tokenError);
    if (token && onChange) onChange(token.id);
  };

  return (
    <div className={stripeStyles.stripeBillingCardForm} style={{ color: "black", ...style }}>
      <div style={{ width: "100%", display: "flex", flexDirection: "column", gap: "0.5em" }}>
        <Typography variant={"h5"} sx={{ color: "neutral.950" }}>
          Enter your billing details
        </Typography>
        <Typography>You will only be charged when you create an index or train a model with Marqtune.</Typography>
        <Typography>Marqo Cloud is billed for what you use. Payments are processed monthly.</Typography>
        <Typography>
          See the {pricingCalculator} and {termsAndConditions} for more information.
        </Typography>
      </div>

      <TextField
        className={stripeStyles.stripeCustomElementStyles}
        fullWidth
        name={"nameOnCard"}
        placeholder={"Name on the card"}
        value={name}
        error={!!nameError}
        helperText={nameError}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleNameOnChange(e)}
      />

      <div className={stripeStyles.stripeCustomElementStyles}>
        <CardNumberElement onChange={handleCardChange} options={{ ...stripeOptions, placeholder: "Card number" }} />
      </div>
      <div style={{ display: "flex", gap: "0.5em" }}>
        <div className={stripeStyles.stripeCustomElementStyles}>
          <CardExpiryElement
            id={"cardExpiry"}
            onChange={handleCardChange}
            options={{ ...stripeOptions, placeholder: "Expiration" }}
          />
        </div>
        <div className={stripeStyles.stripeCustomElementStyles}>
          <CardCvcElement
            onChange={handleCardChange}
            onReady={() => setShowProgressLoader(false)}
            options={stripeOptions}
          />
        </div>
      </div>
      <div className={stripeStyles.stripeCustomElementStyles} style={{ display: "flex", alignItems: "center" }}>
        <Typography sx={{ color: "neutral.600", fontSize: "0.875rem" }}>Powered by </Typography>
        <StripeLogo />
      </div>
      {showProgressLoader && (
        <div style={{ alignItems: "center" }}>
          {submittingMessage && (
            <Typography sx={{ color: "neutral.500", textAlign: "center" }}>{submittingMessage}</Typography>
          )}
          <CircularProgress size={50} />
        </div>
      )}
      {error && <Alert severity={"error"}> {error.message ?? error} </Alert>}
    </div>
  );
}
