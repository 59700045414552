import moment from "moment";

export const getRemainingCountdown = (
  timeOfOperation: string,
  countdownDuration: number,
  countdownDelayInMs: number,
) => {
  const currentTime = moment.utc().local();
  const creationTime = moment.utc(timeOfOperation).local().format();
  const elapsedSeconds = currentTime.diff(creationTime, "seconds");
  const delayInSeconds = countdownDelayInMs / 1000;
  let remainingTime = countdownDuration - Math.floor(elapsedSeconds / delayInSeconds);

  if (elapsedSeconds >= countdownDuration * delayInSeconds) {
    return 0;
  }

  if (remainingTime > countdownDuration) {
    remainingTime = countdownDuration;
  }

  return remainingTime;
};
