import { createSlice } from "@reduxjs/toolkit";
import { Price } from "../../api/billing/billing.types";
import { IndexWorkflowsInferenceMapping, IndexWorkflowsStorageMapping } from "../../constants/enums";

export type InferencePrices = {
  "marqo.CPU.small": Price;
  [IndexWorkflowsInferenceMapping.CPU]: Price;
  [IndexWorkflowsInferenceMapping.GPU]: Price;
};

export type StoragePrices = {
  [IndexWorkflowsStorageMapping.BASIC]: Price;
  [IndexWorkflowsStorageMapping.BALANCED]: Price;
  [IndexWorkflowsStorageMapping.PERFORMANCE]: Price;
};

export type InitialPricingType = {
  isFetching: boolean;
  hasNullPrices: boolean;
  fetchingError: string;
  inference: InferencePrices;
  storage: StoragePrices;
};

export const pricingInitialState: InitialPricingType = {
  isFetching: false,
  hasNullPrices: false,
  fetchingError: "",
  inference: {
    "marqo.CPU.small": null,
    [IndexWorkflowsInferenceMapping.CPU]: null,
    [IndexWorkflowsInferenceMapping.GPU]: null,
  },
  storage: {
    [IndexWorkflowsStorageMapping.BASIC]: null,
    [IndexWorkflowsStorageMapping.BALANCED]: null,
    [IndexWorkflowsStorageMapping.PERFORMANCE]: null,
  },
};

const pricingSlice = createSlice({
  name: "pricing",
  initialState: pricingInitialState,
  reducers: {
    setGetPricesFetchingError: (state, { payload }) => {
      const combinedPrices = [...Object.values(payload.inference), ...Object.values(payload.storage)];
      state.fetchingError = payload;
      state.isFetching = false;
      state.inference = pricingInitialState.inference;
      state.storage = pricingInitialState.storage;
      state.hasNullPrices = combinedPrices.some((price) => price === null);
    },
    setIsFetchingPrices: (state, { payload }) => {
      state.isFetching = payload;
    },
    setProductPrices: (state, { payload }) => {
      const combinedPrices = [...Object.values(payload.inference), ...Object.values(payload.storage)];
      state.inference = payload.inference;
      state.storage = payload.storage;
      state.fetchingError = "";
      state.isFetching = false;
      state.hasNullPrices = combinedPrices.some((price) => price === null);
    },
  },
});

export const { setGetPricesFetchingError, setIsFetchingPrices, setProductPrices } = pricingSlice.actions;

export default pricingSlice.reducer;
