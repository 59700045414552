import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getNeutralRgb } from "../../theme/dark-theme-options";

export const formClasses = makeStyles((theme: Theme) => ({
  extendedErrorWrapper: {
    display: "flex",
  },
  unwrappedForm: {
    "& .MuiFormControl-root.MuiTextField-root": {
      background: getNeutralRgb("900"),
    },
  },
  checkboxLabel: {
    fontWeight: 300,
    color: getNeutralRgb("900"),
    "& p": {
      fontSize: "0.875rem",
    },
    "& *": {
      color: getNeutralRgb("900"),
    },
    "& > .MuiFormControlLabel-root": {
      marginRight: "0 !important",
    },
    "& .MuiCheckbox-root:hover": {
      backgroundColor: "transparent !important",
    },
    "& .MuiLink-root": {
      fontWeight: "bold",
    },
  },
}));
