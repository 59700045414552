import { createSlice } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import moment from "moment";
import { ChartItemData } from "../../components/chart/chart.types";
import { timePeriods } from "../../components/range-buttons/range-buttons.component";
import { RangeItemType } from "../../components/range-buttons/range-buttons.types";
import { getIndexMetricsMany } from "../../thunks/indexes.thunk";
import { getIndexMetricsData } from "../../utils/indices/metrics-data-convert";

type InitialMetricsCommonStateType = {
  isFetching: boolean;
  selectedRange: RangeItemType["value"];
  dateStart: string;
  dateEnd: string;
  charts: {
    currentStep: number;
    data: ChartItemData[][];
  };
};

export const initialDateEnd = moment();
export const initialDateStart = timePeriods["3h"];

export const indexMetricsCommonInitialState: InitialMetricsCommonStateType = {
  isFetching: false,
  selectedRange: "3h",
  dateStart: initialDateStart.toISOString(),
  dateEnd: initialDateEnd.toISOString(),
  charts: {
    data: [],
    currentStep: 0,
  },
};

const indexMetricsCommon = createSlice({
  name: "indexMetricsCommon",
  initialState: indexMetricsCommonInitialState,
  reducers: {
    setMetricsFetching: (state, action) => {
      state.isFetching = action.payload;
    },
    setSelectedRange: (state, action) => {
      state.selectedRange = action.payload;
    },

    setResetZoom: (state) => {
      state.charts.currentStep = 0;
    },
    setNextStep: (state) => {
      const nextStep = state.charts.currentStep + 1;
      if (nextStep < state.charts.data.length) {
        state.charts.currentStep = nextStep;
      }
    },
    setPrevStep: (state) => {
      const prevStep = state.charts.currentStep - 1;
      if (prevStep >= 0) {
        state.charts.currentStep = prevStep;
      }
    },
    setModalDatetimes: (state, { payload }) => {
      state.dateStart = payload.start;
      state.dateEnd = payload.end;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getIndexMetricsMany.fulfilled, (state, action) => {
      const chartsData = action.payload.map((responseData): WritableDraft<ChartItemData> => {
        const { dateStart, dateEnd, data: response, operation } = responseData;
        const { datasets, labels } = getIndexMetricsData(response.datapoints);
        return {
          id: operation,
          datasets,
          labels,
          dateStart,
          dateEnd,
        };
      });
      const length = state.charts.data.push(chartsData);
      state.charts.currentStep = length - 1;
    });
  },
});

export const { setMetricsFetching, setSelectedRange, setPrevStep, setNextStep, setResetZoom, setModalDatetimes } =
  indexMetricsCommon.actions;

export default indexMetricsCommon.reducer;
