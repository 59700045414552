import { MetricOperationTypes } from "../components/page-parts/indexes/indexes.types";

export const CHART_DATE_RANGE_FORMAT = "MMMM DD YYYY, HH:mm";
export const CHART_LABEL_FORMAT = "DD MMM YY HH:mm";

export const ACCOUNT_LEVEL_METRIC_CHART_NAMES: Record<string, string> = {
  [MetricOperationTypes.error400]: "API 4XX Errors",
  [MetricOperationTypes.error500]: "API 5XX Errors",
  [MetricOperationTypes.rps]: "API Requests",
  [MetricOperationTypes.latency]: "API Latency",
};

export const CHART_NAMES: Record<string, string> = {
  [MetricOperationTypes.primaryShardCount]: "Primary shards",
  [MetricOperationTypes.replicaShardCount]: "Replica shards",
  [MetricOperationTypes.docCount]: "Count of documents",
  [MetricOperationTypes.dataSize]: "Data size",
  [MetricOperationTypes.totalSearchCount]: "Total search count",
  [MetricOperationTypes.searchLatency]: "Search Latency",
  [MetricOperationTypes.indexingLatency]: "Indexing Latency",
  ...ACCOUNT_LEVEL_METRIC_CHART_NAMES,
};

export const MONITOR_DATA_TYPES = [
  MetricOperationTypes.dataSize,
  MetricOperationTypes.docCount,
  MetricOperationTypes.totalSearchCount,
  MetricOperationTypes.primaryShardCount,
  MetricOperationTypes.searchLatency,
  MetricOperationTypes.indexingLatency,
];

export const ACCOUNT_LEVEL_DATA_TYPES: MetricOperationTypes[] = [
  MetricOperationTypes.error400,
  MetricOperationTypes.error500,
  MetricOperationTypes.rps,
  MetricOperationTypes.latency,
];
