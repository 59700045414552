import { MoreVertRounded } from "@mui/icons-material";
import { Grid, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import classNames from "classnames";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { MarqtuneEvaluation } from "../../../../api/marqtune/types";
import config from "../../../../config";
import { ellipsisStyles } from "../../../../constants/styles";
import { setMarqtuneEvaluationsPage } from "../../../../slices/marqtune";
import { useSelector } from "../../../../store";
import { toHrMinConvention, toMonthDYr } from "../../../../utils/time-formatter";
import CopyButton from "../../../copy-button/copy-button.component";
import { StatusChip } from "../chip.component";
import { MarqtuneEvaluationActionPrompt } from "../prompts.component";
import { MarqtuneStyleClasses } from "../styles";
import { isReady } from "../utils";
import MarqtuneRootSection from "./marqtune-root-section.component";

const MarqtuneEvaluationsSection = () => {
  const navigate = useNavigate();
  const classes = MarqtuneStyleClasses();
  const { list: data, isFetching, itemsPerPage, currentPage } = useSelector(({ marqtune }) => marqtune.evaluations);
  const [selectedEvaluation, setSelectedEvaluation] = useState<MarqtuneEvaluation>(null);
  const [actionsMenuAnchor, setActionsMenuAnchor] = useState(null);

  const handleOnClickCell = (evaluationId: string) => {
    navigate(`/marqtune/evaluations/${evaluationId}`);
  };

  const handleActionsMenuClose = () => {
    setActionsMenuAnchor(null);
    setSelectedEvaluation(null);
  };

  const handleOnClickActionMenu = (e: React.MouseEvent, item: MarqtuneEvaluation) => {
    e.stopPropagation();
    setSelectedEvaluation(item);
    setActionsMenuAnchor(e.currentTarget);
  };

  const renderTableRow = (rowItem: MarqtuneEvaluation, index: number) => {
    const { evaluationId, model, status, startedAt } = rowItem;

    return (
      <TableRow
        key={index}
        data-testid={`MarqtuneEvaluations-TableRow-${evaluationId}`}
        className={classNames(
          classes.centeredTableRowCells,
          classes.rootSectionTableRow,
          "EvaluationsSection-TableRow",
        )}
      >
        {/* ID */}
        <TableCell onClick={() => handleOnClickCell(evaluationId)}>
          <Grid container justifyContent={"flex-start"} flexWrap={"nowrap"} gap={1} overflow={"hidden"}>
            <Typography variant={"subtitle2"} sx={ellipsisStyles}>
              {evaluationId}
            </Typography>
            <CopyButton text={evaluationId} label={"Evaluation ID"} />
          </Grid>
        </TableCell>

        {/* MODEL */}
        <TableCell onClick={() => handleOnClickCell(evaluationId)}>
          <Grid container justifyContent={"center"} flexWrap={"nowrap"} gap={1} overflow={"hidden"}>
            <Typography variant={"subtitle2"} sx={ellipsisStyles}>
              {model}
            </Typography>
          </Grid>
        </TableCell>

        {/* STARTED AT */}
        <TableCell onClick={() => handleOnClickCell(evaluationId)}>
          <Grid container justifyContent={"center"} gap={1} flexDirection="column" overflow={"hidden"}>
            <Typography textAlign={"center"}>{toMonthDYr(startedAt)}</Typography>
            <Typography color={"textSecondary"} textAlign={"center"}>
              {toHrMinConvention(startedAt)}
            </Typography>
          </Grid>
        </TableCell>

        {/* STATUS */}
        <TableCell onClick={() => handleOnClickCell(evaluationId)}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className="RowEndActionsWrapper"
            flexWrap="nowrap"
          >
            <Grid item>
              <StatusChip isReady={isReady(rowItem)} status={status} />
            </Grid>
            <Grid item>
              <IconButton
                sx={{ padding: "2px" }}
                onClick={(e: React.MouseEvent) => handleOnClickActionMenu(e, rowItem)}
              >
                <MoreVertRounded />
              </IconButton>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <MarqtuneRootSection
      tableHeaders={[
        { title: "EVALUATION ID", id: "eval-id" },
        { title: "MODEL", id: "eval-model" },
        { title: "DATE STARTED (UTC)", id: "eval-started-at", order: { key: "startedAt", type: "date" } },
        { title: "STATUS", id: "eval-status" },
      ]}
      setPageChangeAction={setMarqtuneEvaluationsPage}
      data={data}
      isFetching={isFetching}
      itemsPerPage={itemsPerPage}
      currentPage={currentPage}
      confirmPopupConfigLabel={"evaluation"}
      cardTitle={"Evaluations"}
      contentSectionIdPrefix={"MarqtuneEvaluations"}
      emptyListMsg={"No evaluation found."}
      tableCellKeyPrefix={"evaluations-section-cell"}
      topRightActionBtnConfig={{
        label: "Evaluate Model",
        href: config.authenticatedPaths.marqtune.evaluationModel,
      }}
      filterPaths={["status", "secondaryStatus", "evaluationId"]}
      setCurrentPage={setMarqtuneEvaluationsPage}
      renderTableRow={renderTableRow}
    >
      <MarqtuneEvaluationActionPrompt
        actionsMenuAnchor={actionsMenuAnchor}
        resourceName={selectedEvaluation?.evaluationId}
        resourceId={selectedEvaluation?.evaluationId}
        agreeHandler={handleActionsMenuClose}
        disagreeHandler={handleActionsMenuClose}
      />
    </MarqtuneRootSection>
  );
};

export default MarqtuneEvaluationsSection;
