import toast from "react-hot-toast";
import baseToast from "./base.toast";

export const changeAccountToast = (promise: Promise<any>, accountName: string) =>
  baseToast(
    promise,
    <span>
      Changing to account <strong>{accountName}</strong>...
    </span>,
    <span>
      Changed to account <strong>{accountName}</strong>
    </span>,
  );
