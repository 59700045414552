import { CircularProgress, Grid } from "@mui/material";

const FetchingDisplay = () => {
  return (
    <Grid
      container
      flexDirection={"column"}
      justifyContent={"center"}
      alignContent={"center"}
      alignSelf={"center"}
      height={"20vh"}
    >
      <CircularProgress />
    </Grid>
  );
};

export default FetchingDisplay;
