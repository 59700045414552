import { Alert, Box, Typography } from "@mui/material";
import { isEmpty } from "lodash";
import Config from "../../config";
import { useSelector } from "../../store";
import { selectInvitationAccounts } from "../../store/selectors";
import { plural } from "../../utils/formatters";
import { LinkRouter } from "../content-layout/content-layout.component";

/**
 * Informs the user that they have pending account invitations.
 */
export default function InvitationBanner() {
  const invitations = useSelector(selectInvitationAccounts);
  if (isEmpty(invitations)) {
    return null;
  }

  return (
    <Box sx={{ pb: 3 }} data-testid="invitation-banner">
      <Alert severity="info">
        <Typography>
          You have been invited to join{" "}
          {invitations.length === 1 ? (
            <>
              the <strong>{invitations[0].name}</strong> account
            </>
          ) : (
            `${invitations.length} account${plural(invitations.length)}`
          )}
          .{" "}
          <LinkRouter to={Config.authenticatedPaths.accountInvitations} variant="p" sx={{ color: "#2196F3" }}>
            View your invitations
          </LinkRouter>{" "}
          to accept or reject.
        </Typography>
      </Alert>
    </Box>
  );
}
