import { Download, MoreVertRounded } from "@mui/icons-material";
import {
  Box,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  Menu,
  MenuItem,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import classNames from "classnames";
import { isEmpty } from "lodash";
import React, { useState } from "react";
import { MarqtuneModel } from "../../../../api/marqtune/types";
import { WrapperClasses, ellipsisStyles } from "../../../../constants/styles";
import { marqtuneStyles } from "../../../../pages/marqtune/marqtune.styles";
import { useDispatch } from "../../../../store";
import {
  downloadMarqtuneCheckpointThunk,
  downloadMarqtuneModelLogsThunk,
  listMarqtuneModelLogsThunk,
} from "../../../../thunks/marqtune.thunk";
import CardTitle from "../../../card-title/card-title.component";
import { LinkRouter } from "../../../content-layout/content-layout.component";
import ContentSection from "../../../content-layout/content-section.component";
import { DetailGridDisplay } from "../detail-grid-display.component";
import LogsViewer from "../logs-viewer/logs-viewer.component";
import { MarqtuneModelActionPrompt } from "../prompts.component";
import { MarqtuneStyleClasses } from "../styles";
import { getDisplayableStatus, isFinished } from "../utils";
import ReleaseCheckpointDialog from "./release-checkpoint.component";

type Props = {
  modelDetails: MarqtuneModel;
  isFetching: boolean;
};

type DetailsConf = {
  [key: string]: {
    label: string;
    value: JSX.Element | string | MarqtuneModel["checkpoints"];
    sizes: {
      [size: string]: number;
    };
    withCopy?: boolean;
  };
};
const CONFIG_SIZES = { xs: 12, sm: 6, lg: 4 };
const modelDetailsConfig = (modelDetails: MarqtuneModel): DetailsConf => ({
  modelId: {
    label: "Model ID",
    value: modelDetails.modelId,
    withCopy: true,
    sizes: CONFIG_SIZES,
  },
  name: {
    label: "Model name",
    value: modelDetails.modelName,
    sizes: CONFIG_SIZES,
  },
  baseModel: {
    label: "Base model",
    value: modelDetails.baseModel,
    sizes: CONFIG_SIZES,
    withCopy: true,
  },
  fineTuningDataset: {
    label: "Training dataset ID",
    value: modelDetails?.datasetId,
    withCopy: true,
    sizes: CONFIG_SIZES,
  },
  status: {
    label: "Status",
    value: getDisplayableStatus(modelDetails),
    sizes: CONFIG_SIZES,
  },
  ...(modelDetails?.failureReason
    ? {
        failureReason: {
          label: "Failure reason",
          value: modelDetails.failureReason,
          sizes: CONFIG_SIZES,
        },
      }
    : {}),
  checkpoints: {
    label: "Checkpoints",
    value: [...(modelDetails.checkpoints ?? [])].sort((a, b) => a.localeCompare(b, undefined, { numeric: true })), // [epoch_1, epoch_2, ..., epoch_n]
    sizes: { xs: 12 },
  },
});

const ModelDetailsSummary = ({ modelDetails }: Props) => {
  const dispatch = useDispatch();
  const classes = marqtuneStyles();
  const wrapperClasses = WrapperClasses();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"), { noSsr: true });
  const [modelActionsMenuAnchor, setModelActionsMenuAnchor] = useState(null);
  const [checkpointMenuAnchor, setCheckpointMenuAnchor] = useState(null);
  const [selectedCheckpoint, setSelectedCheckpoint] = useState<string | null>(null);
  const [releaseDialogOpen, setReleaseDialogOpen] = useState(false);
  const { modelName } = modelDetails;
  let styleClasses = MarqtuneStyleClasses();

  const hasCheckpoints = !isEmpty(modelDetails?.checkpoints);
  const modelConfig = modelDetailsConfig(modelDetails);

  const handleCheckpointActionsMenu = (e: React.MouseEvent, _checkpoint: string) => {
    e.stopPropagation();
    setCheckpointMenuAnchor(e.currentTarget);
    setSelectedCheckpoint(_checkpoint);
  };

  const handleModelActions = (e: React.MouseEvent) => {
    e.stopPropagation();
    setModelActionsMenuAnchor(e.currentTarget);
  };

  const confirmDeleteAgreeHandler = () => {
    setModelActionsMenuAnchor(null);
  };

  const handleModelActionsMenuClose = () => {
    setModelActionsMenuAnchor(null);
    setCheckpointMenuAnchor(null);
  };

  const handleDownloadCheckpoint = () => {
    if (modelDetails) {
      dispatch(
        downloadMarqtuneCheckpointThunk({
          modelId: modelDetails.modelId,
          checkpoint: selectedCheckpoint,
        }),
      );
    }
    handleModelActionsMenuClose();
  };

  const handleReleaseDialogClose = () => {
    setReleaseDialogOpen(false);
  };

  const handleReleaseClick = (checkpoint: string) => {
    setSelectedCheckpoint(checkpoint);
    handleModelActionsMenuClose();
    setReleaseDialogOpen(true);
  };

  return (
    <ContentSection id={"MarqtuneModelDetails-Summary"} mb={3}>
      <Grid container flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} mb={2}>
        <Grid item>
          <CardTitle title={modelDetails.modelName} noDivider typographyProps={{ variant: "h6" }} />
        </Grid>
        <Grid item>
          <IconButton onClick={handleModelActions}>
            <MoreVertRounded />
          </IconButton>
        </Grid>
      </Grid>

      {/* DETAILS SUMMARY */}

      <MarqtuneModelActionPrompt
        resourceName={modelName}
        resourceId={modelDetails.modelId}
        actionsMenuAnchor={modelActionsMenuAnchor}
        agreeHandler={confirmDeleteAgreeHandler}
        disagreeHandler={handleModelActionsMenuClose}
      />

      <Grid container flexDirection={"row"} spacing={1} mt={2} gap={lgUp ? 0 : 2}>
        <Grid container item xs={12} lg={8} pr={2} rowGap={2}>
          {Object.entries(modelConfig).map(([param, restOfConfig]) => {
            if (param === "checkpoints") return null;

            return <DetailGridDisplay key={`model-details-grid-${restOfConfig.label}`} {...restOfConfig} />;
          })}
        </Grid>

        {/* HEADER FOR CHECKPOINTS, RELEASED STATUS, AND MENU */}
        <Grid container item xs={12} lg={4} alignContent={"flex-start"} className={classes.gridPartition}>
          <Grid container item xs={12} alignItems="center">
            <Grid item xs={3} justifyContent="center">
              <Typography variant={"subtitle2"} color={"neutral.200"} fontWeight={600} align="center">
                Checkpoints
              </Typography>
            </Grid>
            <Grid item xs={8} justifyContent="center">
              <Typography variant={"subtitle2"} color={"neutral.200"} fontWeight={600} align="center">
                Available for index creation
              </Typography>
            </Grid>
            <Grid item xs={1} justifyContent="center">
              {/* Placeholder for the three dots icon header */}
            </Grid>
          </Grid>

          {/* LIST OF CHECKPOINTS, RELEASED STATUS, AND MENU */}
          {!hasCheckpoints ? (
            <Typography variant={"subtitle2"} color={"neutral.400"} sx={ellipsisStyles}>
              -
            </Typography>
          ) : (
            <Grid container item xs={12} justifyContent="center" className={classes.checkpointListWrapper}>
              <List className={classNames(classes.leanList, wrapperClasses.directLeanScrollbar, "pl-0")}>
                {(modelConfig.checkpoints.value as MarqtuneModel["checkpoints"]).reverse().map((checkpoint, index) => {
                  const isReleased = modelDetails.releasedCheckpoints?.includes(checkpoint);
                  return (
                    <ListItem key={`checkpoint-${index}`} className={classNames("listStyleNone")} sx={{ mb: 3 }}>
                      <Grid container justifyContent={"space-between"} alignItems={"center"}>
                        <Grid container item xs={3} justifyContent="center">
                          <Typography variant={"body2"} color={"textPrimary"} sx={{ fontWeight: 500 }}>
                            {checkpoint}
                          </Typography>
                        </Grid>
                        <Grid container item xs={8} justifyContent="center">
                          {!isReleased ? (
                            <LinkRouter
                              to="#"
                              className={styleClasses.releaseLink}
                              onClick={() => handleReleaseClick(checkpoint)}
                            >
                              Release
                            </LinkRouter>
                          ) : (
                            <Typography variant="subtitle2" sx={ellipsisStyles}>
                              Yes
                            </Typography>
                          )}
                        </Grid>
                        <Grid container item xs={1} justifyContent="center">
                          <IconButton
                            onClick={(e: React.MouseEvent) => handleCheckpointActionsMenu(e, checkpoint)}
                            sx={{ padding: 0 }}
                          >
                            <MoreVertRounded fontSize="small" />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </ListItem>
                  );
                })}
              </List>
            </Grid>
          )}
        </Grid>

        {/* CHECKPOINT ACTIONS MENU */}
        <Menu
          open={Boolean(checkpointMenuAnchor)}
          anchorEl={checkpointMenuAnchor}
          onClose={() => setCheckpointMenuAnchor(null)}
        >
          <MenuItem onClick={handleDownloadCheckpoint}>
            <ListItemIcon>
              <Download fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit">Download</Typography>
          </MenuItem>
        </Menu>
      </Grid>

      <Box mt={3}>
        <LogsViewer
          isResourceTaskFinished={isFinished(modelDetails)}
          sectionId={"ModelDetails"}
          resourceId={modelDetails.modelId}
          dispatchIntervalInSec={30}
          dispatchAction={listMarqtuneModelLogsThunk}
          downloadLogsDispatchAction={downloadMarqtuneModelLogsThunk}
          resourceLogs={modelDetails?.resourceLogs}
        />
      </Box>

      {selectedCheckpoint && !modelDetails.releasedCheckpoints?.includes(selectedCheckpoint) && (
        <ReleaseCheckpointDialog
          open={releaseDialogOpen}
          modelName={modelDetails.modelName}
          modelId={modelDetails.modelId}
          checkpointList={[selectedCheckpoint]}
          onClose={handleReleaseDialogClose}
        />
      )}
    </ContentSection>
  );
};

export default ModelDetailsSummary;
