import { Alert, Box, Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { isEmpty } from "lodash";
import Config from "../../../config";
import { useSelector } from "../../../store";
import { selectActiveAccounts } from "../../../store/selectors";
import { LinkRouter } from "../../content-layout/content-layout.component";

/**
 * Informs the user that they need to select or create an account for functionality.
 */
export default function GuestBanner() {
  return (
    <Box sx={{ pb: 3 }} data-testid="guest-billing-banner">
      <Alert severity="info">
        <SelectOrCreateAccountMessage />
      </Alert>
    </Box>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: "#2196F3", // TODO(oliver): Derive this color from the theme.
    cursor: "pointer",
    "&:hover": { textDecoration: "underline" },
  },
}));

/**
 * Displays a message about requiring an account, including a link to create one, and a link to
 * select one if the user is a member of some already.
 */
export function SelectOrCreateAccountMessage() {
  const accounts = useSelector(selectActiveAccounts);

  const url = Config.authenticatedPaths.createAccount;

  const classes = useStyles();

  /**
   * Finds the account selector in the DOM and clicks it to show available accounts.
   *
   * This is a bit of a hack rather than setting some global state to open the selector.
   */
  const clickAccountSelector = () => {
    const selector = document.getElementById("account-selector");
    selector.dispatchEvent(new MouseEvent("mousedown", { bubbles: true }));
  };

  return (
    <Typography>
      {!isEmpty(accounts) ? (
        <>
          {/* biome-ignore lint/a11y/useKeyWithClickEvents: need to think through a11y handling. */}
          <span className={classes.link} onClick={clickAccountSelector}>
            Select existing
          </span>{" "}
          or{" "}
          <LinkRouter to={url} variant="p" className={classes.link}>
            create additional
          </LinkRouter>{" "}
          account for full access to Marqo Cloud features.
        </>
      ) : (
        <>
          <LinkRouter to={url} variant="p" className={classes.link}>
            Add billing details
          </LinkRouter>{" "}
          for full access to Marqo Cloud features.
        </>
      )}
    </Typography>
  );
}
