import { AxiosError, AxiosPromise } from "axios";
import toast from "react-hot-toast";

export const setIntegrationAPIKeyToast = (promise: AxiosPromise) => {
  let defaultToastErr = "Failed to set the api key";

  toast.promise(promise, {
    loading: "Setting the api key...",
    success: "Successfully set the integration api key",
    error: (e) => {
      const err = e as AxiosError;
      const error_msg = err?.response?.data?.error_msg || null;
      defaultToastErr = error_msg || defaultToastErr;

      return defaultToastErr;
    },
  });
};
