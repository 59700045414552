import { ExtensionRounded, Group, Help, MusicNoteRounded, Payment, VpnKey, Workspaces } from "@mui/icons-material";
import { Box, Button, Drawer, Link, Theme, useMediaQuery } from "@mui/material";
import React, { useMemo } from "react";
import config from "../../config";
import QuickLinks from "../../constants/links";
import { LinkRouter } from "../content-layout/content-layout.component";
import MarqoVector from "../logo/marqo-vector";
import WrappedMarqoLogo from "../logo/wrapped-marqo-logo";
import { Scrollbar } from "../scrollbar/scrollbar.component";
import { DashboardSidebarSection } from "./dashboard-sidebar-section.component";
import {
  DRAWER_PAPER_PROPS_SX_BASE,
  DRAWER_PAPER_PROPS_SX_LG_UP,
  DashboardSidebarStyles,
} from "./dashboard-sidebar.styles";
import { DashboardSidebarProps, GetSectionsType } from "./dashboard-sidebar.types";

export const getSections: GetSectionsType = (withIntegrations, withBilling, withMarqtuneUI) => [
  {
    items: [
      {
        title: "Indexes",
        path: config.authenticatedPaths.indexes,
        icon: <Workspaces fontSize="small" />,
      },
      ...(withMarqtuneUI
        ? [
            {
              title: "Marqtune",
              path: config.authenticatedPaths.marqtune.root,
              icon: <MusicNoteRounded fontSize="small" />,
            },
          ]
        : []),
      {
        title: "Team Members",
        path: config.authenticatedPaths.members,
        icon: <Group fontSize="small" />,
      },
      {
        title: "API Keys",
        path: config.authenticatedPaths.apiKeys,
        icon: <VpnKey fontSize="small" />,
      },
      ...(withIntegrations
        ? [
            {
              title: "Integrations",
              path: config.authenticatedPaths.integrations,
              icon: <ExtensionRounded fontSize="small" />,
            },
          ]
        : []),
      ...(withBilling
        ? [
            {
              title: "Billing",
              path: config.authenticatedPaths.billing,
              icon: <Payment fontSize="small" />,
            },
          ]
        : []),
    ],
  },
];

export const DashboardSidebar: React.FC<DashboardSidebarProps> = (props): JSX.Element => {
  const { onClose, open, withIntegrations, withBilling, withMarqtuneUI } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"), { noSsr: true });
  const sections = useMemo(() => getSections(withIntegrations, withBilling, withMarqtuneUI), []);
  const classes = DashboardSidebarStyles();
  const content = (
    <Scrollbar className={classes.scrollbar}>
      <Box className="scrollbar-box">
        <LinkRouter to={"/"}>
          <WrappedMarqoLogo rootSx={{ pt: 3, pb: 2, px: 0, ml: 0 }} />
        </LinkRouter>

        <Box className="sidebar-section-wrapper">
          {sections.map((section, index) => (
            <DashboardSidebarSection key={index} sx={{ mt: 2, "& + &": { mt: 2 } }} {...section} />
          ))}
        </Box>

        <Box className="bottom-links-wrapper">
          <Link
            component={Button}
            href={QuickLinks.zendesk_root}
            target={"_blank"}
            rel="noopener"
            fullWidth
            className={classes.bottomLink}
          >
            <Box mr={1} display={"flex"}>
              <Help fontSize="small" />
            </Box>
            Contact Support
          </Link>
          <Link
            component={Button}
            href={QuickLinks.cloud_doc}
            target={"_blank"}
            rel="noopener"
            fullWidth
            className={classes.bottomLink}
          >
            <Box mr={1} display={"flex"} alignItems={"center"}>
              <MarqoVector />
            </Box>
            Documentation
          </Link>
        </Box>
      </Box>
    </Scrollbar>
  );

  if (lgUp) {
    return (
      <Drawer open anchor="left" variant="permanent" PaperProps={{ sx: DRAWER_PAPER_PROPS_SX_LG_UP }}>
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      variant="temporary"
      sx={{ zIndex: (theme) => theme.zIndex.appBar + 100 }}
      PaperProps={{ sx: DRAWER_PAPER_PROPS_SX_BASE }}
    >
      {content}
    </Drawer>
  );
};
