export const DEFAULT_PROMPT_DIALOG_MSG = "Are you sure?";
export const TOOLTIP_MSG_SPLIT_INDEX =
  "Operation will create new index with copied data across and increased number of pods.";

// inference
export const TOOLTIP_MSG_INFERENCE_PODS =
  "Operation will reconfigure inference pods according to new instance type and/or number of inference pods.";
export const GPU_TO_CPU_WARNING =
  'If you are using a GPU instance and have device="cuda", you will receive an error when switching to a CPU instance type. Please ensure you check this before switching instance type.';
export const PRE_ELIMINATING_PODS_WARNING =
  "If you set inference pods to 0, your Marqo cluster will become unavailable until you increase the pod count back to 1 or more.";
export const CANNOT_ELIMINATE_PODS_ERROR = "Unable to execute request. Inference pods are already set to 0.";
export const CANNOT_ELIMINATE_PODS_WARNING = "Inference pods are already set to 0.";
export const INFERENCE_EDIT_WARNING =
  "Your inference pods might be unavailable after switching instance types. Please ensure you check this before applying changes.";
export const POST_INFERENCE_EDIT_WARNING =
  "You recently reconfigured your inference pods. As a result of these changes, it is possible that indexing and other operations may be temporarily unavailable.";
export const BELOW_MIN_NO_OF_MODELS_ERROR = "Select at least 1 model.";
export const EXCEEDED_MAX_NO_OF_MODELS_ERROR = "You can only select a maximum of 3 models.";

// indexes
export const MARQO_V1_MINOR_VERSION = "1.4.0";
export const RESTRICTED_INDEX_ACTIONS_MSG = "Index deletion and modification are restricted when state is not ready.";

// billing
export const BILLING_PORTAL_SECTION_INFO =
  "In the billing portal you can view historic invoices and update billing information.";
// auth
export const EMAIL_IS_ALREADY_REGISTERED = "This email is already registered with a Marqo account.";

export const DISALLOW_GT2_GPU_CPU_LARGE_BASIC_MSG =
  "The maximum number of GPU or CPU.large inference pods is 2 times the number of shards for indexes with basic storage.";
