import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";
import { RadioGroupFieldProps } from "./select-field";

const RadioGroupField: React.FC<RadioGroupFieldProps> = ({ options, ...props }) => {
  return (
    <RadioGroup row {...props}>
      {options &&
        options.map(({ value, label }, key) => {
          return (
            <FormControlLabel key={key} value={value} control={<Radio disabled={!!props?.disabled} />} label={label} />
          );
        })}
    </RadioGroup>
  );
};

export default RadioGroupField;
