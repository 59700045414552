import { createSlice } from "@reduxjs/toolkit";
import { getIntegrationsThunk } from "../../thunks/integrations.thunk";

type InitialState = {
  isFetching: boolean;
  datadog: {
    key: string;
    site: string;
  };
};

const initialState: InitialState = {
  isFetching: false,
  datadog: {
    key: "",
    site: "",
  },
};

const slice = createSlice({
  name: "integrations",
  initialState,
  reducers: {
    setIsFetchingIntegrations: (state, { payload }) => {
      state.isFetching = payload;
    },
    setIntegrations: (state, { payload }) => {
      state.isFetching = false;
      state.datadog.key = payload.datadog_api_key;
      state.datadog.site = payload.datadog_site;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getIntegrationsThunk.fulfilled, (state, { payload }) => {
        state.isFetching = false;
        state.datadog.key = !!payload.datadog_api_key ? payload.datadog_api_key : "";
        state.datadog.site = !!payload.datadog_site ? payload.datadog_site : "";
      })
      .addCase(getIntegrationsThunk.rejected, (state, _) => {
        state.isFetching = initialState.isFetching;
        state.datadog = { ...initialState.datadog };
      });
  },
});

export const { setIsFetchingIntegrations, setIntegrations } = slice.actions;
export default slice.reducer;
