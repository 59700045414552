//For common constants

export const ESTIMATED_HOURS = 730;
export const API_KEYS_LIMIT = 10;
export const INDEX_LIMIT = 5;
export const INDEX_CONFIG_LIMITS = {
  inference: { min: 1 }, // max: 5
  replica: { min: 0 }, // max: 1
  shards: { min: 1 }, // max: 5
  nameLength: {
    min: 1,
    max: 32,
  },
};

export const INDEX_PROCESS_AVE_DURATIONS = {
  v2_index_creation: 5,
  v1_index_creation: 10,
};

export const TEXT_OPTIMISED_MODELS = [
  "sentence-transformers/all-MiniLM-L6-v1",
  "sentence-transformers/all-MiniLM-L6-v2",
  "sentence-transformers/all-mpnet-base-v1",
  "sentence-transformers/all-mpnet-base-v2",
  "sentence-transformers/stsb-xlm-r-multilingual",
  "flax-sentence-embeddings/all_datasets_v3_MiniLM-L12",
  "flax-sentence-embeddings/all_datasets_v3_MiniLM-L6",
  "flax-sentence-embeddings/all_datasets_v4_MiniLM-L12",
  "flax-sentence-embeddings/all_datasets_v4_MiniLM-L6",
  "flax-sentence-embeddings/all_datasets_v3_mpnet-base",
  "flax-sentence-embeddings/all_datasets_v4_mpnet-base",
  "hf/e5-small",
  "hf/e5-base",
  "hf/e5-large",
  "hf/e5-small-unsupervised",
  "hf/e5-base-unsupervised",
  "hf/e5-large-unsupervised",
  "hf/e5-small-v2",
  "hf/e5-base-v2",
  "hf/e5-large-v2",
  "hf/multilingual-e5-small",
  "hf/multilingual-e5-base",
  "hf/multilingual-e5-large",

  "onnx/all-MiniLM-L6-v1",
  "onnx/all-MiniLM-L6-v2",
  "onnx/all_datasets_v3_MiniLM-L12",
  "onnx/all_datasets_v3_MiniLM-L6",
  "onnx/all_datasets_v3_mpnet-base",
  "onnx/all_datasets_v4_MiniLM-L12",
  "onnx/all_datasets_v4_MiniLM-L6",
  "onnx/all_datasets_v4_mpnet-base",

  "all-MiniLM-L6-v1",
  "all-MiniLM-L6-v2",
  "all-mpnet-base-v1",
  "all-mpnet-base-v2",
  "all_datasets_v3_MiniLM-L12",
  "all_datasets_v3_MiniLM-L6",
  "all_datasets_v3_mpnet-base",
  "all_datasets_v4_MiniLM-L12",
  "all_datasets_v4_MiniLM-L6",
  "all_datasets_v4_mpnet-base",

  "hf/all-MiniLM-L6-v1",
  "hf/all-MiniLM-L6-v2",
  "hf/all-mpnet-base-v1",
  "hf/all-mpnet-base-v2",
  "hf/all_datasets_v3_MiniLM-L12",
  "hf/all_datasets_v3_MiniLM-L6",
  "hf/all_datasets_v3_mpnet-base",
  "hf/all_datasets_v4_MiniLM-L12",
  "hf/all_datasets_v4_MiniLM-L6",
  "hf/all_datasets_v4_mpnet-base",
];

export const IMAGE_COMPATIBLE_MODELS = [
  "RN101",
  "RN50",
  "RN50x16",
  "RN50x4",
  "RN50x64",
  "ViT-B/16",
  "ViT-B/32",
  "ViT-L/14",
  "ViT-L/14@336px",
  "fp16/ViT-B/16",
  "fp16/ViT-B/32",
  "fp16/ViT-L/14",

  "onnx16/open_clip/RN101-quickgelu/openai",
  "onnx16/open_clip/RN101-quickgelu/yfcc15m",
  "onnx16/open_clip/RN101/openai",
  "onnx16/open_clip/RN101/yfcc15m",
  "onnx16/open_clip/RN50-quickgelu/cc12m",
  "onnx16/open_clip/RN50-quickgelu/openai",
  "onnx16/open_clip/RN50-quickgelu/yfcc15m",
  "onnx16/open_clip/RN50/cc12m",
  "onnx16/open_clip/RN50/openai",
  "onnx16/open_clip/RN50/yfcc15m",
  "onnx16/open_clip/RN50x16/openai",
  "onnx16/open_clip/RN50x4/openai",
  "onnx16/open_clip/RN50x64/openai",
  "onnx16/open_clip/ViT-B-16-plus-240/laion400m_e31",
  "onnx16/open_clip/ViT-B-16-plus-240/laion400m_e32",
  "onnx16/open_clip/ViT-B-16/laion400m_e31",
  "onnx16/open_clip/ViT-B-16/laion400m_e32",
  "onnx16/open_clip/ViT-B-16/openai",
  "onnx16/open_clip/ViT-B-32-quickgelu/laion400m_e31",
  "onnx16/open_clip/ViT-B-32-quickgelu/laion400m_e32",
  "onnx16/open_clip/ViT-B-32-quickgelu/openai",
  "onnx16/open_clip/ViT-B-32/laion2b_e16",
  "onnx16/open_clip/ViT-B-32/laion400m_e31",
  "onnx16/open_clip/ViT-B-32/laion400m_e32",
  "onnx16/open_clip/ViT-B-32/openai",
  "onnx16/open_clip/ViT-H-14/laion2b_s32b_b79k",
  "onnx16/open_clip/ViT-L-14-336/openai",
  "onnx16/open_clip/ViT-L-14/laion2b_s32b_b82k",
  "onnx16/open_clip/ViT-L-14/laion400m_e32",
  "onnx16/open_clip/ViT-L-14/openai",
  "onnx16/open_clip/ViT-g-14/laion2b_s12b_b42k",
  "onnx16/openai/ViT-L/14",
  "onnx32/open_clip/RN101-quickgelu/openai",
  "onnx32/open_clip/RN101-quickgelu/yfcc15m",
  "onnx32/open_clip/RN101/openai",
  "onnx32/open_clip/RN101/yfcc15m",
  "onnx32/open_clip/RN50-quickgelu/cc12m",
  "onnx32/open_clip/RN50-quickgelu/openai",
  "onnx32/open_clip/RN50-quickgelu/yfcc15m",
  "onnx32/open_clip/RN50/cc12m",
  "onnx32/open_clip/RN50/openai",
  "onnx32/open_clip/RN50/yfcc15m",
  "onnx32/open_clip/RN50x16/openai",
  "onnx32/open_clip/RN50x4/openai",
  "onnx32/open_clip/RN50x64/openai",
  "onnx32/open_clip/ViT-B-16-plus-240/laion400m_e31",
  "onnx32/open_clip/ViT-B-16-plus-240/laion400m_e32",
  "onnx32/open_clip/ViT-B-16/laion400m_e31",
  "onnx32/open_clip/ViT-B-16/laion400m_e32",
  "onnx32/open_clip/ViT-B-16/openai",
  "onnx32/open_clip/ViT-B-32-quickgelu/laion400m_e31",
  "onnx32/open_clip/ViT-B-32-quickgelu/laion400m_e32",
  "onnx32/open_clip/ViT-B-32-quickgelu/openai",
  "onnx32/open_clip/ViT-B-32/laion2b_e16",
  "onnx32/open_clip/ViT-B-32/laion400m_e31",
  "onnx32/open_clip/ViT-B-32/laion400m_e32",
  "onnx32/open_clip/ViT-B-32/openai",
  "onnx32/open_clip/ViT-H-14/laion2b_s32b_b79k",
  "onnx32/open_clip/ViT-L-14-336/openai",
  "onnx32/open_clip/ViT-L-14/laion2b_s32b_b82k",
  "onnx32/open_clip/ViT-L-14/laion400m_e32",
  "onnx32/open_clip/ViT-L-14/openai",
  "onnx32/open_clip/ViT-g-14/laion2b_s12b_b42k",
  "onnx32/openai/ViT-L/14",
  "open_clip/RN101-quickgelu/openai",
  "open_clip/RN101-quickgelu/yfcc15m",
  "open_clip/RN101/openai",
  "open_clip/RN101/yfcc15m",
  "open_clip/RN50-quickgelu/cc12m",
  "open_clip/RN50-quickgelu/openai",
  "open_clip/RN50-quickgelu/yfcc15m",
  "open_clip/RN50/cc12m",
  "open_clip/RN50/openai",
  "open_clip/RN50/yfcc15m",
  "open_clip/RN50x16/openai",
  "open_clip/RN50x4/openai",
  "open_clip/RN50x64/openai",
  "open_clip/ViT-B-16-plus-240/laion400m_e31",
  "open_clip/ViT-B-16-plus-240/laion400m_e32",
  "open_clip/ViT-B-16/laion2b_s34b_b88k",
  "open_clip/ViT-B-16/laion400m_e31",
  "open_clip/ViT-B-16/laion400m_e32",
  "open_clip/ViT-B-16/openai",
  "open_clip/ViT-B-32-quickgelu/laion400m_e31",
  "open_clip/ViT-B-32-quickgelu/laion400m_e32",
  "open_clip/ViT-B-32-quickgelu/openai",
  "open_clip/ViT-B-32/laion2b_e16",
  "open_clip/ViT-B-32/laion2b_s34b_b79k",
  "open_clip/ViT-B-32/laion400m_e31",
  "open_clip/ViT-B-32/laion400m_e32",
  "open_clip/ViT-B-32/openai",
  "open_clip/ViT-H-14/laion2b_s32b_b79k",
  "open_clip/ViT-L-14-336/openai",
  "open_clip/ViT-L-14/laion2b_s32b_b82k",
  "open_clip/ViT-L-14/laion400m_e31",
  "open_clip/ViT-L-14/laion400m_e32",
  "open_clip/ViT-L-14/openai",
  "open_clip/ViT-bigG-14/laion2b_s39b_b160k",
  "open_clip/ViT-g-14/laion2b_s12b_b42k",
  "open_clip/ViT-g-14/laion2b_s34b_b88k",
  "open_clip/coca_ViT-B-32/laion2b_s13b_b90k",
  "open_clip/coca_ViT-B-32/mscoco_finetuned_laion2b_s13b_b90k",
  "open_clip/coca_ViT-L-14/laion2b_s13b_b90k",
  "open_clip/coca_ViT-L-14/mscoco_finetuned_laion2b_s13b_b90k",
  "open_clip/convnext_base/laion400m_s13b_b51k",
  "open_clip/convnext_base_w/laion2b_s13b_b82k",
  "open_clip/convnext_base_w/laion2b_s13b_b82k_augreg",
  "open_clip/convnext_base_w/laion_aesthetic_s13b_b82k",
  "open_clip/convnext_base_w_320/laion_aesthetic_s13b_b82k",
  "open_clip/convnext_base_w_320/laion_aesthetic_s13b_b82k_augreg",
  "open_clip/convnext_large_d/laion2b_s26b_b102k_augreg",
  "open_clip/convnext_large_d_320/laion2b_s29b_b131k_ft",
  "open_clip/convnext_large_d_320/laion2b_s29b_b131k_ft_soup",
  "open_clip/roberta-ViT-B-32/laion2b_s12b_b32k",
  "open_clip/xlm-roberta-base-ViT-B-32/laion5b_s13b_b90k",
  "open_clip/xlm-roberta-large-ViT-H-14/frozen_laion5b_s13b_b90k",

  "multilingual-clip/XLM-Roberta-Large-Vit-L-14",
  "multilingual-clip/XLM-R-Large-Vit-B/16+",
  "multilingual-clip/XLM-Roberta-Large-Vit-B-32",
  "multilingual-clip/LABSE-Vit-L-14",
];

export const AUDIO_VIDEO_COMPATIBLE_MODELS = ["LanguageBind/Video_V1.5_FT_Audio_FT_Image"];

export const DEFAULT_TEXT_BASED_MODEL = "hf/e5-large";
export const DEFAULT_IMAGE_MODEL = "open_clip/ViT-L-14/laion2b_s32b_b82k"; // "ViT-L/14"
export const DEFAULT_AUDIO_VIDEO_MODEL = "LanguageBind/Video_V1.5_FT_Audio_FT_Image";

export const V1_RECOMMENDED_TEXT_INDEX_DEFAULTS = `{
  "treat_urls_and_pointers_as_images": false,
  "model": "hf/e5-large",
  "normalize_embeddings": true,
  "text_preprocessing": {
    "split_length": 2,
    "split_overlap": 0,
    "split_method": "sentence"
  },
  "image_preprocessing": {
    "patch_method": null
  },
  "ann_parameters": {
    "space_type": "cosinesimil",
    "parameters": {
      "ef_construction": 128,
      "m": 16
    }
  }
}`;

export const V1_RECOMMENDED_MULTIMODAL_INDEX_DEFAULTS = `{
  "treat_urls_and_pointers_as_images": true,
  "model": "open_clip/ViT-L-14/laion2b_s32b_b82k",
  "normalize_embeddings": true,
  "text_preprocessing": {
    "split_length": 2,
    "split_overlap": 0,
    "split_method": "sentence"
  },
  "image_preprocessing": {
    "patch_method": null
  },
  "ann_parameters": {
    "space_type": "cosinesimil",
    "parameters": {
      "ef_construction": 128,
      "m": 16
    }
  }
}`;
