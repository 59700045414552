import { Theme, alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getNeutralRgb } from "../../theme/dark-theme-options";

export const CodeSnippetsWrapperStyles = makeStyles((theme: Theme) => ({
  codeSnippetsWrapper: {
    "& .terminal-wrapper": {
      width: "100%",
      borderRadius: "10px",
      backgroundColor: "#1A1B28 !important",
    },
    "& .terminal-header": {
      textTransform: "uppercase",
      borderRadius: "10px",
      borderBottomLeftRadius: "0",
      borderBottomRightRadius: "0",
      border: `1px solid ${alpha(getNeutralRgb("150"), 0.3)} !important`,
    },
    "& .highlight-wrapper": {
      borderBottomLeftRadius: "10px",
      borderBottomRightRadius: "10px",
      width: "100%",
      border: `1px solid ${alpha(getNeutralRgb("150"), 0.3)}`,
      borderTop: "0",
    },
    "& .tab-wrapper": {
      backgroundColor: "#1A1B28",
      border: `1px solid ${alpha(getNeutralRgb("150"), 0.3)}`,
      borderRadius: "10px",
      overflow: "hidden",
    },
    "& .sub-header": {
      borderRadius: "10px",

      [theme.breakpoints.up("md")]: {
        "&:first-of-type": {
          borderBottomRightRadius: "0",
          borderBottomLeftRadius: "0",
          borderBottom: `1px solid ${alpha(getNeutralRgb("400"), 0.3)}`,
        },
      },
      [theme.breakpoints.down("md")]: {
        "&:first-of-type": {
          borderBottomLeftRadius: "0",
          borderBottomRightRadius: "0",
          borderBottom: `1px solid ${alpha(getNeutralRgb("400"), 0.3)}`,
        },
      },
    },
    "& .MuiTab-root[aria-selected='false']": {
      color: `${alpha(theme.palette.primary.main, 0.4)} !important`,
    },
    "& .MuiTab-root[aria-selected='true']": {
      color: `${theme.palette.primary.main} !important`,
    },
    "& pre": {
      fontFamily: "Fira Code",
      fontSize: "0.875rem",
      padding: "2em 3em",
      width: "100%",
      borderRadius: "10px",
      borderTopLeftRadius: "0",
      borderTopRightRadius: "0",
      overflow: "auto",
      backgroundColor: "#1A1B28 !important",

      [theme.breakpoints.down("lg")]: {
        fontSize: "0.675rem",
      },
    },
    "& .CodeSnippetsWrapper-Tab": {
      fontWeight: 500,
      fontSize: "0.8rem",
    },
  },
}));
