import axios, { AxiosPromise } from "axios";
import config from "../../config";
import { endpoints } from "../../config/common";
import { AccountId } from "../api.types";
import { GetAccountLimitsResponse, ResGetUserData } from "./user.types";

const getUserData = (accountId: AccountId): AxiosPromise<ResGetUserData> => {
  return axios({
    method: "GET",
    baseURL: config.baseURL,
    url: endpoints.account,
    params: { accountId },
  });
};

const getAccountLimits = (accountId: AccountId): AxiosPromise<GetAccountLimitsResponse> => {
  return axios({
    method: "GET",
    baseURL: config.baseURL,
    url: endpoints.getAccountLimits,
    params: { accountId },
  });
};

const getMarqoEndpoint = (accountId: AccountId): AxiosPromise<any> => {
  return axios({
    method: "GET",
    baseURL: config.baseURL,
    url: endpoints.getMarqoEndpoint,
    params: { accountId },
  });
};

const setLastSelectedAccount = (accountId: AccountId): AxiosPromise<any> => {
  return axios({
    method: "PUT",
    baseURL: config.baseURL,
    url: endpoints.setLastSelectedAccount,
    params: { accountId },
  });
};

const User = {
  getData: getUserData,
  getAccountLimits,
  getMarqoEndpoint,
  setLastSelectedAccount,
};
export default User;
