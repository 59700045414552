import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const LogoClasses = makeStyles((theme: Theme) => ({
  logo: {
    marginLeft: "-3px",
  },
  neonGreenHippoWhiteText: {
    "& path": {
      fill: theme.palette.text.primary,
    },
    "& path:last-of-type": {
      fill: theme.palette.primary.main,
    },
  },
}));
