import { AxiosError, AxiosPromise } from "axios";
import toast, { Renderable } from "react-hot-toast";
import { FormFieldErrorsProps } from "../../api/api.types";

const baseToast = (promise: AxiosPromise, loadingMsg: Renderable, successMsg: Renderable, failMsg: Renderable = "") => {
  const defaultToastError = "Error sending request";

  return toast.promise(promise, {
    loading: loadingMsg,
    success: successMsg,
    error: (e) => {
      const err = e as AxiosError;
      let error_msg;

      try {
        error_msg = err?.response?.data?.error_msg || err?.response?.data?.error || null;

        if (!!failMsg) {
          error_msg = failMsg;
        } else if (!error_msg) {
          const parsedFieldErrors = JSON.parse(err?.response?.data?.error) as FormFieldErrorsProps;
          error_msg = Object.values(parsedFieldErrors)
            .flat()
            .map(({ message }) => message);
        } else if (typeof error_msg === "string") {
          try {
            const parsedFieldErrors = JSON.parse(error_msg) as FormFieldErrorsProps;
            error_msg = Object.values(parsedFieldErrors)
              .flat()
              .map(({ message }) => message);
          } catch (innerSyntaxError) {}
        }
      } catch (innerErr) {
        error_msg = null;
      }

      return error_msg || defaultToastError;
    },
  });
};

export default baseToast;
