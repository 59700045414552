import { Theme, alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const indexDetailsStyles = makeStyles((theme: Theme) => ({
  indexGrid: {
    "&.index-grid-partition": {
      rowGap: "1rem",

      [theme.breakpoints.up("md")]: {
        paddingLeft: "1.5rem",
        paddingRight: "1.5rem",
      },

      [theme.breakpoints.down("md")]: {
        paddingTop: "1rem",
      },

      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
        justifyContent: "space-between",
        paddingLeft: "0",
        paddingRight: "0",
      },

      "&:first-of-type": {
        paddingLeft: "0",
        paddingRight: "0",

        [theme.breakpoints.up("md")]: {
          borderRight: `1px solid ${alpha(theme.palette.text.disabled, 0.1)}`,
        },
        [theme.breakpoints.down("md")]: {
          paddingTop: 0,
          paddingBottom: "1rem",
          borderBottom: `1px solid ${alpha(theme.palette.text.disabled, 0.1)}`,
        },
      },
    },
  },
}));
