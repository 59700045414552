import { TextField, TextFieldProps, Theme, useMediaQuery } from "@mui/material";
import React from "react";

const MultilineField: React.FC<TextFieldProps> = (props) => {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"), { noSsr: true });
  const mdOnly = useMediaQuery((theme: Theme) => theme.breakpoints.only("md"), { noSsr: true });

  return (
    <TextField
      {...props}
      autoComplete={"off"}
      autoCorrect={"off"}
      autoCapitalize={"off"}
      spellCheck={false}
      multiline
      fullWidth={true}
      rows={20}
      InputProps={{
        style: {
          fontFamily: "Courier New, monospace",
          fontSize: lgUp ? "0.9rem" : mdOnly ? "0.8rem" : "0.7rem",
        },
      }}
    />
  );
};

export default MultilineField;
