import account from "./account/account.api";
import auth from "./auth/auth.api";
import billing from "./billing/billing.api";
import indexes from "./indexes/indexes.api";
import integrations from "./integrations/api";
import marqtune from "./marqtune/api";
import settings from "./settings/settings.api";
import stripeIntegration from "./stripe/stripe.api";
import user from "./user/user.api";

const API = {
  account,
  auth,
  billing,
  indexes,
  integrations,
  marqtune,
  settings,
  stripeIntegration,
  user,
};
export default API;
