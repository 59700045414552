import { Grid, List, ListItem, Typography } from "@mui/material";
import { Fragment, useEffect } from "react";
import { batch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import ContentLayout from "../../components/content-layout/content-layout.component";
import { BreadCrumbItem } from "../../components/content-layout/content-layout.types";
import FetchingDisplay from "../../components/fetching-display/fetching-display.component";
import CheckpointEvaluation from "../../components/page-parts/marqtune/model-details/checkpoint-evaluation.component";
import ModelDetailsSummary from "../../components/page-parts/marqtune/model-details/summary.component";
import {
  MarqtuneEvaluationGuideQuickLink,
  SettingMarqtuneEvaluationSchemaParamQuickLink,
} from "../../components/page-parts/quick-links/quick-links";
import config from "../../config";
import { useBreadcrumbs } from "../../hooks/use-breadcrumbs";
import { useDispatch, useSelector } from "../../store";
import { selectEvaluationDatasets, selectModelByModelId } from "../../store/selectors";
import { listMarqtuneDatasetsThunk, listMarqtuneModelsThunk } from "../../thunks/marqtune.thunk";
import { marqtuneStyles } from "./marqtune.styles";

const breadcrumbsList: BreadCrumbItem[] = [{ title: "Marqtune", link: "/marqtune" }];

export const CheckpointGuide = () => {
  const classes = marqtuneStyles();

  return (
    <Grid container className={classes.guide} gap={2} mb={3}>
      <Typography variant={"subtitle2"}>
        A Marqtune Model is a collection of checkpoints from a training task. Each completed epoch is stored as a
        checkpoint. Each checkpoint contains a complete model.
      </Typography>

      <Grid container className={classes.guide} gap={2}>
        {/* MODEL GUIDE */}

        <Grid container>
          <Typography variant={"subtitle2"}>Checkpoints can be:</Typography>
        </Grid>

        <Grid container mt={-2}>
          <List className={classes.leanList}>
            {[
              "evaluated (see the details below)",
              "used as a base model for a new training task",
              "released so they can be used in a Marqo Index",
              "downloaded to be used with an external application",
            ].map((item, index) => (
              <ListItem key={index} className="disc">
                <Typography variant={"subtitle2"}>{item}</Typography>
              </ListItem>
            ))}
          </List>
        </Grid>
      </Grid>

      <Typography variant={"subtitle2"}>
        You can evaluate the performance of your trained checkpoint using the code snippet below. You can also evaluate
        checkpoints against other checkpoints or other models via the API. See the {MarqtuneEvaluationGuideQuickLink}{" "}
        for more details.
      </Typography>

      <Typography variant={"subtitle2"}>
        Select the checkpoint you would like to evaluate and your evaluation dataset to populate the code snippets
        below. To create an evaluation dataset, create a dataset via the API and{" "}
        {SettingMarqtuneEvaluationSchemaParamQuickLink}.
      </Typography>
    </Grid>
  );
};

const MarqtuneModelDetailsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { modelId } = useParams();
  const { isFetching } = useSelector(({ marqtune }) => marqtune.models);
  const evaluationDatasets = useSelector(selectEvaluationDatasets);
  const { setBreadcrumbs, clearBreadcrumbs } = useBreadcrumbs();
  const modelDetails = useSelector(selectModelByModelId(modelId));
  const matchingDetailsId = modelDetails?.modelId;
  const pageTitle = modelDetails?.modelName || "-";

  useEffect(() => {
    batch(async () => {
      dispatch(listMarqtuneDatasetsThunk());
      dispatch(listMarqtuneModelsThunk());
    });

    setBreadcrumbs(breadcrumbsList);
    return () => clearBreadcrumbs();
  }, []);

  useEffect(() => {
    if (!matchingDetailsId && !isFetching) {
      navigate(config.authenticatedPaths.marqtune.root);
    }
  }, [matchingDetailsId, isFetching]);

  return (
    <ContentLayout pageTitle={pageTitle} headTitle={pageTitle}>
      {isFetching || !matchingDetailsId ? (
        <FetchingDisplay />
      ) : (
        <Fragment>
          <ModelDetailsSummary modelDetails={modelDetails} isFetching={isFetching} />
          <CheckpointGuide />
          <CheckpointEvaluation
            checkpoints={modelDetails.checkpoints}
            datasets={evaluationDatasets}
            modelId={modelId}
          />
        </Fragment>
      )}
    </ContentLayout>
  );
};

export default MarqtuneModelDetailsPage;
