export function prettifyJSON(value: string | null, indentation = 2) {
  try {
    if (value === null) {
      return "";
    }

    return JSON.stringify(JSON.parse(value), null, indentation);
  } catch (err) {
    return "";
  }
}

export function stringifyDictOrEmpty(value: Record<string, any>, indentation = 2) {
  try {
    return JSON.stringify(value, null, indentation) || "";
  } catch (err) {
    return "";
  }
}

export function getParsedJsonOrEmptyDict(value: string | null) {
  try {
    if (value === null) {
      return {};
    }

    return JSON.parse(value) || {};
  } catch (err) {
    return {};
  }
}

export const plural = (val: string | number): string => {
  val = Number(val);
  return val > 1 || val === 0 ? "s" : "";
};
