import { ModelInfo } from "../../api/indexes/indexes.types";
import { MarqoVersion } from "../../constants/enums";
import { indexNameRegex, v2IndexNameRegex } from "../../constants/validation";
import { prettifyJSON } from "../../utils/formatters";
import { CreateIndexFormProps, ModifyIndexFormProps } from "./types";

// Regular expression to match the format marqtune/uuid/string
const marqtuneModelRegex = /^marqtune\/[0-9a-fA-F-]{36}\/[\w-]+$/;

const indexDefaultsValidJSON = (val: any) => {
  try {
    const prettified = prettifyJSON(val || null);

    if (prettified === "") {
      return false;
    }

    return !Object.keys(JSON.parse(prettified)).some((objectKey) => objectKey === "");
  } catch (e) {
    return false;
  }
};

const modelInOptions = (val: any, version: MarqoVersion, modelList: ModelInfo[]) => {
  try {
    const parsedIndexDefs = JSON.parse(val);
    if (
      (version === MarqoVersion.V2 && "modelProperties" in parsedIndexDefs) ||
      (version === MarqoVersion.V1 && "model_properties" in parsedIndexDefs)
    ) {
      return true;
    }
    return modelList.some(
      ({ name }) => name === parsedIndexDefs["model"] || marqtuneModelRegex.test(parsedIndexDefs["model"]),
    );
  } catch (e) {
    return false;
  }
};

const isModelDefined = (val: string) => {
  try {
    const parsedIndexDefs = JSON.parse(val);
    return "model" in parsedIndexDefs;
  } catch (e) {
    return false;
  }
};

const getModelInOppositeOptionsMsg = (version: MarqoVersion, isMultimodal: boolean) => {
  return `Please set ${
    version === MarqoVersion.V2 ? "treatUrlsAndPointersAsImages" : "treat_urls_and_pointers_as_images"
  } to ${!isMultimodal} to use a ${!isMultimodal ? "multimodal" : "text-based"} model.`;
};

const getModelPropsDefinedMsg = (version: MarqoVersion) => {
  return `Please define ${
    version === MarqoVersion.V2 ? "modelProperties" : "model_properties"
  } to use your custom model.`;
};

const isModelPropsDefined = (val: string, version: MarqoVersion, modelList: ModelInfo[]) => {
  try {
    const parsedIndexDefs = JSON.parse(val);
    const keys = Object.keys(parsedIndexDefs);
    let modelPropsDefined = false;
    if (version === MarqoVersion.V2) {
      modelPropsDefined = keys.includes("modelProperties");
    } else {
      modelPropsDefined = keys.includes("model_properties");
    }

    if (!modelPropsDefined) {
      return modelInOptions(val, version, modelList);
    }

    return true;
  } catch (e) {
    return true;
  }
};

const validIndexNameByVersion = (val: any, version: MarqoVersion) => {
  return version === MarqoVersion.V1 ? indexNameRegex.test(val) : v2IndexNameRegex.test(val);
};

const validateStorageConfig = (val: any, formValues: CreateIndexFormProps | ModifyIndexFormProps) => {
  const { inferencePodType, numberOfInferencePods, numberOfShards } = formValues;
  return !(
    val === "marqo.basic" &&
    inferencePodType !== "marqo.CPU.small" &&
    Number(numberOfInferencePods) > numberOfShards * 2
  );
};

const isReplicaZeroForBasicStorage = (val: number, storageShardType: string) => {
  // validation is not needed for non-basic storage
  if (storageShardType !== "marqo.basic") return true;

  return val === 0;
};

const IndexFormValidators = {
  indexDefaults: {
    validJSON: indexDefaultsValidJSON,
    modelInOptions,
    isModelDefined,
    getModelInOppositeOptionsMsg,
    getModelPropsDefinedMsg,
    isModelPropsDefined,
  },
  indexName: {
    validator: validIndexNameByVersion,
  },
  storageShardType: {
    validator: validateStorageConfig,
  },
  numberOfReplicas: {
    isReplicaZeroForBasicStorage,
  },
};

export default IndexFormValidators;
