import * as Yup from "yup";
import { Option } from "../components/form-fields/select-field";

// eslint-disable-next-line no-useless-escape
export const indexNameRegex = /^[a-z0-9](?:[a-z0-9\-]*[a-z0-9])?$/;
export const v2IndexNameRegex = /^[a-z0-9](?:[a-z0-9\-_]*[a-z0-9])?$/;

export const constructRequiredNumberValidation = (inputFieldName: string, min: number, max: number) => {
  return Yup.number()
    .min(min, `${inputFieldName} must be greater than or equal to ${min}.`)
    .max(max, `${inputFieldName} must be less than or equal to ${max}.`)
    .required(`${inputFieldName} is required.`);
};

export enum INTENTION_OF_USE {
  WORK = "Work",
  PERSONAL = "Personal",
  BOTH = "Both",
}

export enum USE_CASE {
  TEXT_SEARCH = "Text Search",
  IMAGE_SEARCH = "Image Search",
  MULTIMODAL_SEARCH = "Multimodal Search",
  RAG = "RAG",
  RECOMMENDATION = "Recommendation",
  OTHER = "Other",
}

export enum INDUSTRY {
  E_COMMERCE = "Ecommerce",
  FINANCIAL_SERVICES = "Financial Services",
  HEALTHCARE = "Healthcare",
  GOVERNMENT = "Government",
  OTHER = "Other",
}

export enum CloudProvider {
  AWS = "AWS",
  GCP = "GCP",
}

export const enumToOptions = (enumType: { [s: string]: string }): Option[] =>
  Object.entries(enumType).map(([key, label]) => ({ id: key, value: label, title: label }));

export const ACCEPTED_COUNTRIES = [
  // north america
  "USA",
  "Canada",
  // europe
  "Austria",
  "Belgium",
  "Bulgaria",
  "Austria",
  "Belgium",
  "Bulgaria",
  "Croatia",
  "Republic of Cyprus",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Hungary",
  "Ireland",
  "Italy",
  "Latvia",
  "Lithuania",
  "Luxembourg",
  "Malta",
  "Netherlands",
  "Poland",
  "Portugal",
  "Romania",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
  "Norway",
  "Switzerland",
  "United Kingdom",
  "Iceland",
  "Monaco",
  "Lichtenstein",
  "Republic of Cyprus",
  "Czech Republic",
  "Denmark",
  "Estonia",
  "Finland",
  "France",
  "Germany",
  "Greece",
  "Hungary",
  "Ireland",
  "Italy",
  "Latvia",
  "Lithuania",
  "Luxembourg",
  "Malta",
  "Netherlands",
  "Poland",
  "Portugal",
  "Romania",
  "Slovakia",
  "Slovenia",
  "Spain",
  "Sweden",
  "Norway",
  "Switzerland",
  "United Kingdom",
  "Iceland",
  "Monaco",
  "Lichtenstein",
  // APAC
  "Singapore",
  "Australia",
  "New Zealand",
  "Brunei",
  // Asia
  "China",
  "Taiwan",
  "Japan",
  "South Korea",
  // Middle east
  "UAE",
  "Saudi Arabia",
  "Oman",
  "Israel",
  "Qatar",
  "Kuwait",
  "Bahrain",
];
