import { Box, Grid, Typography } from "@mui/material";
import { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import CardTitle from "../../components/card-title/card-title.component";
import ContentLayout from "../../components/content-layout/content-layout.component";
import ContentSection from "../../components/content-layout/content-section.component";
import FetchingDisplay from "../../components/fetching-display/fetching-display.component";
import CreateTaskQuickStart from "../../components/page-parts/marqtune/train-model-quick-start.component";
import {
  CreatedAMarqtuneTrainingDatasetQuickLink,
  FineTuningChargesPerMinQuickLink,
  FineTuningGuideQuickLink,
} from "../../components/page-parts/quick-links/quick-links";
import { useBreadcrumbs } from "../../hooks/use-breadcrumbs";
import { useDispatch, useSelector } from "../../store";
import { selectTrainingDatasets } from "../../store/selectors";
import { listMarqtuneDatasetsThunk } from "../../thunks/marqtune.thunk";
import { marqtuneStyles } from "./marqtune.styles";

const MarqtuneTrainModelPage = () => {
  const classes = marqtuneStyles();
  const dispatch = useDispatch();
  const { setBreadcrumbs, clearBreadcrumbs } = useBreadcrumbs();
  const { isGuest } = useSelector(({ user }) => user.data);
  const { isFetching } = useSelector(({ marqtune }) => marqtune.datasets);
  const datasets = useSelector(selectTrainingDatasets);

  useEffect(() => {
    dispatch(listMarqtuneDatasetsThunk());

    setBreadcrumbs([{ title: "Marqtune", link: "/marqtune/" }]);
    return () => clearBreadcrumbs();
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Train Model | Marqo</title>
      </Helmet>
      <ContentLayout pageTitle={"Train Model"}>
        {isFetching ? (
          <FetchingDisplay />
        ) : (
          <Fragment>
            <ContentSection id={"TrainModelSummary"}>
              <CardTitle title="Train Model" />

              {/* GUIDE */}

              <Grid container className={classes.guide} gap={2}>
                <Typography variant={"subtitle2"}>
                  To train a model, you must have {CreatedAMarqtuneTrainingDatasetQuickLink}.
                </Typography>

                <Grid item container>
                  <Typography variant={"subtitle2"}>
                    For detailed information about training parameter selection, see the {FineTuningGuideQuickLink}.
                  </Typography>
                </Grid>

                <Typography variant={"subtitle2"}>
                  First, select your dataset then create a training job via the Marqtune API using the code snippets
                  below as a guide. You will need to enter your base model or checkpoint to train, and specify the left
                  and right data field parameters in the code snippet below.
                </Typography>

                <Typography variant={"subtitle2"}>
                  Training can take hours to days depending on the volume and type of data, and is{" "}
                  {FineTuningChargesPerMinQuickLink}. You can check on the progress of your training task in the console
                  or via the API. You can terminate the task anytime. You will be billed for the time elapsed. Completed
                  epochs will be available as model checkpoints.
                </Typography>
              </Grid>
            </ContentSection>

            {/* SELECTOR, SCHEMA, and QUICK START */}

            <Box mt={2}>
              <CreateTaskQuickStart datasets={datasets} />
            </Box>
          </Fragment>
        )}
      </ContentLayout>
    </Fragment>
  );
};

export default MarqtuneTrainModelPage;
