import { FormConfigType } from "../../components/form/form.types";
import { emailFieldConfig, passwordFieldConfig } from "./auth-field.configs";

export const emailLoginFormConfig = (): FormConfigType => {
  return {
    fields: {
      email: emailFieldConfig(),
      password: passwordFieldConfig(),
      submit: {
        hidden: true,
        initialValue: null,
      },
    },
    formInputMode: "light-zen",
  };
};
