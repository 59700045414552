import axios from "axios";
import AppConfig from "../config";
import UserToken from "../utils/services/tokens-service";

/**
 * Interceptor (middleware), will add user token to every server request
 */
axios.interceptors.request.use((config) => {
  const token = UserToken.get();

  if (config.baseURL === AppConfig.marqtuneBaseURL) {
    config.headers["x-api-key"] = `Bearer ${token}`;
  } else {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});

// Response interceptor to handle 401 and 403 errors
axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401 || error.response.status === 403) {
      UserToken.remove();
      window.location.pathname = "/";
    }
    return Promise.reject(error);
  },
);
