import { CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga4";
import { HelmetProvider } from "react-helmet-async";
import { Toaster } from "react-hot-toast";
import { Provider as ReduxProvider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import config from "./config";
import { BreadcrumbsProvider } from "./contexts/breadcrumbs-context";
import { AuthConsumer, AuthProvider } from "./contexts/jwt-context";
import { SettingsConsumer, SettingsProvider } from "./contexts/settings-context";
import { store } from "./store";
import { createTheme } from "./theme";
import "./api/axiosConf";

ReactGA.initialize(config.ga4MeasurementId);

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <ReduxProvider store={store}>
        <AuthProvider>
          <SettingsProvider>
            <SettingsConsumer>
              {({ settings }) => (
                <ThemeProvider
                  theme={createTheme({
                    direction: settings.direction,
                    responsiveFontSizes: settings.responsiveFontSizes,
                    mode: settings.theme,
                  })}
                >
                  <CssBaseline />
                  <Toaster position="top-center" />
                  <BreadcrumbsProvider>
                    <HelmetProvider>
                      <AuthConsumer>{(auth) => <App auth={auth} />}</AuthConsumer>
                    </HelmetProvider>
                  </BreadcrumbsProvider>
                </ThemeProvider>
              )}
            </SettingsConsumer>
          </SettingsProvider>
        </AuthProvider>
      </ReduxProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root"),
);
