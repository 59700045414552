import axios, { AxiosPromise } from "axios";
import config from "../../config/index";
import { AccountId } from "../api.types";
import { GetProductPricesRes, HasStripeCardRes } from "./billing.types";

const hasStripeCard = (accountId: AccountId): AxiosPromise<HasStripeCardRes> => {
  return axios({
    method: "POST",
    baseURL: config.baseURL,
    url: `${config.endpoints.hasStripeCard}`,
    params: { accountId },
  });
};

const getProductPrices = (accountId: AccountId): AxiosPromise<GetProductPricesRes> => {
  return axios({
    method: "GET",
    baseURL: config.baseURL,
    url: `${config.endpoints.getPrices}`,
    params: { accountId },
  });
};

const Billing = {
  get: {
    billingInfo: hasStripeCard,
    prices: getProductPrices,
  },
};

export default Billing;
