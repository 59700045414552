import { CircularProgress, Grid } from "@mui/material";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import AuthBase from "../../components/authentication/auth-base.component";
import { JWTLogin } from "../../components/authentication/jwt-login";
import { getFormConfig } from "../../components/form/form.component";
import { SocialAuthDivider } from "../../components/page-parts/auth/social-auth-divider.component";
import config from "../../config";
import { emailLoginFormConfig } from "../../form-configs/auth/signin.forms";
import { useAuth } from "../../hooks/use-auth";

const Login = () => {
  const { login } = useAuth();
  const navigate = useNavigate();
  const [isLoginPending, setIsLoginPending] = useState<boolean>(false);
  const [isMounted, setIsMounted] = useState(true);

  const getLoginError = (error: AxiosError, defaultMsg: string) => {
    const err = error as AxiosError;
    const errMsg = err.response?.data?.error;

    switch (err.response?.status) {
      case 400:
        return errMsg || defaultMsg;
      case 404:
        return "The email address you entered isn't connected to an account.";
      default:
        return defaultMsg;
    }
  };

  useEffect(() => {
    return () => {
      // Component will unmount
      setIsMounted(false);
    };
  }, []);

  const emailLoginFormik = useFormik({
    ...getFormConfig<{
      email: string;
      password: string;
      submit: string | boolean;
    }>(emailLoginFormConfig()),
    onSubmit: async (values, helpers) => {
      try {
        const { email, password } = values;
        await login(email, password);
        navigate(config.authenticatedPaths.home);
      } catch (e) {
        const err = e as AxiosError;
        const errMsg = getLoginError(err, "Failed to sign in.");
        helpers.setErrors({ submit: errMsg });
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);
      } finally {
        if (isMounted) {
          setIsLoginPending(false);
        }
      }
    },
  });

  const nextHandler = () => {
    setIsLoginPending(true);
    emailLoginFormik.submitForm();
  };

  const ssoErrorHandler = (error: string) => {
    emailLoginFormik.setErrors({ submit: error });
  };

  return (
    <AuthBase formDisplayTitle="" pageTitle={"Login"}>
      {isLoginPending && (
        <Grid container height={"30vh"} justifyContent={"center"} alignContent={"center"}>
          <CircularProgress />
        </Grid>
      )}

      {!isLoginPending && (
        <Fragment>
          <SocialAuthDivider
            authPath={config.publicPaths.login}
            btnTextPrefix={"Sign in"}
            intent={"signin"}
            errorHandler={ssoErrorHandler}
          />
          <JWTLogin formik={emailLoginFormik} currentFormConfig={emailLoginFormConfig()} nextHandler={nextHandler} />
        </Fragment>
      )}
    </AuthBase>
  );
};

export default Login;
