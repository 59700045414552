import { Button } from "@mui/material";
import { AsyncThunk } from "@reduxjs/toolkit";
import { useDispatch } from "../../store";
import { downloadFileFromURL } from "../../utils/download";

type Props = {
  buttonLabel: string;
};

type UrlDownloadButtonProps = Props & {
  url: string;
  filename: string;
};

type ThunkDownloadButtonProps = Props & {
  dispatchAction: AsyncThunk<any, string, {}>;
  resourceId: string;
  disabled?: boolean;
};

export const UrlDownloadButton = ({ url, buttonLabel, filename }: UrlDownloadButtonProps) => {
  const handleOnClick = () => {
    downloadFileFromURL(url, filename);
  };

  return <Button onClick={handleOnClick}>{buttonLabel}</Button>;
};

export const ThunkDownloadButton = ({
  buttonLabel,
  dispatchAction,
  resourceId,
  disabled = false,
}: ThunkDownloadButtonProps) => {
  const dispatch = useDispatch();

  const handleOnClick = () => {
    dispatch(dispatchAction(resourceId));
  };

  return (
    <Button onClick={handleOnClick} disabled={disabled}>
      {buttonLabel}
    </Button>
  );
};
