import { MenuItem, RadioGroupProps, TextField, TextFieldProps } from "@mui/material";
import React from "react";

export type Option = {
  id: number | string;
  value?: string | number;
  title: string | number;
  imageUrl?: string;
  imageAltText?: string;
  description?: string;
  subDescription?: string;
  isDivider?: boolean;
  className?: string;
};

export type SelectProps = TextFieldProps & {
  options: Option[];
  startAdornment?: React.ReactNode;
};

export type RadioGroupFieldProps = RadioGroupProps & {
  disabled?: boolean;
  options: {
    value?: string | number;
    label: string | number;
  }[];
};

const Select: React.FC<SelectProps> = ({ options, ...props }): JSX.Element => {
  return (
    <TextField {...props} select>
      {options &&
        options.map((item) => (
          <MenuItem key={item.id} value={item.value} disabled={item.isDivider} className={item.className || undefined}>
            {item.title}
          </MenuItem>
        ))}
    </TextField>
  );
};

export default Select;
