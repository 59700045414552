import { AxiosPromise } from "axios";
import baseToast from "./base.toast";

export const resendInviteToast = (promise: AxiosPromise) =>
  baseToast(promise, "Resending invite...", "Invite has been sent");
export const acceptInviteToast = (promise: AxiosPromise) =>
  baseToast(promise, "Accepting invite", "Accepted invite", "Error accepting invite");
export const rejectInviteToast = (promise: AxiosPromise) => baseToast(promise, "Rejecting invite", "Rejected invite");
export const addMemberToast = (promise: AxiosPromise) => baseToast(promise, "Adding member", "Member has been added");
export const removeMemberToast = (promise: AxiosPromise) =>
  baseToast(promise, "Removing member", "Member has been removed");
