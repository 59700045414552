import * as Yup from "yup";
import { FormConfigType } from "../components/form/form.types";
import { Limits } from "./types";

export type TCreateAPIKeyForm = {
  name: string;
  submit: string;
};

const apiKeyLimits: Limits = {
  min: 3,
  max: 255,
};

export const createApiKeyFormConfig: FormConfigType = {
  fields: {
    name: {
      label: "API Key Name",
      initialValue: "",
      sizes: {
        xs: 12,
        sm: 6,
        md: 4,
      },
      validation: {
        rules: {},
        validate: Yup.string()
          .min(apiKeyLimits.min, `API Key name must be at least ${apiKeyLimits.min} characters`)
          .max(apiKeyLimits.max, `API Key name must be at most ${apiKeyLimits.max} characters`)
          .required("API key name is required"),
      },
    },
    submit: {
      hidden: true,
      initialValue: null,
      sizes: {
        xs: 12,
        sm: 6,
        md: 4,
      },
    },
  },
};
