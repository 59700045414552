import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";
import { MarqtuneGetModelDownloadLinkPayload } from "../api/marqtune/types";
import { releaseCheckpointToast } from "../components/page-parts/marqtune/model-details/release-checkpoint.toast";
import { RootState } from "../store";
import { selectCurrentAccountId, selectModelByModelId } from "../store/selectors";
import { downloadFileFromURL } from "../utils/download";

/** REQUEST ACCESS **/

export const requestMarqtuneAccessThunk = createAsyncThunk("marqtune/requestAccessThunk", async (_, { getState }) => {
  const accountId = selectCurrentAccountId(getState() as RootState);
  return API.marqtune.requestAccess(accountId).then(({ data }) => data);
});

/***
 * DATASETS
 */

export const listMarqtuneDatasetsThunk = createAsyncThunk("marqtune/datasets/all", async (_, { getState }) => {
  const accountId = selectCurrentAccountId(getState() as RootState);
  return API.marqtune.datasets.all(accountId).then(({ data }) => data);
});

export const getMarqtuneDatasetThunk = createAsyncThunk(
  "marqtune/datasets/details",
  async (datasetId: string, { getState }) => {
    const accountId = selectCurrentAccountId(getState() as RootState);
    return API.marqtune.datasets.details(accountId, datasetId).then(({ data }) => data);
  },
);

export const listMarqtuneDatasetLogsThunk = createAsyncThunk(
  "marqtune/datasets/logs",
  async (datasetId: string, { getState }) => {
    const accountId = selectCurrentAccountId(getState() as RootState);
    return API.marqtune.datasets.logs(accountId, datasetId).then(({ data }) => data);
  },
);

export const downloadMarqtuneDatasetLogsThunk = createAsyncThunk(
  "marqtune/datasets/downloadLogs",
  async (datasetId: string, { getState, rejectWithValue }) => {
    const accountId = selectCurrentAccountId(getState() as RootState);
    return API.marqtune.datasets.logsUrl(accountId, datasetId).then(({ data }) => {
      if (data?.downloadUrl) {
        downloadFileFromURL(data?.downloadUrl, `marqtune-${datasetId}-logs`);
        return data;
      }

      return rejectWithValue("Download URL not found");
    });
  },
);

export const deleteMarqtuneDatasetThunk = createAsyncThunk(
  "marqtune/datasets/deleteDataset",
  async (datasetId: string, { dispatch, getState }) => {
    const accountId = selectCurrentAccountId(getState() as RootState);
    return API.marqtune.datasets.deleteDataset(accountId, datasetId).then(({ data }) => {
      dispatch(listMarqtuneDatasetsThunk());
      return data;
    });
  },
);

/***
 * EVALUATIONS
 */

export const listMarqtuneEvaluationsThunk = createAsyncThunk("marqtune/evaluations/all", async (_, { getState }) => {
  const accountId = selectCurrentAccountId(getState() as RootState);
  return API.marqtune.evaluations.all(accountId).then(({ data }) => data);
});

export const getMarqtuneEvaluationDetailsThunk = createAsyncThunk(
  "marqtune/evaluations/details",
  async (evaluationId: string, { getState }) => {
    const accountId = selectCurrentAccountId(getState() as RootState);
    return API.marqtune.evaluations.details(accountId, evaluationId).then(({ data }) => data);
  },
);

export const listMarqtuneEvaluationLogsThunk = createAsyncThunk(
  "marqtune/evaluations/logs",
  async (evaluationId: string, { getState }) => {
    const accountId = selectCurrentAccountId(getState() as RootState);
    return API.marqtune.evaluations.logs(accountId, evaluationId).then(({ data }) => data);
  },
);

export const downloadMarqtuneEvaluationLogsThunk = createAsyncThunk(
  "marqtune/evaluations/downloadLogs",
  async (evaluationId: string, { getState, rejectWithValue }) => {
    const accountId = selectCurrentAccountId(getState() as RootState);
    return API.marqtune.evaluations.logsUrl(accountId, evaluationId).then(({ data }) => {
      if (data?.downloadUrl) {
        downloadFileFromURL(data?.downloadUrl, `marqtune-${evaluationId}-logs`);
        return data;
      }

      return rejectWithValue("Download URL not found");
    });
  },
);

export const deleteMarqtuneEvaluationThunk = createAsyncThunk(
  "marqtune/evaluations/deleteEvaluation",
  async (evaluationId: string, { getState }) => {
    const accountId = selectCurrentAccountId(getState() as RootState);
    return API.marqtune.evaluations.deleteEvaluation(accountId, evaluationId).then(({ data }) => data);
  },
);

/***
 * MODELS
 */

export const listMarqtuneModelsThunk = createAsyncThunk("marqtune/models/all", async (_, { getState }) => {
  const accountId = selectCurrentAccountId(getState() as RootState);
  return API.marqtune.models.all(accountId).then(({ data }) => data);
});

export const getMarqtuneModelDetailsThunk = createAsyncThunk(
  "marqtune/models/detail",
  async (modelId: string, { getState }) => {
    const accountId = selectCurrentAccountId(getState() as RootState);
    return API.marqtune.models.details(accountId, modelId).then(({ data }) => data);
  },
);

export const listMarqtuneModelLogsThunk = createAsyncThunk(
  "marqtune/models/logs",
  async (modelId: string, { getState }) => {
    const accountId = selectCurrentAccountId(getState() as RootState);
    return API.marqtune.models.logs(accountId, modelId).then(({ data }) => data);
  },
);

export const downloadMarqtuneModelLogsThunk = createAsyncThunk(
  "marqtune/models/downloadLogs",
  async (modelId: string, { getState, rejectWithValue }) => {
    const accountId = selectCurrentAccountId(getState() as RootState);
    return API.marqtune.models.logsUrl(accountId, modelId).then(({ data }) => {
      if (data?.downloadUrl) {
        downloadFileFromURL(data?.downloadUrl, `marqtune-${modelId}-logs`);
        return data;
      }

      return rejectWithValue("Download URL not found");
    });
  },
);

export const downloadMarqtuneCheckpointThunk = createAsyncThunk(
  "marqtune/models/downloadCheckpoint",
  async ({ modelId, checkpoint }: MarqtuneGetModelDownloadLinkPayload, { getState, rejectWithValue }) => {
    const accountId = selectCurrentAccountId(getState() as RootState);
    return API.marqtune.models.checkpointUrl(accountId, modelId, checkpoint).then(({ data }) => {
      if (data?.downloadUrl) {
        downloadFileFromURL(data?.downloadUrl, `marqtune-${modelId}-${checkpoint}`);
        return data;
      }

      return rejectWithValue("Download URL not found");
    });
  },
);

export const deleteMarqtuneModelThunk = createAsyncThunk(
  "marqtune/models/deleteModel",
  async (modelId: string, { dispatch, getState }) => {
    const accountId = selectCurrentAccountId(getState() as RootState);
    return API.marqtune.models.deleteModel(accountId, modelId).then(({ data }) => {
      dispatch(listMarqtuneModelsThunk());
      return data;
    });
  },
);

export const releaseCheckpointAndFetchModelDetailsThunk = createAsyncThunk(
  "marqtune/models/releaseCheckpointAndFetchModelDetails",
  async (
    { modelId, newCheckpoint }: { modelId: string; newCheckpoint: string },
    { getState, dispatch, rejectWithValue },
  ) => {
    const state = getState() as RootState;
    const accountId = selectCurrentAccountId(state);
    const modelDetails = selectModelByModelId(modelId)(state);

    if (!modelDetails) {
      throw new Error(`Model with id ${modelId} not found`);
    }

    try {
      const releaseCheckpointPromise = API.marqtune.models.releaseCheckpoint(accountId, modelId, newCheckpoint);
      await releaseCheckpointToast(releaseCheckpointPromise, modelDetails.modelName);

      // Dispatch getMarqtuneModelDetailsThunk after successful release
      await dispatch(getMarqtuneModelDetailsThunk(modelId));
    } catch (error) {
      console.error("Error updating model", error);
      return rejectWithValue(error);
    }
  },
);
