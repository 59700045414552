import _ from "lodash";
//npm i lodash.get && npm i -D @types/lodash.get
import React, { useEffect, useState } from "react";

type UseFiltersReturn<T> = {
  filtered: T[];
  filterInputValue: string;
  setFilterInputValue: React.Dispatch<React.SetStateAction<string>>;
  setFilterItems: React.Dispatch<React.SetStateAction<T[]>>;
};

type UseFiltersParams = {
  paths: string[] | string[][];
};

export const useFilters = <T = any>({ paths }: UseFiltersParams): UseFiltersReturn<T> => {
  const [input, setInput] = useState<string>("");
  const [items, setItems] = useState<T[] | null>(null);
  const [filtered, setFiltered] = useState<T[] | null>(null);

  useEffect(() => {
    if (!input.length) {
      return setFiltered(null);
    }
    if (!paths?.length) {
      return setFiltered(items);
    }
    if (items) {
      const result = items.filter((item: T) => {
        const values = paths.map((prop) => _.get(item, prop));

        return values.some((el) => el.toLowerCase().includes(input.toLowerCase()));
      });
      setFiltered(result);
    }
    // eslint-disable-next-line
  }, [input, items]);

  return {
    filtered,
    filterInputValue: input,
    setFilterInputValue: setInput,
    setFilterItems: setItems,
  };
};
