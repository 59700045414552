import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import API from "../api";
import { DeleteAPIKeyReq } from "../api/settings/settings.types";
import { createAPIKeyToast, deleteAPIKeyToast } from "../components/toast/api-keys.toast";
import { TCreateAPIKeyForm } from "../form-configs/api-keys.forms";
import { setAPIKeyIsFetching, setAPIKeys } from "../slices/settings";
import { RootState } from "../store";
import { selectCurrentAccountId } from "../store/selectors";
import { FormCbDTO } from "./types";

export type CreateAPIKeyErr = {
  error: {
    name: string;
    reason: string;
    status: string;
    type: string;
    root_cause: {
      name: string;
      type: string;
    }[];
  };
};

export const createAPIKeyThunk = createAsyncThunk(
  "settings/api-keys/create",
  async (req: FormCbDTO<TCreateAPIKeyForm>, { getState }) => {
    const accountId = selectCurrentAccountId(getState() as RootState);
    const { formData, onSuccess } = req;

    try {
      const { name } = formData;
      const payload = { key_name: name.trim() };
      const promise = API.settings.apiKeys.create(accountId, payload);
      createAPIKeyToast(promise);
      const { data } = await promise;

      if (onSuccess) {
        onSuccess();
      }
      return { ...data };
    } catch (err) {
      const error = err as AxiosError<CreateAPIKeyErr>;
      return Promise.reject(error);
    }
  },
);

export const getAPIKeysThunk = createAsyncThunk("settings/api-keys/get", async (_, { dispatch, getState }) => {
  const accountId = selectCurrentAccountId(getState() as RootState);
  if (!accountId) {
    return Promise.reject("No account selected");
  }
  dispatch(setAPIKeyIsFetching(true));

  try {
    const { data } = await API.settings.apiKeys.get(accountId);
    dispatch(setAPIKeys(data));
    return data;
  } catch (err) {
    return Promise.reject(err);
  } finally {
    dispatch(setAPIKeyIsFetching(false));
  }
});

export const deleteAPIKeyThunk = createAsyncThunk(
  "settings/api-keys/delete",
  async (payload: DeleteAPIKeyReq, { dispatch, getState }) => {
    const accountId = selectCurrentAccountId(getState() as RootState);
    try {
      const promise = API.settings.apiKeys.delete(accountId, payload);
      deleteAPIKeyToast(promise);
      const { data } = await promise;
      dispatch(getAPIKeysThunk());
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
);
