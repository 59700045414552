import { useEffect, useState } from "react";
import React from "react";
export type TSortDirections = "desc" | "asc";
type TCompareType = "date" | string;

type TToggleSort<T = any> = (params: { key: keyof T | string; compareType: TCompareType | null }) => void;

type TSortRules<T = any> = {
  field: keyof T;
  direction: TSortDirections;
  compareType?: TCompareType;
};
type TUseSortParams<T> = {
  defaultRule?: TSortRules<T>;
};
type TUseSortReturn<T> = {
  setSortItems: React.Dispatch<React.SetStateAction<T[]>>;
  sorted: T[];
  sortDirection: TSortDirections;
  toggleSort: TToggleSort<T>;
};

const _defaultRule: TSortRules = {
  field: null,
  direction: null,
  compareType: null,
};

export const useSort = <T>({ defaultRule = _defaultRule }: TUseSortParams<T>): TUseSortReturn<T> => {
  const [rules, setRules] = useState<TSortRules>(defaultRule);
  const [sorted, setSorted] = useState<T[] | null>(null);
  const [itemsForSort, setSortItems] = useState<T[] | null>(null);

  const toggleSort: TToggleSort = ({ key, compareType = null }) => {
    setRules((prev): TSortRules => {
      const sortDirection: TSortDirections = prev.direction === "asc" ? "desc" : "asc";
      return {
        field: key,
        direction: sortDirection,
        compareType: compareType,
      };
    });
  };

  useEffect(() => {
    if (itemsForSort) {
      setSorted(() => {
        const items = [...itemsForSort];
        items.sort((a: Record<string, any>, b: Record<string, any>) => {
          const rule = rules.field as string;
          let _a = a[rule];
          let _b = b[rule];
          if (rules.compareType === "date") {
            _a = new Date(_a);
            _b = new Date(_b);
          }
          return rules.direction === "desc" ? _b - _a : _a - _b;
        });
        return items;
      });
    }
  }, [rules, itemsForSort]);

  return { setSortItems, sorted, sortDirection: rules.direction, toggleSort };
};
