import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const inputClasses = makeStyles((theme: Theme) => ({
  stepArrows: {
    "& input[type=number]::-webkit-outer-spin-button, input[type=number]::-webkit-inner-spin-button": {
      opacity: 1,
    },
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: "25%",
    },
  },
};

export const SelectStyles = makeStyles((theme: Theme) => ({
  selectOptionCard: {
    "&:hover": {
      cursor: "pointer",
    },
    "&.disabled-card": {
      opacity: 0.7,
      cursor: "default",
    },
  },
  selectedCard: {
    border: `1px solid ${theme.palette.background.paper}`,
  },
  select: {
    fontSize: "0.875rem",
    fontWeight: "600",
  },
  image: {
    maxWidth: "100%",
  },
  divider: {
    fontSize: "1rem",
    color: "#FFFFFF",
    backgroundColor: "transparent",
    padding: "6px 12px",
    margin: "6px 0",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    position: "relative",
    "&::before, &::after": {
      content: '""',
      flex: 1,
      borderBottom: "1px solid rgba(255, 255, 255, 0.5)",
      margin: "0 12px",
    },
  },
  dividerSpacer: {
    marginBottom: "20px",
  },
}));
