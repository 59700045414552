import { VpnKey } from "@mui/icons-material";
import * as Yup from "yup";
import { renderInputStartAdornment } from "../../components/form/form.component";
import { FormConfigType } from "../../components/form/form.types";
import { emailFieldConfig, strictPasswordFieldConfig } from "./auth-field.configs";
import { passwordComponentProps } from "./common-auth.props";

export const resetPasswordFormConfig: FormConfigType = {
  fields: {
    email: emailFieldConfig(),
    code: {
      label: "Confirmation Code",
      sizes: { xs: 12 },
      componentProps: {
        placeholder: "Confirmation Code",
        ...renderInputStartAdornment(<VpnKey fontSize={"small"} />),
      },
      validation: {
        rules: {},
        validate: Yup.string().max(255),
      },
    },
    newPassword: strictPasswordFieldConfig("New password"),
    passwordConfirm: {
      label: "Confirm new password",
      initialValue: "",
      type: "password",
      ...passwordComponentProps("Confirm new password"),
      sizes: { xs: 12 },
      validation: {
        rules: {},
        validate: Yup.string().when("newPassword", {
          is: true,
          otherwise: Yup.string()
            .min(8, "Must be at least 8 characters")
            .max(255)
            .required("Please confirm your password"),
        }),
      },
    },
    submit: {
      hidden: true,
      initialValue: null,
    },
  },
  formInputMode: "light-zen",
};

export const resetPasswordReqFormConfig: FormConfigType = {
  fields: {
    email: emailFieldConfig(),
    submit: {
      hidden: true,
      initialValue: null,
    },
  },
  formInputMode: "light-zen",
};
