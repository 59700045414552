import { Grid, TableCell, TableRow, Typography } from "@mui/material";
import classNames from "classnames";
import { Fragment } from "react";
import { ellipsisStyles } from "../../constants/styles";
import CopyButton from "../copy-button/copy-button.component";
import { MarqtuneStyleClasses } from "../page-parts/marqtune/styles";
import Table from "../table/table.component";
import { TableClasses } from "../table/table.styles";

type Props = {
  schemaConfig: () => {
    [key: string]: string;
  };
};

const DatasetSchemaTable = ({ schemaConfig }: Props) => {
  const tableClasses = TableClasses();
  const marqtuneClasses = MarqtuneStyleClasses();

  return (
    <Fragment>
      <Grid container mt={5} gap={2}>
        <Typography variant={"subtitle2"}>Dataset schema</Typography>
        <Grid container item className={classNames(marqtuneClasses.schemaTableWrapper)}>
          <Table
            additionalClasses={[tableClasses.noHeadings, marqtuneClasses.datasetSchemaTable]}
            tableHeaders={[]}
            inProgress={false}
            isEmpty={false}
            mt={0}
            footer={null}
            emptyListMsg={""}
          >
            <TableRow data-testid={"dataset-schema-params"}>
              <TableCell>
                <Typography variant={"subtitle2"}>Column Header</Typography>
              </TableCell>

              {Object.keys(schemaConfig()).map((param, key) => (
                <TableCell key={param}>
                  <Grid container>
                    <Grid item sx={{ color: "neutral.250", maxWidth: "80%" }} pr={1}>
                      <Typography variant={"body2"} sx={ellipsisStyles}>
                        {param}
                      </Typography>
                    </Grid>
                    {param && param !== "-" && (
                      <Grid item xs={2}>
                        <CopyButton text={param} label={"Parameter"} />
                      </Grid>
                    )}
                  </Grid>
                </TableCell>
              ))}
            </TableRow>

            <TableRow data-testid={"dataset-schema-datatypes"}>
              <TableCell>
                <Typography variant={"subtitle2"}>Data type</Typography>
              </TableCell>
              {Object.values(schemaConfig()).map((dtype, key) => (
                <TableCell key={`dataset-schema-dtype-${dtype}-${key}`} sx={{ color: "neutral.250" }}>
                  <Typography variant={"body2"} sx={ellipsisStyles}>
                    {dtype}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </Table>
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default DatasetSchemaTable;
