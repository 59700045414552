import _ from "lodash";
import { MarqtuneResource } from "../../../api/marqtune/types";
import { MARQTUNE_FINISHED_STATUSES, MARQTUNE_READY_STATUSES } from "./types";

export const isReady = (resource: MarqtuneResource) => {
  return MARQTUNE_READY_STATUSES.includes(_.upperCase(resource?.status || ""));
};

export const isFinished = (resource: MarqtuneResource) => {
  return MARQTUNE_FINISHED_STATUSES.includes(_.upperCase(resource?.status || ""));
};

export const getParsedTaskParameters = (parameters: string) => {
  try {
    return JSON.parse(parameters);
  } catch (error) {
    return {};
  }
};

export const getDisplayableStatus = (resource: MarqtuneResource) => {
  const { status, secondaryStatus } = resource;

  if (isReady(resource) || status === secondaryStatus) {
    return status;
  }

  return `${status}: ${secondaryStatus}`;
};
