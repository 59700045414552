import { Circle, InfoRounded, MoreVertRounded } from "@mui/icons-material";
import { Box, Chip, CircularProgress, Grid, IconButton, Tooltip, Typography } from "@mui/material";
import React from "react";
import { Clock } from "../../../../icons/clock";
import {
  ChipColor,
  SvgColor,
  getIndexStatusColorAndOpacity,
  getIndexStatusLabel,
  getTooltipMsg,
} from "../../../../utils/indices";
import IndexDetailsUtils from "../../../../utils/indices/index-details-utils";
import CardTitle from "../../../card-title/card-title.component";
import ContentSection from "../../../content-layout/content-section.component";
import { DetailGridDisplay } from "../../marqtune/detail-grid-display.component";
import IndexCountdown from "../index-overview/index-countdown.component";
import { IndexItem } from "../indexes.types";
import { indexDetailsStyles } from "./index-details.styles";

type Props = {
  indexObj: IndexItem;
  indexName: string;
  isFetching: boolean;
  handleMenuOpen: (e: React.MouseEvent) => void;
};

const IndexSummarySection = ({ indexObj, indexName, isFetching, handleMenuOpen }: Props) => {
  const classes = indexDetailsStyles();

  return (
    <ContentSection id={"IndexSummary-Section"}>
      <Grid container flexDirection={"column"}>
        <Grid container item flexDirection={"row"} justifyContent={"space-between"}>
          <Grid item>
            <Grid container flexDirection={"row"} gap={1}>
              <Grid item>
                <CardTitle title={indexName} noDivider rootProps={{ justifyContent: "center" }} />
              </Grid>

              {IndexDetailsUtils.showStatusCircle(isFetching, indexObj) && (
                <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
                  <Circle fontSize="small" color={getIndexStatusColorAndOpacity(indexObj.indexStatus)[0] as SvgColor} />

                  {getIndexStatusLabel(indexObj.indexStatus) === "Ready" && (
                    <Box display={"flex"} flexDirection={"row"} alignItems={"center"} pl={1}>
                      <Typography fontWeight={600} sx={{ fontWeight: 300, fontSize: "0.875rem" }}>
                        {getIndexStatusLabel(indexObj.indexStatus)}
                      </Typography>
                    </Box>
                  )}
                </Box>
              )}

              {IndexDetailsUtils.showStatusInfo(isFetching, indexObj) && (
                <Box display={"flex"} flexDirection={"row"} alignItems={"center"} ml={1} gap={1}>
                  {IndexDetailsUtils.countdownAction(isFetching, indexObj) && (
                    <Grid item>
                      <CircularProgress size={"1rem"} color={"inherit"} />
                    </Grid>
                  )}

                  <Chip size="small" color={"default" as ChipColor} label={getIndexStatusLabel(indexObj.indexStatus)} />

                  {getIndexStatusLabel(indexObj.indexStatus) === "Creating" && (
                    <Grid item container flexDirection={"row"} justifySelf={"center"} gap={1}>
                      <Grid item>
                        <Clock sx={{ fontSize: "0.9rem" }} />
                      </Grid>

                      <Grid item display={"flex"} alignItems={"center"}>
                        <Typography sx={{ fontSize: "0.8rem", color: "neutral.400" }}>
                          <IndexCountdown
                            showCountdown={true}
                            timeOfOperation={indexObj.Created}
                            version={indexObj.marqoVersion}
                          />
                        </Typography>
                      </Grid>
                    </Grid>
                  )}

                  {IndexDetailsUtils.countdownAction(isFetching, indexObj) && (
                    <IndexCountdown
                      showCountdown={false}
                      timeOfOperation={indexObj.Created}
                      version={indexObj.marqoVersion}
                    />
                  )}

                  {getIndexStatusLabel(indexObj.indexStatus) !== "Creating" && (
                    <Grid item lg={2} sx={{ justifyContent: "center", flexDirection: "column", display: "flex" }}>
                      {getTooltipMsg(indexObj.indexStatus, indexObj.errorMsg) !== null && (
                        <Tooltip title={getTooltipMsg(indexObj.indexStatus, indexObj.errorMsg)}>
                          <InfoRounded sx={{ color: "neutral.400", fontSize: "1.2rem" }} />
                        </Tooltip>
                      )}
                    </Grid>
                  )}
                </Box>
              )}
            </Grid>
          </Grid>

          {["Ready", "Failed"].includes(getIndexStatusLabel(indexObj.indexStatus)) && (
            <Grid item>
              <IconButton onClick={handleMenuOpen}>
                <MoreVertRounded />
              </IconButton>
            </Grid>
          )}
        </Grid>

        <Grid container mt={2} flexDirection={"row"}>
          {[IndexDetailsUtils.indexSummaryGridConfig, IndexDetailsUtils.indexScalingAndModelGridConfig].map(
            (gridConfig, gridIndex) => {
              return (
                <Grid
                  container
                  item
                  className={`${classes.indexGrid} index-grid-partition`}
                  key={`index-grid-${gridIndex}`}
                  xs={12}
                  md={6}
                  xl={4}
                >
                  {Object.entries(gridConfig(indexObj)).map(([_, { title, text, copyTitle }]) => {
                    return (
                      <DetailGridDisplay
                        key={title}
                        label={title}
                        value={text}
                        withCopy={Boolean(copyTitle)}
                        sizes={{ xs: 12, sm: 6 }}
                      />
                    );
                  })}
                </Grid>
              );
            },
          )}
        </Grid>
      </Grid>
    </ContentSection>
  );
};

export default IndexSummarySection;
