import { useFormik } from "formik";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import ContentLayout from "../../components/content-layout/content-layout.component";
import { BreadCrumbItem } from "../../components/content-layout/content-layout.types";
import ContentSection from "../../components/content-layout/content-section.component";
import { getFormConfig } from "../../components/form/form.component";
import { StandardForm } from "../../components/form/standard-form.component";
import config from "../../config";
import { API_KEYS_LIMIT } from "../../constants/common";
import { TCreateAPIKeyForm, createApiKeyFormConfig } from "../../form-configs/api-keys.forms";
import { useBreadcrumbs } from "../../hooks/use-breadcrumbs";
import { setToast } from "../../slices/app";
import { useDispatch, useSelector } from "../../store";
import { createAPIKeyThunk, getAPIKeysThunk } from "../../thunks/api-keys.thunk";
import { generateRandomKey } from "../../utils";

const breadcrumbsList: BreadCrumbItem[] = [{ title: "API Keys", link: config.authenticatedPaths.apiKeys }];

const APIKeyCreatePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setBreadcrumbs, clearBreadcrumbs } = useBreadcrumbs();
  const { keyList } = useSelector((state) => state.settings.apiKeys);
  const { isGuest } = useSelector((state) => state.user.data);

  const formik = useFormik({
    ...getFormConfig<TCreateAPIKeyForm>(createApiKeyFormConfig),
    onSubmit: async (data, _) => {
      if (keyList.length < API_KEYS_LIMIT) {
        await dispatch(
          createAPIKeyThunk({
            formData: data,
            onSuccess: () => {
              dispatch(getAPIKeysThunk());
              navigate(config.authenticatedPaths.apiKeys);
            },
          }),
        );
      } else {
        dispatch(
          setToast({
            msg: `Max API Keys is limited to ${API_KEYS_LIMIT}.`,
            type: "error",
            hash: generateRandomKey(6),
          }),
        );
      }
    },
  });

  useEffect(() => {
    setBreadcrumbs(breadcrumbsList);
    return () => clearBreadcrumbs();
  }, []);

  return (
    <ContentLayout pageTitle="Generate API Key">
      <ContentSection id={"CreateApiKey-Section"}>
        <StandardForm
          checkIfShallow={false}
          formik={formik}
          formConfig={createApiKeyFormConfig}
          promptTitle={""}
          submitBtnLabel={"Create API Key"}
          onPreSubmitHandler={() => {
            if (isGuest) {
              navigate(config.authenticatedPaths.billing);
              return false;
            }
          }}
          postSendNavigateTo={config.authenticatedPaths.apiKeys}
        />
      </ContentSection>
    </ContentLayout>
  );
};

export default APIKeyCreatePage;
