import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";
import { AccountIdProp } from "../api/api.types";
import { RootState } from "../store";
import { selectCurrentAccountId } from "../store/selectors";

type ResponseCallbacks = {
  onSuccess?: () => void;
  onError?: (err: unknown) => void;
};

export const getUserData = createAsyncThunk("user/getData", async (props: ResponseCallbacks, { getState }) => {
  const accountId = selectCurrentAccountId(getState() as RootState);
  try {
    const { data } = await API.user.getData(accountId);
    props.onSuccess?.();
    return data;
  } catch (err) {
    props.onError?.(err);
    return Promise.reject(err);
  }
});

export const getAccountLimitsThunk = createAsyncThunk("user/getAccountLimits", async (_, { getState }) => {
  const accountId = selectCurrentAccountId(getState() as RootState);
  try {
    const { data } = await API.user.getAccountLimits(accountId);
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
});

export const getMarqoEndpointThunk = createAsyncThunk("user/getMarqoEndpoint", async (_, { getState }) => {
  const accountId = selectCurrentAccountId(getState() as RootState);
  try {
    const { data } = await API.user.getMarqoEndpoint(accountId);
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
});

export const setLastSelectedAccount = createAsyncThunk(
  "account/setLastSelected",
  async ({ accountId }: AccountIdProp) => {
    try {
      await API.user.setLastSelectedAccount(accountId);
    } catch (err) {
      return Promise.reject(err);
    }
  },
);
