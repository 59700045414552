import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme: Theme) => ({
  blink: {
    animation: "$blink 2s infinite",
    borderRadius: "8px",
    height: theme.spacing(2),
    margin: theme.spacing(1, 0),
  },
  "@keyframes blink": {
    "0%": {
      background: "#546E7A",
    },
    "25%": {
      background: "#78909C",
    },

    "75%": {
      background: "#546E7A",
    },
    "100%": {
      background: "#607D8B",
    },
  },
}));
