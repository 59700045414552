import { TableCell, TableRow, Typography } from "@mui/material";
import { capitalize, isEmpty } from "lodash";
import { AccountId } from "../../api/api.types";
import { StyledActionButton } from "../../components/button/button.styles";
import ContentLayout from "../../components/content-layout/content-layout.component";
import ContentSection from "../../components/content-layout/content-section.component";
import Table from "../../components/table/table.component";
import { useDispatch, useSelector } from "../../store";
import { selectInvitationAccounts } from "../../store/selectors";
import { acceptAccountInvite, rejectAccountInvite } from "../../thunks/account.thunk";

const tableHeaders = ["Name", "Role", "Actions"];

export default function AccountInvitations() {
  const dispatch = useDispatch();

  const accounts = useSelector(selectInvitationAccounts);

  const handleAccept = (accountId: AccountId) => {
    dispatch(acceptAccountInvite({ accountId }));
  };
  const handleReject = (accountId: AccountId) => {
    dispatch(rejectAccountInvite({ accountId }));
  };

  return (
    <ContentLayout headTitle={"Account invitations"} pageTitle={"Account invitations"}>
      <ContentSection id={"AccountInvitations-Section"}>
        <Typography mb={4} variant={"h5"}>
          Account invitations
        </Typography>

        <Table
          tableHeaders={tableHeaders.map((title, i) => ({ id: i, title }))}
          inProgress={false}
          isEmpty={isEmpty(accounts)}
          emptyListMsg={"No invitations found."}
        >
          {accounts.map(({ id, name, role }, i) => (
            <TableRow key={i} id={`Account-${id}`}>
              <TableCell>{name}</TableCell>
              <TableCell>{capitalize(role)}</TableCell>

              {/* action buttons */}

              <TableCell style={{ display: "flex", gap: "1em" }}>
                <div>
                  <StyledActionButton actiontype="success" onClick={() => handleAccept(id)}>
                    Accept
                  </StyledActionButton>
                </div>
                <div>
                  <StyledActionButton actiontype="error" onClick={() => handleReject(id)}>
                    Reject
                  </StyledActionButton>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </Table>
      </ContentSection>
    </ContentLayout>
  );
}
