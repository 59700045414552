import { Grid, IconButton, TableCell, TableRow, Theme, Typography, useMediaQuery } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { UsageProps } from "../../../../api/stripe/stripe.types";
import { ChevronDown } from "../../../../icons/chevron-down";
import { ChevronUp } from "../../../../icons/chevron-up";
import { useSelector } from "../../../../store";
import { getFormattedAmount } from "../../../../utils/billing";
import { unixToMonthDYr } from "../../../../utils/time-formatter";
import Table, { TTableHeader } from "../../../table/table.component";
import { TABLE_BORDER_TOP_STYLE } from "../stripe/stripe.styles";
import { SubItemBaseStyles } from "./billing.styles";

export const usageDetailsTHeaders: TTableHeader[] = [
  { id: 0, title: "Description" },
  { id: 1, title: "Hours" },
  { id: 2, title: "Price" },
  { id: 3, title: "Total" },
  { id: 4, title: "" },
];

type DropdownState = { [category_key: string]: boolean };

type CategorySubItemProps = {
  description: string;
  totalUsage: number;
  price: number | null;
  subTotal: number;
  priceDecimals?: number;
  customPriceFormat?: null | string;
  unit?: string;
};

export const CategorySubItem = (props: CategorySubItemProps) => {
  const { description, totalUsage, price, subTotal, priceDecimals = 2, customPriceFormat = null, unit = "" } = props;

  const getDisplayHours = () => {
    const convertedHrs = +(totalUsage / 60).toFixed(2);
    return `${convertedHrs} hours (${totalUsage} min)`;
  };

  const displayHours = /^minute/.test(unit) && totalUsage ? getDisplayHours() : totalUsage.toLocaleString("en-US");

  return (
    <TableRow>
      <TableCell sx={SubItemBaseStyles}>{description}</TableCell>
      <TableCell sx={SubItemBaseStyles}>{displayHours}</TableCell>
      <TableCell sx={{ ...SubItemBaseStyles, minWidth: "100px", textAlign: "left" }}>
        <Grid sx={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
          {!!customPriceFormat ? price : getFormattedAmount(price, priceDecimals)}
        </Grid>
      </TableCell>
      <TableCell sx={{ ...SubItemBaseStyles, minWidth: "100px", textAlign: "right", textOverflow: "ellipsis" }}>
        <Grid>{getFormattedAmount(subTotal, 2)}</Grid>
      </TableCell>
    </TableRow>
  );
};

const UsageDetails: React.FC = (): JSX.Element => {
  const { breakdown, isFetching, discount, credits, usageCostSummary, totalDebits } = useSelector(
    ({ billing }) => billing.stripeIntegration.monthToDateUsage,
  );
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"), { noSsr: true });
  // states
  const [categoryDropdownStates, setCategoryDropdownStates] = useState<DropdownState>({});
  const iconSize: "small" | "inherit" | "large" | "medium" = mdUp ? "medium" : "small";

  useEffect(() => {
    if (!!breakdown) {
      const dropdowns: DropdownState = {};

      Object.entries(breakdown).forEach(([category, _]) => {
        dropdowns[category] = false;
      });

      if (hasValidDiscounts()) {
        dropdowns.discounts = false;
      }

      if (hasValidCredits()) {
        dropdowns.credits = false;
      }

      setCategoryDropdownStates(dropdowns);
    }
  }, [breakdown, discount]);

  function hasValidDiscounts() {
    return !!discount && discount?.is_valid && (discount?.percent_off || discount?.amount_off);
  }

  function hasValidCredits() {
    return !!credits;
  }

  function hasCreditRecord() {
    return Object.keys(credits).length > 0;
  }

  function getTotal(subItems: UsageProps[]): number {
    return Number.parseFloat(
      subItems
        .reduce((accumulator, { total_usage, unit_amount }) => {
          return accumulator + total_usage * unit_amount;
        }, 0.0)
        .toFixed(6),
    );
  }

  function getTotalDiscountValue(amountOff: number, percentOffDiscountValue: number): number {
    return (amountOff + percentOffDiscountValue) * -1;
  }

  function getTotalCredits(): number {
    if (credits === null || hasCreditRecord() === false) {
      return 0;
    }
    if (totalDebits === 0) {
      return 0;
    }
    // If the credits cannot cover the total debits, then all credits
    if (credits.ending_balance + totalDebits > 0) {
      return credits.ending_balance;
    }
    // If the credits can cover the total debits, then return the new adjusted credits
    // Note: ending_balance is always negative
    return -1 * totalDebits;
  }

  function calculateCreditDisplayAmount(totalDebits: number, endingBalance: number, amount: number): number {
    // "totalDebits + credits.ending_balance > 0" means that the credits cannot cover the total debits
    const result = totalDebits + endingBalance > 0 ? amount : totalDebits * -1;

    // Convert -0 to 0
    if (result === 0 && 1 / result === Number.NEGATIVE_INFINITY) {
      return 0;
    }
    return result;
  }

  function dropdownHandler(dropdownCategory: string): void {
    const _dropdownStates: DropdownState = { ...categoryDropdownStates };
    _dropdownStates[dropdownCategory] = !categoryDropdownStates[dropdownCategory];
    setCategoryDropdownStates(_dropdownStates);
  }

  function buildCategoryHeader(
    fontWeight: string,
    formattedTotal: string,
    dropdownCategory: string,
    borderTop = TABLE_BORDER_TOP_STYLE,
  ) {
    return (
      <TableRow sx={{ borderTop }}>
        <TableCell colSpan={1}>
          <Typography variant="subtitle1" textTransform={"capitalize"} fontWeight={fontWeight}>
            {dropdownCategory}
          </Typography>
        </TableCell>
        <TableCell colSpan={3}>
          <Typography variant="subtitle2" sx={{ textAlign: "right" }} fontWeight={fontWeight}>
            {formattedTotal}
          </Typography>
        </TableCell>

        {dropdownCategory.toLowerCase() !== "total" && (
          <TableCell colSpan={1} style={{ width: "0.5em" }}>
            <IconButton onClick={() => dropdownHandler(dropdownCategory)}>
              {categoryDropdownStates[dropdownCategory] ? (
                <ChevronUp fontSize={iconSize} />
              ) : (
                <ChevronDown fontSize={iconSize} />
              )}
            </IconButton>
          </TableCell>
        )}
      </TableRow>
    );
  }

  const buildPercentOffDiscountSubItem = () => {
    return (
      <CategorySubItem
        key="usage-discount-percent-off"
        description={discount.coupon_name}
        totalUsage={0}
        price={null}
        subTotal={discount.percent_off_discount_value * -1}
        priceDecimals={2}
        customPriceFormat={`${discount.percent_off} %`}
      />
    );
  };

  const buildAmountOffDiscountSubItem = () => {
    return (
      <CategorySubItem
        key="usage-discount-amount-off"
        description={discount.coupon_name}
        totalUsage={0}
        price={discount.amount_off}
        subTotal={discount.amount_off * -1}
      />
    );
  };

  const buildCreditItem = () => {
    return (
      <CategorySubItem
        key="usage-credits-sub-item"
        description={unixToMonthDYr(credits.created)}
        totalUsage={0}
        price={credits.amount}
        subTotal={calculateCreditDisplayAmount(totalDebits, credits.ending_balance, credits.amount)}
      />
    );
  };

  const buildUsageItem = (subItem: UsageProps, category: string) => {
    return (
      <CategorySubItem
        key={`usage-${category}-${subItem.product}`}
        description={subItem.product}
        totalUsage={subItem.total_usage}
        price={subItem.unit_amount}
        subTotal={subItem.total_usage * subItem.unit_amount}
        priceDecimals={6}
        unit={subItem.unit}
      />
    );
  };

  return (
    <Grid container item xs={12} md={9} minHeight={"175px"}>
      <Table
        tableHeaders={usageDetailsTHeaders}
        inProgress={isFetching}
        emptyListMsg={"No subscription items found."}
        isEmpty={!breakdown || Object.entries(breakdown).length === 0}
      >
        {!!breakdown &&
          categoryDropdownStates !== null &&
          Object.entries(breakdown).map(([category, subItems], categoryIndex) => (
            <Fragment key={`usage-item-${category}`}>
              {/* item summary */}
              {buildCategoryHeader(
                "regular",
                getFormattedAmount(getTotal(subItems), 2),
                category,
                categoryIndex > 0 ? TABLE_BORDER_TOP_STYLE : "",
              )}

              {/* breakdown */}
              {categoryDropdownStates[category] && subItems.map((subItem) => buildUsageItem(subItem, category))}
            </Fragment>
          ))}

        {/* discount */}
        {!!categoryDropdownStates && Object.keys(categoryDropdownStates).length > 0 && hasValidDiscounts() && (
          <Fragment>
            {buildCategoryHeader(
              "regular",
              getFormattedAmount(getTotalDiscountValue(discount.amount_off, discount.percent_off_discount_value), 2),
              "discounts",
            )}

            {categoryDropdownStates["discounts"] && !!discount.percent_off && buildPercentOffDiscountSubItem()}
            {categoryDropdownStates["discounts"] && !!discount.amount_off && buildAmountOffDiscountSubItem()}
          </Fragment>
        )}

        {/* credits */}
        {!!categoryDropdownStates && Object.keys(categoryDropdownStates).length > 0 && hasValidCredits() && (
          <Fragment>
            {buildCategoryHeader("regular", getFormattedAmount(getTotalCredits(), 2), "credits")}
            {categoryDropdownStates["credits"] && hasCreditRecord() && buildCreditItem()}
          </Fragment>
        )}

        {/* total */}
        {!!breakdown &&
          categoryDropdownStates !== null &&
          buildCategoryHeader("bold", getFormattedAmount(usageCostSummary, 2), "total")}
      </Table>
    </Grid>
  );
};
export default UsageDetails;
