import { Link } from "@mui/material";
import React from "react";
import QuickLinks from "../../constants/links";

type Props = {
  title: string;
};

const MailToSupportLink: React.FC<React.PropsWithChildren<Props>> = ({ title }) => {
  return (
    <Link
      href={QuickLinks.mail_to_support}
      target={"_blank"}
      rel="noopener"
      sx={{
        textDecoration: "none",
        display: "contents",
        color: "primary.main",
      }}
    >
      {title}
    </Link>
  );
};

export default MailToSupportLink;
