import { Box, Grid, InputLabel, Tab, Tabs } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { MarqtuneDataset, MarqtuneModel } from "../../../api/marqtune/types";
import { NO_DATASET_SCHEMA_CONFIG, datasetSchemaTableConfig } from "../../../pages/marqtune/dataset-details.page";
import { useSelector } from "../../../store";
import { selectDatasetByDatasetId } from "../../../store/selectors";
import DatasetSchemaTable from "../../dataset-schema-table/dataset-schema-table.component";
import SelectField from "../../form-fields/select-field";
import { formClasses } from "../../form/form.styles";
import TabPanel, { tabPanelControlProps } from "../../tab-panel/tab-panel.component";
import ModelDetailsCodeWrapper from "./model-details/model-details-code-wrapper.component";

type Props = {
  models: MarqtuneModel[];
  datasets: MarqtuneDataset[];
};

const getCheckpointOptions = (checkpoints: MarqtuneModel["checkpoints"]) => {
  return checkpoints.map((checkpoint) => ({
    id: checkpoint,
    title: checkpoint,
    value: checkpoint,
  }));
};

const getDatasetOptions = (datasets: MarqtuneDataset[]) => {
  return datasets.map(({ datasetId, datasetName }) => ({
    id: datasetId,
    title: datasetName,
    value: datasetId,
  }));
};

const EvaluationModelQuickStart = ({ models, datasets }: Props) => {
  const formComponentClasses = formClasses();
  const datasetOptions = getDatasetOptions(datasets);

  const [selectedModelId, setSelectedModelId] = useState(models[0]?.modelId || "");
  const [checkpointOptions, setCheckpointOptions] = useState(getCheckpointOptions(models[0]?.checkpoints || []));
  const [checkpointSelect, setCheckpointSelect] = useState(checkpointOptions.length ? checkpointOptions[0].value : "");
  const [datasetSelect, setDatasetSelect] = useState(datasetOptions.length ? datasetOptions[0].value : "");
  const [tabValue, setTabValue] = useState(0);
  const idPrefix = "marqtune-model-details";

  const datasetDetails = useSelector(selectDatasetByDatasetId(datasetSelect));

  useEffect(() => {
    if (models.length > 0) {
      const firstModel = models[0];
      setSelectedModelId(firstModel.modelId);
      const initialCheckpoints = getCheckpointOptions(firstModel.checkpoints);
      setCheckpointOptions(initialCheckpoints);
      setCheckpointSelect(initialCheckpoints.length ? initialCheckpoints[0].value : "");
    }
  }, [models]);

  useEffect(() => {
    const selectedModel = models.find((model) => model.modelId === selectedModelId);
    if (selectedModel) {
      const newCheckpoints = getCheckpointOptions(selectedModel.checkpoints);
      setCheckpointOptions(newCheckpoints);
      setCheckpointSelect(newCheckpoints.length ? newCheckpoints[0].value : "");
    }
  }, [selectedModelId]);

  const handleModelChange = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLSelectElement;
    setSelectedModelId(target.value);
  };

  const handleCheckpointChange = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLSelectElement;
    setCheckpointSelect(target.value);
  };

  const handleDatasetChange = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLSelectElement;
    setDatasetSelect(target.value);
  };

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <Fragment>
      <Box mb={2}>
        <form className={formComponentClasses.unwrappedForm}>
          <Grid container spacing={1}>
            {models.length > 0 && (
              <Grid item xs={12} sm={6} md={4} lg={3}>
                <Grid item mb={1}>
                  <InputLabel htmlFor="model-selector">Model</InputLabel>
                </Grid>
                <Grid item>
                  <SelectField
                    fullWidth
                    id="model-selector"
                    name="model-selector"
                    placeholder="Select Model"
                    value={selectedModelId}
                    onChange={handleModelChange}
                    options={models.map((model) => ({
                      id: model.modelId,
                      title: model.modelName,
                      value: model.modelId,
                    }))}
                    disabled={!models.length}
                  />
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Grid item mb={1}>
                <InputLabel htmlFor="checkpoint-selector">Checkpoint</InputLabel>
              </Grid>
              <Grid item>
                <SelectField
                  fullWidth
                  id="checkpoint-selector"
                  name="checkpoint-selector"
                  placeholder="Select Checkpoint"
                  value={checkpointSelect}
                  onChange={handleCheckpointChange}
                  options={checkpointOptions}
                  disabled={!checkpointOptions.length}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
              <Grid item mb={1}>
                <InputLabel htmlFor="dataset-selector">Evaluation Dataset</InputLabel>
              </Grid>
              <Grid item>
                <SelectField
                  fullWidth
                  id="dataset-selector"
                  name="dataset-selector"
                  placeholder="Select Dataset"
                  value={datasetSelect}
                  onChange={handleDatasetChange}
                  options={datasetOptions}
                  disabled={!datasetOptions.length}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>

      <DatasetSchemaTable
        schemaConfig={() => (datasetDetails ? datasetSchemaTableConfig(datasetDetails) : NO_DATASET_SCHEMA_CONFIG)}
      />

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={tabValue} onChange={handleTabChange} aria-label="model-details-tabs">
          <Tab sx={{ fontSize: "0.8rem" }} label="CHECKPOINT EVALUATION" {...tabPanelControlProps(0, idPrefix)} />
        </Tabs>
      </Box>

      <TabPanel value={tabValue} index={0} idPrefix={idPrefix}>
        <ModelDetailsCodeWrapper
          evaluationDatasetId={datasetSelect}
          checkpoint={checkpointSelect}
          modelId={selectedModelId}
          showReleaseCheckpointSnippet={false}
        />
      </TabPanel>
    </Fragment>
  );
};

export default EvaluationModelQuickStart;
