import React, { useState } from "react";
import { BreadCrumbItem } from "../components/content-layout/content-layout.types";

type TInitialBreadcrumbsState = {
  breadcrumbs: BreadCrumbItem[] | null;
  clearBreadcrumbs?: () => void;
  setBreadcrumbs?: (breadcrumbs: BreadCrumbItem[]) => void;
  pageTitle?: string;
  setPageTitle?: (pageTitle: string) => void;
};

const initialState: TInitialBreadcrumbsState = {
  breadcrumbs: null,
  pageTitle: "",
};

export const BreadcrumbsContext = React.createContext<TInitialBreadcrumbsState>(initialState);

export const BreadcrumbsProvider: React.FC = ({ children }): JSX.Element => {
  const [breadcrumbs, setBreadcrumbs] = useState<BreadCrumbItem[] | null>(null);
  const [pageTitle, setPageTitle] = useState<string>("");

  const handleSetBreadcrumbs = (breadcrumbs: BreadCrumbItem[]) => {
    setBreadcrumbs(breadcrumbs);
  };

  const handleSetPageTitle = (title = "") => {
    setPageTitle(title);
  };

  const handleClearBreadcrumbs = () => {
    setBreadcrumbs(null);
    setPageTitle("");
  };

  return (
    <BreadcrumbsContext.Provider
      value={{
        breadcrumbs,
        clearBreadcrumbs: handleClearBreadcrumbs,
        setBreadcrumbs: handleSetBreadcrumbs,
        pageTitle,
        setPageTitle: handleSetPageTitle,
      }}
    >
      {children}
    </BreadcrumbsContext.Provider>
  );
};
