const QuickLinks = {
  // MARQO
  privacy_policy: "https://www.marqo.ai/privacy-policy",
  terms_and_condition: "https://www.marqo.ai/terms-and-conditions",
  cloud_doc: "https://docs.marqo.ai/latest/Cloud-Reference/getting_started/",
  mail_to_support: "https://marqo.zendesk.com/hc/en-us/requests/new",
  zendesk_root: "https://marqo.zendesk.com",
  supported_models: "https://docs.marqo.ai/latest/Guides/Models-Reference/dense_retrieval/",
  pricing: "https://www.marqo.ai/pricing",
  // INDEXES
  sizing_index: "https://docs.marqo.ai/latest/Cloud-Reference/getting_started/#sizing-your-index",
  create_structured_index: "https://docs.marqo.ai/latest/API-Reference/Indexes/create_structured_index/",
  // DATADOG
  datadog_site: "https://www.datadoghq.com/",
  datadog_api_key: "https://docs.datadoghq.com/account_management/api-app-keys/#api-keys",
  // MEMBERS
  adding_team_members: "https://docs.marqo.ai/latest/Cloud-Reference/getting_started/?h=adding#adding-team-members",
  // MARQTUNE
  marqtune_evaluation_guide: "https://docs.marqo.ai/latest/Marqtune/API/evaluation/evaluate_model/",
  setting_marqtune_evaluation_schema_param:
    "https://docs.marqo.ai/latest/Marqtune/API/evaluation/evaluation_parameters/",
  marqtune_data_guide: "https://docs.marqo.ai/latest/Marqtune/finetuning_data/",
  created_a_marqtune_training_dataset:
    "https://docs.marqo.ai/latest/Marqtune/API/dataset/create_dataset/#example-training-dataset",
  created_a_marqtune_evaluation_dataset:
    "https://docs.marqo.ai/latest/Marqtune/API/dataset/create_dataset/#example-evaluation-dataset",
  fine_tuning_guide: "https://docs.marqo.ai/latest/Marqtune/what_is_finetuning/",
  fine_tuning_charges_per_min: "https://docs.marqo.ai/latest/Marqtune/getting_started/#what-is-the-cost",
  marqtune_documentation: "https://docs.marqo.ai/latest/Marqtune/getting_started/",
  marqtune_example_training_dataset:
    "https://marqo-gcl-public.s3.us-west-2.amazonaws.com/marqtune_test/datasets/v1/gs_100k_training.csv.gz",
  marqtune_example_eval_dataset:
    "https://marqo-gcl-public.s3.us-west-2.amazonaws.com/marqtune_test/datasets/v1/gs_25k_eval.csv.gz",
};

export default QuickLinks;
