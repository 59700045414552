import { createSlice } from "@reduxjs/toolkit";

type AppStateProps = {
  toast: {
    msg: string;
    type: null | "error" | "success" | "info";
    hash: string;
  };
};

const initialState: AppStateProps = {
  toast: {
    msg: "",
    type: null,
    hash: "",
  },
};

const slice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setToast: (state, { payload }) => {
      const { msg, hash } = payload;
      state.toast.msg = msg;
      state.toast.type = payload.type;
      state.toast.hash = hash;
    },
  },
});

export const { setToast } = slice.actions;
export default slice.reducer;
