import { Info } from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { Prism } from "prism-react-renderer";
import React, { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import toast from "react-hot-toast";
import { Navigate, useLocation, useNavigate } from "react-router";
import { Route, Routes } from "react-router-dom";
import bashLang from "refractor/lang/bash";
import pythonLang from "refractor/lang/python";
import DashboardLayout from "./components/dashboard-layout/dashboard-layout.component";
import { SplashScreen } from "./components/splash-screen/splash-screen.component";
import config from "./config";
import { TAuthContext } from "./contexts/jwt-context";
import { useAuth } from "./hooks/use-auth";
import { usePageLoadMetrics } from "./hooks/use-page-load-metrics";
import CreateAccount from "./pages/accounts/account-create.page";
import AccountInvitations from "./pages/accounts/account-invitations.page";
import ConfirmSignup from "./pages/authentication/confirm-signup.page";
import Login from "./pages/authentication/login.page";
import Register from "./pages/authentication/register.page";
import Billing from "./pages/billing/billing.page";
import CreateIndex from "./pages/indices/index-create.page";
import IndexDetails from "./pages/indices/index-details.page";
import ModifyIndex from "./pages/indices/index-modify.page";
import Overview from "./pages/indices/indexes.page";
import IntegrationsPage from "./pages/integrations";
import MainPage from "./pages/main.page";
import MarqtuneCreateDatasetPage from "./pages/marqtune/create-dataset.page";
import MarqtuneDatasetDetailsPage from "./pages/marqtune/dataset-details.page";
import MarqtuneEvaluationDetailsPage from "./pages/marqtune/evaluation-details.page";
import MarqtuneEvaluationModelPage from "./pages/marqtune/evaluation-model.page";
import MarqtunePage from "./pages/marqtune/marqtune.page";
import MarqtuneModelDetailsPage from "./pages/marqtune/model-details.page";
import MarqtuneTrainModelPage from "./pages/marqtune/train-model.page";
import APIKeyCreatePage from "./pages/settings/api-keys-create.page";
import APIKeysPage from "./pages/settings/api-keys.page";
import AcceptInvitePage from "./pages/settings/members/accept-invite.page";
import AddMember from "./pages/settings/members/add-member.page";
import MembersPage from "./pages/settings/members/members.page";
import PasswordResetRequest from "./pages/settings/password-reset/password-reset-request.page";
import PasswordReset from "./pages/settings/password-reset/password-reset.page";
import { setToast } from "./slices/app";
import { useDispatch, useSelector } from "./store";
import { selectCurrentAccountId, selectMustCreateOrSelectAccount } from "./store/selectors";
import { generateRandomKey } from "./utils";
import { TRoute, getRoutes } from "./utils/get-routes";
import ga4EventsLogger from "./utils/google-analytics/events/logger";
import { getNormalizedPathname, getQueryParam, isPathPublic } from "./utils/locationUtils";

bashLang(Prism);
pythonLang(Prism);

type AppProps = {
  auth: TAuthContext;
};

type LocState = {
  info?: string;
};

export const appPaths = (withMarqtuneUI: boolean, withMarqtune: boolean): TRoute[] => [
  //  MAIN PAGE
  { path: "/", component: MainPage },
  //  INDEXES PAGE
  {
    path: "/indexes",
    subRoutes: [
      { isIndex: true, component: Overview, isPrivate: true },
      { path: ":indexName", component: IndexDetails, isPrivate: true },
      { path: "create", component: CreateIndex, isPrivate: true },
      { path: "edit/:indexName", component: ModifyIndex, isPrivate: true },
    ],
  },
  //  AUTHENTICATION PAGE
  {
    path: "/authentication",
    subRoutes: [
      { path: "login/?code:code", component: Login },
      // http://localhost:3000/authentication/register/?code=5bde98b9-2ae6-4f4d-a44c-6ab755ef4d85
      // { path: "register/#access_token=:access_token&token_type=Bearer&expires_in=:expiry", component: Register },
      { path: "login", component: Login },
      { path: "register/?code:code", component: Register },
      { path: "register", component: Register },
      { path: "confirm-signup/:email", component: ConfirmSignup },
      { path: "password-reset/:email", component: PasswordReset },
      { path: "password-reset", component: PasswordReset },
      { path: "forgot-password", component: PasswordResetRequest },
      { path: "accept-invite/?code:code", component: AcceptInvitePage },
      { path: "accept-invite/:email", component: AcceptInvitePage },
      { path: "accept-invite", component: AcceptInvitePage },
    ],
  },
  //  INTEGRATIONS PAGE
  {
    path: "/integrations",
    subRoutes: [{ isIndex: true, component: IntegrationsPage, isPrivate: true }],
  },
  //  SETTINGS PAGE
  //  TODO: refactor if there is index page for /settings path
  {
    path: "/settings/api-keys",
    subRoutes: [
      { isIndex: true, component: APIKeysPage, isPrivate: true },
      { path: "create", component: APIKeyCreatePage, isPrivate: true },
    ],
  },
  {
    path: "/settings/team-members",
    subRoutes: [
      { isIndex: true, component: MembersPage, isPrivate: true },
      { path: "add", component: AddMember, isPrivate: true },
    ],
  },
  // BILLING
  {
    path: "/billing",
    subRoutes: [{ isIndex: true, component: Billing, isPrivate: true }],
  },
  // ACCOUNT PAGE
  {
    path: "/accounts",
    subRoutes: [
      { path: "create", component: CreateAccount, isPrivate: true },
      { path: "invitations", component: AccountInvitations, isPrivate: true },
    ],
  },
  // MARQTUNE
  ...(withMarqtuneUI
    ? [
        {
          path: "/marqtune",
          subRoutes: [
            { isIndex: true, component: MarqtunePage, isPrivate: true },
            ...(withMarqtune
              ? [
                  { path: "train-model", component: MarqtuneTrainModelPage, isPrivate: true },
                  // datasets
                  { path: "datasets/:datasetId", component: MarqtuneDatasetDetailsPage, isPrivate: true },
                  { path: "datasets/create", component: MarqtuneCreateDatasetPage, isPrivate: true },
                  // evaluations
                  { path: "evaluations/:evaluationId", component: MarqtuneEvaluationDetailsPage, isPrivate: true },
                  { path: "evaluation-model", component: MarqtuneEvaluationModelPage, isPrivate: true },
                  // models
                  { path: "models/:modelId", component: MarqtuneModelDetailsPage, isPrivate: true },
                ]
              : []),
          ],
        },
      ]
    : []),
];

const App: React.FC<AppProps> = () => {
  const muiTheme = useTheme();
  const { isAuthenticated, authChecking, setLoggedIn, isInitialized } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const locState = location.state as LocState;
  const pageLoadTime = usePageLoadMetrics();
  const toastState = useSelector((state) => state.app.toast);
  const { isAccountDataSet } = useSelector(({ user }) => user);
  const { withBilling, withIntegrations, withMarqtuneUI, withMarqtune } = useSelector(({ user }) => user.data);
  const mustSelectAccount = useSelector(selectMustCreateOrSelectAccount);

  const routes = (
    <Routes>
      {getRoutes(appPaths(withMarqtuneUI, withMarqtune), authChecking, isInitialized)}
      <Route path={"*"} element={<Navigate to={"/"} />} />
    </Routes>
  );

  const makeToast = (toastMsg: string, toastType: "error" | "success" | "info") => {
    if (toastType === "error") {
      toast.error(toastMsg, {
        style: {
          background: muiTheme.palette.background.default,
          color: muiTheme.palette.text.primary,
        },
      });
    } else if (toastType === "info") {
      toast(toastMsg, {
        icon: <Info />,
        style: {
          background: muiTheme.palette.background.default,
          color: muiTheme.palette.text.primary,
        },
      });
    } else {
      toast.success(toastMsg, {
        style: {
          background: muiTheme.palette.background.default,
          color: muiTheme.palette.text.primary,
        },
      });
    }
  };

  if (!location.hash) {
    window.scrollTo(0, 0);
  }

  useEffect(() => {
    if (locState?.info) {
      dispatch(
        setToast({
          msg: locState.info,
          type: "info",
          hash: generateRandomKey(6),
        }),
      );
    }
  }, [locState?.info]);

  useEffect(() => {
    if (!authChecking && isInitialized) {
      const normalizedPath = getNormalizedPathname(location.pathname);
      const isActivatingAccount = normalizedPath.includes(config.publicPaths.acceptInvite);
      const isConfirmingSignup = normalizedPath.includes(config.publicPaths.confirmSignup);
      const isResettingPass = normalizedPath.includes(config.publicPaths.passwordReset);
      const code = getQueryParam(location.search, "code");
      const cloudSelector = getQueryParam(location.search, "cloud-selector");
      const showCloudSelector = normalizedPath === config.publicPaths.register && cloudSelector === "true";
      const isOnSocialSignup = normalizedPath === config.publicPaths.register && !!code;
      const isOnSocialLogin = normalizedPath === config.publicPaths.login && !!code;
      const isOnSocialJoinOrg = normalizedPath === config.publicPaths.acceptInvite && !!code;

      if (isAuthenticated && !isAccountDataSet) {
        // wait for account data to be set
        // splash
      } else if (isAuthenticated && isAccountDataSet) {
        setLoggedIn();

        // Path to navigate to. Assume the requested path, unless one of the following checks
        // overrides it with a redirect.
        let path = normalizedPath;

        if (isResettingPass || isActivatingAccount || isConfirmingSignup || isPathPublic(normalizedPath)) {
          // Don't access public/auth-related paths once authenticated.
          path = config.authenticatedPaths.home;
        } else if (mustSelectAccount) {
          const allowed = [config.authenticatedPaths.createAccount, config.authenticatedPaths.accountInvitations];
          if (!allowed.includes(path)) {
            path = config.authenticatedPaths.home;
          }
        } else if (!withBilling) {
          // If billing is disabled, don't allow the billing page.
          if (normalizedPath === config.authenticatedPaths.billing) {
            path = config.authenticatedPaths.home;
          }
        }

        return navigate(path);
      } else {
        if (isOnSocialSignup || isOnSocialLogin || isOnSocialJoinOrg || showCloudSelector) {
          return navigate(`${normalizedPath}${location.search}`);
        }

        if (isPathPublic(normalizedPath) || isActivatingAccount || isResettingPass || isConfirmingSignup) {
          return navigate(normalizedPath);
        }

        return navigate(config.publicPaths.login); // login is the default
      }
    }
  }, [
    isAuthenticated,
    authChecking,
    location.pathname,
    withBilling,
    withIntegrations,
    isAccountDataSet,
    location.search,
  ]);

  useEffect(() => {
    toastState.msg && makeToast(toastState.msg, toastState.type);
  }, [toastState.hash]);

  useEffect(() => {
    if (pageLoadTime > 0) {
      ga4EventsLogger.logPageLoad(pageLoadTime);
    }
  }, [pageLoadTime]);

  return (
    <Fragment>
      <Helmet>
        <title>Marqo Cloud</title>
      </Helmet>

      {!authChecking && isInitialized ? (
        isAuthenticated ? (
          <DashboardLayout>{routes}</DashboardLayout>
        ) : (
          <Fragment>{routes}</Fragment>
        )
      ) : (
        <SplashScreen open={true} />
      )}
    </Fragment>
  );
};

export default App;
