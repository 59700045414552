import config from "../config";

export const getNormalizedPathname = (locPathname: string) => {
  return locPathname.endsWith("/") ? locPathname.replace(/\/+$/, "") + "/" : `${locPathname}/`;
};

export const getQueryParam = (locSearch: string, paramName: string): string | undefined => {
  try {
    const _params = new URLSearchParams(locSearch);
    return _params.get(paramName);
  } catch (e) {
    return undefined;
  }
};

export const isPathPublic = (locPathname: string) => Object.values(config.publicPaths).includes(locPathname);
