import * as Yup from "yup";
import { FieldConfigType } from "../../components/form/form.types";
import { FormValuesExtended } from "./../types";
import { emailComponentProps, passwordComponentProps } from "./common-auth.props";
import { passwordRegex, passwordValidationMsg } from "./validators";

type ExtraPwValidation = (yupSchema: Yup.StringSchema) => Yup.StringSchema;

interface BasePasswordBasedFormValues {
  password: string;
}

export const emailFieldConfig = (disable = false, hidden = false): FieldConfigType => {
  return {
    label: "Email",
    sizes: {
      xs: 12,
    },
    ...emailComponentProps(disable),
    hidden,
    validation: {
      rules: {
        onTouch: false,
        onBlur: false,
      },
      validate: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
    },
  };
};

export const passwordFieldConfig = (label = "Password"): FieldConfigType => {
  return {
    label,
    type: "password",
    sizes: {
      xs: 12,
    },
    ...passwordComponentProps(label),
    validation: {
      rules: {},
      validate: Yup.string().max(255).required(`${label} is required`),
    },
  };
};

export const strictPasswordFieldConfig = (
  label = "Password",
  extraPwValidation: ExtraPwValidation = (schema) => schema,
): FieldConfigType => {
  return {
    label,
    type: "password",
    sizes: {
      xs: 12,
    },
    ...passwordComponentProps(label),
    validation: {
      rules: {},
      validate: extraPwValidation(
        Yup.string().min(8, passwordValidationMsg).max(255).matches(passwordRegex, passwordValidationMsg),
      ),
    },
  };
};

export const confirmPasswordFieldConfig = <ExtraType extends BasePasswordBasedFormValues>(
  label = "Confirm Password",
): FieldConfigType => {
  return {
    label,
    type: "password",
    sizes: {
      xs: 12,
    },
    ...passwordComponentProps(label),
    validation: {
      rules: {},
      validate: Yup.string()
        .max(255)
        .required("Confirm password is required")
        .test("passwords-match", "Passwords must match", (val, formValues) => {
          const password = (formValues as FormValuesExtended<ExtraType>).from[0].value?.password;
          return val === password;
        }),
    },
  };
};
