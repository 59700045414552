import { Alert, Button, Grid } from "@mui/material";
import { FormikProps } from "formik";
import React, { Fragment, PropsWithChildren, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { hasEmpty, shallowEqual } from "../../utils/equality";
import PromptDialog from "../dialogs/prompt-dialog.component";
import Form from "./form.component";
import { CurrentFieldSettingProps, FormConfigType, FormProps } from "./form.types";

type Props = Omit<FormProps, "spacing"> & {
  formik: FormikProps<any>;
  formConfig: FormConfigType;
  currentSettings?: CurrentFieldSettingProps[];
  promptTitle?: string;
  warningContent?: string;
  formHasOnchangeError?: boolean;
  postSendNavigateTo?: string;
  submitBtnLabel?: string;
  promptDialogMsg?: string | React.ReactNode;
  withPromptDialog?: boolean;
  checkIfShallow?: boolean;
  resetOnCancel?: boolean;
  showEditOnSubmit?: boolean;
  editBtnDisabled?: boolean;
  enableActionsViaEditBtn?: boolean;
  onToggleEditCallback?: () => void;
  onCancelCallback?: () => void;
  // biome-ignore lint/suspicious/noConfusingVoidType: option to return `false` to change behavior.
  onPreSubmitHandler?: () => void | boolean;
};

export const StandardForm: React.FC<PropsWithChildren<Props>> = (props) => {
  const [openPrompt, setOpenPrompt] = useState(false);
  const [showEditBtn, setShowEditBtn] = useState(true);
  const { formik, formConfig, promptTitle, formHasOnchangeError, editBtnDisabled, children, ...rest } = props;
  const navigate = useNavigate();

  useEffect(() => {
    if (props.showEditOnSubmit && !formik.isSubmitting) {
      setShowEditBtn(true);
    }
  }, [props.showEditOnSubmit, formik.isSubmitting]);

  const computeSubmitButtonIsDisabled = () => {
    const formikValues = formik.values;
    let defaultChecks: boolean;

    if (Object.keys(formikValues).includes("submit")) {
      delete formikValues["submit"];
    }

    defaultChecks =
      formik.isSubmitting || hasEmpty(formikValues) || Object.values(formik.errors).length > 0 || formHasOnchangeError;

    if (!!props?.checkIfShallow) {
      return shallowEqual(formikValues, formik.initialValues) || defaultChecks;
    }
    return defaultChecks;
  };

  const handleCancel = () => {
    setShowEditBtn(true);

    if (rest?.resetOnCancel) {
      formik.resetForm();
    }

    if (rest?.onCancelCallback) {
      rest.onCancelCallback();
    }

    if (!!rest?.postSendNavigateTo) {
      navigate(rest.postSendNavigateTo);
    }
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    if (props?.withPromptDialog) {
      setOpenPrompt(true);
    } else {
      if (props?.onPreSubmitHandler) {
        if (props.onPreSubmitHandler() === false) {
          return;
        }
      }
      formik.handleSubmit();
    }
  };

  return (
    <Fragment>
      {props?.withPromptDialog && (
        <PromptDialog
          title={promptTitle || ""}
          promptDialogMsg={rest?.promptDialogMsg ? rest.promptDialogMsg : "Apply Changes?"}
          openDialog={openPrompt}
          disagreeHandler={() => setOpenPrompt(false)}
          agreeHandler={() => {
            formik.handleSubmit();
            setOpenPrompt(false);
          }}
        />
      )}

      <Form spacing={4} formik={formik} formConfig={formConfig} wrapChildren={true} {...rest}>
        {children}

        <Grid item container justifyContent={"center"}>
          {props?.warningContent && (
            <Alert
              severity={"warning"}
              sx={(theme) => ({
                mt: 4,
                border: `2px solid ${theme.palette.warning.main}`,
                backgroundColor: "background.warning",
              })}
            >
              {props.warningContent}
            </Alert>
          )}
        </Grid>

        {showEditBtn && rest?.enableActionsViaEditBtn && (
          <Fragment>
            <Button
              size="large"
              variant="contained"
              color="secondary"
              fullWidth
              disabled={!!editBtnDisabled}
              onClick={() => {
                setShowEditBtn(!showEditBtn);
                if (rest?.onToggleEditCallback) {
                  rest.onToggleEditCallback();
                }
              }}
              sx={{ maxWidth: "max-content", height: "max-content" }}
            >
              Edit
            </Button>
          </Fragment>
        )}

        {((!showEditBtn && rest?.enableActionsViaEditBtn) || !rest?.enableActionsViaEditBtn) && (
          <Fragment>
            <Button
              size="large"
              variant="outlined"
              color="inherit"
              disabled={formik.isSubmitting}
              fullWidth
              onClick={handleCancel}
              sx={{ maxWidth: "max-content", height: "max-content" }}
            >
              Cancel
            </Button>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color={"secondary"}
              disabled={computeSubmitButtonIsDisabled()}
              onClick={handleSubmit}
              sx={{ maxWidth: "max-content", height: "max-content" }}
            >
              {rest?.submitBtnLabel ? rest.submitBtnLabel : "Apply Changes"}
            </Button>
          </Fragment>
        )}
      </Form>
    </Fragment>
  );
};
