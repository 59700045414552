import { Ga4Event } from "../index";

const GaEvents: { [event: string]: Ga4Event } = {
  signup_page_view: {
    category: "Signup",
    event_name: "signup_page_view",
    label: "signup_page_view",
  },
  cc_signup_page_view: {
    category: "Signup",
    event_name: "cc_signup_page_view",
    label: "cc_signup_page_view",
  },
  cc_signup_page_successful_submit: {
    category: "Signup",
    event_name: "cc_signup_page_successful_submit",
    label: "cc_signup_page_successful_submit",
  },
  cc_signup_page_failed_submit: {
    category: "Signup",
    event_name: "cc_signup_page_failed_submit",
    label: "cc_signup_page_failed_submit",
  },
  guest_billing_page_view: {
    category: "Signup",
    event_name: "guest_billing_page_view",
    label: "guest_billing_page_view",
  },
  guest_billing_page_successful_submit: {
    category: "Signup",
    event_name: "guest_billing_page_successful_submit",
    label: "guest_billing_page_successful_submit",
  },
  guest_billing_page_failed_submit: {
    category: "Signup",
    event_name: "guest_billing_page_failed_submit",
    label: "guest_billing_page_failed_submit",
  },
  console_view: {
    category: "Console View",
    event_name: "console_view",
    label: "console_view",
  },
  create_index_view: {
    category: "Indexes",
    event_name: "create_index_view",
    label: "create_index_view",
  },
  create_index_submit: {
    category: "Indexes",
    event_name: "create_index_submit",
    label: "cc_signup_page_view",
  },
  page_load: {
    category: "Page Load Time",
    event_name: "page_load",
    label: "Page Load Time",
  },
};

export default GaEvents;
