import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import API from "../api";
import { setGetPricesFetchingError, setIsFetchingPrices, setProductPrices } from "../slices/billing/pricing";
import { RootState } from "../store";
import { selectCurrentAccountId } from "../store/selectors";

export const getProductPricesThunk = createAsyncThunk(
  "billing/getProductPricesThunk",
  async (_, { dispatch, getState }) => {
    const accountId = selectCurrentAccountId(getState() as RootState);
    dispatch(setIsFetchingPrices(true));
    try {
      const { data } = await API.billing.get.prices(accountId);
      dispatch(setProductPrices(data));
      return data;
    } catch (e) {
      const err = e as AxiosError;
      dispatch(setGetPricesFetchingError(err));
      return Promise.reject(e);
    }
  },
);
