import { createSlice } from "@reduxjs/toolkit";
import { MarqtuneDataset, MarqtuneEvaluation, MarqtuneModel } from "../../api/marqtune/types";
import {
  getMarqtuneModelDetailsThunk,
  listMarqtuneDatasetLogsThunk,
  listMarqtuneDatasetsThunk,
  listMarqtuneEvaluationLogsThunk,
  listMarqtuneEvaluationsThunk,
  listMarqtuneModelLogsThunk,
  listMarqtuneModelsThunk,
  requestMarqtuneAccessThunk,
} from "../../thunks/marqtune.thunk";
import { fillMissingDatasetFields, fillMissingEvaluationFields, fillMissingModelFields } from "./helpers";

export type LogUnit = {
  timestamp: string;
  message: string;
};

export type LogsState = {
  logs: LogUnit[];
  isFetching: boolean;
};

type InitialState = {
  requestAccess: {
    isPending: boolean;
    successful: boolean;
  };
  datasets: {
    list: MarqtuneDataset[];
    isFetching: boolean;
    itemsPerPage: number;
    currentPage: number;
  };
  evaluations: {
    list: MarqtuneEvaluation[];
    isFetching: boolean;
    itemsPerPage: number;
    currentPage: number;
  };
  models: {
    list: MarqtuneModel[];
    isFetching: boolean;
    itemsPerPage: number;
    currentPage: number;
  };
};

export const MarqtuneInitialState: InitialState = {
  requestAccess: {
    isPending: false,
    successful: !!localStorage.marqtuneAccessRequested,
  },
  datasets: {
    list: [],
    isFetching: false,
    itemsPerPage: 5,
    currentPage: 0,
  },
  evaluations: {
    list: [],
    isFetching: false,
    itemsPerPage: 5,
    currentPage: 0,
  },
  models: {
    list: [],
    isFetching: false,
    itemsPerPage: 5,
    currentPage: 0,
  },
};

const marqtuneSlice = createSlice({
  name: "marqtune",
  initialState: MarqtuneInitialState,
  reducers: {
    setMarqtuneDatasetsPage: (state, { payload }) => {
      state.datasets.currentPage = payload;
    },
    setMarqtuneEvaluationsPage: (state, { payload }) => {
      state.evaluations.currentPage = payload;
    },
    setMarqtuneModelsPage: (state, { payload }) => {
      state.models.currentPage = payload;
    },
    updateModel: (state, { payload }) => {
      const updatedModel = payload;
      const index = state.models.list.findIndex((model) => model.modelId === updatedModel.modelId);
      if (index !== -1) {
        state.models.list[index] = updatedModel;
      } else {
        state.models.list.push(updatedModel);
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestMarqtuneAccessThunk.pending, (state) => {
        state.requestAccess.isPending = true;
      })
      .addCase(requestMarqtuneAccessThunk.fulfilled, (state) => {
        state.requestAccess.isPending = false;
        state.requestAccess.successful = true;
        // Persist the success client-side to prevent showing the button on refresh.
        // This is a temporary feature, so we don't need a backend solution.
        localStorage.marqtuneAccessRequested = true;
      })
      .addCase(requestMarqtuneAccessThunk.rejected, (state) => {
        state.requestAccess.isPending = false;
        state.requestAccess.successful = false;
      })
      .addCase(listMarqtuneDatasetsThunk.pending, (state, _) => {
        state.datasets.isFetching = true;
      })
      .addCase(listMarqtuneDatasetsThunk.fulfilled, (state, { payload }) => {
        state.datasets.isFetching = false;
        state.datasets.list = fillMissingDatasetFields(payload || []);
        state.datasets.currentPage = 0;
      })
      .addCase(listMarqtuneDatasetsThunk.rejected, (state, _) => {
        state.datasets.isFetching = false;
        state.datasets.list = [];
        state.datasets.currentPage = 0;
      })
      .addCase(listMarqtuneDatasetLogsThunk.pending, (state, action) => {
        const argDatasetId: string = action.meta.arg;
        const matchingDataset = state.datasets.list.find(({ datasetId }) => datasetId === argDatasetId);
        if (matchingDataset) {
          matchingDataset.resourceLogs.isFetching = true;
        }
      })
      .addCase(listMarqtuneDatasetLogsThunk.fulfilled, (state, action) => {
        const argDatasetId: string = action.meta.arg;
        const matchingDataset = state.datasets.list.find(({ datasetId }) => datasetId === argDatasetId);
        if (matchingDataset) {
          matchingDataset.resourceLogs.isFetching = false;
          matchingDataset.resourceLogs.logs = action.payload;
        }
      })
      .addCase(listMarqtuneDatasetLogsThunk.rejected, (state, action) => {
        const argDatasetId: string = action.meta.arg;
        const matchingDataset = state.datasets.list.find(({ datasetId }) => datasetId === argDatasetId);
        if (matchingDataset) {
          matchingDataset.resourceLogs.isFetching = false;
        }
      })
      .addCase(listMarqtuneEvaluationsThunk.pending, (state, _) => {
        state.evaluations.isFetching = true;
      })
      .addCase(listMarqtuneEvaluationsThunk.fulfilled, (state, { payload }) => {
        state.evaluations.isFetching = false;
        state.evaluations.list = fillMissingEvaluationFields(payload || []);
        state.evaluations.currentPage = 0;
      })
      .addCase(listMarqtuneEvaluationsThunk.rejected, (state, _) => {
        state.evaluations.isFetching = false;
        state.evaluations.list = [];
        state.evaluations.currentPage = 0;
      })
      .addCase(listMarqtuneEvaluationLogsThunk.pending, (state, action) => {
        const argEvaluationId: string = action.meta.arg;
        const matchingEvaluation = state.evaluations.list.find(({ evaluationId }) => evaluationId === argEvaluationId);
        if (matchingEvaluation) {
          matchingEvaluation.resourceLogs.isFetching = true;
        }
      })
      .addCase(listMarqtuneEvaluationLogsThunk.fulfilled, (state, action) => {
        const argEvaluationId: string = action.meta.arg;
        const matchingEvaluation = state.evaluations.list.find(({ evaluationId }) => evaluationId === argEvaluationId);
        if (matchingEvaluation) {
          matchingEvaluation.resourceLogs.isFetching = false;
          matchingEvaluation.resourceLogs.logs = action.payload;
        }
      })
      .addCase(listMarqtuneEvaluationLogsThunk.rejected, (state, action) => {
        const argEvaluationId: string = action.meta.arg;
        const matchingEvaluation = state.evaluations.list.find(({ evaluationId }) => evaluationId === argEvaluationId);
        if (matchingEvaluation) {
          matchingEvaluation.resourceLogs.isFetching = false;
        }
      })
      .addCase(listMarqtuneModelsThunk.pending, (state, _) => {
        state.models.isFetching = true;
      })
      .addCase(listMarqtuneModelsThunk.fulfilled, (state, { payload }) => {
        state.models.isFetching = false;
        state.models.list = fillMissingModelFields(payload || []);
        state.models.currentPage = 0;
      })
      .addCase(listMarqtuneModelsThunk.rejected, (state, _) => {
        state.models.isFetching = false;
        state.models.list = [];
        state.models.currentPage = 0;
      })
      .addCase(listMarqtuneModelLogsThunk.pending, (state, action) => {
        const argModelId: string = action.meta.arg;
        const matchingModel = state.models.list.find(({ modelId }) => modelId === argModelId);
        if (matchingModel) {
          matchingModel.resourceLogs.isFetching = true;
        }
      })
      .addCase(listMarqtuneModelLogsThunk.fulfilled, (state, action) => {
        const argModelId: string = action.meta.arg;
        const matchingModel = state.models.list.find(({ modelId }) => modelId === argModelId);
        if (matchingModel) {
          matchingModel.resourceLogs.isFetching = false;
          matchingModel.resourceLogs.logs = action.payload;
        }
      })
      .addCase(listMarqtuneModelLogsThunk.rejected, (state, action) => {
        const argModelId: string = action.meta.arg;
        const matchingModel = state.models.list.find(({ modelId }) => modelId === argModelId);
        if (matchingModel) {
          matchingModel.resourceLogs.isFetching = false;
        }
      })
      .addCase(getMarqtuneModelDetailsThunk.fulfilled, (state, { payload }) => {
        const index = state.models.list.findIndex((model) => model.modelId === payload.modelId);
        if (index !== -1) {
          state.models.list[index] = payload;
        } else {
          state.models.list.push(payload);
        }
      });
  },
});

export const { setMarqtuneDatasetsPage, setMarqtuneEvaluationsPage, setMarqtuneModelsPage, updateModel } =
  marqtuneSlice.actions;
export default marqtuneSlice.reducer;
