import { ChangeEvent } from "react";
import { FieldInputType, FormConfigType } from "../../components/form/form.types";

export const advancedDetailsFieldGroup: FormConfigType["fields"]["groupName"] = {
  groupName: "Advanced details",
};

export const commonTextPropValues = (isDisabled = false) => {
  return {
    componentProps: {
      fullWidth: false,
      disabled: isDisabled,
    },
    sizes: {
      xs: 12,
      md: 6,
      xl: 3,
    },
  };
};

export const defaultCardSizes = {
  sizes: {
    xs: 12,
    sm: 12,
    md: 12,
    lg: 12,
    xl: 12,
  },
};

export const commonInputPropValues = (min: number, disabled = false) => {
  return {
    type: "number" as FieldInputType,
    componentProps: {
      fullWidth: true,
      disabled: disabled,
      min: min,
    },
    transformValue: (event: ChangeEvent<HTMLInputElement>) => {
      const value: number = Number.parseFloat(event.target.value);

      if (Number.isNaN(value)) {
        return event.target.value;
      } else {
        return Math.max(value, min);
      }
    },
    sizes: {
      xs: 12,
      sm: 9,
      md: 4,
      lg: 4,
      xl: 3,
    },
  };
};

export const numberInputSizingProps = {
  labelSizing: {
    lg: 10,
  },
  tooltipJustifyEndOnLgUp: true,
};
