import { Theme, alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getNeutralRgb } from "../../theme/dark-theme-options";

export const TableClasses = makeStyles((theme: Theme) => ({
  leanThead: {
    "& thead": {
      background: "transparent",
      borderBottom: "1rem solid transparent",

      "& th": {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
  smallTableHeadings: {
    "& thead th": {
      "&.MuiTableCell-root": {
        fontSize: "0.7rem",
        textTransform: "none",
      },
    },
    "& tbody tr:hover:not(.tr-emptyListMsg)": {
      background: alpha(getNeutralRgb("600"), 0.3),
      cursor: "pointer",
    },
    "& tbody .MuiButton-root": {
      justifyContent: "flex-start",
    },
    "& .MuiPagination-ul": {
      justifyContent: "flex-end",
      marginRight: "-1rem",
    },
  },
  noHeadings: {
    backgroundColor: getNeutralRgb("800"),

    "& .MuiTableCell-root": {
      border: `1px solid ${getNeutralRgb("600")}`,
    },
  },
}));
