import { ContentCopy, EditRounded, InfoRounded, MoreVertRounded, SearchSharp } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  ListItemIcon,
  Menu,
  MenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import classNames from "classnames";
import copy from "copy-to-clipboard";
import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import CardTitle from "../../components/card-title/card-title.component";
import ContentLayout from "../../components/content-layout/content-layout.component";
import ContentSection from "../../components/content-layout/content-section.component";
import DeletePrompt from "../../components/dialogs/delete-prompt.component";
import MailToSupportLink from "../../components/mail-to-support-link/mail-to-support-link.component";
import IndexCountdown from "../../components/page-parts/indexes/index-overview/index-countdown.component";
import { IndexItem } from "../../components/page-parts/indexes/indexes.types";
import TableFetching from "../../components/table-fetch/table-fetching.component";
import Config from "../../config";
import { IndexWorkflowsInferenceMapping, IndexWorkflowsStorageMapping } from "../../constants/enums";
import { PageStyles, WrapperClasses, ellipsisStyles } from "../../constants/styles";
import { useAuth } from "../../hooks/use-auth";
import { useFilters } from "../../hooks/use-filters";
import { useSort } from "../../hooks/use-sort";
import { setToast } from "../../slices/app";
import { setCurrentPage } from "../../slices/indexes/indexes-main";
import { useSelector } from "../../store";
import { selectCurrentAccount } from "../../store/selectors";
import { deleteIndex, getAllIndices } from "../../thunks/indexes.thunk";
import { generateRandomKey } from "../../utils";
import { plural } from "../../utils/formatters";
import ga4EventsLogger from "../../utils/google-analytics/events/logger";
import { ChipColor, getIndexStatusColorAndOpacity, getIndexStatusLabel, getTooltipMsg } from "../../utils/indices";
import { toHrMinConvention, toMonthDYr } from "../../utils/time-formatter";

const tableHeader = [
  { id: 0, title: "Index Name" },
  { id: 1, title: "Endpoint" },
  { id: 2, title: "Storage Shards" },
  { id: 3, title: "Inference Pods" },
  { id: 4, title: "Date Created (UTC)", order: { key: "Created", type: "date" } },
  { id: 5, title: "Status" },
];

const Overview = () => {
  const navigate = useNavigate();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"), { noSsr: true });
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.only("sm"), { noSsr: true });
  const xsOnly = useMediaQuery((theme: Theme) => theme.breakpoints.only("xs"), { noSsr: true });
  // states
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [menuTempData, setMenuTempData] = useState<IndexItem | null>(null);
  const [openDeletePrompt, setOpenDeletePrompt] = useState(false);
  const [refreshCountdown, setRefreshCountdown] = useState<number>(0);

  //selectors
  const { common } = useSelector((state) => state.indices);
  const currentAccount = useSelector(selectCurrentAccount);
  const { data: userData, limits: accountLimits } = useSelector(({ user }) => user);
  const { email, organization } = userData;

  //utils
  const { setSortItems, sorted, sortDirection, toggleSort } = useSort<IndexItem>({
    defaultRule: {
      field: "Created",
      compareType: "date",
      direction: "desc",
    },
  });
  const { filtered, filterInputValue, setFilterInputValue, setFilterItems } = useFilters<IndexItem>({
    paths: ["indexName", "indexStatus"],
  });
  const dispatch = useDispatch();
  const classes = PageStyles();
  const wrapperClasses = WrapperClasses();

  //getters

  const getItems = useMemo(() => {
    if (!common.list || common.list.length === 0) {
      return [];
    }
    return filtered || sorted || common.list || [];
  }, [common.list, sorted, filtered]);

  const getPaginatedItems = useMemo(
    () =>
      getItems.slice(
        common.tableConfig.currentPage * common.tableConfig.itemsPerPage,
        common.tableConfig.currentPage * common.tableConfig.itemsPerPage + common.tableConfig.itemsPerPage,
      ),
    // eslint-disable-next-line
    [common.list, getItems, common.tableConfig.currentPage, common.tableConfig.itemsPerPage],
  );

  const getPagesCount = () => {
    return Math.ceil(getItems.length / common.tableConfig.itemsPerPage);
  };

  //handlers
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFilterInputValue(value);
    dispatch(setCurrentPage(0));
  };

  const handlePageChange = (_: any, newPage: number) => {
    dispatch(setCurrentPage(newPage - 1));
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setMenuTempData(null);
  };

  const handleMenuSelect = (action: "delete" | "edit") => {
    if (action === "edit") {
      return navigate(`edit/${menuTempData.indexName}`);
    }
    if (action === "delete") {
      dispatch(deleteIndex(menuTempData));
    }
    setMenuAnchor(null);
    setMenuTempData(null);
  };

  const agreeDeleteIndexHandler = () => {
    handleMenuSelect("delete");
    setOpenDeletePrompt(false);
  };

  const handleSimpleCopy = (valueToCopy: string, label: string) => {
    copy(valueToCopy);
    dispatch(setToast({ msg: `${label} has been copied to clipboard.`, type: "success", hash: generateRandomKey(6) }));
  };

  const handleMenuOpen =
    (item: IndexItem): React.MouseEventHandler<HTMLButtonElement> =>
    (e) => {
      e.stopPropagation();
      setMenuAnchor(e.currentTarget);
      setMenuTempData(item);
    };

  //lifecycle
  useEffect(() => {
    if (common.list?.length) {
      setSortItems(common.list);
    }

    let interval: NodeJS.Timer;

    interval = setInterval(() => {
      if (
        common.list?.some((index) =>
          ["Creating", "Modifying", "Deleting"].includes(getIndexStatusLabel(index.indexStatus)),
        )
      ) {
        setRefreshCountdown((prev) => prev + 1);
        dispatch(getAllIndices({ forPollingOnly: true }));
      } else {
        setRefreshCountdown(0);
      }
    }, 30000);
    return () => {
      clearInterval(interval);
      setRefreshCountdown(0);
    };

    // eslint-disable-next-line
  }, [common.list, refreshCountdown]);

  useEffect(() => {
    if (sorted?.length) {
      setFilterItems(sorted);
    }
    // eslint-disable-next-line
  }, [sorted, filterInputValue]);

  useEffect(() => {
    ga4EventsLogger.logConsoleView(email, organization);
    dispatch(getAllIndices({}));
    // eslint-disable-next-line
  }, []);

  const actionBtns = (
    <Fragment>
      <Button
        color={"secondary"}
        variant={"contained"}
        fullWidth={false}
        sx={{ ml: 2, ...(xsOnly || sm ? { padding: "0.875rem" } : {}) }}
        onClick={() => navigate(Config.authenticatedPaths.createIndex)}
      >
        Create Index
      </Button>
    </Fragment>
  );

  return (
    <ContentLayout pageTitle={"Overview"}>
      <DeletePrompt
        resourceName={menuTempData?.indexName}
        accountName={currentAccount?.name ?? ""}
        openDeletePrompt={openDeletePrompt}
        disagreeHandler={() => setOpenDeletePrompt(false)}
        agreeHandler={agreeDeleteIndexHandler}
      />
      <Menu open={Boolean(menuAnchor)} anchorEl={menuAnchor} onClose={handleMenuClose}>
        {menuTempData?.indexStatus !== "FAILED" && (
          <MenuItem onClick={() => navigate(`/indexes/edit/${menuTempData.indexName}`)}>
            <ListItemIcon sx={(theme) => ({ color: theme.palette.text.primary })}>
              <EditRounded fontSize="small" />
            </ListItemIcon>
            <Typography variant="inherit" color={"text.primary"}>
              Edit
            </Typography>
          </MenuItem>
        )}
        <MenuItem onClick={() => setOpenDeletePrompt(true)}>
          <ListItemIcon sx={(theme) => ({ color: theme.palette.error.main })}>
            <DeleteIcon fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit" color={"error.main"}>
            Delete
          </Typography>
        </MenuItem>
      </Menu>

      {/* indexes */}

      <ContentSection id={"IndexList-Section"}>
        <Grid container alignItems={"center"} justifyContent={"space-between"}>
          <Grid item xs={12} sm={3} md={2} alignItems={"center"}>
            <CardTitle title={"Indexes"} noDivider rootProps={{ justifyContent: "center" }} />
          </Grid>
          <Grid
            item
            container
            xs={12}
            md={9}
            flexDirection={"row"}
            rowGap={mdUp ? 2 : 3}
            justifyContent={mdUp ? "right" : "flex-end"}
          >
            {/* search field */}

            <Grid item xs={12} sm={6} md={4} sx={{ minWidth: "3em" }} mt={mdUp ? 0 : 3}>
              <TextField
                fullWidth
                size={"small"}
                value={filterInputValue}
                onChange={handleSearchChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position={"start"}>
                      <SearchSharp />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            {/* action buttons */}

            {!mdUp && (
              <Grid item container xs={12} sm={6} justifyContent={sm ? "flex-start" : "center"} mt={sm ? 3 : 0}>
                {actionBtns}
              </Grid>
            )}
            {mdUp && actionBtns}
          </Grid>
        </Grid>

        {/* Indexes Table */}

        <Grid container>
          <Box sx={{ overflow: "auto" }} className={wrapperClasses.directLeanScrollbar}>
            <Box sx={{ width: "100%", display: "table", tableLayout: "fixed" }}>
              <Table sx={{ mt: 3 }}>
                <TableHead>
                  <TableRow>
                    {tableHeader.map((cell) => (
                      <TableCell
                        key={cell.id}
                        sortDirection={"asc"}
                        sx={{
                          textAlign: ["Index Name", "Status"].includes(cell.title) ? "left" : "center",
                          fontSize: "0.7rem !important",
                          textWrap: "nowrap",
                        }}
                      >
                        {cell.order ? (
                          <TableSortLabel
                            active={Boolean(sortDirection)}
                            direction={sortDirection || "asc"}
                            onClick={() =>
                              cell.order &&
                              toggleSort({
                                key: cell.order?.key,
                                compareType: cell.order?.type,
                              })
                            }
                          >
                            {cell.title}
                          </TableSortLabel>
                        ) : (
                          cell.title
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>

                {common.fetching.inProgress && <TableFetching columns={tableHeader} linesCount={3} />}

                {!common.fetching.inProgress && (
                  <TableBody>
                    {!common.fetching.inProgress && !getItems.length && (
                      <TableRow>
                        <TableCell colSpan={tableHeader.length}>
                          <Typography color={"textPrimary"} textAlign={"center"}>
                            No indexes found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                    {Boolean(getItems.length) &&
                      getPaginatedItems.map((row) => (
                        <TableRow sx={{ cursor: "pointer", width: "100%" }} key={row.indexName} hover>
                          {/* index name */}
                          <TableCell
                            className={classes.tableCell}
                            onClick={() => navigate(`/indexes/${row.indexName}`)}
                          >
                            <Tooltip title={row.indexName}>
                              <Grid
                                container
                                flexDirection={"row"}
                                justifyContent={"space-between"}
                                height={"100%"}
                                spacing={1}
                              >
                                <Grid container item xs={10} className="noWrap" height={"100%"}>
                                  <Typography sx={{ ...ellipsisStyles }}>{row.indexName}</Typography>
                                </Grid>
                                <Grid container item xs={2} flexDirection={"column"} justifyContent={"flex-end"}>
                                  <IconButton
                                    size="small"
                                    style={{ padding: 0 }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleSimpleCopy(row.indexName, "Index name");
                                    }}
                                  >
                                    <ContentCopy fontSize={"small"} />
                                  </IconButton>
                                </Grid>
                              </Grid>
                            </Tooltip>
                          </TableCell>

                          {/* endpoint */}
                          <TableCell
                            className={classes.tableCell}
                            onClick={() => navigate(`/indexes/${row.indexName}`)}
                          >
                            <Tooltip title={row.marqoEndpoint}>
                              <Grid className="noWrap" container flexDirection={"row"} justifyContent={"space-between"}>
                                <Grid item xs={10}>
                                  <Typography textAlign={"left"} sx={{ ...ellipsisStyles }}>
                                    {row.marqoEndpoint}
                                  </Typography>
                                </Grid>
                                {row.marqoEndpoint !== "Creating" && (
                                  <IconButton
                                    size="small"
                                    style={{ padding: 0 }}
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleSimpleCopy(row.marqoEndpoint, "Endpoint URL");
                                    }}
                                  >
                                    <ContentCopy fontSize={"small"} />
                                  </IconButton>
                                )}
                              </Grid>
                            </Tooltip>
                          </TableCell>

                          {/* storage shards */}
                          <TableCell
                            className={classNames(classes.tableCell, "TableCell-SmallFontSize")}
                            onClick={() => navigate(`/indexes/${row.indexName}`)}
                          >
                            <Typography textAlign={"center"}>
                              {row.numberOfShards}{" "}
                              {
                                IndexWorkflowsStorageMapping[
                                  row.storageClass as keyof typeof IndexWorkflowsStorageMapping
                                ]
                              }
                            </Typography>
                            <Typography color={"textSecondary"} textAlign={"center"}>
                              {row.numberOfReplicas} replica
                              {plural(row.numberOfReplicas)}
                            </Typography>
                          </TableCell>

                          {/* inference pods */}
                          <TableCell
                            className={classNames(classes.tableCell)}
                            onClick={() => navigate(`/indexes/${row.indexName}`)}
                          >
                            <Typography textAlign={"center"}>
                              {row.numberOfInferences}{" "}
                              {
                                IndexWorkflowsInferenceMapping[
                                  row.inferenceType as keyof typeof IndexWorkflowsInferenceMapping
                                ]
                              }
                            </Typography>
                          </TableCell>

                          {/* date */}
                          <TableCell
                            className={classNames(classes.tableCell, "TableCell-Wide")}
                            onClick={() => navigate(`/indexes/${row.indexName}`)}
                          >
                            <Typography textAlign={"center"}>{toMonthDYr(row.Created)}</Typography>
                            <Typography color={"textSecondary"} textAlign={"center"}>
                              {toHrMinConvention(row.Created)}
                            </Typography>
                          </TableCell>

                          {/* status */}
                          <TableCell className={classNames(classes.tableCell, "TableCell-SmallFontSize")}>
                            <Grid
                              container
                              flexDirection={"row"}
                              justifyContent={"space-between"}
                              gap={1}
                              className={"noWrap"}
                            >
                              {["Ready", "Failed", "Deleted"].includes(getIndexStatusLabel(row.indexStatus)) && (
                                <Grid item lg={8}>
                                  <Chip
                                    label={getIndexStatusLabel(row.indexStatus)}
                                    color={getIndexStatusColorAndOpacity(row.indexStatus)[0] as ChipColor}
                                    sx={{ opacity: getIndexStatusColorAndOpacity(row.indexStatus)[1] }}
                                  />
                                </Grid>
                              )}

                              {["Creating", "Modifying", "Deleting"].includes(getIndexStatusLabel(row.indexStatus)) && (
                                <Grid item container>
                                  <Grid item container flexDirection={"row"} alignItems={"center"} gap={1} mb={1}>
                                    {getTooltipMsg(row.indexStatus, row.errorMsg) !== null && (
                                      <Tooltip title={getTooltipMsg(row.indexStatus, row.errorMsg)}>
                                        <InfoRounded sx={{ color: "neutral.400" }} />
                                      </Tooltip>
                                    )}
                                    <CircularProgress size={"1.3em"} color={"inherit"} />
                                  </Grid>
                                  <Grid item container>
                                    <Typography>{getIndexStatusLabel(row.indexStatus)} index</Typography>
                                  </Grid>
                                  {["Creating"].includes(getIndexStatusLabel(row.indexStatus)) && (
                                    <Grid item container>
                                      <Typography sx={{ fontSize: "0.9em", color: "neutral.400" }}>
                                        <IndexCountdown
                                          showCountdown={true}
                                          timeOfOperation={row.Created}
                                          version={row.marqoVersion}
                                        />
                                      </Typography>
                                    </Grid>
                                  )}
                                </Grid>
                              )}

                              {!["Ready", "Creating", "Modifying", "Deleting"].includes(
                                getIndexStatusLabel(row.indexStatus),
                              ) && (
                                <Grid
                                  item
                                  lg={2}
                                  sx={{ justifyContent: "center", flexDirection: "column", display: "flex" }}
                                >
                                  {getTooltipMsg(row.indexStatus, row.errorMsg) !== null && (
                                    <Tooltip title={getTooltipMsg(row.indexStatus, row.errorMsg)}>
                                      <InfoRounded sx={{ color: "neutral.400" }} />
                                    </Tooltip>
                                  )}
                                </Grid>
                              )}

                              {["Ready", "Failed"].includes(getIndexStatusLabel(row.indexStatus)) && (
                                <Grid
                                  item
                                  lg={2}
                                  sx={{ justifyContent: "center", flexDirection: "column", display: "flex" }}
                                >
                                  <IconButton onClick={handleMenuOpen(row)} sx={{ padding: 0 }}>
                                    <MoreVertRounded />
                                  </IconButton>
                                </Grid>
                              )}
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                )}
                {getItems.length > common.tableConfig.itemsPerPage && (
                  <TableFooter>
                    <TableRow>
                      <TableCell colSpan={tableHeader.length}>
                        <Pagination
                          count={getPagesCount()}
                          page={common.tableConfig.currentPage + 1}
                          onChange={handlePageChange}
                        />
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                )}
              </Table>

              {!userData.isGuest && common.list?.length === accountLimits.index && !common.fetching.inProgress && (
                <Grid container item justifyContent={"center"} mt={2} mb={1}>
                  <MailToSupportLink title={"Need more indexes? Contact us."} />
                </Grid>
              )}
            </Box>
          </Box>
        </Grid>
      </ContentSection>
    </ContentLayout>
  );
};

export default Overview;
