import { LogsState } from ".";
import { MarqtuneDataset, MarqtuneEvaluation, MarqtuneModel } from "../../api/marqtune/types";

const DEFAULT_RES_LOGS_STATE: LogsState = { logs: [], isFetching: false };

export const fillMissingModelFields = (stateModelList: MarqtuneModel[]): MarqtuneModel[] =>
  stateModelList.map((model) => ({
    ...model,
    resourceLogs: DEFAULT_RES_LOGS_STATE,
  }));

export const fillMissingDatasetFields = (stateDatasetList: MarqtuneDataset[]): MarqtuneDataset[] =>
  stateDatasetList.map((dataset) => ({
    ...dataset,
    resourceLogs: DEFAULT_RES_LOGS_STATE,
  }));

export const fillMissingEvaluationFields = (stateEvaluationList: MarqtuneEvaluation[]): MarqtuneEvaluation[] =>
  stateEvaluationList.map((evaluation) => ({
    ...evaluation,
    resourceLogs: DEFAULT_RES_LOGS_STATE,
  }));
