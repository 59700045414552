import { Typography } from "@mui/material";
import ContentLayout from "../../components/content-layout/content-layout.component";
import ContentSection from "../../components/content-layout/content-section.component";
import { SelectOrCreateAccountMessage } from "../../components/page-parts/billing/guest-banner.component";

export default function SelectAccount() {
  return (
    <ContentLayout headTitle={"Select Account"} pageTitle={"Select Account"} hideGuestBanner={true}>
      <ContentSection id={"SelectAccount-Section"}>
        <Typography mb={4} variant={"h5"}>
          ⬅️ Select Account
        </Typography>
        <SelectOrCreateAccountMessage />
      </ContentSection>
    </ContentLayout>
  );
}
