import { Grid, Tooltip, Typography } from "@mui/material";
import { ClassNameMap } from "@mui/styles";
import classNames from "classnames";
import React from "react";
import { SelectStyles } from "../../form-fields/form-fields.styles";
import { Option } from "../../form-fields/select-field";
import { Size } from "../../form/form.types";
import { useStyles } from "../../table-fetch/table-fetching.styles";

type Props = Omit<Option, "id"> & {
  isDisabled: boolean;
  selectedValue: Option["value"];
  onClickFn: (value: Option["value"]) => void;
  name: string;
  disableToastMsg?: string;
  sizes: Size;
  styles?: (props?: any) => ClassNameMap;
};

const SelectOptionCard: React.FC<Props> = ({
  isDisabled,
  onClickFn,
  value,
  name,
  selectedValue,
  title,
  sizes,
  description,
  imageUrl,
  imageAltText,
  subDescription,
  disableToastMsg = "",
  styles,
}) => {
  const classes = SelectStyles();
  const inputStyles = styles?.() ?? {};
  const isSameValue = selectedValue === value;

  return (
    <Tooltip title={!isSameValue && disableToastMsg ? disableToastMsg : ""}>
      <Grid
        container
        item
        xs={12}
        md={4}
        lg={4}
        xl={3}
        {...sizes}
        flexDirection={"column"}
        width={"100%"}
        className={classNames(classes.selectOptionCard, inputStyles.selectOptionCard, {
          "selected-card": isSameValue,
          "disabled-card": !isSameValue && isDisabled,
        })}
        onClick={() => onClickFn(value)}
        data-testid={`${name}-${value}`}
      >
        <Grid
          container
          item
          height={"100%"}
          px={2}
          py={2}
          gap={1}
          className={classNames(isSameValue ? [classes.selectedCard, inputStyles.selectedCard] : [])}
          sx={{
            backgroundColor: "neutral.800",
            borderRadius: "7px",
          }}
        >
          {imageUrl && (
            <Grid container className="image">
              <img className={classes.image} src={imageUrl} alt={imageAltText} />
            </Grid>
          )}
          <Grid container className="title">
            <Typography variant={"subtitle1"} sx={{ fontWeight: "bold" }}>
              {title}
            </Typography>
          </Grid>
          <Grid container className={classNames({ "is-empty": description == null }, "description")}>
            <Typography variant={"subtitle2"} sx={{ fontWeight: "300" }}>
              {description}
            </Typography>
          </Grid>
          <Grid
            container
            sx={{ marginTop: "-8px" }}
            className={classNames({ "is-empty": subDescription == null }, "sub-description")}
          >
            <Typography sx={{ fontWeight: "300", fontSize: "0.75em" }}>{subDescription}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Tooltip>
  );
};

export default SelectOptionCard;
