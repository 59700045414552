import { useCallback, useEffect, useState } from "react";
import { batch } from "react-redux";
import { useDispatch } from "../store";
import { getAPIKeysThunk } from "../thunks/api-keys.thunk";
import { hasStripeCardThunk } from "../thunks/billing.thunk";
import { getAllIndices } from "../thunks/indexes.thunk";
import { getProductPricesThunk } from "../thunks/pricing.thunk";

//Hook to get all the needed data of application
export const useDashboardInit = (): { isInitiated: boolean } => {
  const [isAppInitiated, setAppInitiated] = useState(false);
  const dispatch = useDispatch();

  const initApp = useCallback(() => {
    batch(async () => {
      // INDICES
      dispatch(getAllIndices({}));
      // API KEYS
      dispatch(getAPIKeysThunk());
      // BILLING
      dispatch(hasStripeCardThunk());
      dispatch(getProductPricesThunk());
      setAppInitiated(true);
    });
  }, [dispatch]);

  useEffect(initApp, [initApp]);

  return { isInitiated: isAppInitiated };
};
