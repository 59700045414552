import { Button, Grid, Typography } from "@mui/material";
import { Fragment } from "react";
import { useDispatch, useSelector } from "../../../../store";
import { requestMarqtuneAccessThunk } from "../../../../thunks/marqtune.thunk";
import { MarqtuneDocumentationQuickLink } from "../../quick-links/quick-links";

const RequestMarqtuneAccess = () => {
  const dispatch = useDispatch();
  const { isPending, successful } = useSelector(({ marqtune }) => marqtune.requestAccess);

  const handleRequestAccess = () => {
    dispatch(requestMarqtuneAccessThunk());
  };

  return (
    <Grid
      container
      gap={2}
      flexDirection={"column"}
      alignSelf={"center"}
      alignItems={"center"}
      justifyContent={"center"}
      height={"calc(90vh - 10em)"}
    >
      {!successful ? (
        <Fragment>
          <Typography variant={"h4"}>Introducing Marqtune</Typography>

          <Typography>Finetune embedding models to enhance relevance and accuracy of retrieval.</Typography>

          <Button
            color={"secondary"}
            variant={"contained"}
            fullWidth={false}
            disabled={isPending}
            onClick={handleRequestAccess}
          >
            Request Access
          </Button>
        </Fragment>
      ) : (
        <Fragment>
          <Typography variant="h4">Marqtune Access Requested</Typography>

          <Typography>
            Thanks for your request. A member of the Marqo team will be in touch via your registered email address soon.
          </Typography>
        </Fragment>
      )}
    </Grid>
  );
};

export default RequestMarqtuneAccess;
