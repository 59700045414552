import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Theme,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useState } from "react";
import { buttonClasses } from "../button/button.styles";
import { StyledDialog } from "./prompt-dialog.styles";

type Props = {
  title: string;
  content: React.ReactNode;
  resourceName: string;
  agreeHandler: () => void;
  open: boolean;
  agreeButtonLabel: string;
  disagreeHandler?: () => void;
};

const ConfirmPrompt = (props: Props) => {
  const { title, content, resourceName, agreeHandler, open, agreeButtonLabel, disagreeHandler } = props;
  const btnClasses = buttonClasses();
  const xsOnly = useMediaQuery((theme: Theme) => theme.breakpoints.only("xs"), { noSsr: true });
  const [confirmInput, setConfirmInput] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmInput(e.target.value);
  };

  const handleOnCancel = () => {
    setConfirmInput("");
    disagreeHandler();
  };

  const handleOnAgree = () => {
    setConfirmInput("");
    agreeHandler();
  };

  return (
    <StyledDialog
      open={open}
      id={`${resourceName}-confirm-prompt-dialog`}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Grid px={xsOnly ? 0 : 2} py={2}>
        <Grid>
          <DialogTitle>{title}</DialogTitle>
        </Grid>
        <Grid>
          <DialogContent>
            <Grid container spacing={2} gap={2} p={2} pr={0} width={"auto"}>
              <Typography>{content}</Typography>
              <TextField value={confirmInput} onChange={handleChange} size="small" fullWidth />
            </Grid>
          </DialogContent>
        </Grid>
        <DialogActions>
          <Grid container item px={2} justifyContent={"flex-end"} gap={2}>
            <Button
              variant={"outlined"}
              color={"inherit"}
              fullWidth={xsOnly}
              onClick={handleOnCancel}
              id={`prompt-dialog-back`}
              autoFocus
            >
              Back
            </Button>
            <Button
              variant={"contained"}
              disabled={confirmInput !== resourceName}
              className={`${btnClasses.dangerButton} ${btnClasses.deleteButton}`}
              onClick={handleOnAgree}
              fullWidth={xsOnly}
              id={`prompt-dialog-yes`}
            >
              {agreeButtonLabel}
            </Button>
          </Grid>
        </DialogActions>
      </Grid>
    </StyledDialog>
  );
};

export default ConfirmPrompt;
