import { Box, Grid, InputLabel, Tab, Tabs } from "@mui/material";
import { Fragment, useState } from "react";
import { MarqtuneDataset, MarqtuneModel } from "../../../../api/marqtune/types";
import { FormField } from "../../../form/form.component";
import { formClasses } from "../../../form/form.styles";
import TabPanel, { tabPanelControlProps } from "../../../tab-panel/tab-panel.component";
import ModelDetailsCodeWrapper from "./model-details-code-wrapper.component";

type Props = {
  checkpoints: MarqtuneModel["checkpoints"];
  datasets: MarqtuneDataset[];
  modelId: string;
};

const getCheckpointOptions = (checkpoints: MarqtuneModel["checkpoints"]) => {
  return checkpoints?.map((checkpoint) => ({
    id: checkpoint,
    title: checkpoint,
    value: checkpoint,
  }));
};

const getDatasetOptions = (datasets: MarqtuneDataset[]) => {
  return datasets.map(({ datasetId, datasetName }) => ({
    id: datasetId,
    title: datasetName,
    value: datasetId,
  }));
};

const CheckpointEvaluation = ({ checkpoints, datasets, modelId }: Props) => {
  const formComponentClasses = formClasses();
  const checkpointOptions = getCheckpointOptions(checkpoints);
  const datasetOptions = getDatasetOptions(datasets);
  const [checkpointSelect, setCheckpointSelect] = useState(checkpointOptions.length ? checkpointOptions[0].value : "");
  const [datasetSelect, setDatasetSelect] = useState(datasetOptions.length ? datasetOptions[0].value : "");

  const [value, setValue] = useState(0);
  const idPrefix = "marqtune-model-details";

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Fragment>
      {/* SELECTORS */}

      <Box mb={2}>
        <form className={formComponentClasses.unwrappedForm}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4} lg={3} flexDirection={"column"}>
              <Grid item mb={1}>
                <InputLabel htmlFor={"checkpoint-selector"}>Checkpoint</InputLabel>
              </Grid>
              <Grid item>
                <FormField
                  fullWidth
                  id={"checkpoint-selector"}
                  name={"checkpoint-selector"}
                  placeholder="Select Checkpoint"
                  value={checkpointSelect}
                  onChange={(e) => {
                    setCheckpointSelect(e.target.value);
                  }}
                  type={"select"}
                  options={checkpointOptions}
                  disabled={!checkpointOptions.length}
                />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3} flexDirection={"column"} gap={2}>
              <Grid item mb={1}>
                <InputLabel htmlFor={"dataset-selector"}>Evaluation Dataset</InputLabel>
              </Grid>
              <Grid item>
                <FormField
                  fullWidth
                  id={"dataset-selector"}
                  name={"dataset-selector"}
                  placeholder="Select Dataset"
                  value={datasetSelect}
                  onChange={(e) => {
                    setDatasetSelect(e.target.value);
                  }}
                  type={"select"}
                  options={datasetOptions}
                  disabled={!datasetOptions.length}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>

      {/* TAB PANEL */}

      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="model-details-tabs">
          <Tab sx={{ fontSize: "0.8rem" }} label="CHECKPOINT EVALUATION" {...tabPanelControlProps(0, idPrefix)} />
        </Tabs>
      </Box>

      <TabPanel value={value} index={0} idPrefix={idPrefix}>
        <ModelDetailsCodeWrapper
          evaluationDatasetId={datasetSelect}
          checkpoint={checkpointSelect}
          modelId={modelId}
          showReleaseCheckpointSnippet={true}
        />
      </TabPanel>
    </Fragment>
  );
};

export default CheckpointEvaluation;
