import { Theme, alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";

export const MarqtuneStyleClasses = makeStyles((theme: Theme) => ({
  schemaTableWrapper: {
    overflow: "hidden",
  },
  fixedWidthTableLayout: {
    "& table": {
      overflow: "auto",
      width: "100%",
      tableLayout: "fixed",

      "& thead th": {
        textWrap: "nowrap",
        maxWidth: "200px",
      },

      "& tbody .MuiTableRow-root": {
        width: "100%",
        verticalAlign: "middle",
      },

      [theme.breakpoints.down("lg")]: {
        tableLayout: "auto",
      },
    },
  },
  datasetSchemaTable: {
    "&.MuiTableContainer-root": {
      borderRadius: theme.spacing(1),
      overflow: "auto",

      "& table": {
        borderCollapse: "separate",
        borderRadius: theme.spacing(1),
        overflow: "auto",
      },
    },
    "& tr:first-child td:first-child": {
      borderTopLeftRadius: theme.spacing(1),
    },
    "& tr:first-child td:last-child": {
      borderTopRightRadius: theme.spacing(1),
    },
    "& tr:last-child td:first-child": {
      borderBottomLeftRadius: theme.spacing(1),
    },
    "& tr:last-child td:last-child": {
      borderBottomRightRadius: theme.spacing(1),
    },
    "& td": {
      minWidth: "150px",
      maxWidth: "150px",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  gridPartition: {
    rowGap: "1rem",

    "&:last-of-type": {
      [theme.breakpoints.up("md")]: {
        paddingLeft: "1.5rem",
        paddingRight: "1.5rem",
      },

      [theme.breakpoints.down("md")]: {
        paddingTop: "1rem",
      },

      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
        justifyContent: "space-between",
        paddingLeft: "0",
        paddingRight: "0",
      },
    },

    "&:first-of-type": {
      [theme.breakpoints.up("md")]: {
        borderRight: `1px solid ${alpha(theme.palette.text.disabled, 0.1)}`,
      },
      [theme.breakpoints.down("md")]: {
        paddingTop: 0,
        paddingBottom: "1rem",
        borderBottom: `1px solid ${alpha(theme.palette.text.disabled, 0.1)}`,
      },
    },
  },
  centeredMidTheadCell: {
    "& thead th:not(:first-child):not(:last-child)": {
      textAlign: "center",
    },
  },
  centeredTableRowCells: {
    "& td:not(:first-child):not(:last-child)": {
      textAlign: "center",
    },
  },
  rootSectionTableRow: {
    "&:not(.ready):not(.ModelsSection-TableRow) td:last-child": {
      "& .MuiGrid-root.RowEndActionsWrapper": {
        width: "100%",
      },
    },

    "&.DatasetsSection-TableRow td": {
      "&:not(:last-child)": {
        width: `calc((100% - 200px) / 3)`,
      },
    },

    "&.TrainingsSection-TableRow td": {
      "&:not(:last-child):not(.narrow)": {
        width: `calc((100% - 200px) / 5)`,
      },
      "&.narrow": {
        width: "100px",
        maxWidth: "100px",
      },
    },

    "&:not(.ready).TrainingsSection-TableRow td:last-child button": {
      marginRight: "-8px",
    },

    "&.ModelsSection-TableRow td": {
      "&:not(:last-child)": {
        width: `calc((100% - 200px) / 6)`,
      },

      "& .MuiGrid-root.RowEndActionsWrapper": {
        width: "100%",
      },
    },

    "& td": {
      "&.MuiTableCell-root": {
        verticalAlign: "middle",
      },

      "& .MuiTypography-root": {
        fontSize: "0.875rem",
      },

      "& .MuiGrid-root.noWrap": {
        flexWrap: "nowrap",
      },
    },

    [theme.breakpoints.down("lg")]: {
      "& td:last-child": {
        maxWidth: "200px",
        minWidth: "200px",
      },

      "& td:not(:last-child)": {
        maxWidth: "200px",
        minWidth: "200px",
        width: "100%",
      },
    },
  },
  releaseLink: {
    color: theme.palette.info.main,
    fontSize: "14px",
    cursor: "pointer",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));
