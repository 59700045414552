import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import API from "../api";
import { AcceptInviteFormData, AcceptInviteReq } from "../api/settings/settings.types";
import {
  acceptInviteToast,
  addMemberToast,
  removeMemberToast,
  resendInviteToast,
} from "../components/toast/members.toast";
import { AddMemberForm } from "../form-configs/members.forms";
import {
  setAcceptInviteLoading,
  setFetchingTeamMembers,
  setInvitationLoading,
  setTeamMemberList,
} from "../slices/settings";
import { setShowTip } from "../slices/user";
import { RootState } from "../store";
import { selectCurrentAccountId } from "../store/selectors";
import { FormCbDTO } from "./types";

export type AddUserReqErr = {
  error: {
    name: string;
    reason: string;
    status: string;
    type: string;
    root_cause: {
      name: string;
      type: string;
    }[];
  };
};

export const addMemberThunk = createAsyncThunk(
  "settings/team-members/add",
  async (req: FormCbDTO<AddMemberForm>, { dispatch, getState }) => {
    const { formData, onSuccess } = req;
    const accountId = selectCurrentAccountId(getState() as RootState);

    try {
      const promise = API.settings.teamMember.add(accountId, formData);
      addMemberToast(promise);
      const { data } = await promise;

      if (onSuccess) {
        onSuccess();
      }
      return { ...data };
    } catch (err) {
      const error = err as AxiosError<AddUserReqErr>;
      dispatch(setShowTip(true));
      return Promise.reject(error);
    }
  },
);

export const getMembersThunk = createAsyncThunk("settings/team-members/get", async (_, { dispatch, getState }) => {
  const accountId = selectCurrentAccountId(getState() as RootState);
  if (!accountId) {
    return Promise.reject("No account selected");
  }
  dispatch(setFetchingTeamMembers(true));
  try {
    const { data } = await API.settings.teamMember.get(accountId);
    dispatch(setTeamMemberList(data));
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
});

export const resendInviteThunk = createAsyncThunk(
  "settings/team-members/resend-invite",
  async (email: string, { getState }) => {
    const accountId = selectCurrentAccountId(getState() as RootState);
    try {
      const promise = API.settings.teamMember.resendInvite(accountId, email);
      resendInviteToast(promise);
      await promise;
    } catch (err) {
      return Promise.reject(err);
    }
  },
);

export const acceptInviteThunk = createAsyncThunk(
  "settings/team-members/accept-invite",
  async (formData: AcceptInviteFormData, { dispatch, getState }) => {
    const accountId = selectCurrentAccountId(getState() as RootState);
    try {
      dispatch(setAcceptInviteLoading(true));
      const { email } = formData;
      const payload: AcceptInviteReq = {
        email,
        temp_pw: formData?.tempPassword,
        new_pw: formData?.password,
        credentials: formData?.credentials,
      };
      const promise = API.settings.teamMember.acceptInvite(accountId, payload);
      acceptInviteToast(promise);
      const { data } = await promise;
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
);

export const getInvitationMethodThunk = createAsyncThunk(
  "settings/team-members/get-invitation-method",
  async (email: string, { dispatch, getState }) => {
    const accountId = selectCurrentAccountId(getState() as RootState);
    try {
      dispatch(setInvitationLoading(true));
      const { data } = await API.settings.teamMember.getInvitationMethod(accountId, email);
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
);

export const removeTeamMemberThunk = createAsyncThunk(
  "settings/team-members/remove",
  async (email: string, { dispatch, getState }) => {
    const accountId = selectCurrentAccountId(getState() as RootState);
    try {
      const promise = API.settings.teamMember.remove(accountId, email);
      await removeMemberToast(promise);
      dispatch(getMembersThunk());
    } catch (err) {
      return Promise.reject(err);
    }
  },
);
