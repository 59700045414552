import { Box, Grid, List, ListItem, Tab, Tabs, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import CardTitle from "../../components/card-title/card-title.component";
import ContentLayout from "../../components/content-layout/content-layout.component";
import ContentSection from "../../components/content-layout/content-section.component";
import CreateDatasetQuickStartCodeWrapper from "../../components/page-parts/marqtune/create-dataset-quick-start.component";
import {
  MarqtuneDataGuideQuickLink,
  MarqtuneEvaluationDatasetQuickLink,
  MarqtuneTrainingDatasetQuickLink,
} from "../../components/page-parts/quick-links/quick-links";
import TabPanel, { tabPanelControlProps } from "../../components/tab-panel/tab-panel.component";
import { useBreadcrumbs } from "../../hooks/use-breadcrumbs";
import { useSelector } from "../../store";
import { marqtuneStyles } from "./marqtune.styles";

const MarqtuneCreateDatasetPage = () => {
  const classes = marqtuneStyles();
  const { setBreadcrumbs, clearBreadcrumbs } = useBreadcrumbs();
  const { isGuest } = useSelector(({ user }) => user.data);
  const [value, setValue] = useState(0);
  const idPrefix = "marqtune-create-dataset";

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    setBreadcrumbs([{ title: "Marqtune", link: "/marqtune/" }]);
    return () => clearBreadcrumbs();
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Create dataset | Marqo</title>
      </Helmet>
      <ContentLayout pageTitle={"Create dataset"}>
        <ContentSection id={"CreateDatasetPage"}>
          <CardTitle title="Create dataset" />

          {/* GUIDE */}

          <Grid container className={classes.guide} gap={2}>
            <Grid container gap={2}>
              <Typography variant={"subtitle2"}>
                First, prepare your data file using the Marqtune data guide for CSV schema and requirements. The snippet
                below includes a step to download a pre-prepared training dataset. Alternatively, you can manually
                download both {MarqtuneTrainingDatasetQuickLink} and a smaller {MarqtuneEvaluationDatasetQuickLink}.
              </Typography>
            </Grid>

            <Grid container gap={1}>
              <Typography variant={"subtitle2"}>
                Dataset validation and creation may take from minutes to hours, depending on your data's size and type.
              </Typography>
            </Grid>
          </Grid>
        </ContentSection>

        {/* TAB PANEL */}

        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="create-dataset-tabs">
            <Tab
              sx={{ fontSize: "0.8rem" }}
              label="CREATE DATASET QUICK START"
              {...tabPanelControlProps(0, idPrefix)}
            />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0} idPrefix={idPrefix}>
          <CreateDatasetQuickStartCodeWrapper inputDataId={"sdfkj"} />
        </TabPanel>
      </ContentLayout>
    </Fragment>
  );
};

export default MarqtuneCreateDatasetPage;
