import { MoreVertRounded } from "@mui/icons-material";
import { Grid, IconButton, TableCell, TableRow, Typography } from "@mui/material";
import classNames from "classnames";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import { MarqtuneDataset } from "../../../../api/marqtune/types";
import config from "../../../../config";
import { TextTransformClasses, ellipsisStyles } from "../../../../constants/styles";
import { setMarqtuneDatasetsPage } from "../../../../slices/marqtune";
import { useSelector } from "../../../../store";
import { toHrMinConvention, toMonthDYr } from "../../../../utils/time-formatter";
import CopyButton from "../../../copy-button/copy-button.component";
import { StatusChip } from "../chip.component";
import { MarqtuneDatasetActionPrompt } from "../prompts.component";
import { MarqtuneStyleClasses } from "../styles";
import { isReady } from "../utils";
import MarqtuneRootSection from "./marqtune-root-section.component";

const MarqtuneDatasetsSection = () => {
  const classes = MarqtuneStyleClasses();
  const textClasses = TextTransformClasses();
  const navigate = useNavigate();
  const { isFetching, itemsPerPage, currentPage, list: data } = useSelector(({ marqtune }) => marqtune.datasets);
  const [selectedDataset, setSelectedDataset] = useState<MarqtuneDataset>(null);
  const [actionsMenuAnchor, setActionsMenuAnchor] = useState(null);

  const handleOnClickCell = (datasetId: any) => {
    navigate(`/marqtune/datasets/${datasetId}/`);
  };

  const handleActionsMenuClose = () => {
    setActionsMenuAnchor(null);
    setSelectedDataset(null);
  };

  const handleOnClickActionMenu = (e: React.MouseEvent, item: MarqtuneDataset) => {
    e.stopPropagation();
    setSelectedDataset(item);
    setActionsMenuAnchor(e.currentTarget);
  };

  const renderTableRow = (rowItem: MarqtuneDataset, index: number) => {
    const { status, datasetId, datasetName, datasetType, startedAt } = rowItem;

    return (
      <TableRow
        key={index}
        className={classNames(
          classes.centeredTableRowCells,
          classes.rootSectionTableRow,
          "DatasetsSection-TableRow",
          ...(isReady(selectedDataset) ? ["ready"] : []),
        )}
      >
        {/* NAME */}
        <TableCell onClick={() => handleOnClickCell(datasetId)}>
          <Grid container>
            <Typography variant={"subtitle2"} sx={ellipsisStyles}>
              {datasetName ?? "-"}
            </Typography>
          </Grid>
        </TableCell>

        {/* ID */}
        <TableCell onClick={() => handleOnClickCell(datasetId)}>
          <Grid container justifyContent={"center"} gap={1} flexWrap={"nowrap"} overflow={"hidden"}>
            <Typography variant={"subtitle2"} sx={ellipsisStyles}>
              {datasetId}
            </Typography>
            <CopyButton text={datasetId} label={"Dataset ID"} />
          </Grid>
        </TableCell>

        {/* DATASET TYPE */}
        <TableCell onClick={() => handleOnClickCell(datasetId)}>
          <Grid container justifyContent={"center"} gap={1} flexWrap={"nowrap"} overflow={"hidden"}>
            <Typography variant={"subtitle2"} sx={ellipsisStyles} className={textClasses.capitalize}>
              {datasetType}
            </Typography>
          </Grid>
        </TableCell>

        {/* STARTED AT */}
        <TableCell onClick={() => handleOnClickCell(datasetId)}>
          <Grid container justifyContent={"center"} gap={1} flexDirection="column" overflow={"hidden"}>
            <Typography textAlign={"center"}>{toMonthDYr(startedAt)}</Typography>
            <Typography color={"textSecondary"} textAlign={"center"}>
              {toHrMinConvention(startedAt)}
            </Typography>
          </Grid>
        </TableCell>

        {/* STATUS and ACTION */}
        <TableCell onClick={() => handleOnClickCell(datasetId)}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            className="RowEndActionsWrapper"
            flexWrap="nowrap"
          >
            <Grid item>
              <StatusChip isReady={isReady(rowItem)} status={status} />
            </Grid>
            <Grid item>
              <IconButton
                sx={{ padding: "2px" }}
                onClick={(e: React.MouseEvent) => handleOnClickActionMenu(e, rowItem)}
              >
                <MoreVertRounded />
              </IconButton>
            </Grid>
          </Grid>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <MarqtuneRootSection
      tableHeaders={[
        { title: "DATASET NAME", id: "dataset-name" },
        { title: "DATASET ID", id: "dataset-id" },
        { title: "DATASET TYPE", id: "dataset-type" },
        { title: "DATE STARTED (UTC)", id: "dataset-started-at", order: { key: "startedAt", type: "date" } },
        { title: "STATUS", id: "dataset-status" },
      ]}
      setPageChangeAction={setMarqtuneDatasetsPage}
      data={data}
      isFetching={isFetching}
      itemsPerPage={itemsPerPage}
      currentPage={currentPage}
      confirmPopupConfigLabel={"dataset"}
      cardTitle={"Datasets"}
      contentSectionIdPrefix={"MarqtuneDatasets"}
      emptyListMsg={"No dataset found."}
      tableCellKeyPrefix={"datasets-section-cell"}
      topRightActionBtnConfig={{
        label: "Create Dataset",
        href: config.authenticatedPaths.marqtune.datasets.create,
      }}
      filterPaths={["datasetName", "datasetId", "status", "secondaryStatus"]}
      setCurrentPage={setMarqtuneDatasetsPage}
      renderTableRow={renderTableRow}
    >
      <MarqtuneDatasetActionPrompt
        actionsMenuAnchor={actionsMenuAnchor}
        resourceId={selectedDataset?.datasetId || ""}
        resourceName={selectedDataset?.datasetName || ""}
        agreeHandler={handleActionsMenuClose}
        disagreeHandler={handleActionsMenuClose}
      />
    </MarqtuneRootSection>
  );
};

export default MarqtuneDatasetsSection;
