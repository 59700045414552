import AddIcon from "@mui/icons-material/Add";
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Theme,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useSelector } from "../../store";
import { selectActiveAccounts, selectCurrentAccountId, selectInvitationAccounts } from "../../store/selectors";
import { selectAccount } from "../../thunks/account.thunk";

const CREATE_KEY = "__create__";
const INVITATIONS_KEY = "__invitations__";

const useStyles = makeStyles((theme: Theme) => ({
  link: {
    color: theme.palette.text.primary,
    padding: "8px",
    marginLeft: "-12px",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "rgba(255,255,255, 0.08)",
    },
  },
}));

export default function AccountSelector() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const accountId = useSelector(selectCurrentAccountId);
  const activeAccounts = useSelector(selectActiveAccounts);
  const selectingAccount = useSelector((state) => state.accounts.selectingAccount);

  const [value, setValue] = useState<string>(accountId);
  useEffect(() => {
    setValue(accountId);
  }, [accountId]);

  const handleChange = (e: SelectChangeEvent) => {
    const accountId = e.target.value;

    if (accountId === CREATE_KEY) {
      navigate(`/accounts/create`);
      return;
    } else if (accountId === INVITATIONS_KEY) {
      navigate(`/accounts/invitations`);
      return;
    }
    dispatch(selectAccount({ accountId }));
    setValue(accountId);
    // TODO: Ensure we can't get stuck with the wrong account ID in memory or something.
  };

  const labelId = "account-select-label";
  const label = "Account";

  return (
    <Box sx={{ display: "flex", width: "100%", paddingLeft: "12px", alignItems: "center" }}>
      <FormControl fullWidth>
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
          id="account-selector"
          name="account-selector"
          style={{ borderBottom: "1px solid #e0e0e0", borderRadius: "4px" }}
          SelectDisplayProps={{ style: { display: "flex" } }}
          label={label}
          labelId={labelId}
          value={value || ""}
          onChange={handleChange}
          disabled={selectingAccount}
        >
          {activeAccounts.map((account) => (
            <MenuItem key={account.id} value={account.id}>
              {/* Show spinner on the item when it is selected while the selection is pending. */}
              <span style={{ display: "inline-block", flexGrow: 1 }}>{account.name}</span>
              {selectingAccount ? <CircularProgress size={16} /> : null}
            </MenuItem>
          ))}

          {IconItem({ icon: <AddIcon />, label: "Create account", key: CREATE_KEY })}
        </Select>
      </FormControl>
    </Box>
  );
}

function IconItem({ icon, label, key }: { icon: React.ReactNode; label: string; key: string }) {
  return (
    <MenuItem key={key} value={key}>
      <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
        {icon}
        {label}
      </div>
    </MenuItem>
  );
}
