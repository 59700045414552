import { combineReducers } from "@reduxjs/toolkit";
import accounts from "../slices/accounts";
import app from "../slices/app";
import billing from "../slices/billing";
import indices from "../slices/indexes";
import integrations from "../slices/integrations";
import marqtune from "../slices/marqtune";
import settings from "../slices/settings";
import user from "../slices/user";

export const rootReducer = combineReducers({
  indices,
  user,
  settings,
  integrations,
  marqtune,
  billing,
  accounts,
  app,
});

export type RootStateType = ReturnType<typeof rootReducer>;
