import { Alert, Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router";
import ContentLayout from "../../components/content-layout/content-layout.component";
import { BreadCrumbItem } from "../../components/content-layout/content-layout.types";
import ContentSection from "../../components/content-layout/content-section.component";
import { getFormConfig } from "../../components/form/form.component";
import { StandardForm } from "../../components/form/standard-form.component";
import config from "../../config";
import {
  IndexStatus,
  IndexWorkflowsInferenceMapping,
  IndexWorkflowsStorageMapping,
  MarqoVersion,
} from "../../constants/enums";
import { editIndexFormConfig } from "../../form-configs/indexes/modify-index.form";
import { ModifyIndexFormProps } from "../../form-configs/indexes/types";
import { useBreadcrumbs } from "../../hooks/use-breadcrumbs";
import { setToast } from "../../slices/app";
import { useDispatch, useSelector } from "../../store";
import { selectAccountLimits, selectIndexItemByName, selectIndexesStatsReqList } from "../../store/selectors";
import { getAllIndexesStatsThunk, modifyIndex } from "../../thunks/indexes.thunk";
import { getAccountLimitsThunk } from "../../thunks/user.thunk";
import { generateRandomKey } from "../../utils";
import { getFormattedAmount } from "../../utils/billing";
import { getIndexStatusLabel } from "../../utils/indices";
import IndexSettingsUtils from "../../utils/indices/index-creation-utils";
import LoadingPage from "../loading.page";

const breadcrumbsList: BreadCrumbItem[] = [{ title: "Overview", link: "/" }];

const ModifyIndex = () => {
  const { withStorageResizing } = useSelector((state) => state.user.data);
  const { fetching } = useSelector((state) => state.indices.common);
  const indexNamesList = useSelector(selectIndexesStatsReqList);
  const { inProgress: isFetchingIndexes } = fetching;
  const {
    inference: inferencePrices,
    storage: storagePrices,
    isFetching: isFetchingPrices,
    hasNullPrices,
    fetchingError,
  } = useSelector((state) => state.billing.pricing);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { indexName } = useParams();
  const matchingIndex = useSelector(selectIndexItemByName(indexName));
  const mappedStorage =
    IndexWorkflowsStorageMapping[matchingIndex?.storageClass as keyof typeof IndexWorkflowsStorageMapping];
  const mappedInference =
    IndexWorkflowsInferenceMapping[matchingIndex?.inferenceType as keyof typeof IndexWorkflowsInferenceMapping];
  const { setBreadcrumbs, clearBreadcrumbs } = useBreadcrumbs();
  const accountLimits = useSelector(selectAccountLimits);
  const hasObjLimit = Object.values(accountLimits)
    .flat()
    .some((val) => typeof val.max === "object");
  const canResize =
    withStorageResizing && /^2/.test(matchingIndex?.marqoVersion) && /^3/.test(matchingIndex?.workflowVersion);

  const formik = useFormik({
    ...getFormConfig<ModifyIndexFormProps>(
      editIndexFormConfig({
        canResize,
        disableReplicas: mappedStorage === "marqo.basic",
        mappedStorage,
        currNumShards: Number(matchingIndex?.numberOfShards),
        currNumReplicas: Number(matchingIndex?.numberOfReplicas),
        currNumInfPods: Number(matchingIndex?.numberOfInferences),
        currInferenceType: matchingIndex?.inferenceType,
        accountLimits,
        storagePrices,
        inferencePrices,
      }),
    ),
    onSubmit: async (data, helpers) => {
      if (isFetchingIndexes || !matchingIndex) return;

      data["indexId"] = indexName;
      data["marqoVersion"] = matchingIndex.marqoVersion;

      await dispatch(
        modifyIndex({
          formData: data,
          onSuccess: () => {
            navigate(`/indexes/${indexName}`);
            dispatch(getAllIndexesStatsThunk(indexNamesList));
          },
          onError: (e) => {
            const errMsg = IndexSettingsUtils.getIndexErrorMessage(e);
            return helpers.setErrors({ submit: errMsg });
          },
        }),
      );
    },
  });

  const disableReplicas: boolean = formik.values.storageShardType === "marqo.basic";

  const indexCost: string = useMemo(() => {
    let computedCost = 0;

    if (canResize) {
      computedCost = IndexSettingsUtils.computeIndexCost(formik.values, inferencePrices, storagePrices);
    } else {
      computedCost = IndexSettingsUtils.inferenceCost(formik.values, inferencePrices);
    }

    return getFormattedAmount(computedCost, 2);
  }, [formik.values, inferencePrices, storagePrices, isFetchingPrices]);

  const showMailToSupport: boolean = useMemo(() => {
    if (!matchingIndex) return;

    const numberOfShards = matchingIndex.numberOfShards;
    const numberOfReplicas = matchingIndex.numberOfReplicas;
    const inferencePods = formik.values.numberOfInferencePods;

    return (
      inferencePods > accountLimits.inference.max ||
      numberOfShards > accountLimits.shard.max ||
      numberOfReplicas > accountLimits.replica.max
    );
  }, [
    matchingIndex,
    formik.values.numberOfShards,
    formik.values.numberOfReplicas,
    formik.values.numberOfInferencePods,
    accountLimits,
  ]);

  useEffect(() => {
    dispatch(getAccountLimitsThunk());
    setBreadcrumbs(breadcrumbsList);
    return () => clearBreadcrumbs();
  }, []);

  useEffect(() => {
    if (fetching.inProgress) return;

    if (!matchingIndex?.indexStatus) {
      dispatch(setToast({ msg: `Index ${indexName} not found`, type: "error", hash: generateRandomKey(6) }));
      navigate(config.authenticatedPaths.indexes);
      return;
    }

    if (getIndexStatusLabel(matchingIndex?.indexStatus) !== IndexStatus.READY) {
      dispatch(setToast({ msg: `${indexName} is not ready.`, type: "error", hash: generateRandomKey(6) }));
      navigate(config.authenticatedPaths.indexes);
      return;
    }

    formik.setValues(
      {
        indexName,
        marqoVersion: matchingIndex.marqoVersion || (MarqoVersion.V1 as string),
        submit: "",
        storageShardType: mappedStorage,
        inferencePodType: mappedInference,
        numberOfShards: matchingIndex.numberOfShards,
        numberOfReplicas: matchingIndex.numberOfReplicas,
        numberOfInferencePods: matchingIndex.numberOfInferences,
      },
      true,
    );
  }, [matchingIndex?.indexName, fetching.inProgress, accountLimits, indexNamesList]);

  return (
    <ContentLayout headTitle={`Edit index "${indexName}"`} pageTitle={`Edit index "${indexName}"`}>
      {isFetchingPrices || !matchingIndex || hasObjLimit ? (
        <LoadingPage />
      ) : (
        <ContentSection id={"EditIndex-Section"}>
          <Typography mb={4} variant={"h5"}>
            Edit Index
          </Typography>

          <StandardForm
            checkIfShallow={false}
            promptTitle={""}
            formik={formik}
            withPromptDialog={false}
            formConfig={editIndexFormConfig({
              canResize,
              disableReplicas,
              mappedStorage,
              currNumShards: matchingIndex.numberOfShards,
              currNumReplicas: matchingIndex.numberOfReplicas,
              currNumInfPods: matchingIndex.numberOfInferences,
              currInferenceType: matchingIndex.inferenceType,
              accountLimits,
              storagePrices,
              inferencePrices,
            })}
            disabledFields={indexName && ["indexName"]}
            postSendNavigateTo={"/indexes"}
            onPreSubmitHandler={null}
            submitBtnLabel={"Edit Index"}
          >
            {formik.errors?.storageShardType && (
              <Grid item xs={12}>
                <Alert severity="error">{formik.errors?.storageShardType}</Alert>
              </Grid>
            )}

            <Grid container item>
              <Alert severity={"info"} sx={{ backgroundColor: "transparent" }}>
                <Grid container item xs={12}>
                  <Typography variant={"subtitle2"}>
                    Estimated Cost {!canResize && "(for inference pods only)"}
                  </Typography>
                </Grid>
                <Grid container item xs={12}>
                  {IndexSettingsUtils.showFallbackDashOnModifyCost(
                    formik,
                    indexName,
                    isFetchingPrices,
                    fetchingError,
                    hasNullPrices,
                    showMailToSupport,
                    indexCost,
                  ) && (
                    <Typography variant={"subtitle2"} color={"primary"} sx={{ mr: 1 }}>
                      -
                    </Typography>
                  )}
                  <Typography variant={"subtitle2"}>
                    {IndexSettingsUtils.showModifyFormattedCost(
                      formik,
                      indexName,
                      isFetchingPrices,
                      fetchingError,
                      hasNullPrices,
                      showMailToSupport,
                      indexCost,
                    ) && indexCost}{" "}
                    / hour
                  </Typography>
                </Grid>
              </Alert>
            </Grid>
          </StandardForm>
        </ContentSection>
      )}
    </ContentLayout>
  );
};

export default ModifyIndex;
