import axios, { AxiosPromise } from "axios";
import config from "../../config";
import { AccountId } from "../api.types";
import { GetIntegrationsResProps, SetIntegrationReqProps, SetIntegrationResProps } from "./types";

const getIntegrations = (accountId: AccountId): AxiosPromise<GetIntegrationsResProps> => {
  return axios({
    method: "GET",
    baseURL: config.baseURL,
    url: config.endpoints.integrations,
    params: { accountId },
    headers: { "Content-Type": "application/json" },
  });
};

const setDatadogIntegrationKey = (
  accountId: AccountId,
  payload: SetIntegrationReqProps,
): AxiosPromise<SetIntegrationResProps> => {
  return axios({
    method: "POST",
    baseURL: config.baseURL,
    url: config.endpoints.integrations,
    params: { accountId },
    headers: { "Content-Type": "application/json" },
    data: payload,
  });
};

/**
 * exports
 */

const IntegrationsAPI = {
  get: getIntegrations,
  setDatadog: setDatadogIntegrationKey,
};
export default IntegrationsAPI;
