import { KeyRounded } from "@mui/icons-material";
import { Fragment } from "react";
import * as Yup from "yup";
import { Option } from "../components/form-fields/select-field";
import { renderInputStartAdornment } from "../components/form/form.component";
import { FormConfigType } from "../components/form/form.types";
import { TooltipTitleLink } from "../components/page-parts/indexes/index-form-tooltips";
import QuickLinks from "../constants/links";

export type IntegrationForm = {
  apiKey: string;
  datadogSite: string;
  submit: string;
};
const apiKeyComponentProps = (disableApiKey: boolean, placeholder = "") => {
  return {
    componentProps: {
      placeholder: placeholder,
      disabled: disableApiKey,
      autoComplete: "",
      ...renderInputStartAdornment(<KeyRounded />),
    },
  };
};

const datadogSiteOptions: Option[] = [
  "datadoghq.com", // default
  "us3.datadoghq.com",
  "us5.datadoghq.com",
  "ap1.datadoghq.com",
  "datadoghq.eu",
  "ddog-gov.com",
].map((siteName: string) => {
  return {
    id: siteName,
    value: siteName,
    title: siteName,
  };
});

export const ddFormTooltip = (
  <Fragment>
    Marqo index requests can be traced and analyzed using Datadog. You can read more about Datadog{" "}
    {TooltipTitleLink(QuickLinks.datadog_site)}.
  </Fragment>
);

const ddApiKeyTooltip = (
  <Fragment>
    Enter your Datadog API Key to submit Marqo metrics and events to your Datadog dashboard. Read more{" "}
    {TooltipTitleLink(QuickLinks.datadog_api_key)}.
  </Fragment>
);

export const DEFAULT_DATADOG_SITE = datadogSiteOptions[0].value;

export const datadogIntegrationFormConfig = (datadogKey: string, disableApiKey: boolean): FormConfigType => {
  return {
    fields: {
      apiKey: {
        label: "API Key",
        initialValue: !!datadogKey ? datadogKey : "",
        type: "password",
        sizes: {
          xs: 12,
          sm: 9,
          md: 4,
        },
        ...apiKeyComponentProps(disableApiKey),
        transformValue: (e) => {
          const { value } = e.target;
          return value.trim();
        },
        validation: {
          rules: {},
          validate: Yup.string().min(1).max(255),
        },
        tooltipTitle: ddApiKeyTooltip,
      },
      datadogSite: {
        label: "Datadog site",
        type: "select",
        options: datadogSiteOptions,
        componentProps: {
          fullWidth: true,
          disabled: disableApiKey, // site field is disabled/enabled along with api key
        },
        sizes: {
          xs: 12,
          sm: 9,
          md: 4,
        },
        initialValue: DEFAULT_DATADOG_SITE,
      },
      submit: {
        hidden: true,
        sizes: {
          xs: 12,
          sm: 6,
          md: 4,
        },
      },
    },
  };
};
