import { Circle } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import { Clock } from "../../../icons/clock";
import { StatusChip } from "./chip.component";

type InProgressDisplayProps = {
  timeLeft: string;
  status: string;
};

type ReadyStatusProps = {
  status: string;
};

export const InProgressStatusDisplay = ({ timeLeft, status }: InProgressDisplayProps) => {
  return (
    <Box display={"flex"} flexDirection={"row"} alignItems={"center"} gap={1}>
      <StatusChip isReady={false} status={status} />

      {timeLeft && (
        <Grid item container flexDirection={"row"} justifySelf={"center"} gap={1}>
          <Grid item>
            <Clock sx={{ fontSize: "0.9rem" }} />
          </Grid>

          <Grid item display={"flex"} alignItems={"center"}>
            <Typography sx={{ fontSize: "0.8rem", color: "neutral.400" }}>
              {/* TODO: CountdownAction HTTP polling based on time left */}
              {timeLeft}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export const ReadyStatusDisplay = ({ status }: ReadyStatusProps) => {
  return (
    <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
      <Circle fontSize="small" color={"success"} />
      <Box display={"flex"} flexDirection={"row"} alignItems={"center"} pl={1}>
        <Typography fontWeight={600} sx={{ fontWeight: 300, fontSize: "0.875rem" }}>
          {status}
        </Typography>
      </Box>
    </Box>
  );
};
