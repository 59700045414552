import { Box } from "@mui/material";
import { useFormik } from "formik";
import { useNavigate } from "react-router";
import AuthBase from "../../../components/authentication/auth-base.component";
import { AuthBaseClasses } from "../../../components/authentication/auth.styles";
import { AuthButton } from "../../../components/button/auth-button.component";
import Form, { getFormConfig } from "../../../components/form/form.component";
import { AlreadyHaveAnAccountQuickLink } from "../../../components/page-parts/quick-links/quick-links";
import config from "../../../config";
import { resetPasswordReqFormConfig } from "../../../form-configs/auth/password.forms";
import { useAuth } from "../../../hooks/use-auth";

const PasswordResetRequest = () => {
  const { forgotPassword } = useAuth();
  const navigate = useNavigate();
  const classes = AuthBaseClasses();

  const formik = useFormik({
    ...getFormConfig<{
      email: string;
      submit: string | boolean;
    }>(resetPasswordReqFormConfig),
    onSubmit: async (values, helpers) => {
      try {
        await forgotPassword(values.email);
        navigate(config.publicPaths.passwordReset);
      } catch (e) {
        helpers.setErrors({
          submit: "Failed to request password reset.",
        });
      }
    },
  });

  return (
    <AuthBase formDisplayTitle="" pageTitle={"Forgot Password"} extraWrapperClasses={"borderRadiusAll"}>
      <Form formik={formik} spacing={2} formConfig={resetPasswordReqFormConfig}>
        <Box className={classes.customFormElementsWrapper}>
          {AlreadyHaveAnAccountQuickLink}
          <AuthButton isDisabled={formik.isSubmitting} label={"Request password reset"} />
        </Box>
      </Form>
    </AuthBase>
  );
};

export default PasswordResetRequest;
