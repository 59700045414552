import { AxiosError, AxiosPromise } from "axios";
import React, { createContext, useEffect, useReducer, useState } from "react";
import { batch, useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import API from "../api";
import {
  ChangePasswordReq,
  ResetPasswordReq,
  SigninRes,
  SignupByEmailForm,
  SsoSignupForm,
} from "../api/auth/auth.types";
import {
  changePasswordToast,
  confirmSignupReqToast,
  resendConfirmationCodeReqToast,
  resetPasswordToast,
} from "../components/toast/user.toast";
import { clearCurrentAccountId, setCurrentAccountId } from "../slices/accounts";
import { clearIndicesState } from "../slices/indexes/indexes-main";
import { clearAPIKeys, clearTeamMemberList } from "../slices/settings";
import { resetUserState } from "../slices/user";
import { getUserData } from "../thunks/user.thunk";
import UserToken from "../utils/services/tokens-service";

type TTempUserData = {
  email: string;
  password: string;
};

type TempRegisterFormData = {
  formErrors: any;
  token: string;
  email?: string;
  password?: string;
};

type SocialUserData = {
  name: string;
  email: string;
  expires_at: number;
};

export type TAuthContext = {
  platform: string;
  isAuthenticated: boolean;
  isInitialized: boolean;
  authChecking: boolean;
  isPasswordSet: boolean;
  isPasswordReset: boolean;
  isPostRegister: boolean;
  isSendingRegReq: boolean;
  isSignupConfirmed: boolean;
  isSigningUp: boolean;
  /** Whether the user is allowed to skip adding a credit card during signup. */
  allowSkipCreditCard: boolean;
  user: any | null;
  socialUserData: SocialUserData;
  credentials?: string | null;
  tempLoginData: TTempUserData | null;
  tempRegisterFormData: TempRegisterFormData | null;
  login: (email: string, password: string) => AxiosPromise<void> | Promise<void>;
  signinViaSSO: (credentials: string) => AxiosPromise<void> | Promise<void>;
  getSocialUserData: (token: string) => AxiosPromise<void> | Promise<void>;
  confirmSignup: (email: string, verificationCode: string) => AxiosPromise<void> | Promise<void>;
  continueWithSocial: (
    code: string,
    intent: CodeExchangeIntent,
    errorHandler: (error: string) => void,
  ) => AxiosPromise<void> | Promise<void>;
  resendConfirmationCode: (email: string) => AxiosPromise<void> | Promise<void>;
  forgotPassword: (email: string) => AxiosPromise<void> | Promise<void>;
  resetPassword: (params: ResetPasswordReq) => AxiosPromise<void> | Promise<void>;
  changePassword: (params: ChangePasswordReq) => AxiosPromise<void> | Promise<void>;
  logout: () => AxiosPromise<void> | Promise<void>;
  preSignup: (formData: SignupByEmailForm | SsoSignupForm) => AxiosPromise<void> | Promise<void>;
  register: (formData: SignupByEmailForm) => AxiosPromise<void> | Promise<void>;
  registerViaSso: (formData: SsoSignupForm) => AxiosPromise<void> | Promise<void>;
  resetIsPostRegister: () => void;
  clearTempData: () => void;
  setLoggedIn: () => void;
  setRegisterFormData: (payload: TempRegisterFormData) => void;
};

export type CodeExchangeIntent = "signup" | "signin" | "accept_invite";

type TActions = {
  type:
    | "INITIALIZE"
    | "LOGIN"
    | "PRE_REGISTER"
    | "REGISTER"
    | "LOGOUT"
    | "AUTHCHECK"
    | "CHANGE_PASSWORD"
    | "CONFIRM_SIGNUP"
    | "CLEAR_CONFIRM_SIGNUP"
    | "RESET_PASSWORD"
    | "CLEAR_IS_POST_REGISTER"
    | "CLEAR_IS_SENDING_REG_REQ"
    | "SET_TEMP_REGISTER_DATA"
    | "SET_SOCIAL_USER_DATA";
  payload?: {
    isAuthenticated?: boolean;
    isInitialized?: boolean;
    authChecking?: boolean;
    acknowledged?: boolean;
    tempRegisterFormData?: TempRegisterFormData;
  };
};

const initialState: TAuthContext = {
  platform: "JWT",
  isAuthenticated: false,
  authChecking: true,
  isInitialized: false,
  isPostRegister: false,
  isSendingRegReq: false,
  isSignupConfirmed: false,
  isSigningUp: false,
  /**
   * This is permanently false, and requires a code change/deployment to change, since there is no
   * mechanism currently for syncing flags to the client before login/signup when it's needed.
   */
  allowSkipCreditCard: false,
  user: null,
  credentials: null,
  tempLoginData: null,
  tempRegisterFormData: null,
  isPasswordSet: null,
  isPasswordReset: null,
  socialUserData: null,
  login: () => Promise.resolve(),
  signinViaSSO: () => Promise.resolve(),
  confirmSignup: () => Promise.resolve(),
  continueWithSocial: () => Promise.resolve(),
  getSocialUserData: () => Promise.resolve(),
  resendConfirmationCode: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  forgotPassword: () => Promise.resolve(),
  resetPassword: () => Promise.resolve(),
  changePassword: () => Promise.resolve(),
  preSignup: () => Promise.resolve(),
  register: () => Promise.resolve(),
  registerViaSso: () => Promise.resolve(),
  resetIsPostRegister: () => {},
  clearTempData: () => {},
  setLoggedIn: () => {},
  setRegisterFormData: (_: TempRegisterFormData) => {},
};

const handlers = {
  INITIALIZE: (state: TAuthContext, action: TActions) => {
    const { isAuthenticated, authChecking } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      authChecking,
    };
  },
  LOGIN: (state: TAuthContext) => {
    return {
      ...state,
      authChecking: false,
      isAuthenticated: true,
    };
  },
  LOGOUT: (state: TAuthContext) => ({
    ...state,
    isAuthenticated: false,
  }),
  RESET_PASSWORD: (state: TAuthContext, action: any) => ({
    ...state,
    isAuthenticated: false,
    isPasswordReset: action.payload?.acknowledged,
  }),
  SET_SOCIAL_USER_DATA: (state: TAuthContext, action: any) => ({
    ...state,
    socialUserData: action.payload,
  }),
  CONFIRM_SIGNUP: (state: TAuthContext, action: any) => ({
    ...state,
    isAuthenticated: false,
    isSignupConfirmed: action.payload?.acknowledged,
  }),
  CLEAR_CONFIRM_SIGNUP: (state: TAuthContext) => ({
    ...state,
    isAuthenticated: false,
    isSignupConfirmed: false,
  }),
  CLEAR_IS_PASSWORD_RESET: (state: TAuthContext) => ({
    ...state,
    isPasswordReset: false,
  }),
  CHANGE_PASSWORD: (state: TAuthContext, action: any) => ({
    ...state,
    isPasswordSet: action.payload?.acknowledged,
  }),
  PRE_REGISTER: (state: TAuthContext) => ({
    ...state,
    isSendingRegReq: true,
    isSigningUp: true,
  }),
  REGISTER: (state: TAuthContext) => {
    return {
      ...state,
      isSendingRegReq: false,
      authChecking: false,
      isAuthenticated: false,
      isPostRegister: true,
    };
  },
  CLEAR_IS_SENDING_REG_REQ: (state: TAuthContext) => {
    return {
      ...state,
      isSendingRegReq: false,
    };
  },
  CLEAR_IS_POST_REGISTER: (state: TAuthContext) => {
    return {
      ...state,
      isPostRegister: false,
      isSendingRegReq: false,
      isSigningUp: false,
    };
  },
  SET_TEMP_REGISTER_DATA: (state: TAuthContext, action: TActions) => {
    const { tempRegisterFormData } = action.payload;
    return {
      ...state,
      tempRegisterFormData,
    };
  },
  AUTHCHECK: (state: TAuthContext, action: TActions) => ({
    ...state,
    authChecking: action.payload.authChecking,
    isAuthenticated: action.payload.isAuthenticated,
  }),
};

const reducer = (state: TAuthContext, action: TActions) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

export const AuthContext = createContext(initialState);

export const AuthProvider: React.FC = (props) => {
  const { children } = props;
  const [credentials, setCredentials] = useState<string | null>(null);
  const [tempLoginData, setTempLoginData] = useState<TTempUserData>({
    email: "",
    password: "",
  });
  const [state, dispatch] = useReducer(reducer, initialState);
  const storeDispatch = useDispatch();
  const navigate = useNavigate();

  const initialize = async (): Promise<void> => {
    try {
      dispatch({
        type: "AUTHCHECK",
        payload: {
          authChecking: true,
          isAuthenticated: false,
        },
      });
      // Check if access token is set in cookies
      const accessToken = UserToken.get();
      if (!!accessToken && accessToken !== "undefined") {
        await storeDispatch(
          getUserData({
            onSuccess: () => {
              dispatch({
                type: "INITIALIZE",
                payload: {
                  isAuthenticated: true,
                  isInitialized: true,
                  authChecking: false,
                },
              });
            },
            onError: () => {
              UserToken.remove();
              storeDispatch(clearCurrentAccountId());
              dispatch({
                type: "INITIALIZE",
                payload: {
                  isAuthenticated: false,
                  isInitialized: true,
                  authChecking: false,
                },
              });
            },
          }),
        );
      } else {
        dispatch({
          type: "INITIALIZE",
          payload: {
            isAuthenticated: false,
            authChecking: false,
          },
        });
      }
    } catch (err) {
      // force delete token if something failed
      UserToken.remove();
      storeDispatch(clearCurrentAccountId());
      dispatch({
        type: "INITIALIZE",
        payload: {
          isAuthenticated: false,
          authChecking: false,
        },
      });
    }

    clearTempData();
  };

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    initialize();
  }, [UserToken.get()]);

  const handleLogin = async (apiPromise: AxiosPromise<SigninRes>): Promise<void> => {
    try {
      const res = await apiPromise;
      const { token, account_id: accountId } = res.data;
      UserToken.set(token);
      storeDispatch(setCurrentAccountId({ accountId }));
      storeDispatch(getUserData({}));
      dispatch({ type: "LOGIN" });
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const login = async (email: string, password: string): Promise<void> => {
    return handleLogin(API.auth.login.signin({ email, password }));
  };

  const signinViaSSO = async (credentials: string): Promise<void> => {
    return handleLogin(API.auth.login.viaSSO({ credentials }));
  };

  const continueWithSocial = async (
    code: string,
    intent: CodeExchangeIntent,
    errorHandler: (error: string) => void,
  ): Promise<void> => {
    try {
      const { data } = await API.auth.social.auth(code, intent);
      const { token, redirect_to, account_id } = data;

      if (!token || !redirect_to) {
        clearTempData();
        return;
      }

      setCredentials(token);

      if (redirect_to === "/") {
        UserToken.set(token);
        clearTempData();
      }

      storeDispatch(setCurrentAccountId({ accountId: account_id }));

      navigate(redirect_to);
    } catch (e) {
      const errorMsg = (e as AxiosError).response?.data?.error || "Failed to authenticate. Please try again.";
      errorHandler(errorMsg);
    }
  };

  const logout = async (): Promise<void> => {
    try {
      await API.auth.logOut();
    } catch (err) {
      console.warn("Failed to logout on server");
      // TODO: show err on errortoast
    } finally {
      const result = UserToken.remove();
      if (result) {
        batch(() => {
          storeDispatch(clearCurrentAccountId());
          storeDispatch(resetUserState());
          storeDispatch(clearIndicesState());
          storeDispatch(clearAPIKeys());
          storeDispatch(clearTeamMemberList());
          dispatch({ type: "LOGOUT" });
        });
      } else {
        console.warn("Failed to remove token");
        // TODO: show err on errortoast
      }
    }
  };

  const confirmSignup = async (email: string, verificationCode: string): Promise<void> => {
    try {
      const promise = API.auth.register.confirm(email, verificationCode);
      const { data } = await confirmSignupReqToast(promise);
      dispatch({ type: "CONFIRM_SIGNUP", payload: data });
      // If the user could be automatically logged in, set the token.
      if (data.token) {
        UserToken.set(data.token);
        storeDispatch(getUserData({}));
        dispatch({ type: "LOGIN" });
      }
    } catch (err) {
      dispatch({
        type: "CONFIRM_SIGNUP",
        payload: {
          acknowledged: false,
        },
      });
      return Promise.reject(err);
    }
  };

  const resendConfirmationCode = async (email: string): Promise<void> => {
    try {
      const promise = API.auth.register.resendConfirmation(email);
      await resendConfirmationCodeReqToast(promise);
    } catch (e) {
      return Promise.reject(e);
    }
  };

  const forgotPassword = async (email: string): Promise<void> =>
    API.auth.password.forgot({ email }).then(({ data }) => {
      const { redirect_to } = data;
      if (redirect_to) {
        navigate(redirect_to, { state: { info: "Please check your email for the verification code." } });
      }
    });

  const resetPassword = async (params: ResetPasswordReq): Promise<void> => {
    try {
      const promise = API.auth.password.reset(params);
      resetPasswordToast(promise);
      const { data } = await promise;
      dispatch({ type: "RESET_PASSWORD", payload: data });
    } catch (err) {
      return Promise.reject(err);
    }
  };

  const changePassword = async (params: ChangePasswordReq): Promise<void> => {
    try {
      const promise = API.auth.password.change(params);
      changePasswordToast(promise);
      const { data } = await promise;
      dispatch({ type: "CHANGE_PASSWORD", payload: data });
    } catch (err) {}
  };

  const preSignup = (formData: SignupByEmailForm | SsoSignupForm): Promise<void> | AxiosPromise => {
    try {
      return API.auth.register.preSignup(formData);
    } catch (e) {
      return Promise.resolve();
    }
  };

  const register = async (formData: SignupByEmailForm): Promise<void> => {
    const { email, password } = formData;
    setTempLoginData({ email, password });
    dispatch({ type: "PRE_REGISTER" });

    try {
      await API.auth.register.byEmail(formData);
      dispatch({ type: "REGISTER" });
    } catch (err) {
      dispatch({ type: "CLEAR_IS_SENDING_REG_REQ" });
      return Promise.reject(err);
    }
  };

  const registerViaSso = async (formData: SsoSignupForm): Promise<void> => {
    try {
      const { submit, ...payload } = formData;
      payload.credentials = credentials;
      const {
        data: { token },
      } = await API.auth.register.viaSSO(payload);
      UserToken.set(token);
      dispatch({ type: "REGISTER" });
    } catch (err) {
      dispatch({ type: "CLEAR_IS_SENDING_REG_REQ" });
      return Promise.reject(err);
    }
  };

  const clearTempData = () => {
    setTempLoginData({ email: "", password: "" });
    setCredentials(null);
  };

  const resetIsPostRegister = () => {
    if (tempLoginData.email || tempLoginData.password) {
      dispatch({ type: "CLEAR_IS_POST_REGISTER" });
      clearTempData();
    }
  };

  const setLoggedIn = () => {
    dispatch({
      type: "LOGIN",
      payload: {
        isAuthenticated: true,
      },
    });
  };

  const setRegisterFormData = (tempRegisterFormData: TempRegisterFormData) => {
    dispatch({
      type: "SET_TEMP_REGISTER_DATA",
      payload: {
        tempRegisterFormData,
      },
    });
  };

  const getSocialUserData = async (token: string): Promise<void> => {
    try {
      const { data } = await API.auth.social.userData(token);
      dispatch({ type: "SET_SOCIAL_USER_DATA", payload: data });
    } catch (e) {
      dispatch({ type: "SET_SOCIAL_USER_DATA", payload: null });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        platform: "JWT",
        login,
        signinViaSSO,
        logout,
        forgotPassword,
        resetPassword,
        changePassword,
        resendConfirmationCode,
        preSignup,
        register,
        registerViaSso,
        credentials,
        tempLoginData,
        resetIsPostRegister,
        clearTempData,
        setLoggedIn,
        setRegisterFormData,
        confirmSignup,
        getSocialUserData,
        continueWithSocial,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const AuthConsumer = AuthContext.Consumer;
