import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment, TextField, TextFieldProps } from "@mui/material";
import React, { useState } from "react";

const PasswordField: React.FC<TextFieldProps> = (props) => {
  const [showPassword, setShowPassword] = useState(false);
  const toggleShowPassword = () => {
    setShowPassword((prev) => !prev);
  };
  return (
    <TextField
      {...props}
      autoComplete={"current-password"}
      type={!showPassword ? props.type : "text"}
      InputProps={{
        startAdornment: props?.InputProps.startAdornment,
        endAdornment: (
          <InputAdornment position={"end"}>
            <IconButton onClick={toggleShowPassword}>{!showPassword ? <Visibility /> : <VisibilityOff />}</IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordField;
