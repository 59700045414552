import { createSelector } from "@reduxjs/toolkit";
import { isEmpty } from "lodash";
import { RootState } from ".";
import { GetAllIndexesStatsReqType } from "../api/indexes/indexes.types";
import { MarqtuneDataset, MarqtuneEvaluation, MarqtuneModel } from "../api/marqtune/types";
import { IndexItem } from "../components/page-parts/indexes/indexes.types";
import { type AccountLimits, getAccountIndexLimits } from "../utils/indices";

export const selectCurrentAccount = createSelector(
  [(state: RootState) => state.accounts.accountDetails, (state: RootState) => state.accounts.currentAccountId],
  (details, id) => (id ? details.find((a) => a.id === id) : undefined),
);

export const selectCurrentAccountId = createSelector(
  ({ accounts }: RootState) => accounts.currentAccountId,
  (currentAccountId) => currentAccountId,
);

export const selectActiveAccounts = createSelector(
  ({ accounts }: RootState) => accounts.accountDetails,
  (accounts) => accounts.filter((account) => account.status === "ACTIVE"),
);
export const selectInvitationAccounts = createSelector(
  ({ accounts }: RootState) => accounts.accountDetails,
  (accounts) => accounts.filter((account) => account.status === "PENDING"),
);

/** True if the user has active accounts available but has none selected. */
export const selectMustCreateOrSelectAccount = createSelector(
  [selectActiveAccounts, selectCurrentAccountId],
  (activeAccounts, currentAccountId) => !currentAccountId && !isEmpty(activeAccounts),
);

export const selectAccountLimits = createSelector(
  ({ user }: RootState) => user.limits,
  ({ inference, shard, replica, index }): AccountLimits => getAccountIndexLimits(inference, replica, shard, index),
);

export const selectFirstApiKeySecret = createSelector(
  ({ settings }: RootState) => settings.apiKeys.keyList,
  (keyList) => (keyList.length ? keyList[0].secret : ""),
);

export const selectIndexesStatsReqList = createSelector(
  ({ indices }: RootState) => indices.common.list,
  (list): GetAllIndexesStatsReqType =>
    list.map(({ indexName, marqoVersion }) => ({ indexName, isV2: /^2/.test(marqoVersion) })),
);

export const selectIndexItemByName = (indexName: string) =>
  createSelector(
    ({ indices }: RootState) => indices.common.list,
    (list): IndexItem | null => list.find((index) => index.indexName === indexName),
  );

export const selectDatasetByDatasetId = (_datasetId: string) =>
  createSelector(
    ({ marqtune }: RootState) => marqtune.datasets.list,
    (list): MarqtuneDataset | null => list.find(({ datasetId }) => datasetId === _datasetId),
  );

export const selectValidDatasetByDatasetId = (_datasetId: string) =>
  createSelector(
    ({ marqtune }: RootState) => marqtune.datasets.list,
    (list): MarqtuneDataset | null => list.find(({ datasetId }) => datasetId === _datasetId),
  );

export const selectEvaluationByEvaluationId = (evaluationId: string) =>
  createSelector(
    ({ marqtune }: RootState) => marqtune.evaluations.list,
    (list): MarqtuneEvaluation | null => list.find((item) => item.evaluationId === evaluationId),
  );

export const selectModelByModelId = (_modelId: string) =>
  createSelector(
    ({ marqtune }: RootState) => marqtune.models.list,
    (list): MarqtuneModel | null => list.find(({ modelId }) => modelId === _modelId),
  );

export const selectSuccessfulMarqtuneModels = createSelector(
  (state: RootState) => state.marqtune.models.list,
  (list: MarqtuneModel[]) => list.filter(({ status }) => status === "Ready"),
);

export const selectTrainingDatasets = createSelector(
  ({ marqtune }: RootState) => marqtune.datasets.list,
  (list) => list.filter(({ datasetType }) => datasetType?.toLowerCase() === "training"),
);

export const selectEvaluationDatasets = createSelector(
  ({ marqtune }: RootState) => marqtune.datasets.list,
  (list) => list.filter(({ datasetType }) => datasetType?.toLowerCase() === "evaluation"),
);
