import { ContentCopy } from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import { Box, Divider, IconButton, ListItemIcon, ListItemText, MenuItem, Popover, Typography } from "@mui/material";
import copy from "copy-to-clipboard";
import PropTypes from "prop-types";
import React from "react";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import { ellipsisStyles } from "../../constants/styles";
import { useAuth } from "../../hooks/use-auth";
import { setToast } from "../../slices/app";
import { useDispatch, useSelector } from "../../store";
import { selectCurrentAccountId, selectMustCreateOrSelectAccount } from "../../store/selectors";
import { generateRandomKey } from "../../utils";

export type AccountPopoverProps = {
  anchorEl: Element;
  onClose: () => void;
  open: boolean;
};

export const AccountPopover: React.FC<AccountPopoverProps> = (props): JSX.Element => {
  const { anchorEl, onClose, open, ...other } = props;
  const navigate = useNavigate();
  const { logout } = useAuth();
  const dispatch = useDispatch();

  const currentAccountId = useSelector(selectCurrentAccountId);
  const { email } = useSelector((state) => state.user.data);
  const mustSelectAccount = useSelector(selectMustCreateOrSelectAccount);

  const handleLogout = async () => {
    try {
      onClose();
      await logout();
      navigate(config.publicPaths.login);
    } catch (err) {}
  };

  const handleMenuItemClick = (path: string) => (): void => {
    navigate(path);
    onClose();
  };

  const handleCopy = (label: string, value: string) => {
    copy(value);
    dispatch(
      setToast({
        msg: `${label} has been copied to clipboard.`,
        type: "success",
        hash: generateRandomKey(6),
      }),
    );
  };

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: "center",
        vertical: "bottom",
      }}
      keepMounted
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 300 } }}
      transitionDuration={0}
      {...other}
    >
      <Box sx={{ my: 1 }}>
        {currentAccountId && (
          <MenuItem component="a">
            <ListItemText
              primary={
                <Box display={"flex"}>
                  <Typography sx={{ mr: 1 }} fontWeight={"bold"}>
                    Account ID:
                  </Typography>
                  <Typography variant="body1" sx={{ ...ellipsisStyles }}>
                    {currentAccountId}
                  </Typography>
                  <IconButton
                    size="small"
                    style={{ padding: 0 }}
                    onClick={() => handleCopy("Account ID", currentAccountId)}
                  >
                    <ContentCopy fontSize={"small"} />
                  </IconButton>
                </Box>
              }
            />
          </MenuItem>
        )}

        <MenuItem>
          <ListItemText
            primary={
              <Box display={"flex"}>
                <Typography sx={{ mr: 1 }} fontWeight={"bold"}>
                  Email:
                </Typography>
                <Typography variant="body1" sx={{ ...ellipsisStyles }}>
                  {email}
                </Typography>
                <IconButton
                  size="small"
                  style={{ padding: 0, justifyContent: "flex-end" }}
                  onClick={() => handleCopy("Email", email)}
                >
                  <ContentCopy fontSize={"small"} />
                </IconButton>
              </Box>
            }
          />
        </MenuItem>

        <Divider />

        {/* settings - api keys */}

        <MenuItem onClick={handleMenuItemClick(config.authenticatedPaths.apiKeys)} disabled={mustSelectAccount}>
          <ListItemIcon>
            <VpnKeyIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">API Keys</Typography>} />
        </MenuItem>

        {/* logout */}

        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={<Typography variant="body1">Logout</Typography>} />
        </MenuItem>
      </Box>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
