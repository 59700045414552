import { CorporateFareRounded, LockRounded, PersonRounded, PublicRounded } from "@mui/icons-material";
import { Theme, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { FormikProps } from "formik";
import { merge } from "lodash";
import * as Yup from "yup";
import { CloudProviderForm, CreditCardForm, UserBackgroundForm } from "../../api/auth/auth.types";
import awsLogo from "../../assets/aws-logo.svg";
import gcpLogo from "../../assets/gcp-logo.svg";
import { FormStep } from "../../components/authentication/auth.enums";
import { renderInputStartAdornment } from "../../components/form/form.component";
import { FieldConfigType, FormConfigType } from "../../components/form/form.types";
import { termsAndConditions } from "../../components/page-parts/quick-links/quick-links";
import { CloudProvider, INDUSTRY, INTENTION_OF_USE, USE_CASE, enumToOptions } from "../../constants/validation";
import { getCountryCodes, getCountryOptions } from "../../utils/forms/country-options";
import { confirmPasswordFieldConfig, emailFieldConfig, strictPasswordFieldConfig } from "./auth-field.configs";

const combineStyles = (styleOne: (theme: Theme) => object, styleTwo: (theme: Theme) => object) => (theme: Theme) =>
  merge(styleOne(theme), styleTwo(theme));

const CLOUD_PROVIDER_LOGO_MAPPINGS: { [key in CloudProvider]: string } = {
  [CloudProvider.AWS]: awsLogo,
  [CloudProvider.GCP]: gcpLogo,
};

const cardSelectStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: "2em",
    width: "100%",
  },
  label: {
    maxWidth: "none",
    "& label": {
      color: theme.palette.primary.contrastText,
    },
  },
  selectOptionCard: {
    color: "black",
    fontWeight: "200",
    maxWidth: "none",
    "& div.MuiGrid-item": {
      backgroundColor: "transparent",
      border: `1px solid ${theme.palette.grey[400]}`,
      padding: "0.1em",
      gap: 0,
      "& h6": {
        fontSize: "0.8em",
        fontWeight: "300",
        margin: "auto",
        textAlign: "center",
        padding: "0 1em",
      },
      "& .is-empty": {
        display: "none",
      },
    },
    "&.selected-card div.MuiGrid-item": {
      borderColor: theme.palette.primary.dark,
    },
  },
  selectedCard: {},
}));

const optionalFreeTextCss = (theme: Theme) => ({
  container: {
    width: "95%",
    "& .MuiFormControl-root": {
      marginTop: "1em",
      marginBottom: "-2em",
    },
  },
});

const userBackgroundDetails = (formStep: FormStep): FormConfigType<UserBackgroundForm>["fields"] => {
  const fields: { [key in keyof UserBackgroundForm]: FieldConfigType } = {
    intentionOfUse: {
      label: "How are you planning to use Marqo?",
      showLabel: true,
      type: "card-select",
      options: enumToOptions(INTENTION_OF_USE),
      styles: cardSelectStyles,
      hidden: formStep !== FormStep.USER_BACKGROUND,
      validation: {
        rules: {},
        validate: Yup.string().test(
          "usage",
          "Please tell us how are you planning to use Marqo",
          (val, form) =>
            formStep !== FormStep.USER_BACKGROUND ||
            enumToOptions(INTENTION_OF_USE).some((option) => option.value === val),
        ),
      },
    },
    useCase: {
      label: "What is your use case?",
      showLabel: true,
      type: "card-select",
      options: enumToOptions(USE_CASE),
      styles: cardSelectStyles,
      hidden: formStep !== FormStep.USER_BACKGROUND,
      validation: {
        rules: {},
        validate: Yup.string().test(
          "reason-sections",
          "Please select your use case",
          (val, form) =>
            formStep !== FormStep.USER_BACKGROUND || enumToOptions(USE_CASE).some((option) => option.value === val),
        ),
      },
      componentProps: {
        onChange: (value: string, formik: FormikProps<any>) => {
          if (value != null && value !== INDUSTRY.OTHER) {
            formik.setFieldValue("otherUseCases", undefined, true);
          }
        },
      },
    },
    otherUseCases: {
      label: "Other",
      type: "text",
      initialValue: "",
      styles: makeStyles(
        combineStyles(optionalFreeTextCss, (theme: Theme) => ({
          container: {
            "& .MuiFormControl-root": {
              marginBottom: "-2em",
            },
          },
        })),
      ),
      hidden: (formValues: UserBackgroundForm) =>
        formValues?.useCase !== USE_CASE.OTHER || formStep !== FormStep.USER_BACKGROUND,
      validation: {
        rules: {},
        validate: Yup.string()
          .max(255)
          .test(
            "other-reasons",
            "Please tell us your use case",
            (val, formValues) =>
              formStep !== FormStep.USER_BACKGROUND ||
              formValues.parent.useCase !== USE_CASE.OTHER ||
              (val && val.length < 255),
          ),
      },
      componentProps: {
        placeholder: "Reason to use Marqo",
      },
    },
    industry: {
      label: "What is your industry?",
      showLabel: true,
      type: "card-select",
      options: enumToOptions(INDUSTRY),
      styles: cardSelectStyles,
      hidden: formStep !== FormStep.USER_BACKGROUND,
      validation: {
        rules: {},
        validate: Yup.string().test(
          "industry-selection",
          "Please select an industry",
          (val, form) =>
            formStep !== FormStep.USER_BACKGROUND || enumToOptions(INDUSTRY).some((option) => option.value === val),
        ),
      },
      componentProps: {
        onChange: (value: string, formik: FormikProps<any>) => {
          if (value != null && value !== INDUSTRY.OTHER) {
            formik.setFieldValue("otherIndustries", undefined, true);
          }
        },
      },
    },
    otherIndustries: {
      label: "Other",
      type: "text",
      initialValue: "",
      hidden: (formValues: UserBackgroundForm) => formValues?.industry !== INDUSTRY.OTHER,
      validation: {
        rules: {},
        validate: Yup.string().test(
          "other-industry",
          "Please type your industry",
          (val, formValues) =>
            formStep !== FormStep.USER_BACKGROUND ||
            formValues.parent.industry !== INDUSTRY.OTHER ||
            (val && val.length < 255),
        ),
      },
      styles: makeStyles(
        combineStyles(optionalFreeTextCss, (theme: Theme) => ({
          container: {
            "& .MuiFormControl-root": {
              marginBottom: "-1em",
            },
          },
        })),
      ),
      componentProps: {
        placeholder: "Industry",
      },
    },
  };

  return fields;
};

const creditCardDetails = (formStep: FormStep): FormConfigType<CreditCardForm>["fields"] => {
  return {
    token: {
      initialValue: "",
      type: "stripe",
      sizes: { xs: 12 },
      hidden: formStep !== FormStep.CC_INFO,
      validation: {
        rules: {},
        validate: Yup.string().matches(/^tok_/),
      },
    },
  };
};

export const cloudProviderDetails = (
  showCloudSelector: boolean,
  stylesOverrides: (theme: Theme) => object = () => ({}),
): { [k in keyof CloudProviderForm]: FieldConfigType } => ({
  cloudProvider: {
    label: "Choose a cloud provider",
    showLabel: true,
    initialValue: CloudProvider.AWS,
    type: "card-select",
    componentProps: {
      cardSizes: {
        xs: 6,
        sm: 6,
        md: 6,
        lg: 6,
        xl: 6,
      },
    },
    styles: makeStyles(
      combineStyles(
        (theme: Theme) => ({
          label: {
            "& label": {
              color: theme.palette.primary.contrastText,
            },
          },
          selectOptionCard: {
            "& .title": {
              display: "none",
            },
            "& .image": {
              width: "50%",
              margin: "auto",
            },
            "& .is-empty": {
              display: "none",
            },
            "& div.MuiGrid-item": {
              backgroundColor: "transparent",
              border: `1px solid ${theme.palette.grey[400]}`,
              padding: "0 0.2em",
            },
            "&.selected-card div.MuiGrid-item": {
              borderColor: theme.palette.primary.contrastText,
            },
          },
        }),
        stylesOverrides,
      ),
    ),
    hidden: !showCloudSelector,
    options: enumToOptions(CloudProvider).map((option) => ({
      ...option,
      imageUrl: CLOUD_PROVIDER_LOGO_MAPPINGS[option.value as CloudProvider],
      imageAltText: `Logo of ${option.value as CloudProvider}`,
    })),
    validation: {
      validate: Yup.string()
        .required()
        .test("cloud-provider", "Please select a cloud provider", (value) =>
          enumToOptions(CloudProvider).some((cloudProvider) => cloudProvider.value === value),
        ),
    },
  },
});

export const signupViaSsoFormConfig = (formStep: FormStep, isCloudSelectorEnabled: boolean): FormConfigType => {
  const showSection = [FormStep.FILL_ALL_SIGNUP_VIA_SSO_FIELDS, FormStep.FILL_ALL_SIGNUP_VIA_EMAIL_FIELDS].includes(
    formStep,
  );

  const showCloudSelector = isCloudSelectorEnabled && showSection;

  return {
    fields: {
      country: {
        label: "Country",
        type: "select",
        initialValue: "US",
        hidden: !showSection,
        options: getCountryOptions(),
        sizes: { xs: 12 },
        validation: {
          rules: {},
          validate: Yup.string()
            .test("selected-option", "Option selected is not valid", (val) => getCountryCodes().includes(val))
            .required("Country is required"),
        },
        componentProps: {
          placeholder: "Country",
          ...renderInputStartAdornment(<PublicRounded fontSize={"small"} />),
        },
      },
      organization: {
        label: "Organization",
        hidden: !showSection,
        sizes: { xs: 12 },
        initialValue: "",
        validation: {
          rules: {},
          validate: Yup.string()
            .max(255)
            .test("required", "Organization is required", (val) => (showSection ? !!val : true)),
        },
        componentProps: {
          placeholder: "Organization",
          autoComplete: "on",
          ...renderInputStartAdornment(<CorporateFareRounded fontSize={"small"} />),
        },
      },
      ...cloudProviderDetails(showCloudSelector),
      acceptTerms: {
        type: "checkbox",
        label: <Typography>I agree to Marqo's {termsAndConditions}</Typography>,
        hidden: !showSection,
        sizes: { xs: 12 },
        initialValue: false,
        validation: {
          rules: {},
          validate: Yup.boolean().oneOf([true], "Accepting terms is required"),
        },
        componentProps: {
          color: "secondary",
        },
      },
      ...userBackgroundDetails(formStep),
      ...creditCardDetails(formStep),
      submit: { hidden: true, initialValue: null },
    },
    formInputMode: "light-zen",
  };
};

export const emailSignupFormConfig = (formStep: FormStep, isCloudSelectorEnabled: boolean): FormConfigType => {
  const enableAuthFields = formStep === FormStep.SELECT_AUTH_METHOD;

  return {
    fields: {
      email: enableAuthFields ? emailFieldConfig() : { hidden: true },
      password: enableAuthFields ? strictPasswordFieldConfig() : { hidden: true },
      confirmPassword: enableAuthFields ? confirmPasswordFieldConfig() : { hidden: true },
      name:
        formStep === FormStep.FILL_ALL_SIGNUP_VIA_EMAIL_FIELDS
          ? {
              label: "Name",
              componentProps: {
                placeholder: "Name",
                ...renderInputStartAdornment(<PersonRounded fontSize={"small"} />),
              },
              sizes: { xs: 12 },
              validation: {
                rules: {},
                validate: Yup.string().max(255).required("Name is required"),
              },
            }
          : { hidden: true },
      ...signupViaSsoFormConfig(formStep, isCloudSelectorEnabled).fields,
    },
    formInputMode: "light-zen",
  };
};

export const confirmSignupFormConfig = (email: string | null): FormConfigType => {
  return {
    fields: {
      email: {
        ...emailFieldConfig(true, true),
        initialValue: !!email ? email : "",
      },
      verificationCode: {
        label: "Verification Code",
        sizes: {
          xs: 12,
        },
        componentProps: {
          placeholder: "Verification Code",
          autoComplete: "current-password",
          ...renderInputStartAdornment(<LockRounded fontSize={"small"} />),
        },
        validation: {
          rules: {
            onTouch: false,
            onBlur: false,
          },
          validate: Yup.string().max(50).required("Verification code is required"),
        },
      },
      submit: {
        hidden: true,
        initialValue: null,
      },
    },
    formInputMode: "light-zen",
  };
};
