export const getFormattingOptions = (fractionDigits = 2) => {
  return {
    style: "decimal",
    minimumFractionDigits: fractionDigits,
    maximumFractionDigits: fractionDigits,
  };
};

export function getFormattedAmount(amount: number | null, fractionDigits = 2) {
  if (amount == null) {
    return `$ 0.${"0".repeat(fractionDigits)}`;
  }

  if (amount < 0) {
    return `- $ ${Math.abs(amount).toLocaleString("en-US", getFormattingOptions(fractionDigits))}`;
  }

  return `$ ${amount.toLocaleString("en-US", getFormattingOptions(fractionDigits))}`;
}
