import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";
import { billingPortalToast } from "../components/toast/billing.toast";
import { setBillingPortalURLIsFetching, setMonthToDateUsageIsFetching } from "../slices/billing/stripe-integration";
import { RootState } from "../store";
import { selectCurrentAccountId } from "../store/selectors";

export const getBillingPortalURLThunk = createAsyncThunk(
  "billing/getBillingPortalURL",
  async (_, { dispatch, getState }) => {
    dispatch(setBillingPortalURLIsFetching(true));
    const accountId = selectCurrentAccountId(getState() as RootState);
    try {
      const promise = API.stripeIntegration.get.billingPortalURL(accountId);
      billingPortalToast(promise);
      const { data } = await promise;
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
);

export const getMonthToDateUsageThunk = createAsyncThunk(
  "billing/getMonthToDateUsage",
  async (_, { dispatch, getState }) => {
    dispatch(setMonthToDateUsageIsFetching(true));
    const accountId = selectCurrentAccountId(getState() as RootState);
    try {
      const { data } = await API.stripeIntegration.get.monthToDateUsage(accountId);
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
);
