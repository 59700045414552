import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { useDashboardInit } from "../../hooks/use-dashboard-init";
import { useSelector } from "../../store";
import { DashboardNavbar } from "../dashboard-navbar/dashboard-navbar.component";
import { DashboardSidebar } from "../dashboard-sidebar/dashboard-sidebar.component";
import { SplashScreen } from "../splash-screen/splash-screen.component";
import { DashboardLayoutRoot } from "./dashboard-layout.styles";

const DashboardLayout: React.FC = (props): JSX.Element => {
  const { children } = props;
  const { isAccountDataSet } = useSelector(({ user }) => user);
  const { withBilling, withIntegrations, withMarqtuneUI } = useSelector(({ user }) => user.data);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { isInitiated } = useDashboardInit();

  return (
    <Fragment>
      <SplashScreen open={!isInitiated || !isAccountDataSet} />

      {isAccountDataSet && (
        <Fragment>
          <DashboardLayoutRoot>
            <Box
              sx={{
                display: "flex",
                flex: "1 1 auto",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {children}
            </Box>
          </DashboardLayoutRoot>
          <DashboardNavbar onOpenSidebar={() => setIsSidebarOpen(true)} />
          <DashboardSidebar
            onClose={() => setIsSidebarOpen(false)}
            withIntegrations={withIntegrations}
            withBilling={withBilling}
            withMarqtuneUI={withMarqtuneUI}
            open={isSidebarOpen}
          />
        </Fragment>
      )}
    </Fragment>
  );
};

DashboardLayout.propTypes = {
  children: PropTypes.node,
};

export default DashboardLayout;
