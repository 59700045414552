import { Card, Grid, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { getFormattedAmount } from "../../../../utils/billing";

type InvoiceSummaryProps = {
  title: string;
  usageCostSummary: number | null;
  subtitles: string;
  isFetching: boolean;
  titleHeight?: number;
  setTitleHeight?: any;
};

const InvoiceSummary: React.FC<InvoiceSummaryProps> = ({
  title,
  usageCostSummary,
  subtitles,
  isFetching,
  titleHeight = 0,
  setTitleHeight = () => {},
}): JSX.Element => {
  // refs
  const titleRef = useRef(null);

  useEffect(() => {
    const element = titleRef.current;

    if (element && title.toLowerCase().includes("forecast")) {
      const observer = new ResizeObserver((entries) => {
        setTitleHeight(entries[0].contentRect.height);
      });

      observer.observe(element);

      return () => {
        observer.unobserve(element);
      };
    }
  }, []);

  return (
    <Grid container item xs={12} md={6} lg={4}>
      <Card
        variant={"outlined"}
        sx={(theme) => ({
          backgroundColor: "background.default",
          pt: 4,
          pb: 3,
          px: 3,
          textAlign: "center",
          width: "100%",
          minHeight: "160px",
          height: "100%",
          [theme.breakpoints.down("sm")]: {
            maxWidth: "100%",
            width: "100%",
          },
        })}
      >
        <Typography
          variant={"h5"}
          color={"textSecondary"}
          ref={titleRef}
          style={titleHeight > 0 ? { height: titleHeight } : {}}
        >
          {title}
        </Typography>
        <Typography variant={"subtitle1"} color={"neutral.400"} fontStyle={"italic"} my={1}>
          {subtitles}
        </Typography>
        <Typography variant={"h5"}>{isFetching ? "-" : getFormattedAmount(usageCostSummary)}</Typography>
      </Card>
    </Grid>
  );
};
export default InvoiceSummary;
