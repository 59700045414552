import { createSlice } from "@reduxjs/toolkit";
import { CreditProps, DiscountProps, StructuredUsageCategoryProps } from "../../api/stripe/stripe.types";
import { hasStripeCardThunk } from "../../thunks/billing.thunk";
import { getBillingPortalURLThunk, getMonthToDateUsageThunk } from "../../thunks/stripe-integration.thunk";

type InitialStripeIntegrationType = {
  billingInfo: {
    hasCard: boolean;
    clientSecret: string | null;
    isFetching: boolean;
  };
  paymentIntent: {
    clientSecret: string;
    isFetching: boolean;
  };
  billingPortal: {
    url: string | null;
    returnURL: string | null;
    isFetching: boolean;
  };
  monthToDateUsage: {
    breakdown: null | StructuredUsageCategoryProps;
    usageCostSummary: null | number;
    rawUsageCostSummary: null | number;
    currency: string;
    discount: DiscountProps;
    credits: CreditProps;
    paymentDueDate: null | number;
    isFetching: boolean;
    currentPeriodStart: number;
    currentPeriodEnd: number;
    forecastedMonthEndCost: number;
    totalDebits: number;
  };
  isSettingDefaultPaymentMethod: boolean;
  newConfigPredictedMonthlyCost: {
    amount: number | null;
    isFetching: boolean;
    errorMsg?: null | string;
  };
};

export const stripeIntegInitialState: InitialStripeIntegrationType = {
  billingInfo: {
    hasCard: null,
    clientSecret: "",
    isFetching: false,
  },
  paymentIntent: {
    clientSecret: null,
    isFetching: false,
  },
  billingPortal: {
    url: null,
    returnURL: null,
    isFetching: false,
  },
  monthToDateUsage: {
    breakdown: null,
    usageCostSummary: null,
    rawUsageCostSummary: null,
    currency: "usd",
    discount: null,
    credits: null,
    paymentDueDate: null,
    currentPeriodStart: null,
    currentPeriodEnd: null,
    isFetching: false,
    forecastedMonthEndCost: null,
    totalDebits: null,
  },
  newConfigPredictedMonthlyCost: {
    amount: null,
    errorMsg: null,
    isFetching: false,
  },
  isSettingDefaultPaymentMethod: null,
};

const stripeIntegrationSlice = createSlice({
  name: "stripeIntegration",
  initialState: stripeIntegInitialState,
  reducers: {
    setIsSettingDefaultPaymentMethod: (state, { payload }) => {
      state.isSettingDefaultPaymentMethod = payload;
    },
    setClientSecret: (state, { payload }) => {
      state.billingInfo.clientSecret = payload;
    },
    setBillingInfoIsFetching: (state, { payload }) => {
      state.billingInfo.isFetching = payload;
    },
    setBillingInfo: (state, { payload }) => {
      state.billingInfo = {
        hasCard: payload.has_card,
        clientSecret: payload?.intent,
        isFetching: payload.isFetching,
      };
    },
    setBillingPortalURLIsFetching: (state, { payload }) => {
      state.billingPortal.isFetching = payload;
    },
    setMonthToDateUsageIsFetching: (state, { payload }) => {
      state.monthToDateUsage.isFetching = payload;
    },
    setTotalDebits: (state, { payload }) => {
      state.monthToDateUsage.totalDebits = payload;
    },
    setNewConfigPredictedMonthlyCost: (state, { payload }) => {
      state.newConfigPredictedMonthlyCost = payload;
    },
    clearBillingPortal: (state, _) => {
      state.billingPortal = {
        url: null,
        returnURL: null,
        isFetching: false,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      // month to date usage
      .addCase(getMonthToDateUsageThunk.fulfilled, (state, { payload }) => {
        const {
          breakdown,
          usage_cost_summary,
          raw_usage_cost_summary,
          total_debits,
          currency,
          discount,
          credits,
          payment_due_date,
          current_period_start,
          current_period_end,
          forecasted_month_end_cost,
        } = payload;

        state.monthToDateUsage = {
          breakdown,
          usageCostSummary: usage_cost_summary,
          rawUsageCostSummary: raw_usage_cost_summary,
          totalDebits: total_debits,
          currency,
          discount,
          credits,
          paymentDueDate: payment_due_date,
          currentPeriodStart: current_period_start,
          currentPeriodEnd: current_period_end,
          isFetching: false,
          forecastedMonthEndCost: forecasted_month_end_cost,
        };
      })
      .addCase(getMonthToDateUsageThunk.rejected, (state, _) => {
        state.monthToDateUsage.isFetching = false;
      })
      // billing portal
      .addCase(getBillingPortalURLThunk.fulfilled, (state, { payload }) => {
        state.billingPortal = {
          url: payload.billing_portal_url,
          returnURL: payload.return_url,
          isFetching: false,
        };
      })
      .addCase(getBillingPortalURLThunk.rejected, (state, _) => {
        state.billingPortal.isFetching = false;
      })
      // billing info
      .addCase(hasStripeCardThunk.fulfilled, (state, { payload }) => {
        state.billingInfo = {
          hasCard: payload.has_card,
          clientSecret: payload?.intent,
          isFetching: false,
        };
      });
  },
});

export const {
  setIsSettingDefaultPaymentMethod,
  setBillingPortalURLIsFetching,
  setMonthToDateUsageIsFetching,
  setBillingInfoIsFetching,
  setNewConfigPredictedMonthlyCost,
  setBillingInfo,
} = stripeIntegrationSlice.actions;

export default stripeIntegrationSlice.reducer;
