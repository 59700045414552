export const V2_RECOMMENDED_UNSTRUCTURED_TEXT_INDEX_DEFAULTS = `{
  "type": "unstructured",
  "treatUrlsAndPointersAsImages": false,
  "treatUrlsAndPointersAsMedia": false,
  "vectorNumericType": "float",
  "model": "hf/e5-base-v2",
  "normalizeEmbeddings": true,
  "textPreprocessing": {
    "splitLength": 2,
    "splitOverlap": 0,
    "splitMethod": "sentence"
  },
  "imagePreprocessing": {
    "patchMethod": null
  },
  "annParameters": {
    "spaceType": "prenormalized-angular",
    "parameters": {
      "efConstruction": 512,
      "m": 16
    }
  },
  "filterStringMaxLength": 50
}`;

export const V2_RECOMMENDED_STRUCTURED_TEXT_INDEX_DEFAULTS = `{
  "type": "structured",
  "vectorNumericType": "float",
  "model": "hf/e5-base-v2",
  "normalizeEmbeddings": true,
  "textPreprocessing": {
    "splitLength": 2,
    "splitOverlap": 0,
    "splitMethod": "sentence"
  },
  "imagePreprocessing": {
    "patchMethod": null
  },
  "annParameters": {
    "spaceType": "prenormalized-angular",
    "parameters": {
      "efConstruction": 512,
      "m": 16
    }
  },
  "tensorFields": [],
  "allFields": []
}`;

export const V2_RECOMMENDED_UNSTRUCTURED_IMAGE_INDEX_DEFAULTS = `{
  "type": "unstructured",
  "treatUrlsAndPointersAsImages": true,
  "treatUrlsAndPointersAsMedia": false,
  "vectorNumericType": "float",
  "model": "open_clip/ViT-B-32/laion2b_s34b_b79k",
  "normalizeEmbeddings": true,
  "textPreprocessing": {
    "splitLength": 2,
    "splitOverlap": 0,
    "splitMethod": "sentence"
  },
  "imagePreprocessing": {
    "patchMethod": null
  },
  "annParameters": {
    "spaceType": "prenormalized-angular",
    "parameters": {
      "efConstruction": 512,
      "m": 16
    }
  },
  "filterStringMaxLength": 50
}`;

export const V2_RECOMMENDED_STRUCTURED_IMAGE_INDEX_DEFAULTS = `{
  "type": "structured",
  "vectorNumericType": "float",
  "model": "open_clip/ViT-L-14/laion2b_s32b_b82k",
  "normalizeEmbeddings": true,
  "textPreprocessing": {
    "splitLength": 2,
    "splitOverlap": 0,
    "splitMethod": "sentence"
  },
  "imagePreprocessing": {
    "patchMethod": null
  },
  "annParameters": {
    "spaceType": "prenormalized-angular",
    "parameters": {
      "efConstruction": 512,
      "m": 16
    }
  },
  "tensorFields": [],
  "allFields": []
}`;

export const V2_RECOMMENDED_UNSTRUCTURED_AUDIO_VIDEO_INDEX_DEFAULTS = `{
  "type": "unstructured",
  "treatUrlsAndPointersAsImages": true,
  "treatUrlsAndPointersAsMedia": true,
  "vectorNumericType": "float",
  "model": "LanguageBind/Video_V1.5_FT_Audio_FT_Image",
  "normalizeEmbeddings": true,
  "textPreprocessing": {
    "splitLength": 2,
    "splitOverlap": 0,
    "splitMethod": "sentence"
  },
  "imagePreprocessing": {
    "patchMethod": null
  },
  "annParameters": {
    "spaceType": "prenormalized-angular",
    "parameters": {
      "efConstruction": 512,
      "m": 16
    }
  },
  "filterStringMaxLength": 50
}`;

export const V2_RECOMMENDED_STRUCTURED_AUDIO_VIDEO_INDEX_DEFAULTS = `{
  "type": "structured",
  "vectorNumericType": "float",
  "model": "LanguageBind/Video_V1.5_FT_Audio_FT",
  "normalizeEmbeddings": true,
  "textPreprocessing": {
    "splitLength": 2,
    "splitOverlap": 0,
    "splitMethod": "sentence"
  },
  "imagePreprocessing": {
    "patchMethod": null
  },
  "annParameters": {
    "spaceType": "prenormalized-angular",
    "parameters": {
      "efConstruction": 512,
      "m": 16
    }
  },
  "tensorFields": [],
  "allFields": []
}`;
