import { TextField } from "@mui/material";
import React from "react";
import { NumericFormat } from "react-number-format";
import { inputClasses } from "./form-fields.styles";

const NumberField: React.FC<any> = (props): JSX.Element => {
  return <NumericFormat customInput={TextField} {...props} />;
};
export default NumberField;

export const ControlNumberField: React.FC<any> = (props): JSX.Element => {
  const classes = inputClasses();
  return <TextField type={"number"} className={classes.stepArrows} {...props} />;
};
