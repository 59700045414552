import { AxiosError } from "axios";
import { isObject } from "lodash";
import { stringHasLink } from "..";
import { IndexWorkflowsError, ModelInfo } from "../../api/indexes/indexes.types";
import { IndexItem } from "../../components/page-parts/indexes/indexes.types";
import {
  AUDIO_VIDEO_COMPATIBLE_MODELS,
  IMAGE_COMPATIBLE_MODELS,
  TEXT_OPTIMISED_MODELS,
  V1_RECOMMENDED_MULTIMODAL_INDEX_DEFAULTS,
  V1_RECOMMENDED_TEXT_INDEX_DEFAULTS,
} from "../../constants/common";
import {
  BASE_DIMENSION,
  FreeMemInGb,
  IndexType,
  IndexWorkflowsInferenceMapping,
  IndexWorkflowsStorageMapping,
  IndexingMode,
  MarqoVersion,
} from "../../constants/enums";
import {
  V2_RECOMMENDED_STRUCTURED_AUDIO_VIDEO_INDEX_DEFAULTS,
  V2_RECOMMENDED_STRUCTURED_IMAGE_INDEX_DEFAULTS,
  V2_RECOMMENDED_STRUCTURED_TEXT_INDEX_DEFAULTS,
  V2_RECOMMENDED_UNSTRUCTURED_AUDIO_VIDEO_INDEX_DEFAULTS,
  V2_RECOMMENDED_UNSTRUCTURED_IMAGE_INDEX_DEFAULTS,
  V2_RECOMMENDED_UNSTRUCTURED_TEXT_INDEX_DEFAULTS,
} from "../../constants/index-settings";
import { CreateIndexFormProps } from "../../form-configs/indexes/types";
import { InferencePrices, StoragePrices } from "../../slices/billing/pricing";
import { prettifyJSON } from "../formatters";

function getRecommendedIndexSettings(formikValues: CreateIndexFormProps) {
  const isV2 = isV2FormIndex(formikValues);
  if (isV2) {
    if (formikValues.indexingMode === IndexingMode.TEXT) {
      return formikValues?.indexType === IndexType.UNSTRUCTURED
        ? V2_RECOMMENDED_UNSTRUCTURED_TEXT_INDEX_DEFAULTS
        : V2_RECOMMENDED_STRUCTURED_TEXT_INDEX_DEFAULTS;
    } else if (formikValues.indexingMode === IndexingMode.AUDIO_VIDEO) {
      return formikValues?.indexType === IndexType.UNSTRUCTURED
        ? V2_RECOMMENDED_UNSTRUCTURED_AUDIO_VIDEO_INDEX_DEFAULTS
        : V2_RECOMMENDED_STRUCTURED_AUDIO_VIDEO_INDEX_DEFAULTS;
    } else {
      return formikValues?.indexType === IndexType.UNSTRUCTURED
        ? V2_RECOMMENDED_UNSTRUCTURED_IMAGE_INDEX_DEFAULTS
        : V2_RECOMMENDED_STRUCTURED_IMAGE_INDEX_DEFAULTS;
    }
  }

  return formikValues?.indexingMode === IndexingMode.TEXT
    ? V1_RECOMMENDED_TEXT_INDEX_DEFAULTS
    : V1_RECOMMENDED_MULTIMODAL_INDEX_DEFAULTS;
}

function getIndexingMode(formikValues: CreateIndexFormProps): IndexingMode {
  const indexDefs = getParsedIndexDefaults(formikValues);

  if (isV2FormIndex(formikValues) && isStructured(formikValues)) {
    return formikValues.indexingMode as IndexingMode;
  }

  const treat: boolean | undefined =
    indexDefs[getTreatUrlsAsImagesParam(formikValues)] || indexDefs["treatUrlsAndPointersAsMedia"];
  if (treat === undefined || typeof treat !== "boolean" || !!treat) {
    return formikValues.indexingMode as IndexingMode;
  }

  return IndexingMode.TEXT;
}

function getParsedIndexDefaults(formikValues: CreateIndexFormProps): any {
  try {
    const parsed = JSON.parse(`${formikValues.indexDefaults}`);
    return ["", undefined, null].includes(parsed) ? {} : parsed;
  } catch (e) {
    return {};
  }
}

function getIndexModel(formikValues: CreateIndexFormProps): string {
  const indexDefs = getParsedIndexDefaults(formikValues);
  const recommendedSettings = JSON.parse(getRecommendedIndexSettings(formikValues));
  return !!indexDefs["model"] ? indexDefs.model : recommendedSettings["model"];
}

function getModelPropsParam(formikValues: CreateIndexFormProps) {
  return isV2FormIndex(formikValues) ? "modelProperties" : "model_properties";
}

function isStructured(formikValues: CreateIndexFormProps): boolean {
  return formikValues.indexType === IndexType.STRUCTURED;
}

function isMultimodal(formikValues: CreateIndexFormProps): boolean {
  try {
    const indexDefs = JSON.parse(`${formikValues.indexDefaults}`);
    if (isV2FormIndex(formikValues)) {
      if (isStructured(formikValues)) {
        return (formikValues.indexingMode as IndexingMode) === IndexingMode.IMAGE;
      }

      return !!(indexDefs["treatUrlsAndPointersAsImages"] || indexDefs["treatUrlsAndPointersAsMedia"]);
    }
    return !!(indexDefs["treat_urls_and_pointers_as_images"] || indexDefs["treat_urls_and_pointers_as_media"]);
  } catch (e) {
    return (formikValues.indexingMode as IndexingMode) === IndexingMode.IMAGE;
  }
}

function getIndexObjIndexingMode(indexObj: IndexItem): IndexingMode {
  if (isV2Index(indexObj) && indexObj.treatUrlsAndPointersAsMedia) {
    return IndexingMode.AUDIO_VIDEO;
  }
  return isIndexObjMultimodal(indexObj) ? IndexingMode.IMAGE : IndexingMode.TEXT;
}

function isIndexObjMultimodal(indexObj: IndexItem): boolean {
  try {
    if (isV2Index(indexObj)) {
      return "treatUrlsAndPointersAsImages" in indexObj && indexObj["treatUrlsAndPointersAsImages"];
    }
    return (
      "index_defaults" in indexObj &&
      "treat_urls_and_pointers_as_images" in indexObj.index_defaults &&
      indexObj.index_defaults.treat_urls_and_pointers_as_images
    );
  } catch (e) {
    return indexObj?.treatUrlsAndPointersAsImages === true;
  }
}

function getTreatUrlsAsImagesParam(formikValues: CreateIndexFormProps): string {
  return isV2FormIndex(formikValues) ? "treatUrlsAndPointersAsImages" : "treat_urls_and_pointers_as_images";
}

function isV2FormIndex(formikValues: CreateIndexFormProps) {
  return (formikValues.marqoVersion as MarqoVersion) === MarqoVersion.V2;
}

function isV2Index(indexObj: IndexItem) {
  return !!indexObj?.marqoVersion && /^2/.test(indexObj.marqoVersion);
}

function computeFormIndexDefaults(formikValues: CreateIndexFormProps) {
  const parsedRecommended = JSON.parse(getRecommendedIndexSettings(formikValues));
  let indexDefs;

  try {
    indexDefs = getParsedIndexDefaults(formikValues);
    indexDefs = Object.keys(indexDefs).length === 0 ? parsedRecommended : indexDefs;
  } catch (e) {
    indexDefs = parsedRecommended;
  }
  return indexDefs;
}

function getModelList(indexingMode: IndexingMode) {
  if (indexingMode === IndexingMode.IMAGE) {
    return IMAGE_COMPATIBLE_MODELS;
  } else if (indexingMode === IndexingMode.AUDIO_VIDEO) {
    return AUDIO_VIDEO_COMPATIBLE_MODELS;
  } else {
    return TEXT_OPTIMISED_MODELS;
  }
}

/**
 * Returns the hourly cost of an index's inference nodes.
 */
function getInferenceCost(formikValues: { [field: string]: any }, inferencePrices: InferencePrices): number {
  const numInference: number = Number(formikValues["numberOfInferencePods"]);
  const inferenceUnitType: string = formikValues["inferencePodType"] as string;

  const matchingInferencePrice: number = inferencePrices[inferenceUnitType as IndexWorkflowsInferenceMapping] || 0;
  return numInference * matchingInferencePrice;
}

/**
 * Returns the hourly cost of an index's storage nodes.
 */
function getStorageCost(formikValues: { [field: string]: any }, storagePrices: StoragePrices): number {
  const numShards: number = Number(formikValues["numberOfShards"]);
  const numReplicas: number = Number(formikValues["numberOfReplicas"]);
  const storageUnitType: string = formikValues["storageShardType"];
  const matchingStoragePrice: number = storagePrices[storageUnitType as IndexWorkflowsStorageMapping] || 0;

  return numShards * (numReplicas + 1) * matchingStoragePrice;
}

/**
 * Returns the hourly cost of an index.
 */
function getComputedIndexCost(
  formikValues: { [field: string]: any },
  inferencePrices: InferencePrices,
  storagePrices: StoragePrices,
): number {
  const inferenceSubCost = getInferenceCost(formikValues, inferencePrices);
  const storageSubCost = getStorageCost(formikValues, storagePrices);
  return inferenceSubCost + storageSubCost;
}

function stringifyIndexDefaults(indexDefaults: any) {
  return prettifyJSON(JSON.stringify(indexDefaults));
}

function hasIndexFormErrors(formik: any) {
  if (formik.touched?.indexName) {
    return Object.values(formik["errors"]).length > 0;
  } else {
    const _formikErrors = { ...formik.errors };
    delete _formikErrors["indexName"];

    if (!!_formikErrors?.numberOfShards && _formikErrors.numberOfShards.includes("or equal to null")) {
      delete _formikErrors["numberOfShards"];
    }

    if (!!_formikErrors?.numberOfInferencePods && _formikErrors.numberOfInferencePods.includes("or equal to null")) {
      delete _formikErrors["numberOfInferencePods"];
    }

    return Object.values(_formikErrors).length > 0;
  }
}

function hasIndexModifyFormErrors(formik: any, indexName: string) {
  let _formikErrors = { ...formik.errors };
  delete _formikErrors["indexName"];

  if (indexName && !!_formikErrors?.indexDefaults) {
    delete _formikErrors["indexDefaults"];
  }

  return Object.values(_formikErrors).length > 0;
}

function showModifyFormattedCost(
  formik: any,
  indexId: string,
  isFetchingPrices: boolean,
  fetchingError: string,
  hasNullPrices: boolean,
  showMailToSupport: boolean,
  formattedCost: null | string,
) {
  return (
    !IndexSettingsUtils.hasModifyErrors(formik, indexId) &&
    !(isFetchingPrices || fetchingError || hasNullPrices) &&
    !(showMailToSupport || formattedCost.includes("NaN"))
  );
}

function showFallbackDashOnModifyCost(
  formik: any,
  indexId: string,
  isFetchingPrices: boolean,
  fetchingError: string,
  hasNullPrices: boolean,
  showMailToSupport: boolean,
  formattedCost: null | string,
) {
  return (
    IndexSettingsUtils.hasModifyErrors(formik, indexId) ||
    isFetchingPrices ||
    fetchingError ||
    hasNullPrices ||
    showMailToSupport ||
    formattedCost.includes("NaN")
  );
}

function getAnnParametersAndVectorType(indexDefaults: string) {
  // Default values
  const defaultVectorNumericType = 32;
  const defaultAnnParametersM = 16;

  let defaults;
  try {
    defaults = JSON.parse(indexDefaults);
  } catch (error) {
    return {
      vectorNumericType: defaultVectorNumericType,
      annParametersM: defaultAnnParametersM,
    };
  }

  const vectorNumericType = defaults.vectorNumericType === "bfloat16" ? 16 : 32 || defaultVectorNumericType;
  const annParametersM = defaults.annParameters?.parameters?.m || defaultAnnParametersM;

  return {
    vectorNumericType,
    annParametersM,
  };
}

function floorToNearest100000(value: number): number {
  return Math.floor(value / 100000) * 100000;
}

function computeVectors(storageType: string, numShards: number, dimension: number, indexDefaults: string) {
  const { vectorNumericType, annParametersM } = getAnnParametersAndVectorType(indexDefaults);
  const floatSizeBits = vectorNumericType;
  const hnswSizeBits = 1.1 * (floatSizeBits * dimension + 64 * annParametersM) * 1000000;
  // this is the size of the HNSW index in GB per 1 million vectors
  const hnswSizeGb = hnswSizeBits / 8 / 1024 / 1024 / 1024;
  const baseVectors = (FreeMemInGb[storageType as keyof typeof FreeMemInGb] / hnswSizeGb) * 1000000;
  const roundedBaseVectors = floorToNearest100000(baseVectors);
  return roundedBaseVectors * numShards;
}

function getIndexSettingsModel(indexObj: IndexItem) {
  if (isV2Index(indexObj)) {
    return indexObj?.model || "-";
  }
  return !!indexObj?.index_defaults ? indexObj.index_defaults?.model || "-" : "-";
}

const getModelDimension = (modelList: ModelInfo[], modelName: string) => {
  return modelList.find((model) => model.name === modelName)?.model_dimension ?? BASE_DIMENSION;
};

function getIndexType(indexObj: IndexItem) {
  if (isV2Index(indexObj)) {
    return indexObj?.type ? indexObj.type[0].toUpperCase() + indexObj.type.slice(1) : "-";
  }
  return "-";
}

function getIndexErrorMessage(e: AxiosError<IndexWorkflowsError, any>): string | undefined {
  const err = e.response?.data;
  let errMsg = err?.error_description?.toString() || err?.error || err?.message || "Invalid request.";
  if (isObject(err) && err.link && !stringHasLink(errMsg)) {
    errMsg += `\nRead more in the documentation here: ${err.link}.`;
  }
  return errMsg;
}

const IndexSettingsUtils = {
  computeIndexCost: getComputedIndexCost,
  inferenceCost: getInferenceCost,
  getRecommendedIndexSettings,
  getIndexingMode,
  getIndexModel,
  getModelPropsParam,
  getModelList,
  getTreatUrlsAsImagesParam,
  isMultimodal,
  isV2Index,
  isV2FormIndex,
  isIndexObjMultimodal,
  getIndexObjIndexingMode,
  getParsedIndexDefaults,
  computeFormIndexDefaults,
  stringifyIndexDefaults,
  hasErrors: hasIndexFormErrors,
  hasModifyErrors: hasIndexModifyFormErrors,
  computeVectors,
  showModifyFormattedCost,
  showFallbackDashOnModifyCost,
  getIndexSettingsModel,
  getModelDimension,
  getIndexType,
  getIndexErrorMessage,
};
export default IndexSettingsUtils;
