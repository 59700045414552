import { InfoRounded } from "@mui/icons-material";
import { CircularProgress, Grid, Tooltip } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import CardTitle from "../../components/card-title/card-title.component";
import ContentLayout from "../../components/content-layout/content-layout.component";
import ContentSection from "../../components/content-layout/content-section.component";
import { getFormConfig } from "../../components/form/form.component";
import { StandardForm } from "../../components/form/standard-form.component";
import {
  DEFAULT_DATADOG_SITE,
  IntegrationForm,
  datadogIntegrationFormConfig,
  ddFormTooltip,
} from "../../form-configs/integrations.forms";
import { useDispatch, useSelector } from "../../store";
import { getIntegrationsThunk, setDatadogAPIKeyThunk } from "../../thunks/integrations.thunk";

const IntegrationsPage = () => {
  const dispatch = useDispatch();
  const { isFetching, datadog } = useSelector(({ integrations }) => integrations);
  const { isGuest } = useSelector(({ user }) => user.data);
  const { key: datadogKey, site: datadogSite } = datadog;
  const [disableApiKey, setDisableApiKey] = useState(true);

  const formik = useFormik({
    ...getFormConfig<IntegrationForm>(datadogIntegrationFormConfig(datadogKey, disableApiKey)),
    onSubmit: async ({ apiKey, datadogSite }, _) => {
      await dispatch(
        setDatadogAPIKeyThunk({
          formData: {
            datadog_api_key: !!apiKey ? apiKey.trim() : "",
            datadog_site: !!datadogSite ? datadogSite.trim() : "",
          },
          onSuccess: () => {
            setDisableApiKey(true);
            dispatch(getIntegrationsThunk());
          },
        }),
      );
    },
  });

  useEffect(() => {
    const newInitialValues = {
      ...formik.initialValues,
      ...formik.values,
    };

    if (datadogKey !== formik.initialValues.apiKey) {
      newInitialValues.apiKey = datadogKey;
    }
    if (datadogSite !== formik.initialValues.datadogSite && !!datadogSite) {
      newInitialValues.datadogSite = datadogSite;
    }
    formik.setValues(newInitialValues);
  }, [datadogKey, datadogSite]);

  useEffect(() => {
    dispatch(getIntegrationsThunk());
  }, []);

  return (
    <ContentLayout pageTitle="Integrations">
      <ContentSection id={"DatadogIntegration-Section"}>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid container item flexDirection={"row"} sm={8} gap={1} alignItems={"center"} mb={1}>
            <Grid item>
              <CardTitle title={"Datadog Integration"} noDivider rootProps={{ justifyContent: "center" }} />
            </Grid>
            <Grid item sx={{ display: "flex", alignSelf: "center" }}>
              <Tooltip title={ddFormTooltip}>
                <InfoRounded sx={{ color: "neutral.400" }} />
              </Tooltip>
            </Grid>
          </Grid>

          <Grid container item xs={12}>
            {isFetching && (
              <Grid container justifyContent={"center"}>
                <CircularProgress />
              </Grid>
            )}
            {!isFetching && (
              <StandardForm
                checkIfShallow={true}
                formik={formik}
                formConfig={datadogIntegrationFormConfig(datadogKey, disableApiKey)}
                promptTitle={""}
                enableActionsViaEditBtn={true}
                onToggleEditCallback={() => setDisableApiKey(false)}
                editBtnDisabled={isGuest}
                onCancelCallback={async () => {
                  await formik.setFieldValue("apiKey", datadogKey);
                  await formik.setFieldValue("datadogSite", !!datadogSite ? datadogSite : DEFAULT_DATADOG_SITE);
                  await formik.setErrors({});
                  setDisableApiKey(true);
                }}
              />
            )}
          </Grid>
        </Grid>
      </ContentSection>
    </ContentLayout>
  );
};

export default IntegrationsPage;
