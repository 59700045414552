import { Box, Grid, Tab, Tabs } from "@mui/material";
import { Fragment, useState } from "react";
import IndexSettingsUtils from "../../../../utils/indices/index-creation-utils";
import IndexDetailsUtils from "../../../../utils/indices/index-details-utils";
import CodeSnippetBlock from "../../../code-snippet-block/code-snippet-block.component";
import CodeSnippetsWrapper from "../../../code-snippets-wrapper/code-snippets-wrapper.component";
import { CodeSnippetsWrapperStyles } from "../../../code-snippets-wrapper/code-snippets-wrapper.styles";
import TabPanel, { tabPanelControlProps } from "../../../tab-panel/tab-panel.component";
import { IndexItem } from "../indexes.types";

type Props = {
  indexObj: IndexItem;
  indexName: string;
};

const IndexDetailsTabs = ({ indexObj, indexName }: Props) => {
  const [value, setValue] = useState(0);
  const mode = IndexSettingsUtils.getIndexObjIndexingMode(indexObj);
  const classes = CodeSnippetsWrapperStyles();
  const idPrefix = "index-details";

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Fragment>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={value} onChange={handleChange} aria-label="index-details-tabs">
          <Tab sx={{ fontSize: "0.8rem" }} label="QUICK START" {...tabPanelControlProps(0, idPrefix)} />
          {IndexSettingsUtils.isV2Index(indexObj) && (
            <Tab sx={{ fontSize: "0.8rem" }} label="INDEX SETTINGS" {...tabPanelControlProps(1, idPrefix)} />
          )}
        </Tabs>
      </Box>

      <TabPanel value={value} index={0} idPrefix={idPrefix}>
        <CodeSnippetsWrapper
          version={indexObj?.marqoVersion}
          endpoint={indexObj.marqoEndpoint}
          indexName={indexName}
          mode={mode}
        />
      </TabPanel>

      <TabPanel value={value} index={1} idPrefix={idPrefix}>
        <Grid
          container
          item
          className={classes.codeSnippetsWrapper}
          sx={{
            backgroundColor: "neutral.800",
            borderRadius: "10px",
          }}
        >
          <Grid container item justifyContent={"center"} px={0} mb={4}>
            <CodeSnippetBlock
              snippet={IndexDetailsUtils.getIndexSettings(indexObj)}
              language={"bash"}
              terminal={"JSON"}
              dataTestIdPrefix={"index-details-settings"}
            />
          </Grid>
        </Grid>
      </TabPanel>
    </Fragment>
  );
};

export default IndexDetailsTabs;
