import { Dialog } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  ".MuiDialog-paper": {
    backgroundColor: "theme.palette.background.authCard", //theme.palette.error.dark,
    minWidth: "20vw",

    ".MuiDialogContentText-root": {
      color: theme.palette.secondary.contrastText,
    },
  },
}));
