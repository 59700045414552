import { Button, Theme, alpha } from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { getNeutralRgb } from "../../theme/dark-theme-options";

type Props = {
  actiontype?: "error" | "info" | "success";
};

export const StyledActionButton = styled(Button)<Props>(({ theme, actiontype }) => ({
  color: (actiontype && theme.palette[actiontype]?.main) || theme.palette.text.primary,
  background: "transparent !important",
  padding: 0,
  width: "auto",
  display: "contents",
}));

export const StyledAddButton = styled(Button)(({ theme }) => ({
  "&:hover": {
    color: theme.palette.text.primary,
  },
}));

export const buttonClasses = makeStyles((theme: Theme) => ({
  authBtn: {
    height: "3.6rem",
    maxHeight: "100%",
    transition:
      "background-position 10s cubic-bezier(0.4, 0, 0.2, 1), background-position 10s cubic-bezier(0.4, 0, 0.2, 1)",
    backgroundSize: "200% 100%",
    backgroundPosition: "100% 0%",
    "&:hover": {
      borderRadius: "0.4375rem",
      backgroundPosition: "0% 0%",
      background: theme.palette.secondary.main,
      // background: "linear-gradient(91deg, #513EE9 5.63%, #009CAD 39.79%, #0FA 79.63%, rgba(0, 255, 170, 0.27) 97.9%, rgba(81, 62, 233, 0.00) 105.1%)",
    },
    "&.MuiButton-disableElevation.Mui-disabled": {
      color: alpha(getNeutralRgb("900"), 0.3),
      background: getNeutralRgb("200"),
    },
  },
  socialProviderBtn: {
    backgroundColor: getNeutralRgb("100"),
    border: `1px solid ${getNeutralRgb("300")}`,
    gap: 4,
    padding: "1.5em 0",
    width: "100%",
    "&:hover": {
      border: "none",
      boxShadow: `0 0 10px 1px ${getNeutralRgb("400")}`,
      background: "transparent",
    },
  },
  googleBtn: {
    "& img": {
      width: "2.5em",
      height: "2.5em",
    },
  },
  githubBtn: {
    "& svg": {
      fill: "black",
      fontSize: "2.5em",
    },
  },
  smallButton: {
    height: "2em",
  },
  iconTextButton: {
    gap: "10px",
  },
  leanTextButton: {
    background: "transparent",
    padding: 0,

    "&:hover": {
      color: theme.palette.primary.main,
      background: "transparent",
      padding: 0,
    },
  },
  dialogActionButton: {
    height: "2.5em",
  },
  dangerButton: {
    "&:hover": {
      background: theme.palette.error.main,
    },
  },
  deleteButton: {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.text.primary,

    "&.Mui-disabled": {
      backgroundColor: alpha(theme.palette.error.main, 0.2),
      color: alpha(theme.palette.text.primary, 0.5),
    },
  },
  noButton: {
    "&:hover": {
      outline: `1px solid ${theme.palette.text.primary}`,
      outlineOffset: "-1px",
    },
  },
  submitButton: {
    backgroundColor: `${theme.palette.secondary.main}`,
    color: `${theme.palette.text.primary}`,

    "&.MuiButton-root.untouched": {
      backgroundColor: `${theme.palette.secondary.main}`,
      color: `${theme.palette.text.primary}`,
    },
    "&:hover:not(.touched), .untouched:hover": {
      backgroundColor: `${theme.palette.secondary.main}`,
      color: `${theme.palette.secondary.contrastText}`,
    },
    "&.MuiButton-disableElevation.Mui-disabled:not(.untouched), &.MuiButton-disableElevation.Mui-disabled.touched": {
      color: alpha(getNeutralRgb("900"), 0.3),
      background: getNeutralRgb("200"),
    },
  },
  backButton: {
    color: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.primary.contrastText}`,

    "&:hover": {
      backgroundColor: `${theme.palette.error.main} !important`,
      color: `${theme.palette.error.contrastText} !important`,
      border: `1px solid ${theme.palette.error.main}`,
    },
  },
  skipButton: {
    color: theme.palette.primary.contrastText,
    border: `1px solid ${theme.palette.primary.light}`,

    "&:hover": {
      backgroundColor: `${theme.palette.secondary.main} !important`,
      color: `${theme.palette.error.contrastText} !important`,
      border: `1px solid ${theme.palette.secondary.main}`,
    },
  },
}));
