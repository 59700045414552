import { Grid, MenuItem, Select } from "@mui/material";
import { FormikProps } from "formik";
import React, { useState } from "react";
import { prettifyJSON } from "../../../utils/formatters";
import IndexSettingsUtils from "../../../utils/indices/index-creation-utils";
import { SelectStyles } from "../../form-fields/form-fields.styles";

type Props = {
  name: string;
  formik: FormikProps<any>;
};

const IndexDefaultsActionBarComponent: React.FC<Props> = ({ formik, name }) => {
  const [selectedHelperUtil, setSelectedHelperUtil] = useState("Format JSON");
  const classes = SelectStyles();

  async function formatJsonAction() {
    const prettifiedValue = prettifyJSON((formik.values[name] as string) || null);

    if (prettifiedValue === "") {
      await formik.setFieldTouched(name, true);
      await formik.setFieldError(name, "Invalid json object.");
    } else {
      await formik.setFieldValue(name, prettifiedValue, true);
    }
  }

  async function useRecoAction() {
    const recommended = IndexSettingsUtils.getRecommendedIndexSettings(formik.values);
    await formik.setFieldValue(name, recommended, true);
    await formik.setFieldTouched(name, true);
    await formik.setFieldValue("model", JSON.parse(recommended)["model"], true);
  }

  const helperUtils = [
    { value: "format_json", label: "Format JSON", action: formatJsonAction },
    { value: "use_recommended_settings", label: "Use recommended settings", action: useRecoAction },
  ];

  async function handleAction(value: string) {
    const util = helperUtils.find((item) => item.label === value);
    if (util !== undefined) {
      util.action();
    }
    setSelectedHelperUtil(value);
  }

  return (
    <Grid item>
      <Select
        id="index-defs-action-select"
        value={selectedHelperUtil}
        className={classes.select}
        renderValue={(selected) => selected}
      >
        {helperUtils.map(({ value, label }) => (
          <MenuItem onClick={() => handleAction(label)} key={value} value={label}>
            {label}
          </MenuItem>
        ))}
      </Select>
    </Grid>
  );
};

export default IndexDefaultsActionBarComponent;
