import { combineReducers } from "@reduxjs/toolkit";
import metricsFull from "./full-index-metrics";
import common from "./indexes-main";
import metricsCommon from "./metrics";

const IndexesReducer = combineReducers({
  common,
  metricsCommon,
  metricsFull,
});

export default IndexesReducer;
