import { RESTRICTED_INDEX_ACTIONS_MSG } from "../../constants";
import { INDEX_CONFIG_LIMITS } from "../../constants/common";
import { IndexStatus } from "../../constants/enums";
import { Limits } from "../../form-configs/types";

export type ChipColor = "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning";
export type SvgColor =
  | "primary"
  | "secondary"
  | "error"
  | "info"
  | "success"
  | "warning"
  | "disabled"
  | "inherit"
  | "action";

export type AccountLimits = {
  inference: Limits;
  shard: Limits;
  replica: Limits;
  indexes: Limits;
  indexNameLength: Limits;
};

export function getIndexStatusColorAndOpacity(indexStatus: string): [ChipColor | SvgColor, string] {
  const status = indexStatus as keyof typeof IndexStatus;

  if (IndexStatus[status] === IndexStatus.READY) {
    return ["success", "1"];
  } else if (
    [
      IndexStatus.CREATING,
      IndexStatus.CREATE_SCHEDULED,
      IndexStatus.MODIFY_SCHEDULED,
      IndexStatus.MODIFYING,
      IndexStatus.DELETE_SCHEDULED,
      IndexStatus.DELETING,
    ].includes(IndexStatus[status])
  ) {
    return ["info", "0.4"];
  } else {
    return ["default", "1"];
  }
}

export function getIndexStatusLabel(indexStatus: string) {
  const status = indexStatus as keyof typeof IndexStatus;

  if ([IndexStatus.MODIFY_SCHEDULED, IndexStatus.MODIFYING].includes(IndexStatus[status])) {
    return "Modifying";
  } else if ([IndexStatus.CREATE_SCHEDULED, IndexStatus.CREATING].includes(IndexStatus[status])) {
    return "Creating";
  } else if ([IndexStatus.DELETE_SCHEDULED, IndexStatus.DELETING].includes(IndexStatus[status])) {
    return "Deleting";
  } else {
    return IndexStatus[status];
  }
}

export const getTooltipMsg = (rowStatus: string, errorMsg: string | null) => {
  if (getIndexStatusLabel(rowStatus) !== IndexStatus.FAILED) {
    return RESTRICTED_INDEX_ACTIONS_MSG;
  } else if (!!errorMsg) {
    return errorMsg;
  }

  return null;
};

export const getAccountIndexLimits = (
  maxInference: number,
  maxReplica: number,
  maxShards: number,
  maxIndexes: number,
): AccountLimits => {
  return {
    inference: {
      min: INDEX_CONFIG_LIMITS.inference.min,
      max: maxInference,
    },
    replica: {
      min: INDEX_CONFIG_LIMITS.replica.min,
      max: maxReplica,
    },
    shard: {
      min: INDEX_CONFIG_LIMITS.shards.min,
      max: maxShards,
    },
    indexes: {
      min: 0,
      max: maxIndexes,
    },
    indexNameLength: { ...INDEX_CONFIG_LIMITS.nameLength },
  };
};
