import { DeleteForever } from "@mui/icons-material";
import { ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { Fragment, useState } from "react";
import { useDispatch } from "../../../store";
import {
  deleteMarqtuneDatasetThunk,
  deleteMarqtuneEvaluationThunk,
  deleteMarqtuneModelThunk,
} from "../../../thunks/marqtune.thunk";
import ConfirmPrompt from "../../dialogs/confirm-prompt.component";

type Props = {
  resourceName: string;
  resourceId: string;
  actionsMenuAnchor: HTMLElement | null;
  agreeHandler: () => void;
  disagreeHandler: () => void;
};

export const MarqtuneDatasetActionPrompt = ({
  actionsMenuAnchor,
  resourceName,
  resourceId,
  agreeHandler,
  disagreeHandler,
}: Props) => {
  const dispatch = useDispatch();
  const [openConfirmPrompt, setOpenConfirmPrompt] = useState(false);

  const onAgree = () => {
    dispatch(deleteMarqtuneDatasetThunk(resourceId));
    setOpenConfirmPrompt(false);
    agreeHandler();
  };

  const onDisagree = () => {
    setOpenConfirmPrompt(false);
    disagreeHandler();
  };

  const handleDeleteModel = () => {
    setOpenConfirmPrompt(true);
  };

  return (
    <Fragment>
      <Menu open={Boolean(actionsMenuAnchor)} anchorEl={actionsMenuAnchor} onClose={disagreeHandler}>
        <MenuItem onClick={handleDeleteModel}>
          <ListItemIcon>
            <DeleteForever fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Delete</Typography>
        </MenuItem>
      </Menu>

      <ConfirmPrompt
        title={"Delete Dataset"}
        open={openConfirmPrompt}
        content={
          <Fragment>
            Type <span style={{ color: "neutral.100", fontWeight: "bold" }}>{resourceName}</span> below to delete your
            dataset.
          </Fragment>
        }
        agreeHandler={onAgree}
        agreeButtonLabel={"Delete"}
        resourceName={resourceName}
        disagreeHandler={onDisagree}
      />
    </Fragment>
  );
};

export const MarqtuneModelActionPrompt = ({
  actionsMenuAnchor,
  resourceName,
  resourceId,
  agreeHandler,
  disagreeHandler,
}: Props) => {
  const dispatch = useDispatch();
  const [openConfirmPrompt, setOpenConfirmPrompt] = useState(false);

  const onAgree = () => {
    dispatch(deleteMarqtuneModelThunk(resourceId));
    setOpenConfirmPrompt(false);
    agreeHandler();
  };

  const onDisagree = () => {
    setOpenConfirmPrompt(false);
    disagreeHandler();
  };

  const handleDeleteModel = () => {
    setOpenConfirmPrompt(true);
  };

  return (
    <Fragment>
      <Menu open={Boolean(actionsMenuAnchor)} anchorEl={actionsMenuAnchor} onClose={disagreeHandler}>
        <MenuItem onClick={handleDeleteModel}>
          <ListItemIcon>
            <DeleteForever fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Delete</Typography>
        </MenuItem>
      </Menu>

      <ConfirmPrompt
        title={"Delete Model"}
        open={openConfirmPrompt}
        content={
          <Fragment>
            Type <span style={{ color: "neutral.100", fontWeight: "bold" }}>{resourceName}</span> below to delete your
            Marqtune Model and its checkpoints. This action cannot be undone.
            <br />
            <br />
            <Typography variant="body1" color="textSecondary" gutterBottom>
              <em>Note:</em> Deleting the model will not affect any Marqo index created with it.
            </Typography>
          </Fragment>
        }
        agreeHandler={onAgree}
        agreeButtonLabel={"Delete"}
        resourceName={resourceName}
        disagreeHandler={onDisagree}
      />
    </Fragment>
  );
};

export const MarqtuneEvaluationActionPrompt = ({
  actionsMenuAnchor,
  resourceName,
  resourceId,
  agreeHandler,
  disagreeHandler,
}: Props) => {
  const dispatch = useDispatch();
  const [openConfirmPrompt, setOpenConfirmPrompt] = useState(false);

  const onAgree = () => {
    dispatch(deleteMarqtuneEvaluationThunk(resourceId));
    setOpenConfirmPrompt(false);
    agreeHandler();
  };

  const onDisagree = () => {
    setOpenConfirmPrompt(false);
    disagreeHandler();
  };

  const handleDeleteModel = () => {
    setOpenConfirmPrompt(true);
  };

  return (
    <Fragment>
      <Menu open={Boolean(actionsMenuAnchor)} anchorEl={actionsMenuAnchor} onClose={disagreeHandler}>
        <MenuItem onClick={handleDeleteModel}>
          <ListItemIcon>
            <DeleteForever fontSize="small" />
          </ListItemIcon>
          <Typography variant="inherit">Delete</Typography>
        </MenuItem>
      </Menu>

      <ConfirmPrompt
        title={"Delete Evaluation"}
        open={openConfirmPrompt}
        content={
          <Fragment>
            Type <span style={{ color: "neutral.100", fontWeight: "bold" }}>{resourceName}</span> below to delete your
            Marqtune evaluation. This action cannot be undone.
          </Fragment>
        }
        agreeHandler={onAgree}
        agreeButtonLabel={"Delete"}
        resourceName={resourceName}
        disagreeHandler={onDisagree}
      />
    </Fragment>
  );
};
