import { Box } from "@mui/material";
import { useFormik } from "formik";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import AuthBase from "../../../components/authentication/auth-base.component";
import { AuthBaseClasses } from "../../../components/authentication/auth.styles";
import { AuthButton } from "../../../components/button/auth-button.component";
import Form, { getFormConfig } from "../../../components/form/form.component";
import { AlreadyHaveAnAccountQuickLink } from "../../../components/page-parts/quick-links/quick-links";
import config from "../../../config";
import { resetPasswordFormConfig } from "../../../form-configs/auth/password.forms";
import { useAuth } from "../../../hooks/use-auth";
import { useDispatch } from "../../../store";

const PasswordReset = () => {
  const { resetPassword, isPasswordReset } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const classes = AuthBaseClasses();
  const params = useParams();
  const email = params?.email;

  const formik = useFormik({
    ...getFormConfig<{
      email: string;
      code: string;
      newPassword: string;
      passwordConfirm: string;
      submit: string | boolean;
    }>(resetPasswordFormConfig),
    onSubmit: async (values, helpers) => {
      const _newPassword = values.newPassword;
      const _passwordConfirm = values.passwordConfirm;

      try {
        if (_newPassword !== _passwordConfirm) {
          helpers.setFieldError("passwordConfirm", "Passwords do not match.");
          helpers.setFieldError("newPassword", "Passwords do not match.");
        } else {
          await resetPassword({
            email: values.email,
            code: values.code,
            new_pw: _newPassword,
          });
          navigate(config.publicPaths.login);
        }
      } catch (e) {
        helpers.setErrors({
          submit: "Failed to reset password.",
        });
      }
    },
  });

  useEffect(() => {
    if (isPasswordReset) {
      navigate(config.publicPaths.login);
      dispatch({ type: "CLEAR_IS_PASSWORD_RESET" });
    }
    // eslint-disable-next-line
  }, [isPasswordReset]);

  useEffect(() => {
    if (email) {
      formik.setFieldValue("email", email);
    }
    // eslint-disable-next-line
  }, [email]);

  return (
    <AuthBase
      pageTitle={"Password Reset"}
      disableMarqoLink={true}
      extraWrapperClasses={"borderRadiusAll"}
      formDisplayTitle=""
    >
      <Form formik={formik} spacing={2} formConfig={resetPasswordFormConfig}>
        <Box className={classes.customFormElementsWrapper}>
          {AlreadyHaveAnAccountQuickLink}
          <AuthButton isDisabled={formik.isSubmitting} label={"Reset Password"} />
        </Box>
      </Form>
    </AuthBase>
  );
};

export default PasswordReset;
