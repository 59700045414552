import { Chip } from "@mui/material";
import { TextTransformClasses } from "../../../constants/styles";

type Props = {
  isReady: boolean;
  status: string;
};

export const StatusChip = ({ isReady, status }: Props) => {
  const textTransfomStyles = TextTransformClasses();

  return (
    <Chip
      size="small"
      color={isReady ? "success" : "default"}
      label={status}
      className={textTransfomStyles.capitalize}
    />
  );
};
