import { AccountCircle } from "@mui/icons-material";
import { Box, Button, Theme, useMediaQuery } from "@mui/material";
import React, { Fragment, useRef, useState } from "react";
import { useSelector } from "../../store";
import { AccountPopover } from "../account-popover/account-popover.component";

const AccountButton: React.FC = (): JSX.Element => {
  const { organization } = useSelector((state) => state.user.data);
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const anchorRef = useRef<Element | null>(null);
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"), { noSsr: true });

  return (
    <Fragment>
      <Box onClick={() => setOpenPopover(true)} ref={anchorRef} sx={{ alignItems: "center", display: "flex", ml: 2 }}>
        <Button variant={"text"} endIcon={<AccountCircle />}>
          {smUp ? organization || "Old acc or not org. provided" : ""}
        </Button>
      </Box>
      <AccountPopover anchorEl={anchorRef.current} onClose={() => setOpenPopover(false)} open={openPopover} />
    </Fragment>
  );
};

export default AccountButton;
