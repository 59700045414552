import { Box } from "@mui/system";

interface Props {
  children?: React.ReactNode;
  index: number;
  value: number;
  idPrefix: string;
}

const TabPanel = (props: Props) => {
  const { children, value, idPrefix, index, ...rest } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`${idPrefix}-tabpanel-${index}`}
      aria-labelledby={`${idPrefix}-tab-${index}`}
      {...rest}
    >
      {value === index && (
        <Box sx={{ pt: 4 }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
};

export const tabPanelControlProps = (index: number, idPrefix: string) => ({
  id: `${idPrefix}-tab-${index}`,
  "aria-controls": `${idPrefix}-tabpanel-${index}`,
});

export default TabPanel;
