import { alpha } from "@mui/material";
import { useMemo } from "react";
import { getNeutralRgb } from "../theme/dark-theme-options";

export const useStripeOptions = () => {
  const options = useMemo(
    () => ({
      theme: "stripe",
      showIcon: true,
      iconStyle: "solid" as "solid" | "default",
      style: {
        base: {
          fontWeight: "300",
          fontFamily: "Poppins",
          colorBackground: "#0D0F35",
          colorPrimary: "#FAFAFA",
          color: "#0D0F35",
          iconColor: alpha(getNeutralRgb("950"), 0.8),
          letterSpacing: "0.025em",
          fontSmoothing: "antialiased",
          border: `1px solid ${getNeutralRgb("300")}`,

          ":-webkit-autofill": {
            color: "#fce883",
          },
          "::placeholder": {
            color: alpha(getNeutralRgb("950"), 0.8),
          },
        },
        invalid: {
          color: "#9e2146",
          iconColor: "#9e2146",
        },
      },
    }),
    [],
  );

  return options;
};
