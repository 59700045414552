import { GetIndexStatsRes, GetV1IndexStatsRes } from "../../api/indexes/indexes.types";
import { IndexItem, IndexStatsType } from "../../components/page-parts/indexes/indexes.types";

function v1StatsToV2(statsPayload: GetV1IndexStatsRes): IndexStatsType {
  return {
    dataSize: statsPayload?.data_size,
    docCount: statsPayload?.doc_count,
    indexPercentage: statsPayload?.index_size,
    vectorCount: statsPayload?.vector_count,
  };
}

function getIndexStats(isV2: boolean, statsPayload: GetV1IndexStatsRes | GetIndexStatsRes): IndexStatsType {
  if (isV2) {
    const v2Stats = statsPayload as GetIndexStatsRes;
    return {
      dataSize: v2Stats?.dataSize,
      docCount: v2Stats?.docCount,
      indexPercentage: v2Stats?.indexSize,
      vectorCount: v2Stats?.vectorCount,
    };
  }
  return v1StatsToV2(statsPayload as GetV1IndexStatsRes);
}

function getUpdatedIndexStatsList(payload: any, rawIndexList: any[]): IndexItem[] {
  const { indexName, index_name } = payload;
  const _indexName = indexName || index_name;
  const updatedList = [...rawIndexList];

  updatedList.forEach((index) => {
    if (index.indexName === _indexName) {
      const isV2 = !!index?.marqoVersion && /^2/.test(index.marqoVersion);
      index.stats = IndexStatsUtils.getIndexStats(isV2, payload);
      index.isFetchingStats = false;
    }
  });

  return updatedList;
}

const IndexStatsUtils = {
  getIndexStats,
  getUpdatedIndexStatsList,
};

export default IndexStatsUtils;
