import * as Yup from "yup";
import { Option } from "../components/form-fields/select-field";
import { FormConfigType } from "../components/form/form.types";
import { confirmPasswordFieldConfig, passwordFieldConfig, strictPasswordFieldConfig } from "./auth/auth-field.configs";
import { FormValuesExtended } from "./types";

export type AddMemberForm = {
  email: string;
  role: string;
  invitationMethod: string;
  submit: string;
};

export const INVITATION_METHOD_OPTIONS: Option[] = [
  { id: "EMAIL_PW", value: "EMAIL_PW", title: "Username/Password" },
  { id: "GOOGLE_SSO", value: "GOOGLE_SSO", title: "Google SSO" },
];

export const addMemberFormConfig: FormConfigType = {
  fields: {
    email: {
      label: "Member's email",
      initialValue: "",
      sizes: {
        xs: 12,
        sm: 7,
        md: 5,
        lg: 4,
        xl: 3,
      },
      validation: {
        rules: {},
        validate: Yup.string().email("Must be a valid email").max(255).required("Email is required"),
      },
    },
    invitationMethod: {
      label: "Authentication method",
      type: "select",
      initialValue: INVITATION_METHOD_OPTIONS[0]["value"],
      options: INVITATION_METHOD_OPTIONS,
      sizes: {
        xs: 12,
        sm: 7,
        md: 6,
        lg: 5,
        xl: 3,
      },
      validation: {
        rules: {},
        validate: Yup.string()
          .max(255)
          .test("valid-invitation-method", "Selected invitation method is not supported", (val) =>
            INVITATION_METHOD_OPTIONS.map(({ value }) => value).includes(val),
          )
          .required("Authentication method is required"),
      },
    },
    submit: {
      hidden: true,
      initialValue: null,
    },
  },
};

export const acceptInviteFormConfig = (hidePwFields: boolean): FormConfigType => {
  const HIDDEN_CONF = { hidden: true };
  return {
    fields: {
      email: { hidden: true },
      tempPassword: hidePwFields ? HIDDEN_CONF : passwordFieldConfig("Temporary password"),
      password: hidePwFields
        ? HIDDEN_CONF
        : strictPasswordFieldConfig("New password", (schema) =>
            schema.test(
              "temppw-neq-pw",
              "Your new password must be different from the temporary password.",
              (val, formValues) => {
                type AcceptInviteFields = FormValuesExtended<ReturnType<typeof acceptInviteFormConfig>["fields"]>;
                return val !== (formValues as AcceptInviteFields).from[0].value.tempPassword;
              },
            ),
          ),
      confirmPassword: hidePwFields ? HIDDEN_CONF : confirmPasswordFieldConfig("Confirm new password"),
      credentials: HIDDEN_CONF,
      submit: HIDDEN_CONF,
    },
    formInputMode: "light-zen",
  };
};
