import { useEffect, useState } from "react";

export const usePageLoadMetrics = () => {
  const [pageLoadTime, setPageLoadTime] = useState(0);

  useEffect(() => {
    const perfObserver = new PerformanceObserver((observedEntries) => {
      const entry: PerformanceEntry = observedEntries.getEntriesByType("navigation")[0];
      setPageLoadTime(entry.duration);
    });

    perfObserver.observe({
      type: "navigation",
      buffered: true,
    });
  }, []);

  return pageLoadTime;
};
