import { Card, Grid } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledContentSection = styled(Card)(({ theme }) => ({
  // media queries
  [theme.breakpoints.down("sm")]: {
    padding: "1em",
  },
}));

export const StyledContentSubSection = styled(Grid)(({ theme }) => ({
  borderRadius: "2em",
  backgroundColor: theme.palette.background.default,
}));
