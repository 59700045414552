import { Button } from "@mui/material";
import { buttonClasses } from "./button.styles";

type Props = {
  isDisabled: boolean;
  label: string;
};

export const AuthButton = ({ isDisabled, label }: Props) => {
  const classes = buttonClasses();

  return (
    <Button
      size="large"
      type="submit"
      variant="contained"
      color="secondary"
      className={classes.authBtn}
      disabled={isDisabled}
      fullWidth
    >
      {label}
    </Button>
  );
};
