import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import API from "../api";
import { AccountIdProp } from "../api/api.types";
import {
  CreateIndexReq,
  CreateV1IndexReq,
  CreateV2IndexReq,
  GetAllIndexesStatsReqType,
  IndexWorkflowsError,
  ModifyIndexReq,
  ReqGetIndexMetrics,
} from "../api/indexes/indexes.types";
import { IndexItem } from "../components/page-parts/indexes/indexes.types";
import { RangeItemType } from "../components/range-buttons/range-buttons.types";
import { createIndexToast, deleteIndexToast } from "../components/toast/indexes.toast";
import config from "../config";
import { MarqoVersion } from "../constants/enums";
import { CreateIndexFormProps, ModifyIndexFormProps } from "../form-configs/indexes/types";
import { setChartFullIsFetching } from "../slices/indexes/full-index-metrics";
import { resetIndexStats, setIndexStats, setIsDeleting, setIsFetchingIndexStats } from "../slices/indexes/indexes-main";
import { setMetricsFetching } from "../slices/indexes/metrics";
import { RootState } from "../store";
import { selectCurrentAccountId } from "../store/selectors";
import { FormCbDTO } from "./types";

export const createIndex = createAsyncThunk(
  "indexes/create",
  async (
    reqDTO: FormCbDTO<CreateIndexFormProps, AxiosError<IndexWorkflowsError>> & Partial<AccountIdProp>,
    { getState, dispatch },
  ) => {
    const currentAccountId = selectCurrentAccountId(getState() as RootState);
    const { accountId, formData, onError, onSuccess } = reqDTO;
    if (accountId && accountId !== currentAccountId) {
      return Promise.reject("Account ID mismatch");
    }
    try {
      const {
        indexName,
        numberOfReplicas,
        numberOfShards,
        indexDefaults,
        storageShardType,
        inferencePodType,
        numberOfInferencePods,
        marqoVersion,
      } = formData;
      const route = indexName.toLowerCase().replace(/ /g, "-");
      let payloadData: CreateIndexReq;
      let endpoint = config.endpoints.v2IndexWorkflows;

      if (marqoVersion === MarqoVersion.V1) {
        payloadData = {
          number_of_shards: numberOfShards,
          number_of_inferences: numberOfInferencePods,
          number_of_replicas: numberOfReplicas,
          inference_type: inferencePodType,
          storage_class: storageShardType,
          index_defaults: JSON.parse(indexDefaults as string),
        } as CreateV1IndexReq;
        endpoint = config.endpoints.v1IndexWorkflows;
      } else {
        const payloadIndexSettings = JSON.parse(indexDefaults as string);
        payloadData = {
          numberOfShards: numberOfShards,
          numberOfInferences: numberOfInferencePods,
          numberOfReplicas: numberOfReplicas,
          inferenceType: inferencePodType,
          storageClass: storageShardType,
          ...payloadIndexSettings,
        } as CreateV2IndexReq;
      }

      const promise = API.indexes.create(currentAccountId, { ...payloadData, route }, endpoint);
      createIndexToast(promise, "Creating", "created");

      const { data } = await promise;
      dispatch(getAllIndices({}));

      if (onSuccess) {
        onSuccess();
      }
      return { ...data, numberOfShards, numberOfReplicas };
    } catch (err) {
      const error = err as AxiosError<IndexWorkflowsError>;

      if (onError) {
        onError(error);
      }
      return Promise.reject(error);
    }
  },
);

export const modifyIndex = createAsyncThunk(
  "indexes/modify",
  async (reqDTO: FormCbDTO<ModifyIndexFormProps, AxiosError<IndexWorkflowsError>>, { getState, dispatch }) => {
    const { formData, onError, onSuccess } = reqDTO;
    const accountId = selectCurrentAccountId(getState() as RootState);
    try {
      const {
        indexName,
        numberOfReplicas,
        numberOfShards,
        inferencePodType,
        numberOfInferencePods,
        marqoVersion,
        storageShardType,
      } = formData;
      const route = indexName.toLowerCase().replace(/ /g, "-");

      const endpoint =
        !!marqoVersion && /^2/.test(marqoVersion)
          ? config.endpoints.v2IndexWorkflows
          : config.endpoints.v1IndexWorkflows;
      const payloadData: ModifyIndexReq = {
        numberOfInferences: numberOfInferencePods,
        numberOfShards,
        numberOfReplicas,
        storageClass: storageShardType,
        inferenceType: inferencePodType,
        route,
      };

      const promise = API.indexes.modify(accountId, payloadData, endpoint);
      createIndexToast(promise, "Modifying", "modified");

      const { data } = await promise;
      dispatch(getAllIndices({}));

      if (onSuccess) {
        onSuccess();
      }
      return data;
    } catch (err) {
      const error = err as AxiosError<IndexWorkflowsError>;

      if (onError) {
        onError(error);
      }
      return Promise.reject(error);
    }
  },
);

export const getAllIndices = createAsyncThunk(
  "indexes/getAll",
  async (reqPayload: { forPollingOnly?: boolean }, { getState }) => {
    const accountId = selectCurrentAccountId(getState() as RootState);
    if (!accountId) {
      return Promise.reject("No account selected");
    }
    try {
      const { data } = await API.indexes.get.all(accountId);
      return data;
    } catch (err) {
      return Promise.reject(err as AxiosError);
    }
  },
);

export const getIndexStatsThunk = createAsyncThunk(
  "indexes/getIndexStats",
  async (reqPayload: { indexName: string; version: string | null }, { getState, dispatch }) => {
    const { indexName, version } = reqPayload;
    const accountId = selectCurrentAccountId(getState() as RootState);
    dispatch(setIsFetchingIndexStats({ indexName, isFetchingStats: true }));
    try {
      const isV2 = /^2/.test(version);
      let statsData = {};

      if (isV2) {
        const { data } = await API.indexes.get.indexStats(accountId, indexName);
        statsData = data;
      } else {
        const { data } = await API.indexes.get.v1IndexStats(accountId, indexName);
        statsData = data;
      }
      dispatch(setIndexStats(statsData));
      return statsData;
    } catch (err) {
      dispatch(setIsFetchingIndexStats({ indexName, isFetchingStats: false }));
      return Promise.reject(err);
    }
  },
);

export const getFullIndexMetrics = createAsyncThunk(
  "indexes/getMetrics",
  async (
    queryParams: ReqGetIndexMetrics & {
      selectedRange?: RangeItemType["value"];
    },
    { getState, dispatch },
  ) => {
    dispatch(setChartFullIsFetching(true));
    const accountId = selectCurrentAccountId(getState() as RootState);
    const { data } = await API.indexes.get.metrics(accountId, queryParams);

    const { operation, selectedRange, date_start, date_end } = queryParams;
    dispatch(setChartFullIsFetching(false));
    return {
      data,
      operation: operation,
      selectedRange: selectedRange,
      dateStart: date_start,
      dateEnd: date_end,
    };
  },
);

export const getIndexMetricsMany = createAsyncThunk(
  "indexes/getMetricsMany",
  async (
    paramsGroup: {
      batchArray: ReqGetIndexMetrics[];
      dateStart: string;
      dateEnd: string;
    },
    { getState, dispatch },
  ) => {
    const { batchArray, dateStart, dateEnd } = paramsGroup;
    const accountId = selectCurrentAccountId(getState() as RootState);
    dispatch(setMetricsFetching(true));
    const response = await API.indexes.get.metricsMany(accountId, batchArray);
    const responseData = response.map((response) => {
      return {
        period: response.data.period,
        data: response.data,
        operation: response.config.params.operation,
        dateStart,
        dateEnd,
      };
    });
    dispatch(setMetricsFetching(false));
    return responseData;
  },
);

export const getAllIndexesStatsThunk = createAsyncThunk(
  "indexes/getAllIndexesStats",
  async (indexNamesList: GetAllIndexesStatsReqType, { getState, dispatch }) => {
    const accountId = selectCurrentAccountId(getState() as RootState);
    for (const index of indexNamesList) {
      dispatch(setIsFetchingIndexStats({ indexName: index.indexName, isFetchingStats: true }));
    }
    const responses = await API.indexes.get.allIndexesStats(accountId, indexNamesList);
    return responses.map((response) => {
      try {
        dispatch(setIndexStats(response.data));
        return response.data;
      } catch (error) {
        const requestData = response.config.data;
        const matchingIndex = indexNamesList.find((index) => index.indexName === requestData.indexName);
        dispatch(resetIndexStats(matchingIndex));
        return Promise.reject(error);
      }
    });
  },
);

export const deleteIndex = createAsyncThunk("indexes/delete", async (index: IndexItem, { getState, dispatch }) => {
  const accountId = selectCurrentAccountId(getState() as RootState);
  dispatch(setIsDeleting(true));
  try {
    const endpoint =
      !!index?.marqoVersion && /^2/.test(index.marqoVersion)
        ? config.endpoints.v2IndexWorkflows
        : config.endpoints.v1IndexWorkflows;
    const promise = API.indexes.delete(accountId, index, endpoint);
    deleteIndexToast(promise);
    await promise;
    await dispatch(getAllIndices({}));
    dispatch(setIsDeleting(false));
    return index;
  } catch (error) {
    return Promise.reject(error);
  }
});

export const getModelsThunk = createAsyncThunk("indexes/getModels", async (_, { getState }) => {
  const accountId = selectCurrentAccountId(getState() as RootState);
  try {
    const { data } = await API.indexes.get.models(accountId);
    return data;
  } catch (err) {
    return Promise.reject(err);
  }
});
