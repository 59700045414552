import React from "react";
import { Route } from "react-router";
import { PrivateRoute } from "../components/router/private-route";

export type TRoute = {
  path?: string;
  isPrivate?: boolean;
  isIndex?: boolean;
  subRoutes?: TRoute[];
  component?: React.FC;
};

export const getRoutes = (
  routes: TRoute[],
  authChecking: boolean,
  isInitialized: boolean,
): ReturnType<typeof Route>[] => {
  if (!authChecking) {
    return routes.map((route, index) => {
      if (route.subRoutes) {
        return (
          <Route key={`subroute-${index}`} path={route.path}>
            {getRoutes(route.subRoutes, authChecking, isInitialized)}
          </Route>
        );
      }
      return (
        <Route
          key={`route-${index}`}
          path={route.path}
          element={
            route.isPrivate ? <PrivateRoute component={route.component} path={route.path} /> : <route.component />
          }
          index={route.isIndex}
        />
      );
    });
  } else {
    return [];
  }
};
