import { AxiosError, AxiosPromise } from "axios";
import toast from "react-hot-toast";

export const createAPIKeyToast = (promise: AxiosPromise) => {
  let defaultToastErr = "Error adding new key";

  toast.promise(promise, {
    loading: "Adding new key...",
    success: "New key has been added",
    error: (e) => {
      const err = e as AxiosError;
      const error_msg = err?.response?.data?.error_msg || null;
      defaultToastErr = error_msg || defaultToastErr;

      return defaultToastErr;
    },
  });
};

export const deleteAPIKeyToast = (promise: AxiosPromise) => {
  let defaultToastErr = "Error deleting key";

  toast.promise(promise, {
    loading: "Deleting key...",
    success: "Key has been deleted",
    error: (e) => {
      const err = e as AxiosError;
      const error_msg = err?.response?.data?.error_msg || null;
      defaultToastErr = error_msg || defaultToastErr;

      return defaultToastErr;
    },
  });
};
