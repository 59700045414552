import { Box } from "@mui/material";
import { SxProps, Theme } from "@mui/system";
import React from "react";
import MarqoLogo, { LogoVariant } from "./marqo-logo";

type Props = {
  rootSx?: SxProps<Theme>;
};

const WrappedMarqoLogo: React.FC<Props> = ({ rootSx }): JSX.Element => (
  <Box display={"flex"} sx={rootSx}>
    <MarqoLogo height={32} variant={LogoVariant.NEON_GREEN_WHITE_TEXT} />
  </Box>
);

export default WrappedMarqoLogo;
