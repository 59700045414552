import { Box, Typography } from "@mui/material";
import { useAuth } from "../../../hooks/use-auth";
import { getNeutralRgb } from "../../../theme/dark-theme-options";
import { AuthBaseClasses, StyledNavLink } from "../../authentication/auth.styles";

export const OtherAuthLink = ({ toSignUp }: { toSignUp: boolean }) => {
  const { clearTempData } = useAuth();

  return (
    <Box mb={2}>
      <Box sx={{ mt: 0, mx: "auto", pb: 4, pt: 0, textAlign: "center", color: getNeutralRgb("950") }}>
        {toSignUp ? "Don't" : "Already"} have an account?{" "}
        <StyledNavLink
          to={`/authentication/${toSignUp ? "register" : "login"}`}
          onClick={clearTempData}
          style={{
            textDecoration: "none",
            marginLeft: "4px",
            fontWeight: "bold",
            color: "secondary.main",
          }}
        >
          Sign {toSignUp ? "up" : "in"}
        </StyledNavLink>
      </Box>
    </Box>
  );
};
