import { PersonAdd, Sync } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  TableCell,
  TableRow,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StyledActionButton, buttonClasses } from "../../../components/button/button.styles";
import CardTitle from "../../../components/card-title/card-title.component";
import ContentLayout from "../../../components/content-layout/content-layout.component";
import ContentSection from "../../../components/content-layout/content-section.component";
import PromptDialog from "../../../components/dialogs/prompt-dialog.component";
import Table from "../../../components/table/table.component";
import config from "../../../config";
import { TTeamMember } from "../../../slices/settings";
import { useDispatch, useSelector } from "../../../store";
import { hasStripeCardThunk } from "../../../thunks/billing.thunk";
import { getMembersThunk, removeTeamMemberThunk, resendInviteThunk } from "../../../thunks/members.thunk";

const tableHeaders = [
  { id: 0, title: "Email" },
  { id: 1, title: "Role" },
  { id: 2, title: "Actions" },
];

const MembersPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { list, isFetching } = useSelector(({ settings }) => settings.teamMembers);
  const { email: loggedEmail, isGuest } = useSelector(({ user }) => user.data);
  const [openPrompt, setOpenPrompt] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);
  const btnClasses = buttonClasses();

  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"), { noSsr: true });
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"), { noSsr: true });
  // handlers

  const handleOnDelete = (email: string) => {
    setMemberToDelete(email);
    setOpenPrompt(true);
  };

  const handleResendInvite = (email: string) => {
    // resend invite
    dispatch(resendInviteThunk(email));
  };

  const agreeHandler = () => {
    dispatch(removeTeamMemberThunk(memberToDelete));
    setMemberToDelete(null);
    setOpenPrompt(false);
  };

  const disagreeHandler = () => {
    setMemberToDelete(null);
    setOpenPrompt(false);
  };

  useEffect(() => {
    dispatch(getMembersThunk());
    dispatch(hasStripeCardThunk());
  }, []);

  return (
    <ContentLayout pageTitle={"Team Members"}>
      <PromptDialog
        title={"Delete member?"}
        openDialog={openPrompt}
        agreeHandler={agreeHandler}
        disagreeHandler={disagreeHandler}
      />

      {/* SECTION: list of users */}

      <ContentSection id={"MemberList-Section"}>
        <Grid container mb={2} alignItems={"center"} justifyContent={"space-between"} sx={mdUp ? {} : { gap: 2 }}>
          <Grid item>
            <CardTitle title={"Team Members"} noDivider rootProps={{ justifyContent: "center" }} />
          </Grid>

          {/* Redirect to add a user */}

          <Grid
            container
            item
            xs={12}
            sm={6}
            gap={2}
            justifyContent={"flex-end"}
            sx={smUp ? {} : { justifyContent: "flex-start" }}
          >
            {/* action buttons */}
            <Grid item>
              <Button
                color={"secondary"}
                variant={"contained"}
                className={btnClasses.iconTextButton}
                onClick={() => navigate(config.authenticatedPaths.addMember)}
              >
                <PersonAdd />
                {mdUp && "New Member"}
              </Button>
            </Grid>
            <Grid item>
              <Tooltip title="Refresh member list">
                <Button
                  color={"secondary"}
                  variant={"contained"}
                  fullWidth={false}
                  disabled={isGuest}
                  onClick={() => dispatch(getMembersThunk())}
                >
                  <Sync />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>

        <Divider />

        <Box sx={{ py: 3 }}>
          <Alert severity="info">
            <Typography>The users you add will share access on API Keys and view your billing summary.</Typography>
          </Alert>
        </Box>

        <Table
          tableHeaders={tableHeaders}
          inProgress={isFetching}
          isEmpty={!list.length}
          emptyListMsg={"No members found."}
        >
          {typeof list !== "string" &&
            list.length &&
            list.map(({ email, status, is_owner }: TTeamMember, key: number) => {
              return (
                <TableRow key={key} id={`Member-${email}`}>
                  <TableCell>{email}</TableCell>
                  <TableCell>{is_owner ? "Owner" : "Member"}</TableCell>

                  {/* action buttons */}

                  <TableCell>
                    {email !== loggedEmail && !is_owner && (
                      <Grid container spacing={2}>
                        {status === "PENDING" && (
                          <Grid item>
                            <StyledActionButton onClick={() => handleResendInvite(email)}>
                              Resend invite
                            </StyledActionButton>
                          </Grid>
                        )}
                        <Grid item>
                          <StyledActionButton actiontype="error" onClick={() => handleOnDelete(email)}>
                            Remove
                          </StyledActionButton>
                        </Grid>
                      </Grid>
                    )}
                  </TableCell>
                </TableRow>
              );
            })}
        </Table>
      </ContentSection>
    </ContentLayout>
  );
};

export default MembersPage;
