import {
  Table as MuiTable,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Typography,
} from "@mui/material";
import classNames from "classnames";
import React from "react";
import { WrapperClasses } from "../../constants/styles";
import TableFetching from "../table-fetch/table-fetching.component";
import { TableClasses } from "./table.styles";

export type TTableHeader = {
  id: number | string;
  title: string;
  order?: {
    key: string;
    type: string;
  };
};

type TableProps = {
  tableHeaders: TTableHeader[];
  inProgress: boolean;
  isEmpty: boolean;
  emptyListMsg: string;
  mt?: number;
  ml?: string | null;
  leanThead?: boolean;
  footer?: React.ReactNode | null;
  additionalClasses?: string[];
  // sorting
  sortDirection?: "asc" | "desc";
  toggleSort?: any;
};

const Table: React.FC<TableProps> = ({
  tableHeaders,
  children,
  inProgress,
  isEmpty,
  emptyListMsg,
  sortDirection,
  toggleSort,
  mt = 2,
  ml = 0,
  leanThead = false,
  footer = null,
  additionalClasses = [],
}): JSX.Element => {
  const wrapperClasses = WrapperClasses();
  const classes = TableClasses();

  const handleTableSort = (order: TTableHeader["order"]) => {
    toggleSort({
      key: order.key,
      compareType: order.type,
    });
  };

  return (
    <TableContainer
      className={classNames(
        wrapperClasses.directLeanScrollbar,
        classes.smallTableHeadings,
        leanThead && classes.leanThead,
        ...additionalClasses,
      )}
      style={{ marginLeft: ml }}
    >
      <MuiTable sx={{ mt }}>
        {tableHeaders.length > 0 && (
          <TableHead>
            <TableRow>
              {tableHeaders.map(({ id, title, order }) => (
                <TableCell key={id} align={"left"}>
                  {order && sortDirection ? (
                    <TableSortLabel
                      active={Boolean(sortDirection)}
                      direction={sortDirection || "asc"}
                      onClick={() => handleTableSort(order)}
                    >
                      {title}
                    </TableSortLabel>
                  ) : (
                    title
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
        )}

        {inProgress && <TableFetching columns={tableHeaders} linesCount={3} />}

        {!inProgress && (
          <TableBody>
            {isEmpty && (
              <TableRow className={"tr-emptyListMsg"}>
                <TableCell colSpan={tableHeaders.length}>
                  <span style={{ paddingTop: "1rem", paddingBottom: "1rem" }}>
                    <Typography variant={"subtitle2"} textAlign={"center"}>
                      {emptyListMsg}
                    </Typography>
                  </span>
                </TableCell>
              </TableRow>
            )}
            {!isEmpty && children}
          </TableBody>
        )}

        {!!footer && footer}
      </MuiTable>
    </TableContainer>
  );
};

export default Table;
