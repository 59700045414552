import React from "react";
import { ReactComponent as MainLogo } from "../../assets/s2search-logo/s2search-logo-small.svg";

const sizes: Record<string, number> = {
  xs: 16,
  sm: 32,
  md: 48,
  lg: 64,
};

type LogoProps = {
  size?: "xs" | "sm" | "md" | "lg" | number;
};

const getSize = (size: number | string): number => {
  if (typeof size === "number") {
    return size;
  }
  return sizes[size];
};

export const Logo: React.FC<LogoProps> = (props) => {
  const { size = "sm" } = props;
  return <MainLogo style={{ width: getSize(size), height: getSize(size) }} />;
};
