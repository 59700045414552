import { SxProps, Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getNeutralRgb } from "../../theme/dark-theme-options";

export const DashboardSidebarStyles = makeStyles((theme: Theme) => ({
  bottomLink: {
    color: getNeutralRgb("100"),
    justifyContent: "left",
    textDecoration: "none",
    marginTop: "0.5rem",

    "&:hover": {
      textDecoration: "none",
      color: "secondary.contrastText",
      background: theme.palette.secondary.main,
    },
  },

  scrollbar: {
    height: "100%",
    "& .simplebar-content": {
      height: "100%",
    },

    "& .scrollbar-box": {
      display: "flex",
      flexDirection: "column",
      height: "100%",
    },

    "& .sidebar-section-wrapper": {
      flexGrow: 1,
    },

    "& .bottom-links-wrapper": {
      padding: "1rem",
      justifyContent: "flex-end",
      width: "100%",

      "& .MuiSvgIcon-root[data-testid=HelpIcon]": {
        marginLeft: "-3px",
      },
    },
  },
}));

export const DRAWER_PAPER_PROPS_SX_BASE = {
  backgroundColor: getNeutralRgb("900"),
  color: getNeutralRgb("100"),
  width: 280,
};

export const DRAWER_PAPER_PROPS_SX_LG_UP: SxProps<Theme> = {
  ...DRAWER_PAPER_PROPS_SX_BASE,
  borderRightColor: "divider",
  borderRightStyle: "solid",
  borderRightWidth: (theme) => (theme.palette.mode === "dark" ? 1 : 0),
};
