import { TrackGoogleAnalyticsEvent } from "../index";
import GaEvents from "./index";

class GA4EventsLogger {
  // SIGN UP EVENTS

  logSignupView(email: string, organization: string) {
    TrackGoogleAnalyticsEvent(GaEvents.signup_page_view, { email, organization });
  }

  logCcSignupView(email: string, organization: string) {
    TrackGoogleAnalyticsEvent(GaEvents.cc_signup_page_view, { email, organization });
  }

  logCcSignupPageFailedSubmit(email: string, organization: string) {
    TrackGoogleAnalyticsEvent(GaEvents.cc_signup_page_failed_submit, { email, organization });
  }

  logCcSignupPageSuccessfulSubmit(email: string, organization: string) {
    TrackGoogleAnalyticsEvent(GaEvents.cc_signup_page_successful_submit, { email, organization });
  }

  logPageLoad(pageLoadTime: number) {
    TrackGoogleAnalyticsEvent(GaEvents.page_load, { pageLoadTime });
  }

  // GUEST EVENTS

  logGuestBillingPageView(email: string, organization: string) {
    TrackGoogleAnalyticsEvent(GaEvents.guest_billing_page_view, { email, organization });
  }

  logGuestBillingPageViewFailedSubmit(email: string, organization: string) {
    TrackGoogleAnalyticsEvent(GaEvents.guest_billing_page_failed_submit, { email, organization });
  }

  logGuestBillingPageViewSuccessfulSubmit(email: string, organization: string) {
    TrackGoogleAnalyticsEvent(GaEvents.guest_billing_page_successful_submit, { email, organization });
  }

  // INDEX EVENTS

  logCreateIndexView(email: string, organization: string) {
    TrackGoogleAnalyticsEvent(GaEvents.create_index_view, { email, organization });
  }

  logCreateIndexSubmit(email: string, organization: string) {
    TrackGoogleAnalyticsEvent(GaEvents.create_index_submit, { email, organization });
  }

  // CONSOLE VIEW

  logConsoleView(email: string, organization: string) {
    TrackGoogleAnalyticsEvent(GaEvents.console_view, { email, organization });
  }
}

const ga4EventsLogger = new GA4EventsLogger();
export default ga4EventsLogger;
