import { Theme, alpha } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { hexToRgb } from "@mui/system";
import { NavLink } from "react-router-dom";
import { getNeutralRgb } from "../../theme/dark-theme-options";

export const StyledNavLink = styled(NavLink)((_) => ({
  textDecoration: "none",
  textAlign: "center",
}));

export const AuthBaseClasses = makeStyles((theme: Theme) => ({
  authBoxMain: {
    backgroundColor: "background.default",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    minHeight: "100vh",
  },
  authContainer: {
    gap: "1rem",
    justifyContent: "center",
    display: "flex",
  },
  authTCWrapper: {
    my: 0,
    mx: "auto",
    padding: "1rem 2rem",
    borderBottomLeftRadius: "10px",
    borderBottomRightRadius: "10px",
    backgroundColor: getNeutralRgb("250"),
  },
  socialAuthDividerWrapper: {
    color: alpha(getNeutralRgb("950"), 0.9),
    textAlign: "center",

    "& .MuiTypography-root": {
      fontSize: "0.875rem",
    },
    "& .MuiTypography-root.kebabed-or": {
      fontSize: "0.875rem",
      width: "50px",
      zIndex: 2,
      color: getNeutralRgb("400"),
      backgroundColor: getNeutralRgb("150"),
      position: "relative",
    },
  },
  horizontalDivider: {
    position: "absolute",
    borderTop: `1px solid ${getNeutralRgb("300")}`,
    width: "100%",
    bottom: "-0.675rem",
  },
  resendConfirmationCode: {
    color: getNeutralRgb("950"),
    fontWeight: "400",
    fontSize: "0.9rem",
    "&:hover": {
      color: `${getNeutralRgb("950")} !important`,
    },
  },
  authMainSectionWrapper: {
    borderRadius: "10px",
    backgroundColor: getNeutralRgb("150"),
    height: "100%",

    "&.borderRadiusAll": {
      borderRadius: "10px",
    },
  },
  authForm: {
    "& .redirect-link": {
      fontWeight: 300,
      color: getNeutralRgb("950"),
      textAlign: "end",

      "&:hover": {
        fontWeight: 400,
      },
    },
    "& .MuiInputAdornment-root svg": {
      fill: "#6B7280",
    },
    "& .formFieldsWrapper": {
      padding: "2rem",
    },
    "& .MuiTypography-root, .MuiInputBase-input, .MuiButton-root": {
      fontFamily: '"Poppins", sans-serif',
    },
    "& .MuiInputBase-root.MuiInputBase-formControl": {
      height: "3.2rem",
    },
    "& .MuiInputBase-input": {
      paddingLeft: "1rem",
      color: getNeutralRgb("950"),
      fontSize: "0.875rem",

      "&::placeholder": {
        color: alpha(getNeutralRgb("950"), 0.8),
      },
    },
    "& .MuiInputBase-input, .MuiFormControl-root": {
      fontWeight: 300,
      color: getNeutralRgb("950"),
      background: getNeutralRgb("150"),
    },
    "& .MuiButton-root, .MuiButton-root:hover": {
      fontSize: "1rem",
      borderRadius: "7px",
    },
    "& .MuiButton-root:hover": {
      color: theme.palette.text.primary,
    },
    "& .MuiOutlinedInput-root.Mui-disabled, .MuiOutlinedInput-input.MuiInputBase-input.Mui-disabled": {
      background: getNeutralRgb("200"),
      "& fieldset.MuiOutlinedInput-notchedOutline": {
        background: getNeutralRgb("200"),
      },
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "7px",
    },
    "& .MuiOutlinedInput-root.Mui-disabled, .MuiOutlinedInput-root:hover.Mui-disabled": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: alpha(getNeutralRgb("950"), 0.1),
      },
    },
    "& .MuiOutlinedInput-root:not(.Mui-disabled), .MuiOutlinedInput-root:hover:not(.Mui-disabled)": {
      "& .MuiOutlinedInput-notchedOutline": {
        borderColor: getNeutralRgb("300"),
      },
    },
    "& .MuiOutlinedInput-root:focus-within .MuiInputAdornment-root svg": {
      fill: getNeutralRgb("950"),
    },
    "& .MuiOutlinedInput-root:focus-within .MuiOutlinedInput-input": {
      color: getNeutralRgb("950"),
    },
    "& .MuiOutlinedInput-root:focus-within:not(.Mui-disabled) .MuiOutlinedInput-notchedOutline:not(.Mui-disabled)": {
      borderColor: getNeutralRgb("950"),
    },
    // error
    "& .MuiAlert-root.MuiAlert-standardError": {
      backgroundColor: getNeutralRgb("250"),
      borderRadius: "7px",
      color: theme.palette.error.main,
    },
  },
  stripeBillingCardForm: {
    "& .post-register-wrapper": {
      borderRadius: "10px",
    },
    "& .MuiDialog-paper, .MuiDialogContent-root": {
      background: getNeutralRgb("150"),
    },
    "& .MuiGrid-root, .MuiDialog-paper, .MuiDialogContent-root": {
      "& .MuiLink-root": {
        color: getNeutralRgb("950"),
      },
      "& .form-subtitle": {
        color: alpha(getNeutralRgb("950"), 0.8),
        fontSize: "0.875rem",
      },
      "& .form-subtitle.checkbox-msg": {
        fontSize: "1rem",
      },
      "& .billing-form-error": {
        color: "#9e2146",
        paddingTop: "0.5rem",
        paddingLeft: "1rem",
      },
      "& .MuiCheckbox-root": {
        fontSize: "0.5rem",
        color: alpha(getNeutralRgb("950"), 0.8),
      },
      "& .MuiButton-root, .MuiButton-disableElevation.Mui-disabled": {
        borderRadius: "7px",
        paddingTop: "1.5rem",
        paddingBottom: "1.5rem",
      },
      "& .MuiOutlinedInput-input:-webkit-autofill": {
        "-webkit-box-shadow": `0 0 0 100px ${getNeutralRgb("700")} inset`,
        "-webkit-text-fill-color": getNeutralRgb("150"),
        borderRadius: "7px",
      },
    },
  },
  customFormElementsWrapper: {
    padding: "0 2rem 2rem 2rem",
  },
  registerFeatureSection: {
    borderTopRightRadius: "10px",
    borderBottomRightRadius: "10px",
    gap: "2rem",
    alignContent: "center",
    height: "100%",

    "& .MuiTypography-root": {
      fontFamily: '"Poppins", sans-serif',
    },
  },
  accountActivation: {
    "& .alert-wrapper": {
      padding: "2rem",
    },
    "& .MuiAlert-root.MuiAlert-standardWarning": {
      color: theme.palette.warning.dark,
      border: `1px solid ${theme.palette.warning.dark}`,

      "& svg": {
        fill: theme.palette.warning.dark,
      },
    },
    "& .MuiAlert-root.MuiAlert-standardError": {
      color: theme.palette.error.main,
      backgroundColor: alpha(hexToRgb(theme.palette.error.main), 0.1),
      border: `1px solid ${theme.palette.error.main}`,

      "& svg": {
        fill: theme.palette.error.main,
      },
    },
    "& .MuiAlert-root.MuiAlert-standardSuccess": {
      color: theme.palette.success.dark,
      backgroundColor: alpha(hexToRgb(theme.palette.success.light), 0.1),
      border: `1px solid ${theme.palette.success.dark}`,

      "& svg": {
        fill: theme.palette.success.dark,
      },
    },
  },
}));
