import axios, { AxiosPromise } from "axios";
import config from "../../config";
import { CreateAccountReq, CreateAccountRes } from "./account.types";

/**
 * Create account by adding a credit card to a guest account.
 *
 * The API returns the same data as the `getUserData` API, updated with new account details.
 */
const create = (params: CreateAccountReq): AxiosPromise<CreateAccountRes> => {
  return axios({
    method: "POST",
    baseURL: config.baseURL,
    url: config.endpoints.createAccount,
    data: params,
    headers: { "Content-Type": "application/json" },
  });
};

export default {
  create,
};
