import { EditRounded } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/DeleteForever";
import { ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { Fragment, useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate, useParams } from "react-router";
import ContentLayout from "../../components/content-layout/content-layout.component";
import DeletePrompt from "../../components/dialogs/delete-prompt.component";
import IndexDetailsTabs from "../../components/page-parts/indexes/index-details/index-details-tabs.component";
import IndexStatsSection from "../../components/page-parts/indexes/index-details/index-stats-section.component";
import IndexSummarySection from "../../components/page-parts/indexes/index-details/index-summary-section.component";
import config from "../../config";
import { useBreadcrumbs } from "../../hooks/use-breadcrumbs";
import { setToast } from "../../slices/app";
import { useDispatch, useSelector } from "../../store";
import { selectCurrentAccount, selectIndexItemByName } from "../../store/selectors";
import { deleteIndex, getIndexStatsThunk } from "../../thunks/indexes.thunk";
import { generateRandomKey } from "../../utils";
import IndexDetailsUtils from "../../utils/indices/index-details-utils";
import LoadingPage from "../loading.page";

const IndexDetails = () => {
  const { indexName } = useParams();
  const { inProgress } = useSelector((state) => state.indices.common.fetching);
  const { name: accountName } = useSelector(selectCurrentAccount);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setBreadcrumbs, clearBreadcrumbs } = useBreadcrumbs();
  const matchingIndex = useSelector(selectIndexItemByName(indexName));
  // states
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [openDeletePrompt, setOpenDeletePrompt] = useState(false);

  // TODO: Handle deleted index
  // if status==deleting and list changes,
  //    show toast msg: "Index has been deleted."

  const handleMenuOpen = (e: React.MouseEvent) => {
    e.stopPropagation();
    setMenuAnchor(e.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
  };

  const agreeDeleteIndexHandler = () => {
    dispatch(deleteIndex(matchingIndex));
    handleMenuClose();
    setOpenDeletePrompt(false);
  };

  useEffect(() => {
    setBreadcrumbs([{ title: "Overview", link: "/" }]);
    return () => clearBreadcrumbs();
  }, []);

  useEffect(() => {
    if (!inProgress && matchingIndex?.indexName) {
      dispatch(getIndexStatsThunk({ indexName, version: matchingIndex?.marqoVersion }));
    } else if (!inProgress && !matchingIndex?.indexName) {
      dispatch(setToast({ msg: `${indexName} not found.`, type: "error", hash: generateRandomKey(6) }));
      navigate(config.authenticatedPaths.indexes);
      return;
    }
  }, [matchingIndex?.indexName, inProgress]);

  return (
    <Fragment>
      <Helmet>
        <title>{indexName} | Marqo</title>
      </Helmet>
      <ContentLayout pageTitle={indexName}>
        {!matchingIndex?.indexName || inProgress ? (
          <LoadingPage />
        ) : (
          <Fragment>
            <DeletePrompt
              resourceName={matchingIndex?.indexName}
              accountName={accountName}
              openDeletePrompt={openDeletePrompt}
              disagreeHandler={() => setOpenDeletePrompt(false)}
              agreeHandler={agreeDeleteIndexHandler}
            />
            <Menu open={Boolean(menuAnchor)} anchorEl={menuAnchor} onClose={handleMenuClose}>
              {matchingIndex?.indexStatus !== "FAILED" && (
                <MenuItem onClick={() => navigate(`/indexes/edit/${indexName}`)}>
                  <ListItemIcon sx={(theme) => ({ color: theme.palette.text.primary })}>
                    <EditRounded fontSize="small" />
                  </ListItemIcon>
                  <Typography variant="inherit" color={"text.primary"}>
                    Edit
                  </Typography>
                </MenuItem>
              )}
              <MenuItem onClick={() => setOpenDeletePrompt(true)}>
                <ListItemIcon sx={(theme) => ({ color: theme.palette.error.main })}>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit" color={"error.main"}>
                  Delete
                </Typography>
              </MenuItem>
            </Menu>

            <IndexSummarySection
              indexObj={matchingIndex}
              indexName={indexName}
              isFetching={inProgress}
              handleMenuOpen={handleMenuOpen}
            />
            <IndexStatsSection indexObj={matchingIndex} />

            {IndexDetailsUtils.showCodeSnippets(inProgress, matchingIndex) && (
              <IndexDetailsTabs indexObj={matchingIndex} indexName={indexName} />
            )}
          </Fragment>
        )}
      </ContentLayout>
    </Fragment>
  );
};

export default IndexDetails;
