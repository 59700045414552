import { Box, Button, Theme, Typography, useMediaQuery } from "@mui/material";
import { FormikProps } from "formik";
import React, { Fragment } from "react";
import { hasEmpty } from "../../utils/equality";
import { buttonClasses } from "../button/button.styles";
import Form from "../form/form.component";
import { FormConfigType } from "../form/form.types";
import { OtherAuthLink } from "../page-parts/auth/sign-up-link.component";
import { AuthBaseClasses, StyledNavLink } from "./auth.styles";

type Props = {
  formik: FormikProps<any>;
  currentFormConfig: FormConfigType;
  nextHandler: () => void;
};

export const JWTLogin = ({ formik, currentFormConfig, nextHandler }: Props) => {
  const classes = AuthBaseClasses();
  const buttonStyles = buttonClasses();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"), { noSsr: true });
  const xs = useMediaQuery((theme: Theme) => theme.breakpoints.only("xs"), { noSsr: true });
  const hasErrors = Object.values(formik.errors).length > 0;
  const { submit: _submit, ...formikValues } = formik.values;
  const isSubmitting = formik.isSubmitting;
  const isTouched = Object.keys(formik.touched).length > 0;

  const isNextBtnEnabled = () => {
    return (
      (!isSubmitting && !hasErrors && hasEmpty(formikValues) && !isTouched) ||
      (!hasErrors && !hasEmpty(formikValues) && isTouched && !isSubmitting)
    );
  };
  const handleNextBtn = () => {
    if (!hasErrors && !hasEmpty(formikValues)) {
      nextHandler();
    }
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === "Enter") {
      handleNextBtn();
    }
  };

  return (
    <Fragment>
      <Form
        formik={formik}
        spacing={2}
        formConfig={currentFormConfig}
        formProps={{
          onKeyDown: handleOnKeyDown,
        }}
      >
        <Box className={classes.customFormElementsWrapper}>
          <Box mb={2} sx={{ marginTop: -3 }}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <StyledNavLink to={"/authentication/forgot-password"}>
                <Typography className={"redirect-link"} sx={{ fontSize: "0.875rem" }}>
                  Forgot password?
                </Typography>
              </StyledNavLink>
            </Box>
          </Box>
          <Button
            disabled={!isNextBtnEnabled()}
            fullWidth
            size="large"
            variant="contained"
            color="secondary"
            className={buttonStyles.authBtn}
            onClick={handleNextBtn}
          >
            Sign in
          </Button>
        </Box>
      </Form>
      <OtherAuthLink toSignUp={true} />
    </Fragment>
  );
};
