import React from "react";
import { ReactComponent as MarqoVectorLogo } from "../../assets/marqo-vector.svg";

type Props = {
  width?: number;
  fill?: string;
};

const MarqoVector: React.FC<React.PropsWithChildren<Props>> = ({ width = 20, fill = "white" }) => {
  return <MarqoVectorLogo width={width} fill={fill} style={{ marginLeft: "-3px" }} />;
};

export default MarqoVector;
