import { authenticatedPaths, endpoints, publicPaths } from "./common";
import { development } from "./development";
import { production } from "./production";

const configs: Record<string, {}> = {
  development,
  production,
};
const _default = {
  baseURL: "http://localhost:8000/api",
  marqtuneBaseURL: "http://localhost:8000/api",
  tokenStorageName: "s2s-token",
  inviteCookieName: "mq-invite",
  // ga-4
  ga4MeasurementId: `${process.env.REACT_APP_GA_MEASUREMENT_ID}`,
  // cognito
  cognitoHostedUiUrl: `${process.env.REACT_APP_COGNITO_HOSTED_UI_URL}`,
  cognitoRegion: `${process.env.REACT_APP_COGNITO_REGION}`,
  cognitoUserPoolId: `${process.env.REACT_APP_COGNITO_USER_POOL_ID}`,
  cognitoClientId: `${process.env.REACT_APP_COGNITO_CLIENT_ID}`,
  cognitoGoogleClientId: `${process.env.REACT_APP_COGNITO_GOOGLE_CLIENT_ID}`,
  cognitoGithubClientId: `${process.env.REACT_APP_COGNITO_GITHUB_CLIENT_ID}`,
};

const Config = {
  ..._default,
  ...configs[process.env.REACT_APP_STAGE],
  endpoints,
  publicPaths,
  authenticatedPaths,
};

export default Config;
