import { Link } from "@mui/material";
import { LinkClasses } from "./lean-link.styles";

type Props = {
  label: string;
  href: string;
  target?: string;
  spanClass?: "light" | "";
};

export const LeanLink = ({ label, href, spanClass, target = "_blank" }: Props): JSX.Element => {
  const classes = LinkClasses();

  return (
    <Link className={classes.leanLink} href={href} target={target}>
      <span className={spanClass ? spanClass : ""}>{label}</span>
    </Link>
  );
};
