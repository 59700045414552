import { Grid } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { useSelector } from "../../../../store";
import { toMonthDYr } from "../../../../utils/time-formatter";
import InvoiceSummary from "./invoice-summary.component";

const BillingSummaryInfo: React.FC = () => {
  const { usageCostSummary, forecastedMonthEndCost, isFetching } = useSelector(
    ({ billing }) => billing.stripeIntegration.monthToDateUsage,
  );
  const [titleHeight, setTitleHeight] = useState(0);

  const now = moment(); // create a new Date object
  const firstDayOfMonth = toMonthDYr(now.startOf("month").toISOString());
  const monthToDateDates = `${firstDayOfMonth} - ${toMonthDYr(moment().toISOString())}`;
  const forecastedMonthEndDates = `${firstDayOfMonth} - ${toMonthDYr(now.endOf("month").toISOString())}`;

  return (
    <Grid container spacing={3} mt={1} justifyContent={"flex-start"}>
      <InvoiceSummary
        title={"Month to date"}
        subtitles={monthToDateDates}
        titleHeight={titleHeight}
        isFetching={isFetching}
        usageCostSummary={usageCostSummary}
      />
      <InvoiceSummary
        title={"Forecasted month end index cost"}
        subtitles={forecastedMonthEndDates}
        setTitleHeight={setTitleHeight}
        isFetching={isFetching}
        usageCostSummary={forecastedMonthEndCost}
      />
    </Grid>
  );
};

export default BillingSummaryInfo;
