import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded";
import PublicRoundedIcon from "@mui/icons-material/PublicRounded";
import { Theme } from "@mui/system";
import * as Yup from "yup";
import { CloudProviderForm } from "../api/auth/auth.types";
import { renderInputStartAdornment } from "../components/form/form.component";
import { FormConfigType } from "../components/form/form.types";
import { getSizes } from "../constants/sizes";
import { getCountryCodes, getCountryOptions } from "../utils/forms/country-options";
import { cloudProviderDetails } from "./auth/signup.forms";

export type AccountFields = {
  name: string;
  country: string;
  organization: string;
};

export type AccountForm = AccountFields & {
  submit?: string;
};

export type CreateAccountForm = AccountForm &
  CloudProviderForm & {
    stripeToken: string;
  };

export type AccountFormProps = {
  initialValues?: Partial<AccountForm>;
  showCloudSelector: boolean;
  editable?: boolean;
};

const sizes = getSizes(12, 12, 12, 12, 12);

export const createAccountFormConfig = (
  { initialValues, showCloudSelector }: AccountFormProps = { showCloudSelector: false },
): FormConfigType<CreateAccountForm> => {
  return {
    description: "Create an account with billing details to create indexes, add team members, and more.",
    fields: {
      name: {
        label: "Account name",
        initialValue: initialValues?.name || "",
        sizes,
        validation: {
          rules: {},
          validate: Yup.string().min(1).max(255).required("Account name is required"),
        },
      },
      organization: {
        label: "Organization",
        sizes,
        initialValue: initialValues?.organization || "",
        validation: {
          rules: {},
          validate: Yup.string().max(255).required("Organization is required"),
        },
        componentProps: {
          placeholder: "Organization",
          autoComplete: "on",
          ...renderInputStartAdornment(<CorporateFareRoundedIcon fontSize={"small"} />),
        },
      },
      country: {
        label: "Country",
        type: "select",
        initialValue: initialValues?.country || "",
        options: getCountryOptions(),
        sizes,
        validation: {
          rules: {},
          validate: Yup.string()
            .test("selected-option", "Option selected is not valid", (val) => getCountryCodes().includes(val))
            .required("Country is required"),
        },
        componentProps: {
          placeholder: "Country",
          ...renderInputStartAdornment(<PublicRoundedIcon fontSize={"small"} />),
        },
      },
      cloudProvider: {
        ...cloudProviderDetails(showCloudSelector, (theme: Theme) => ({
          label: {
            "& label": {
              color: "white",
            },
          },
          selectOptionCard: {
            "& div.MuiGrid-item": {
              backgroundColor: "white",
              border: `0.1em solid transparent`,
              outline: `0.2em solid transparent`,
            },
            "&.selected-card div.MuiGrid-item": {
              borderColor: theme.palette.grey[800],
              outlineColor: theme.palette.primary.main,
            },
          },
        })).cloudProvider,
        label: "Cloud Provider",
      },
      stripeToken: {
        initialValue: "",
        type: "stripe",
        sizes: { xs: 12 },
        transformValue: (e) => e,
        validation: {
          rules: {},
          validate: Yup.string().matches(/^tok_/),
        },
        componentProps: {
          style: {
            backgroundColor: "white",
            borderRadius: "0.5em",
            padding: "1.5em 2em",
          },
        },
      },
      submit: {
        hidden: true,
        sizes: {
          xs: 12,
          sm: 6,
          md: 4,
        },
      },
    },
  };
};
