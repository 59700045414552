import { Box, Grid, Typography } from "@mui/material";
import { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import CardTitle from "../../components/card-title/card-title.component";
import ContentLayout from "../../components/content-layout/content-layout.component";
import ContentSection from "../../components/content-layout/content-section.component";
import FetchingDisplay from "../../components/fetching-display/fetching-display.component";
import EvaluationModelQuickStart from "../../components/page-parts/marqtune/evaluation-model-quick-start.component";
import {
  CreatedAMarqtuneEvaluationDatasetQuickLink,
  FineTuningChargesPerMinQuickLink,
  FineTuningGuideQuickLink,
} from "../../components/page-parts/quick-links/quick-links";
import { useBreadcrumbs } from "../../hooks/use-breadcrumbs";
import { useDispatch, useSelector } from "../../store";
import { selectEvaluationDatasets, selectSuccessfulMarqtuneModels } from "../../store/selectors";
import { listMarqtuneDatasetsThunk, listMarqtuneModelsThunk } from "../../thunks/marqtune.thunk";
import { marqtuneStyles } from "./marqtune.styles";

const MarqtuneEvaluationModelPage = () => {
  const classes = marqtuneStyles();
  const dispatch = useDispatch();
  const { setBreadcrumbs, clearBreadcrumbs } = useBreadcrumbs();
  const { isGuest } = useSelector(({ user }) => user.data);
  const { isFetching } = useSelector(({ marqtune }) => marqtune.datasets);
  const datasets = useSelector(selectEvaluationDatasets);
  const models = useSelector(selectSuccessfulMarqtuneModels);

  useEffect(() => {
    dispatch(listMarqtuneDatasetsThunk());
    dispatch(listMarqtuneModelsThunk());

    setBreadcrumbs([{ title: "Marqtune", link: "/marqtune/" }]);
    return () => clearBreadcrumbs();
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>Evaluate Model | Marqo</title>
      </Helmet>
      <ContentLayout pageTitle={"Evaluate Model"}>
        {isFetching ? (
          <FetchingDisplay />
        ) : (
          <Fragment>
            <ContentSection id={"EvaluateModelSummary"}>
              <CardTitle title="Evaluate Model" />

              {/* GUIDE */}

              <Grid container className={classes.guide} gap={2}>
                <Typography variant={"subtitle2"}>
                  Model evaluation in Marqtune refers to the process of assessing the performance of a fine-tuned model.
                  This involves determining how well the model generalizes to new, unseen data. The evaluation process
                  is crucial to ensure that the model meets the desired accuracy and performance standards. To evaluate
                  a model, you must have {CreatedAMarqtuneEvaluationDatasetQuickLink}.
                </Typography>

                <Grid item container>
                  <Typography variant={"subtitle2"}>
                    For detailed information about model training and evaluation, see the {FineTuningGuideQuickLink}.
                  </Typography>
                </Grid>

                <Typography variant={"subtitle2"}>
                  First, select your model, checkpoint and evaluation dataset then create a evaluation job via the
                  Marqtune API using the code snippets below as a guide.
                </Typography>

                <Typography variant={"subtitle2"}>
                  Evaluation can take anywhere form few minutes to days depending on the volume and type of data, and is{" "}
                  {FineTuningChargesPerMinQuickLink}. You can check on the progress of your evaluation task in the
                  console or via the API. You can terminate the task anytime. You will be billed for the time elapsed.
                </Typography>
              </Grid>
            </ContentSection>

            {/* SELECTOR, SCHEMA, and QUICK START */}

            <Box mt={2}>
              <EvaluationModelQuickStart models={models} datasets={datasets} />
            </Box>
          </Fragment>
        )}
      </ContentLayout>
    </Fragment>
  );
};

export default MarqtuneEvaluationModelPage;
