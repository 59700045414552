import { Elements as StripeElements } from "@stripe/react-stripe-js";
import { AxiosError } from "axios";
import { useFormik } from "formik";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { SignupByEmailForm, SsoSignupForm } from "../../api/auth/auth.types";
import { stripeServicePromise } from "../../api/stripe/stripe.api";
import AuthBase from "../../components/authentication/auth-base.component";
import { FormStep } from "../../components/authentication/auth.enums";
import { JWTRegister } from "../../components/authentication/jwt-register";
import { getFormConfig } from "../../components/form/form.component";
import { SocialAuthDivider } from "../../components/page-parts/auth/social-auth-divider.component";
import {
  stripeAppearance as appearance,
  stripeFontsSrc,
} from "../../components/page-parts/billing/stripe/stripe.styles";
import config from "../../config";
import { emailSignupFormConfig, signupViaSsoFormConfig } from "../../form-configs/auth/signup.forms";
import { useAuth } from "../../hooks/use-auth";
import ga4EventsLogger from "../../utils/google-analytics/events/logger";
import UserToken from "../../utils/services/tokens-service";

const Register = () => {
  const { preSignup, register, registerViaSso, credentials } = useAuth();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [formStep, setFormStep] = useState<FormStep>(FormStep.SELECT_AUTH_METHOD);
  const userToken = UserToken.get();

  const isCloudSelectorEnabled = searchParams.get("cloud-selector") === "true";

  const handleReset = () => {
    setFormStep(FormStep.SELECT_AUTH_METHOD);
  };

  const preSignupActions = () => preSignup(getFormik().form.values);

  const emailSignupFormik = useFormik<SignupByEmailForm>({
    ...getFormConfig<SignupByEmailForm>(emailSignupFormConfig(formStep, isCloudSelectorEnabled)),
    onSubmit: async (formData, helpers) => {
      const stopSubmit = () => {
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);
      };

      try {
        formData.name = formData.name.trim();
        formData.email = formData.email.trim().toLowerCase();
        formData.isCloudSelectorEnabled = isCloudSelectorEnabled;
        await register(formData);
        ga4EventsLogger.logCcSignupPageSuccessfulSubmit(formData.email, formData.organization);
        navigate(`/authentication/confirm-signup/${formData.email}`);
      } catch (e) {
        const err = e as AxiosError;
        const responseError = err.response?.data?.error;

        try {
          // parse form errors
          const message = JSON.parse(responseError);

          if (message?.password) {
            const passwordErrors = message.password
              .map((el: { code: string; message: string }): string => el.message)
              .join(", ");
            helpers.setErrors({
              password: passwordErrors,
            });
          } else {
            helpers.setErrors({
              submit: "Failed to sign up.",
            });
          }
        } catch (parseErr) {
          helpers.setErrors({
            // hacky
            // TODO: new error param to separate form errors from generic `error`
            submit: !responseError || responseError?.length > 100 ? "Failed to sign up." : responseError,
          });
        }

        ga4EventsLogger.logCcSignupPageFailedSubmit(formData.email, formData.organization);
        stopSubmit();
      } finally {
        handleReset();
      }
    },
  });

  const ssoSignupFormik = useFormik<SsoSignupForm>({
    ...getFormConfig<SsoSignupForm>(signupViaSsoFormConfig(formStep, isCloudSelectorEnabled)),
    onSubmit: async (formData, helpers) => {
      const stopSubmit = () => {
        helpers.setStatus({ success: false });
        helpers.setSubmitting(false);
      };

      try {
        formData.isCloudSelectorEnabled = isCloudSelectorEnabled;
        await registerViaSso(formData);
      } catch (e) {
        const err = e as AxiosError;
        const responseError = err.response?.data?.error;

        if (!!responseError) {
          emailSignupFormik.setErrors({
            submit: responseError,
          });
        } else {
          emailSignupFormik.setErrors({
            submit: "Failed to sign up.",
          });
        }

        ga4EventsLogger.logCcSignupPageFailedSubmit("Signup via SSO", formData.organization);
        stopSubmit();
        setFormStep(FormStep.FILL_ALL_SIGNUP_VIA_SSO_FIELDS);
      } finally {
        handleReset();
      }
    },
  });

  const getFormik = () => {
    return credentials
      ? { form: ssoSignupFormik, formConfig: signupViaSsoFormConfig(formStep, isCloudSelectorEnabled) }
      : { form: emailSignupFormik, formConfig: emailSignupFormConfig(formStep, isCloudSelectorEnabled) };
  };

  const signupNextHandler = () => {
    if (formStep === FormStep.SELECT_AUTH_METHOD) {
      setFormStep(FormStep.FILL_ALL_SIGNUP_VIA_EMAIL_FIELDS);
    } else if (
      formStep === FormStep.FILL_ALL_SIGNUP_VIA_EMAIL_FIELDS ||
      formStep === FormStep.FILL_ALL_SIGNUP_VIA_SSO_FIELDS
    ) {
      setFormStep(FormStep.USER_BACKGROUND);
    } else if (formStep === FormStep.USER_BACKGROUND) {
      preSignupActions();
      setFormStep(FormStep.CC_INFO);
    } else {
      emailSignupFormik.submitForm();
    }
  };

  const ssoErrorHandler = (error: string) => {
    emailSignupFormik.setErrors({ submit: error });
  };

  useEffect(() => {
    // navigate only to home on successful sso signup
    if (!!userToken && userToken !== "undefined") {
      navigate(config.authenticatedPaths.home);
    }
  }, [userToken]);

  useEffect(() => {
    if (credentials) {
      setFormStep(FormStep.FILL_ALL_SIGNUP_VIA_SSO_FIELDS);
    }
  }, [credentials]);

  const { form, formConfig } = getFormik();

  return (
    <Fragment>
      <AuthBase formDisplayTitle="" pageTitle={"Register"}>
        {(formStep === FormStep.SELECT_AUTH_METHOD || emailSignupFormik.errors?.submit) && (
          <SocialAuthDivider
            authPath={config.publicPaths.register}
            btnTextPrefix={"Sign up"}
            intent={"signup"}
            errorHandler={ssoErrorHandler}
          />
        )}

        {[
          FormStep.SELECT_AUTH_METHOD,
          FormStep.FILL_ALL_SIGNUP_VIA_EMAIL_FIELDS,
          FormStep.FILL_ALL_SIGNUP_VIA_SSO_FIELDS,
          FormStep.USER_BACKGROUND,
        ].includes(formStep) && (
          <JWTRegister
            nextHandler={signupNextHandler}
            currentStep={formStep}
            formik={form}
            currentFormConfig={formConfig}
            formBtnText={"Next"}
            showOtherAuthLink={formStep === FormStep.SELECT_AUTH_METHOD}
          />
        )}

        {formStep === FormStep.CC_INFO && (
          <StripeElements
            stripe={stripeServicePromise}
            options={{ fonts: [{ cssSrc: stripeFontsSrc }], appearance, loader: "always" }}
          >
            <JWTRegister
              nextHandler={() => form.submitForm()}
              currentStep={formStep}
              formik={form}
              currentFormConfig={formConfig}
              formBtnText={"Complete Setup"}
              formSubmittingText="Creating your account..."
            />
          </StripeElements>
        )}
      </AuthBase>
    </Fragment>
  );
};

export default Register;
