import { Alert, Box, Typography, useTheme } from "@mui/material";
import Config from "../../../config";
import { CodeExchangeIntent } from "../../../contexts/jwt-context";
import { AuthBaseClasses } from "../../authentication/auth.styles";
import { GoogleButton } from "../../social-buttons";

type Props = {
  btnTextPrefix: string;
  authPath: string;
  intent: CodeExchangeIntent;
  errorHandler: (error: string) => void;
};

export const SocialAuthDivider = (props: Props) => {
  const classes = AuthBaseClasses();
  const theme = useTheme();

  return (
    <Box className={classes.socialAuthDividerWrapper} sx={{ mt: 4, mx: "auto", px: 4 }}>
      {props.authPath === Config.publicPaths.register && (
        <Alert
          icon={false}
          variant="outlined"
          sx={{
            marginBottom: "1em",
            backgroundColor: "#dcfce7", // green-100
            borderColor: "#86efac", //green-300
            color: theme.palette.primary.contrastText,
            justifyContent: "center",
          }}
        >
          Use your work email, <strong>get $200 in credits</strong>{" "}
          <span style={{ fontSize: "large", verticalAlign: "middle" }}>💰</span>
        </Alert>
      )}

      <Box my={2} sx={{ display: "flex", justifyContent: "center", gap: "1em" }}>
        <GoogleButton {...props} />
      </Box>

      <Box sx={{ position: "relative", width: "100%", zIndex: 1 }}>
        <Box className={classes.horizontalDivider} />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography className={"kebabed-or"}>or</Typography>
      </Box>
    </Box>
  );
};
