import { Alert, Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { batch } from "react-redux";
import { useNavigate } from "react-router";
import ContentLayout from "../../components/content-layout/content-layout.component";
import { BreadCrumbItem } from "../../components/content-layout/content-layout.types";
import ContentSection from "../../components/content-layout/content-section.component";
import { SelectStyles } from "../../components/form-fields/form-fields.styles";
import { getFormConfig } from "../../components/form/form.component";
import { StandardForm } from "../../components/form/standard-form.component";
import MailToSupportLink from "../../components/mail-to-support-link/mail-to-support-link.component";
import config from "../../config";
import { IndexWorkflowsStorageMapping, IndexingMode, MarqoVersion } from "../../constants/enums";
import { createIndexFormConfig } from "../../form-configs/indexes/create-index.form";
import { CreateIndexFormProps } from "../../form-configs/indexes/types";
import { useBreadcrumbs } from "../../hooks/use-breadcrumbs";
import { setToast } from "../../slices/app";
import { setToCreate } from "../../slices/indexes/indexes-main";
import { useDispatch, useSelector } from "../../store";
import { selectAccountLimits, selectIndexesStatsReqList } from "../../store/selectors";
import { createIndex, getAllIndexesStatsThunk, getModelsThunk } from "../../thunks/indexes.thunk";
import { listMarqtuneModelsThunk } from "../../thunks/marqtune.thunk";
import { getAccountLimitsThunk } from "../../thunks/user.thunk";
import { generateRandomKey } from "../../utils";
import { getFormattedAmount } from "../../utils/billing";
import ga4EventsLogger from "../../utils/google-analytics/events/logger";
import IndexSettingsUtils from "../../utils/indices/index-creation-utils";
import LoadingPage from "../loading.page";

const breadcrumbsList: BreadCrumbItem[] = [{ title: "Overview", link: "/" }];
const CreateIndex = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setBreadcrumbs, clearBreadcrumbs } = useBreadcrumbs();
  const { list } = useSelector((state) => state.indices.common);
  const indexNamesList = useSelector(selectIndexesStatsReqList);
  const {
    inference: inferencePrices,
    storage: storagePrices,
    isFetching: isFetchingPrices,
    hasNullPrices,
    fetchingError,
  } = useSelector((state) => state.billing.pricing);
  const accountLimits = useSelector(selectAccountLimits);

  const { withV1Indexes, email, organization, isGuest, withMarqtune, withAudioVideoIndexingMode } = useSelector(
    ({ user }) => user.data,
  );
  const modelList = useSelector(({ indices }) => indices.common.models);
  const openSourceModels = useSelector(({ indices }) => indices.common.opensourceModels);
  const marqtunedModels = useSelector(({ indices }) => indices.common.marqtunedModels);
  const [disableReplica, setDisableReplica] = useState(true);
  const [indexingMode, setIndexingMode] = useState<IndexingMode>(IndexingMode.TEXT);
  const [version, setVersion] = useState<MarqoVersion>(MarqoVersion.V2);
  const hasReachedLimit = list.length === accountLimits.indexes.max;
  const classes = SelectStyles();
  const hasObjLimit = Object.values(accountLimits)
    .flat()
    .some((val) => typeof val.max === "object");

  const formik = useFormik({
    ...getFormConfig<CreateIndexFormProps>(
      createIndexFormConfig({
        withV1Indexes,
        withAudioVideoIndexingMode,
        disableReplica,
        accountLimits,
        inferencePrices,
        storagePrices,
        indexingMode,
        modelList,
        version,
        classes,
      }),
    ),
    onSubmit: async (data, helpers) => {
      if (isGuest) {
        dispatch(setToCreate(data));
        navigate(config.authenticatedPaths.createAccount);
        return;
      }

      if (list.length >= accountLimits.indexes.max) {
        dispatch(
          setToast({
            msg: "You've reached the maximum allowed number of indexes.",
            type: "error",
            hash: generateRandomKey(6),
          }),
        );
      } else {
        await dispatch(
          createIndex({
            formData: data as CreateIndexFormProps,
            onSuccess: () => {
              navigate(`/indexes/${data["indexName"]}`);
              dispatch(getAllIndexesStatsThunk(indexNamesList));
            },
            onError: (e) => {
              const errMsg = IndexSettingsUtils.getIndexErrorMessage(e);
              return helpers.setErrors({ submit: errMsg });
            },
          }),
        );
      }
    },
  });

  const indexCost: string = useMemo(() => {
    return getFormattedAmount(IndexSettingsUtils.computeIndexCost(formik.values, inferencePrices, storagePrices), 2);
  }, [
    formik.values["storageShardType"],
    formik.values["inferencePodType"],
    formik.values["numberOfShards"],
    formik.values["numberOfReplicas"],
    formik.values["numberOfInferencePods"],
    inferencePrices,
    storagePrices,
    isFetchingPrices,
  ]);

  const showMailToSupport: boolean = useMemo(() => {
    const inferencePods = Number(formik.values["numberOfInferencePods"]);
    const shards = Number(formik.values["numberOfShards"]);
    const replicas = Number(formik.values["numberOfReplicas"]);
    return (
      inferencePods > accountLimits.inference.max ||
      shards > accountLimits.shard.max ||
      replicas > accountLimits.replica.max
    );
  }, [
    formik.values["numberOfShards"],
    formik.values["numberOfReplicas"],
    formik.values["numberOfInferencePods"],
    accountLimits,
  ]);

  const onPreSubmitHandler = () => {
    ga4EventsLogger.logCreateIndexSubmit(email, organization);
  };

  function updateIndexingModeAndModel() {
    const indexDefs = JSON.parse(`${formik.values["indexDefaults"]}`);
    const indexDefsKeys = Object.keys(indexDefs);

    const modelPropName = IndexSettingsUtils.getModelPropsParam(formik.values);
    const treatUrlsName = IndexSettingsUtils.getTreatUrlsAsImagesParam(formik.values);
    if (!indexDefsKeys.includes(modelPropName)) {
      let indexingMode: IndexingMode;
      if (indexDefsKeys.includes(treatUrlsName)) {
        indexingMode = IndexSettingsUtils.getIndexingMode(formik.values);
        formik.setFieldValue("indexingMode", indexingMode, true);
      } else {
        indexingMode = formik.values.indexingMode;
      }

      setIndexingMode(indexingMode);
      const modelList = IndexSettingsUtils.getModelList(indexingMode as IndexingMode);

      if (indexDefsKeys.includes("model") && !!indexDefs["model"].trim() && modelList.includes(indexDefs["model"])) {
        formik.setFieldValue("model", indexDefs["model"], true);
      }
    }
  }

  useEffect(() => {
    ga4EventsLogger.logCreateIndexView(email, organization);
    batch(async () => {
      dispatch(getAccountLimitsThunk());
      dispatch(getModelsThunk());
      if (withMarqtune) dispatch(listMarqtuneModelsThunk());
    });
    setBreadcrumbs(breadcrumbsList);
    return () => clearBreadcrumbs();
  }, []);

  useEffect(() => {
    // updates numberOfReplicas
    // on change of formik.values.storageShardType
    const { storageShardType } = formik.values;

    if (storageShardType !== IndexWorkflowsStorageMapping.BASIC) {
      setDisableReplica(false);
      return;
    }

    (async () => {
      await formik.setFieldValue("numberOfReplicas", 0, true);
      await formik.setFieldTouched("numberOfReplicas", true);
    })();

    setDisableReplica(true);
  }, [formik.values["storageShardType"]]);

  useEffect(() => {
    // updates indexing mode
    try {
      updateIndexingModeAndModel();
    } catch (e) {}
    // eslint-disable-next-line
  }, [formik.values.indexDefaults]);

  useEffect(() => {
    // updates index default's model
    try {
      const indexDefs = JSON.parse(`${formik.values["indexDefaults"]}`);
      indexDefs["model"] = formik.values.model;
      formik.setFieldValue("indexDefaults", IndexSettingsUtils.stringifyIndexDefaults(indexDefs));
    } catch (e) {}
    // eslint-disable-next-line
  }, [formik.values.model]);

  useEffect(() => {
    // updates "type" in index settings
    const indexDefs = IndexSettingsUtils.getParsedIndexDefaults(formik.values);
    if (!!formik.values.indexType) {
      indexDefs["type"] = formik.values.indexType;
      formik.setFieldValue("indexDefaults", IndexSettingsUtils.getRecommendedIndexSettings(formik.values), true);
    }
    // eslint-disable-next-line
  }, [formik.values?.indexType]);

  const updateIndexDefaults = (selectedModelName: string) => {
    const updatedIndexDefaults = {
      ...JSON.parse(formik.values.indexDefaults),
      model: selectedModelName,
    };
    formik.setFieldValue("indexDefaults", JSON.stringify(updatedIndexDefaults, null, 2), true);
  };

  useEffect(() => {
    if (openSourceModels.length === 0) return;
    const selectedModelName = formik.values.model;
    if (!selectedModelName) {
      return;
    }

    formik.setFieldValue("model", selectedModelName);
    const { modelName, releasedCheckpoint } = parseModelName(selectedModelName);
    const marqtunedModel = findMarqtunedModel(modelName, releasedCheckpoint);
    const updatedModelName = marqtunedModel
      ? `marqtune/${marqtunedModel.modelId}/${releasedCheckpoint}`
      : selectedModelName;

    updateIndexDefaults(updatedModelName);
  }, [formik.values.model, marqtunedModels, formik.values.marqoVersion, modelList]);

  const parseModelName = (selectedModelName: string) => {
    const [_, modelName, releasedCheckpoint] = selectedModelName.split("/");
    return { modelName, releasedCheckpoint };
  };

  const findMarqtunedModel = (modelName: string, releasedCheckpoint: string) =>
    marqtunedModels.find(
      (model) => model.modelName === modelName && model.releasedCheckpoints?.includes(releasedCheckpoint),
    );

  useEffect(() => {
    // recomputes model and indexing mode visibility
    setVersion(formik.values.marqoVersion as MarqoVersion);
    const recommended = IndexSettingsUtils.getRecommendedIndexSettings(formik.values);
    (async () => {
      await formik.setFieldValue("indexDefaults", recommended);
      await formik.setFieldValue("model", JSON.parse(recommended)["model"]);
    })();
  }, [formik.values.indexingMode, formik.values.marqoVersion]);
  const modelDimension = IndexSettingsUtils.getModelDimension(modelList, formik.values.model);
  return (
    <ContentLayout headTitle={"Index create"} pageTitle={"Create index"}>
      {hasObjLimit ? (
        <LoadingPage />
      ) : (
        <ContentSection id={"CreateIndex-Section"}>
          <Typography mb={4} variant={"h5"}>
            Create Index
          </Typography>
          <StandardForm
            checkIfShallow={false}
            promptTitle={""}
            formik={formik}
            withPromptDialog={false}
            formConfig={createIndexFormConfig({
              withV1Indexes,
              withAudioVideoIndexingMode,
              disableReplica,
              accountLimits,
              inferencePrices,
              storagePrices,
              indexingMode,
              modelList,
              version,
              classes,
            })}
            postSendNavigateTo={"/indexes"}
            onPreSubmitHandler={onPreSubmitHandler}
            submitBtnLabel={"Create Index"}
          >
            {formik.errors.storageShardType && (
              <Grid item xs={12}>
                <Alert severity="error">{formik.errors.storageShardType}</Alert>
              </Grid>
            )}

            <Grid container item>
              <Alert severity={"info"} sx={(_) => ({ backgroundColor: "transparent" })}>
                <Grid container item xs={12}>
                  <Typography variant={"subtitle2"}>Estimated Cost</Typography>
                </Grid>
                <Grid container item xs={12}>
                  {(IndexSettingsUtils.hasErrors(formik) ||
                    isFetchingPrices ||
                    fetchingError ||
                    hasNullPrices ||
                    showMailToSupport ||
                    indexCost.includes("NaN")) && (
                    <Typography variant={"subtitle2"} color={"primary"} sx={{ mr: 1 }}>
                      -
                    </Typography>
                  )}
                  <Typography variant={"subtitle2"}>
                    {!IndexSettingsUtils.hasErrors(formik) &&
                      !(isFetchingPrices || fetchingError || hasNullPrices) &&
                      !(showMailToSupport || indexCost.includes("NaN")) &&
                      indexCost}{" "}
                    / hour
                  </Typography>

                  {!IndexSettingsUtils.hasErrors(formik) && (
                    <Grid container item>
                      <Typography sx={{ fontSize: "0.875rem" }}>
                        Stores up to{" "}
                        {IndexSettingsUtils.computeVectors(
                          formik.values["storageShardType"],
                          formik.values["numberOfShards"],
                          modelDimension,
                          formik.values["indexDefaults"],
                        ).toLocaleString("en-US")}{" "}
                        {modelDimension} dim. vectors
                      </Typography>
                    </Grid>
                  )}

                  {(showMailToSupport || hasReachedLimit) && (
                    <Grid container item>
                      <MailToSupportLink
                        title={`${hasReachedLimit ? "Need more indexes?" : "Need a larger index?"} Contact us.`}
                      />
                    </Grid>
                  )}
                </Grid>
              </Alert>
            </Grid>
          </StandardForm>
        </ContentSection>
      )}
    </ContentLayout>
  );
};

export default CreateIndex;
