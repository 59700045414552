import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { releaseCheckpointAndFetchModelDetailsThunk } from "../../../../thunks/marqtune.thunk";
import SelectField from "../../../form-fields/select-field";
import { cancelButtonStyles, getReleaseButtonStyles } from "./release-checkpoint.styles";

type ReleaseCheckpointDialogProps = {
  open: boolean;
  modelName: string;
  modelId: string;
  checkpointList: string[];
  onClose: () => void;
};

const ReleaseCheckpointDialog: React.FC<ReleaseCheckpointDialogProps> = ({
  open,
  modelName,
  modelId,
  checkpointList,
  onClose,
}) => {
  const dispatch = useDispatch();
  const [selectedCheckpoint, setSelectedCheckpoint] = useState(checkpointList.length > 0 ? checkpointList[0] : "");

  const handleRelease = () => {
    dispatch(releaseCheckpointAndFetchModelDetailsThunk({ modelId, newCheckpoint: selectedCheckpoint }));
    onClose();
  };

  const handleCheckpointChange = (e: React.SyntheticEvent) => {
    const target = e.target as HTMLSelectElement;
    setSelectedCheckpoint(target.value);
  };

  // Get the theme to use in styles
  const theme = useTheme();

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Make this model available for Marqo indexes</DialogTitle>
      <DialogContent>
        <Typography variant="body1" color="textSecondary" gutterBottom>
          Releasing this checkpoint will make the model available in Marqo Cloud, allowing the model to be selected when
          you create a Marqo index.
        </Typography>
        <Box mt={2} mb={2}>
          <Typography variant="body1" color="textSecondary" gutterBottom>
            <em>Note:</em> The model will remain private and accessible only within your Marqo Cloud account.
          </Typography>
        </Box>
        <Box mt={3}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>
                  <Typography variant="subtitle1">Model ID</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1" gutterBottom>
                    {modelId}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>
                  <Typography variant="subtitle1">Model Name</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1" gutterBottom>
                    {modelName}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={4}>
                  <Typography variant="subtitle1">Checkpoint</Typography>
                </Grid>
                <Grid item xs={8}>
                  {checkpointList.length > 1 ? (
                    <SelectField
                      fullWidth
                      id="checkpoint-selector"
                      name="checkpoint-selector"
                      placeholder="Select checkpoint"
                      value={selectedCheckpoint}
                      onChange={handleCheckpointChange}
                      options={checkpointList.map((checkpoint) => ({
                        id: checkpoint,
                        title: checkpoint,
                        value: checkpoint,
                      }))}
                    />
                  ) : (
                    <Typography variant="body1" gutterBottom>
                      {checkpointList[0]}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={cancelButtonStyles}>
          Cancel
        </Button>
        <Button onClick={handleRelease} sx={getReleaseButtonStyles(theme)}>
          Release
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReleaseCheckpointDialog;
