import { GitHub } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import classNames from "classnames";
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import GoogleLogo from "../../assets/google.png";
import config from "../../config";
import { CodeExchangeIntent } from "../../contexts/jwt-context";
import { useAuth } from "../../hooks/use-auth";
import { getQueryParam } from "../../utils/locationUtils";
import { buttonClasses } from "../button/button.styles";

enum IdentityProvider {
  GOOGLE = "Google",
  GITHUB = "Github",
}

type AuthBtnProps = {
  authPath: string;
  btnTextPrefix: string;
  intent: CodeExchangeIntent;
  errorHandler?: (error: string) => void;
};

const getHostedUiOauthUri = (identityProvider: string, authPath: string) => {
  const _encodedRedirectURI = encodeURI(window.location.origin + authPath);
  return `${config.cognitoHostedUiUrl}/oauth2/authorize?client_id=${config.cognitoClientId}&response_type=code&identity_provider=${identityProvider}&redirect_uri=${_encodedRedirectURI}`;
};

export const GithubButton = ({ authPath }: AuthBtnProps) => {
  const classes = buttonClasses();
  return (
    <Button
      className={classNames(classes.githubBtn, classes.socialProviderBtn)}
      href={getHostedUiOauthUri(IdentityProvider.GITHUB, authPath)}
    >
      <GitHub />
    </Button>
  );
};

export const GoogleButton = ({ authPath, btnTextPrefix, intent, errorHandler = () => {} }: AuthBtnProps) => {
  const { continueWithSocial } = useAuth();
  const classes = buttonClasses();
  const btnText = `${btnTextPrefix} with Google`;

  const location = useLocation();
  const code = getQueryParam(location?.search, "code");

  useEffect(() => {
    if (!code || intent === "accept_invite") return;
    // Note: accept_invite blocked temporarily to limit the scope of changes
    continueWithSocial(code, intent, errorHandler);
  }, [code]);

  return (
    <Button
      className={classNames(classes.googleBtn, classes.socialProviderBtn)}
      href={getHostedUiOauthUri(IdentityProvider.GOOGLE, authPath)}
    >
      <img src={GoogleLogo} alt={"Google"} />
      <Typography color={"neutral.900"} sx={{ textWrap: "nowrap" }}>
        {btnText}
      </Typography>
    </Button>
  );
};
