import React from "react";
import { Navigate } from "react-router";
import config from "../../config";
import { useAuth } from "../../hooks/use-auth";
import { SplashScreen } from "../splash-screen/splash-screen.component";
export const PrivateRoute = ({ component: Component, path, ...rest }: { component: React.FC; path?: string }) => {
  const { isAuthenticated, authChecking } = useAuth();
  if (authChecking) {
    return <SplashScreen open />;
  }
  if (isAuthenticated) {
    return <Component {...rest} />;
  }
  return <Navigate to={config.publicPaths.login} />;
};
