import { Grid, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import Config from "../../../config";
import { WrapperClasses } from "../../../constants/styles";
import { useSelector } from "../../../store";
import { selectFirstApiKeySecret } from "../../../store/selectors";
import CodeSnippetBlock from "../../code-snippet-block/code-snippet-block.component";
import { CodeSnippetsWrapperStyles } from "../../code-snippets-wrapper/code-snippets-wrapper.styles";
import MarqtuneSnippets from "./snippets";

export const operations = ["Create dataset"];

type Tools = {
  [language: string]: {
    displayName: string;
    terminal: string;
  };
};

const tools: Tools = {
  python: {
    displayName: "Python3",
    terminal: "Python3",
  },
  // TODO: Add support for cURL
  // "bash": {
  //   displayName: "cURL",
  //   terminal: "bash",
  // },
};

type Props = {
  inputDataId: string;
};

const CreateDatasetQuickStartCodeWrapper = ({ inputDataId }: Props) => {
  const apiKey = useSelector(selectFirstApiKeySecret);
  const [activeOpsTab, setActiveOpsTab] = useState(operations[0]);
  const [activeToolTab, setActiveToolTab] = useState(Object.keys(tools)[0]);
  const [snippetTerminal, setSnippetTerminal] = useState(Object.values(tools)[0].terminal);

  const snippet = MarqtuneSnippets.python.create_dataset({
    marqtuneClientUrl: Config.marqtuneBaseURL,
    apiKey: apiKey || "",
    inputDataId,
  });
  const classes = CodeSnippetsWrapperStyles();
  const wrapperClasses = WrapperClasses();

  // handlers
  const handleOpsChange = (_: React.SyntheticEvent, value: string) => {
    setActiveOpsTab(value);
  };

  const handleToolChange = (_: React.SyntheticEvent, value: string) => {
    setActiveToolTab(value);
    setSnippetTerminal(tools[value as keyof typeof tools].terminal);
  };

  return (
    <Grid container item xs={12} className={classes.codeSnippetsWrapper}>
      <Grid container className="tab-wrapper" flexDirection={"column"} mb={4}>
        <Grid item className={"sub-header"} px={2}>
          <Tabs value={activeOpsTab} onChange={handleOpsChange} className={wrapperClasses.leanSnippetScrollbar}>
            {operations.map((displayName) => (
              <Tab
                key={displayName}
                label={displayName}
                value={displayName}
                sx={{
                  fontWeight: 500,
                  fontSize: "0.8rem",
                }}
              />
            ))}
          </Tabs>
        </Grid>

        <Grid className={"sub-header"} item px={2}>
          <Tabs value={activeToolTab} onChange={handleToolChange}>
            {Object.entries(tools).map(([language, { displayName }]) => (
              <Tab
                key={language}
                label={displayName}
                value={language}
                sx={{
                  fontWeight: 500,
                  fontSize: "0.8rem",
                }}
              />
            ))}
          </Tabs>
        </Grid>
      </Grid>

      <Grid container>
        {activeToolTab === "python" && (
          <Grid container item justifyContent={"center"} mb={4} px={0}>
            <CodeSnippetBlock
              dataTestIdPrefix={"create-dataset-py-setup"}
              snippet={MarqtuneSnippets.setup}
              language={"bash"}
              terminal={"Bash"}
            />
          </Grid>
        )}

        {snippet && (
          <Grid container item justifyContent={"center"} px={0} mb={4}>
            <CodeSnippetBlock
              dataTestIdPrefix={"py-create-dataset"}
              snippet={snippet}
              language={activeToolTab}
              terminal={snippetTerminal}
            />
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default CreateDatasetQuickStartCodeWrapper;
