import { SearchSharp } from "@mui/icons-material";
import { Button, Grid, InputAdornment, Pagination, TextField, Theme, useMediaQuery } from "@mui/material";
import { PayloadAction } from "@reduxjs/toolkit";
import React, { Fragment, PropsWithChildren, useEffect, useMemo } from "react";
import { MarqtuneDataset, MarqtuneEvaluation, MarqtuneModel, MarqtuneResource } from "../../../../api/marqtune/types";
import { useFilters } from "../../../../hooks/use-filters";
import { useSort } from "../../../../hooks/use-sort";
import { useDispatch } from "../../../../store";
import PaginationUtils from "../../../../utils/pagination";
import CardTitle from "../../../card-title/card-title.component";
import ContentSection from "../../../content-layout/content-section.component";
import Table, { TTableHeader } from "../../../table/table.component";
import { MarqtuneStyleClasses } from "../styles";

type Props = {
  tableHeaders: TTableHeader[];
  setPageChangeAction: (payload: any) => PayloadAction;
  data: MarqtuneDataset[] | MarqtuneModel[] | MarqtuneEvaluation[];
  isFetching: boolean;
  itemsPerPage: number;
  currentPage: number;
  confirmPopupConfigLabel: string;
  sortDateFieldName?: string;
  // layout
  cardTitle: string;
  contentSectionIdPrefix: string;
  emptyListMsg: string;
  tableCellKeyPrefix: string;
  topRightActionBtnConfig: null | {
    label: string;
    href: string;
  };
  filterPaths: string[];
  setCurrentPage: any;
  // actions
  renderTableRow: (rowDetail: any, index: number) => React.ReactNode;
};

const MarqtuneRootSection = ({ children, ...props }: PropsWithChildren<Props>) => {
  const {
    tableHeaders,
    setPageChangeAction,
    data,
    isFetching,
    itemsPerPage,
    currentPage,
    sortDateFieldName,
    // layout
    cardTitle,
    contentSectionIdPrefix,
    emptyListMsg,
    tableCellKeyPrefix,
    topRightActionBtnConfig,
    filterPaths,
    setCurrentPage,
    renderTableRow,
  } = props;
  const classes = MarqtuneStyleClasses();
  const dispatch = useDispatch();
  const xsOnly = useMediaQuery((theme: Theme) => theme.breakpoints.only("xs"), { noSsr: true });
  const sm = useMediaQuery((theme: Theme) => theme.breakpoints.only("sm"), { noSsr: true });
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"), { noSsr: true });

  const { setSortItems, sorted, sortDirection, toggleSort } = useSort<MarqtuneResource>({
    defaultRule: {
      field: sortDateFieldName as keyof MarqtuneResource,
      compareType: "date",
      direction: "desc",
    },
  });

  const { filtered, filterInputValue, setFilterInputValue, setFilterItems } = useFilters<MarqtuneResource>({
    paths: filterPaths,
  });

  const getItems = useMemo(() => {
    if (!data || data.length === 0) {
      return [];
    }
    return filtered || sorted || data || [];
  }, [data, sorted, filtered]);

  const getPaginatedItems = useMemo(
    () => getItems.slice(currentPage * itemsPerPage, currentPage * itemsPerPage + itemsPerPage),
    // eslint-disable-next-line
    [data, getItems, currentPage, itemsPerPage],
  );

  const handlePageChange = (_: React.ChangeEvent<unknown>, value: number) => {
    dispatch(setPageChangeAction(value - 1));
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setFilterInputValue(value);
    dispatch(setCurrentPage(0));
  };

  const getTopRightActionButton = () => {
    return (
      <Button
        color={"secondary"}
        variant={"contained"}
        fullWidth={false}
        sx={{ ml: 2, ...(xsOnly || sm ? { padding: "0.875rem" } : {}) }}
        href={topRightActionBtnConfig ? topRightActionBtnConfig.href : ""}
      >
        {topRightActionBtnConfig.label}
      </Button>
    );
  };

  const getPagination = () => {
    return (
      <Fragment>
        {!isFetching && data.length > itemsPerPage && (
          <Grid container justifyContent={"flex-end"} mr={-1} mt={2}>
            <Pagination
              count={PaginationUtils.getPagesCount(getItems, itemsPerPage)}
              page={currentPage + 1}
              onChange={handlePageChange}
            />
          </Grid>
        )}
      </Fragment>
    );
  };

  useEffect(() => {
    if (data?.length) {
      setSortItems(data);
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (sorted?.length) {
      setFilterItems(sorted);
    }

    // eslint-disable-next-line
  }, [sorted, filterInputValue]);

  return (
    <ContentSection id={`${contentSectionIdPrefix}-Section`}>
      <Grid container mb={1} id={`${contentSectionIdPrefix}-SectionTitle`} justifyContent={"space-between"}>
        <Grid item>
          <CardTitle title={cardTitle} noDivider />
        </Grid>

        <Grid
          item
          container
          xs={12}
          md={9}
          rowGap={mdUp ? 2 : 3}
          flexDirection={"row"}
          justifyContent={mdUp ? "right" : "flex-end"}
        >
          {/* search field */}

          <Grid
            item
            xs={12}
            {...(topRightActionBtnConfig ? { sm: 6, md: 4 } : { sm: 12, md: 4 })}
            sx={{ minWidth: "3em" }}
            mt={mdUp ? 0 : 3}
          >
            <TextField
              fullWidth
              size={"small"}
              value={filterInputValue}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position={"start"}>
                    <SearchSharp />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          {/* action buttons */}

          {topRightActionBtnConfig && (
            <Fragment>
              {!mdUp && (
                <Grid item container xs={12} sm={6} justifyContent={sm ? "flex-start" : "center"} mt={sm ? 3 : 0}>
                  {getTopRightActionButton()}
                </Grid>
              )}
              {mdUp && getTopRightActionButton()}
            </Fragment>
          )}
        </Grid>
      </Grid>

      {children}

      <Grid container item flexDirection={"column"} justifyContent={"space-between"} height={"100%"}>
        <Grid container item sx={{ overflow: "auto", width: "100%" }}>
          <Table
            tableHeaders={tableHeaders}
            inProgress={isFetching}
            isEmpty={!data.length || getPaginatedItems.length === 0}
            mt={1}
            footer={null}
            emptyListMsg={emptyListMsg}
            additionalClasses={[classes.centeredMidTheadCell, classes.fixedWidthTableLayout]}
            sortDirection={sortDirection}
            toggleSort={toggleSort}
          >
            {!isFetching &&
              getPaginatedItems.map((dataEntry, index) => {
                return renderTableRow(dataEntry, index);
              })}
          </Table>
        </Grid>

        {/* PAGINATION */}

        {!isFetching && !!data && data.length !== 0 && (
          <Grid container item data-testid={`${tableCellKeyPrefix}-pagination`}>
            {getPagination()}
          </Grid>
        )}
      </Grid>
    </ContentSection>
  );
};

export default MarqtuneRootSection;
