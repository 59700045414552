import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch as useReduxDispatch, useSelector as useReduxSelector } from "react-redux";
import { RootStateType, rootReducer } from "./root-reducer";

export const setupStore = (preloadedState?: Partial<RootStateType>) => {
  // @ts-ignore
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export const store = setupStore({});

export type RootState = RootStateType;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = typeof store.dispatch;

export const useDispatch = () => useReduxDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootStateType> = useReduxSelector;
