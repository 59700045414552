import { combineReducers } from "@reduxjs/toolkit";
import pricing from "./pricing";
import stripeIntegration from "./stripe-integration";

const BillingReducer = combineReducers({
  stripeIntegration,
  pricing,
});

export default BillingReducer;
