import { Option } from "../../components/form-fields/select-field";
import { ACCEPTED_COUNTRIES } from "../../constants/validation";
import countries from "../../data/countries.json";

export function getCountryOptions(): Option[] {
  return countries
    .filter(({ country }) => ACCEPTED_COUNTRIES.includes(country))
    .map(({ country: countryName, alpha2Code }) => {
      return {
        id: alpha2Code,
        value: alpha2Code,
        title: countryName,
      };
    });
}

export function getCountryCodes(): string[] {
  return getCountryOptions().map(({ value }) => value as string);
}
