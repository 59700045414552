import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";
import { CreateAccountReq } from "../api/account/account.types";
import { AccountIdProp } from "../api/api.types";
import { changeAccountToast } from "../components/toast/account.toast";
import { acceptInviteToast, rejectInviteToast } from "../components/toast/members.toast";
import { setCurrentAccountId } from "../slices/accounts";
import { clearIndicesState } from "../slices/indexes/indexes-main";
import { clearAPIKeys, clearTeamMemberList } from "../slices/settings";
import { RootState } from "../store";
import { selectCurrentAccount } from "../store/selectors";
import { getAPIKeysThunk } from "./api-keys.thunk";
import { getAllIndices } from "./indexes.thunk";
import { getMembersThunk } from "./members.thunk";
import { getAccountLimitsThunk, getUserData, setLastSelectedAccount } from "./user.thunk";

/** Sets the current account ID, and fetches all account-related data. */
export const selectAccount = createAsyncThunk(
  "account/select",
  async ({ accountId, onlyFetch }: { onlyFetch?: boolean } & AccountIdProp, { dispatch, getState }) => {
    // Select the account in the store.
    dispatch(setCurrentAccountId({ accountId }));
    // Notify the server of the change.
    const promise = dispatch(setLastSelectedAccount({ accountId }));
    // Show a toast while the server is being notified.
    const { name } = selectCurrentAccount(getState() as RootState);
    changeAccountToast(promise, name);

    // Clear key existing data on the client.
    dispatch(clearIndicesState());
    dispatch(clearAPIKeys());
    dispatch(clearTeamMemberList());

    // By default, changing account will force a page reload to ensure the client's state is clean.
    // (Without this, we would need to ensure all data is reset and in-flight requests are cancelled
    // to avoid race conditions, which is error-prone and unnecessarily difficult at this point.)
    //
    // If explicitly requested, just fetch the new account's data and update the store. This should
    // be safe for guest users creating their first account.
    if (onlyFetch) {
      dispatch(getUserData({}));
      dispatch(getAllIndices({}));
      dispatch(getAPIKeysThunk());
      dispatch(getMembersThunk());
      dispatch(getAccountLimitsThunk());
    } else {
      await promise;
      console.log("Reloading page to fetch new account data...");
      window.location.reload();
    }
    return promise;
  },
);

/** Calls the accounts API to create a new account, then selects it on success. */
export const createAccount = createAsyncThunk("account/create", async (payload: CreateAccountReq) => {
  const { name, country, organization, token, cloud_provider } = payload;
  const { data } = await API.account.create({ name, country, organization, token, cloud_provider });
  return data;
});

export const acceptAccountInvite = createAsyncThunk(
  "account/accept",
  async ({ accountId }: AccountIdProp, { dispatch, getState }) => {
    const email = (getState() as RootState).user.data.email;
    const promise = API.settings.teamMember.update(accountId, email, { status: "ACTIVE" });
    acceptInviteToast(promise);
    const { data } = await promise;
    if (!accountId) {
      dispatch(selectAccount({ accountId }));
    } else {
      // Update list of user's accounts to refresh invitations list.
      dispatch(getUserData({}));
    }
    return data;
  },
);

export const rejectAccountInvite = createAsyncThunk(
  "account/reject",
  async ({ accountId }: AccountIdProp, { getState }) => {
    const email = (getState() as RootState).user.data.email;
    const promise = API.settings.teamMember.remove(accountId, email);
    await rejectInviteToast(promise);
    return Promise.resolve(accountId);
  },
);
