import { createSlice } from "@reduxjs/toolkit";
import { AccountId, AccountIdProp } from "../../api/api.types";
import { createAccount, rejectAccountInvite, selectAccount } from "../../thunks/account.thunk";
import { getUserData } from "../../thunks/user.thunk";

export type AccountDetails = {
  id: string;
  name: string;
  organization: string;
  country: string;
  role: string;
  status: string;
};

type InitialState = {
  /** The ID of the account the user has selected. */
  currentAccountId?: AccountId;
  accountDetails: AccountDetails[];
  selectingAccount?: boolean;
};

export const AccountsInitialState: InitialState = {
  currentAccountId: null,
  accountDetails: [],
  selectingAccount: false,
};

const slice = createSlice({
  name: "accounts",
  initialState: AccountsInitialState,
  reducers: {
    setCurrentAccountId: (state, { payload }: { payload: AccountIdProp }) => {
      state.currentAccountId = payload.accountId;
    },
    clearCurrentAccountId: (state) => {
      state.currentAccountId = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAccount.fulfilled, (state, { payload }) => {
        state.accountDetails = payload.account_data.accounts || [];
        state.currentAccountId = payload.account_id;
      })
      .addCase(getUserData.fulfilled, (state, { payload }) => {
        const accounts = payload.accounts || [];
        state.accountDetails = accounts;
        state.currentAccountId = payload.is_guest ? null : payload.customer_visible_account_id;
      })
      .addCase(rejectAccountInvite.fulfilled, (state, { payload }) => {
        state.accountDetails = state.accountDetails.filter((account) => account.id !== payload);
      })
      .addCase(selectAccount.pending, (state) => {
        state.selectingAccount = true;
      })
      .addCase(selectAccount.fulfilled, (state) => {
        state.selectingAccount = false;
      })
      .addCase(selectAccount.rejected, (state) => {
        state.selectingAccount = false;
      });
  },
});

export const { setCurrentAccountId, clearCurrentAccountId } = slice.actions;
export default slice.reducer;
