import { IndexingMode } from "../../constants/enums";
import { SampleSnippetProps } from "../sample-codes.types";

export const sampleV2Snippets = (rawEndpointUrl: string, indexName = "", apiKey = ""): SampleSnippetProps => {
  const endpointUrl = rawEndpointUrl.toLowerCase() === "unavailable" ? "<index-endpoint>" : rawEndpointUrl;
  return {
    bash: {
      Setup: `# Install the Marqo Python client module, if not already installed.
pip install marqo`,
      "Add documents": {
        [IndexingMode.TEXT]: `curl -XPOST ${endpointUrl}/indexes/${indexName}/documents \\
      -H "Content-Type: application/json" \\
      -H "x-api-key: ${apiKey}" \\
      -d '{
          "documents": [
              {
                  "_id": "example_doc_1",
                  "title": "The Travels of Marco Polo",
                  "description": "A 13th-century travelogue describing the travels of Polo"
              },
              {
                  "_id": "example_doc_2",
                  "title": "Extravehicular Mobility Unit (EMU)",
                  "description": "The EMU is a spacesuit that provides environmental protection"
              }
          ],
          "tensorFields": ["description"]
      }'`,
        [IndexingMode.IMAGE]: `curl -XPOST ${endpointUrl}/indexes/${indexName}/documents \\
      -H "Content-Type: application/json" \\
      -H "x-api-key: ${apiKey}" \\
      -d '{
          "documents": [
              {
                  "_id": "example_doc_1",
                  "title": "Flying Plane",
                  "image": "https://raw.githubusercontent.com/marqo-ai/marqo/mainline/examples/ImageSearchGuide/data/image2.jpg"
              },
              {
                  "_id": "example_doc_2",
                  "title": "Red Bus",
                  "image": "https://raw.githubusercontent.com/marqo-ai/marqo/mainline/examples/ImageSearchGuide/data/image4.jpg"
              }
          ],
          "tensorFields": ["image"]
      }'`,
        [IndexingMode.AUDIO_VIDEO]: `curl -XPOST ${endpointUrl}/indexes/${indexName}/documents \\
      -H "Content-Type: application/json" \\
      -H "x-api-key: ${apiKey}" \\
      -d '{
          "documents": [
              {
                  "_id": "example_doc_1",
                  "description": "United states government inquiry",
                  "video_field": "https://marqo-k400-video-test-dataset.s3.amazonaws.com/videos/---QUuC4vJs_000084_000094.mp4"
              },
              {
                  "_id": "example_doc_2",
                  "description": "Bird calls in the wild",
                  "audio_field": "https://marqo-ecs-50-audio-test-dataset.s3.amazonaws.com/audios/4-145081-A-9.wav"
              }
          ],
          "tensorFields": ["video_field", "audio_field"]
      }'`,
      },
      Search: {
        [IndexingMode.TEXT]: `curl -XPOST ${endpointUrl}/indexes/${indexName}/search \\
        -H "Content-Type: application/json" \\
        -H "x-api-key: ${apiKey}" \\
        -d '{
            "q": "what is the best outfit to wear on the moon?"
        }'`,
        [IndexingMode.IMAGE]: `curl -XPOST ${endpointUrl}/indexes/${indexName}/search \\
        -H "Content-Type: application/json" \\
        -H "x-api-key: ${apiKey}" \\
        -d '{
            "q": "a flying plane"
        }'`,
        [IndexingMode.AUDIO_VIDEO]: `curl -XPOST ${endpointUrl}/indexes/${indexName}/search \\
        -H "Content-Type: application/json" \\
        -H "x-api-key: ${apiKey}" \\
        -d '{"q": "birds"}'`,
      },
      "Delete documents": `curl -XPOST ${endpointUrl}/indexes/${indexName}/documents/delete-batch \\
      -H "Content-Type: application/json" \\
      -H "x-api-key: ${apiKey}" \\
      -d '["example_doc_1", "example_doc_2"]'`,
      "Delete index": `curl -XDELETE https://api.marqo.ai/api/v2/indexes/${indexName} -H "x-api-key: ${apiKey}"`,
    },
    python: {
      "Add documents": {
        [IndexingMode.TEXT]: `import marqo

mq = marqo.Client(
    "https://api.marqo.ai",
    api_key="${apiKey}"
)

results = mq.index("${indexName}").add_documents(
    documents=[
        {
            "_id": "example_doc_1",
            "title": "The Travels of Marco Polo",
            "description": "A 13th-century travelogue describing the travels of Polo"
        },
        {
            "_id": "example_doc_2",
            "title": "Extravehicular Mobility Unit (EMU)",
            "description": "The EMU is a spacesuit that provides environmental protection"
        }
    ],
    tensor_fields=["description"]
)

print(results)`,
        [IndexingMode.IMAGE]: `import marqo

mq = marqo.Client(
    "https://api.marqo.ai",
    api_key="${apiKey}"
)

results = mq.index("${indexName}").add_documents(
    documents=[
        {
            "_id": "example_doc_1",
            "title": "Flying Plane",
            "image": "https://raw.githubusercontent.com/marqo-ai/marqo/mainline/examples/ImageSearchGuide/data/image2.jpg"
        },
        {
            "_id": "example_doc_2",
            "title": "Red Bus",
            "image": "https://raw.githubusercontent.com/marqo-ai/marqo/mainline/examples/ImageSearchGuide/data/image4.jpg"
        }
    ],
    tensor_fields=["image"]
)

print(results)`,
        [IndexingMode.AUDIO_VIDEO]: `import marqo

mq = marqo.Client(
    "https://api.marqo.ai",
    api_key="${apiKey}"
)

results = mq.index("${indexName}").add_documents(
    documents=[
        {
            "_id": "example_doc_1",
            "description": "United states government inquiry",
            "video_field": "https://marqo-k400-video-test-dataset.s3.amazonaws.com/videos/---QUuC4vJs_000084_000094.mp4"
        },
        {
            "_id": "example_doc_2",
            "description": "Bird calls in the wild",
            "audio_field": "https://marqo-ecs-50-audio-test-dataset.s3.amazonaws.com/audios/4-145081-A-9.wav"
        }
    ],
    tensor_fields=["video_field", "audio_field"]
)

print(results)`,
      },
      Search: {
        [IndexingMode.TEXT]: `import marqo

mq = marqo.Client(
    "https://api.marqo.ai",
    api_key="${apiKey}"
)

results = mq.index("${indexName}").search(
    q="What is the best outfit to wear on the moon?",
)

print(results)`,
        [IndexingMode.IMAGE]: `import marqo
mq = marqo.Client(
    "https://api.marqo.ai",
    api_key="${apiKey}"
)

results = mq.index("${indexName}").search(
    q="A flying plane",
)

print(results)`,
        [IndexingMode.AUDIO_VIDEO]: `import marqo
mq = marqo.Client(
    "https://api.marqo.ai",
    api_key="${apiKey}"
)

results = mq.index("${indexName}").search(
    q="birds"
)
print(results)`,
      },
      "Delete documents": `import marqo

mq = marqo.Client(
    "https://api.marqo.ai",
    api_key="${apiKey}"
)

results = mq.index("${indexName}").delete_documents(ids=["example_doc_1", "example_doc_2"])

print(results)`,
      "Delete index": `import marqo

mq = marqo.Client(
    "https://api.marqo.ai",
    api_key="${apiKey}"
)

results = mq.index("${indexName}").delete()

print(results)`,
    },
  };
};
