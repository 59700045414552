import { Grid, Tab, Tabs } from "@mui/material";
import classNames from "classnames";
import React, { Fragment, useState } from "react";
import { IndexingMode } from "../../constants/enums";
import { WrapperClasses } from "../../constants/styles";
import { sampleCodes } from "../../sample-codes/indexes/v1-sample-snippets";
import { sampleV2Snippets } from "../../sample-codes/indexes/v2-sample-snippets";
import { useSelector } from "../../store";
import { selectFirstApiKeySecret } from "../../store/selectors";
import CodeSnippetBlock from "../code-snippet-block/code-snippet-block.component";
import { CodeSnippetsWrapperStyles } from "./code-snippets-wrapper.styles";

const operations = ["Add documents", "Search", "Delete documents", "Delete index"];

type Tools = {
  [language: string]: {
    displayName: string;
    terminal: string;
  };
};

const tools: Tools = {
  python: {
    displayName: "Python3",
    terminal: "Python3",
  },
  bash: {
    displayName: "cURL",
    terminal: "bash",
  },
};

function getSnippet(
  version: string,
  lang: string,
  op: string,
  endpoint: string,
  indexName = "",
  apiKey = "",
): string | Record<string, string> {
  if (/^2/.test(version)) {
    return sampleV2Snippets(endpoint, indexName, apiKey)[lang][op];
  }
  return sampleCodes(endpoint, indexName, apiKey)[lang][op];
}

type Props = {
  endpoint: string;
  indexName: string;
  mode: IndexingMode;
  version: string;
};

const CodeSnippetsWrapper: React.FC<Props> = ({ version, endpoint, indexName, mode }) => {
  const apiKey = useSelector(selectFirstApiKeySecret);
  const [activeOpsTab, setActiveOpsTab] = useState(operations[0]);
  const [activeToolTab, setActiveToolTab] = useState(Object.keys(tools)[0]);
  const [snippetTerminal, setSnippetTerminal] = useState(Object.values(tools)[0].terminal);
  const snippet = getSnippet(version, activeToolTab, activeOpsTab, endpoint, indexName, apiKey);
  const setupSnippet = getSnippet(version, "bash", "Setup", endpoint, indexName, apiKey) as string;

  const classes = CodeSnippetsWrapperStyles();
  const wrapperClasses = WrapperClasses();

  // handlers
  const handleOpsChange = (_: React.SyntheticEvent, value: string) => {
    setActiveOpsTab(value);
  };

  const handleToolChange = (_: React.SyntheticEvent, value: string) => {
    setActiveToolTab(value);
    setSnippetTerminal(tools[value as keyof typeof tools].terminal);
  };

  return (
    <Fragment>
      <Grid container item xs={12} className={classes.codeSnippetsWrapper}>
        <Grid container className="tab-wrapper" flexDirection={"column"} mb={4}>
          <Grid item className={"sub-header"} px={2}>
            <Tabs
              value={activeOpsTab}
              onChange={handleOpsChange}
              className={classNames(wrapperClasses.leanSnippetScrollbar)}
            >
              {operations.map((displayName) => (
                <Tab key={displayName} label={displayName} value={displayName} className="CodeSnippetsWrapper-Tab" />
              ))}
            </Tabs>
          </Grid>

          <Grid className={"sub-header"} item px={2}>
            <Tabs value={activeToolTab} onChange={handleToolChange}>
              {Object.entries(tools).map(([language, { displayName }]) => (
                <Tab key={language} label={displayName} value={language} className="CodeSnippetsWrapper-Tab" />
              ))}
            </Tabs>
          </Grid>
        </Grid>

        <Grid container>
          {activeToolTab === "python" && (
            <Grid container item justifyContent={"center"} mb={4} px={0}>
              <CodeSnippetBlock
                dataTestIdPrefix="index-py-setup"
                snippet={setupSnippet}
                language={"bash"}
                terminal={"Bash"}
              />
            </Grid>
          )}

          {typeof snippet === "object" && (
            <Grid container item justifyContent={"center"} px={0} mb={4} gap={1}>
              <CodeSnippetBlock
                snippet={snippet[mode] as string}
                language={activeToolTab}
                terminal={snippetTerminal}
                dataTestIdPrefix={"index-type-based"}
              />
            </Grid>
          )}

          {typeof snippet === "string" && (
            <Grid container item justifyContent={"center"} px={0} mb={4}>
              <CodeSnippetBlock
                dataTestIdPrefix={"index-non-type-based"}
                snippet={snippet}
                language={activeToolTab}
                terminal={snippetTerminal}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

export default CodeSnippetsWrapper;
