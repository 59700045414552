import { Alert, Box, CircularProgress, Typography } from "@mui/material";
import { SerializedError } from "@reduxjs/toolkit";
import { Elements as StripeElements } from "@stripe/react-stripe-js";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { CreateAccountRes } from "../../api/account/account.types";
import { AccountId } from "../../api/api.types";
import { stripeServicePromise } from "../../api/stripe/stripe.api";
import ContentLayout from "../../components/content-layout/content-layout.component";
import ContentSection from "../../components/content-layout/content-section.component";
import { getFormConfig } from "../../components/form/form.component";
import { StandardForm } from "../../components/form/standard-form.component";
import { stripeFontsSrc } from "../../components/page-parts/billing/stripe/stripe.styles";
import Config from "../../config";
import { CreateAccountForm, createAccountFormConfig } from "../../form-configs/accounts.forms";
import { CreateIndexFormProps } from "../../form-configs/indexes/types";
import { clearToCreate } from "../../slices/indexes/indexes-main";
import { useDispatch, useSelector } from "../../store";
import { selectIndexesStatsReqList } from "../../store/selectors";
import { createAccount, selectAccount } from "../../thunks/account.thunk";
import { hasStripeCardThunk } from "../../thunks/billing.thunk";
import { createIndex, getAllIndexesStatsThunk } from "../../thunks/indexes.thunk";

export default function CreateAccount() {
  const { toCreate } = useSelector(({ indices }) => indices.common);
  const indexNamesList = useSelector(selectIndexesStatsReqList);
  const { isGuest, organization, country, withCloudSelector: showCloudSelector } = useSelector(({ user }) => user.data);
  const initialValues = { organization, country };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState("");

  // Clear pending index creation details when the user navigates away from this page.
  useEffect(() => {
    dispatch(hasStripeCardThunk());
    return () => {
      dispatch(clearToCreate());
    };
  }, []);

  // Creates an index if the user tried to create one before entering billing details.
  const handlePendingIndexCreation = (accountId: AccountId) => {
    if (!toCreate) return;

    dispatch(
      createIndex({
        accountId,
        formData: toCreate as CreateIndexFormProps,
        onSuccess: () => {
          navigate(`/indexes/${toCreate["indexName"]}`);
          dispatch(getAllIndexesStatsThunk(indexNamesList));
        },
      }),
    );
  };

  const formik = useFormik({
    ...getFormConfig<CreateAccountForm>(createAccountFormConfig({ initialValues, showCloudSelector })),
    onSubmit: async (data, helpers) => {
      const result: { payload: CreateAccountRes } | { error: SerializedError } = await dispatch(
        createAccount({
          name: data.name,
          country: data.country,
          organization: data.organization,
          token: data.stripeToken,
          cloud_provider: data.cloudProvider,
        }),
      );
      if ("error" in result) {
        setError(`Failed to create account (${result.error.message})`);
      } else {
        setError("");
        const accountId = (result.payload as CreateAccountRes).account_id;
        navigate(Config.authenticatedPaths.indexes);
        handlePendingIndexCreation(accountId);

        // Guests have no pre-existing client state that could lead to confusion. However if the
        // user is not a guest, refresh the whole page to ensure the client is clean.
        dispatch(selectAccount({ accountId, onlyFetch: isGuest }));
      }
    },
  });

  return (
    <ContentLayout headTitle={"Create Account"} pageTitle={"Create Account"} hideGuestBanner={true}>
      {toCreate && (
        <Box sx={{ pb: 3 }} data-testid="to-create-banner">
          <Alert severity="info">
            <Typography>{`Index ${toCreate.indexName} will be created in your new account.`}</Typography>
          </Alert>
        </Box>
      )}

      <ContentSection id={"CreateAccount-Section"} sx={{ maxWidth: "550px" }}>
        <Typography mb={4} variant={"h5"}>
          Create Account
        </Typography>
        <StripeElements stripe={stripeServicePromise} options={{ fonts: [{ cssSrc: stripeFontsSrc }] }}>
          <StandardForm
            formik={formik}
            formConfig={createAccountFormConfig({ initialValues, showCloudSelector })}
            postSendNavigateTo={"/"}
            submitBtnLabel={"Create Account"}
            warningContent={error}
          />
        </StripeElements>

        {formik.isSubmitting && (
          <div style={{ display: "flex", justifyContent: "center", margin: "1em" }}>
            <Alert severity="info">
              <Typography>Creating your account, this may take a moment...</Typography>
              <div style={{ height: "50px", textAlign: "center", marginTop: "1em" }}>
                <CircularProgress />
              </div>
            </Alert>
          </div>
        )}
      </ContentSection>
    </ContentLayout>
  );
}
