import { Theme, alpha } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { leanScrollbarStyles } from "../../constants/styles";

export const marqtuneStyles = makeStyles((theme: Theme) => ({
  gridPartition: {
    [theme.breakpoints.up("lg")]: {
      paddingLeft: "1.5rem !important",
      borderLeft: `1px solid ${alpha(theme.palette.text.disabled, 0.1)}`,
    },
    [theme.breakpoints.down("lg")]: {
      borderTop: `1px solid ${alpha(theme.palette.text.disabled, 0.1)}`,
    },
  },
  leanList: {
    paddingLeft: theme.spacing(2),

    "&.pl-0": {
      paddingLeft: 0,
    },

    "& .mb-1": {
      marginBottom: theme.spacing(1),
    },

    "& li": {
      display: "list-item",
      padding: 0,

      "&.ordered": {
        listStyleType: "decimal",
      },

      "&.disc": {
        listStyleType: "disc",
      },

      "&.listStyleNone": {
        listStyleType: "none",
      },
    },
  },
  checkpointListWrapper: {
    overflow: "hidden",
    maxHeight: "10rem",

    "& .MuiList-root": {
      overflow: "auto",
      height: "100%",
      width: "100%",
      ...leanScrollbarStyles,
    },
  },
  guide: {
    color: theme.palette.text.secondary,
  },
}));
