import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { AsyncThunk } from "@reduxjs/toolkit";
import classNames from "classnames";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { WrapperClasses } from "../../../../constants/styles";
import { ChevronDown } from "../../../../icons/chevron-down";
import { ChevronUp } from "../../../../icons/chevron-up";
import { LogsState } from "../../../../slices/marqtune";
import { useDispatch } from "../../../../store";
import { ThunkDownloadButton } from "../../../download-button/download-button.component";
import { liveLogsClasses } from "./logs.styles";

type Props = {
  isResourceTaskFinished: boolean;
  sectionId: string;
  resourceId: string;
  dispatchIntervalInSec: number;
  dispatchAction: AsyncThunk<LogsState["logs"], any, {}>;
  downloadLogsDispatchAction: AsyncThunk<any, any, {}>;
  resourceLogs: {
    logs: LogsState["logs"];
    isFetching: boolean;
  };
};

const LogsViewer = ({
  isResourceTaskFinished,
  resourceLogs = { logs: [], isFetching: false },
  dispatchAction,
  downloadLogsDispatchAction,
  ...props
}: Props) => {
  const dispatch = useDispatch();
  const classes = liveLogsClasses();
  const wrapperClasses = WrapperClasses();
  const { sectionId, resourceId, dispatchIntervalInSec } = props;
  const logsRef = useRef<HTMLPreElement>(null);
  const [expandLogs, setExpandLogs] = useState(true);
  const isResourceIdValid = resourceId && resourceId !== "-";

  const handleToggle = () => {
    setExpandLogs(!expandLogs);
  };

  useEffect(() => {
    if (isResourceIdValid) {
      dispatch(dispatchAction(resourceId));
    }
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isResourceIdValid && !isResourceTaskFinished) {
        dispatch(dispatchAction(resourceId));
      }
    }, dispatchIntervalInSec * 1000);
    return () => clearInterval(interval);
  }, [resourceId, isResourceTaskFinished]);

  const concatenatedLogs = useMemo(() => {
    if (resourceLogs.isFetching && isEmpty(resourceLogs.logs)) return "Fetching logs...";
    else if (resourceLogs.logs?.length) {
      return resourceLogs.logs.map((log) => `${log.timestamp} ${log.message}`).join("\n");
    }
    return "No logs available";
  }, [resourceLogs.logs, resourceLogs.isFetching]);

  const scrollToBottom = () => {
    logsRef.current?.scrollTo(0, logsRef.current.scrollHeight);
  };

  useEffect(() => {
    if (expandLogs) {
      scrollToBottom();
    }
  }, [concatenatedLogs, expandLogs]);

  return (
    <Grid container gap={2} data-testid={`${sectionId}-ResourceLogs`}>
      <Grid container justifyContent={"space-between"}>
        <Grid container item onClick={handleToggle} alignItems={"center"} gap={2} width={"auto"}>
          <Typography variant="subtitle2" id={sectionId} className={classes.clickToExpand}>
            Logs
          </Typography>

          <Grid item>
            <IconButton sx={{ minWidth: "0", width: "min-content", padding: 0 }}>
              {expandLogs ? <ChevronDown /> : <ChevronUp />}
            </IconButton>
          </Grid>
        </Grid>

        {resourceId && resourceId !== "-" && (
          <Grid item>
            <Tooltip
              title={isResourceTaskFinished ? "" : "Downloading of logs will be available once the resource is ready."}
            >
              <span>
                <ThunkDownloadButton
                  dispatchAction={downloadLogsDispatchAction}
                  resourceId={resourceId}
                  buttonLabel="Download logs"
                  disabled={!isResourceTaskFinished}
                />
              </span>
            </Tooltip>
          </Grid>
        )}
      </Grid>
      {expandLogs && (
        <Grid
          container
          item
          data-testid={"ResourceLogs-wrapper"}
          className={classNames(classes.wrapper, wrapperClasses.directLeanScrollbar)}
        >
          <pre ref={logsRef}>{concatenatedLogs}</pre>
        </Grid>
      )}
    </Grid>
  );
};

export default LogsViewer;
