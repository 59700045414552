import { loadStripe } from "@stripe/stripe-js";
import axios, { AxiosPromise } from "axios";
import config from "../../config";
import { AccountId } from "../api.types";
import { GetBillingPortalURLResponse, GetMonthToDateResponse } from "./stripe.types";

export const stripeServicePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);

export const getBillingPortalURL = (accountId: AccountId): AxiosPromise<GetBillingPortalURLResponse> => {
  return axios({
    method: "POST",
    baseURL: config.baseURL,
    url: `${config.endpoints.getBillingPortalURL}`,
    params: { accountId },
  });
};

export const getMonthToDateUsage = (accountId: AccountId): AxiosPromise<GetMonthToDateResponse> => {
  return axios({
    method: "GET",
    baseURL: config.baseURL,
    url: `${config.endpoints.getMonthToDateUsage}`,
    params: { accountId },
  });
};

const StripeIntegration = {
  get: {
    billingPortalURL: getBillingPortalURL,
    monthToDateUsage: getMonthToDateUsage,
  },
};
export default StripeIntegration;
