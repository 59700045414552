import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";
import { SetIntegrationReqProps } from "../api/integrations/types";
import { setIntegrationAPIKeyToast } from "../components/toast/integrations.toast";
import { setIsFetchingIntegrations } from "../slices/integrations";
import { RootState } from "../store";
import { selectCurrentAccountId } from "../store/selectors";
import { FormCbDTO } from "./types";

export const setDatadogAPIKeyThunk = createAsyncThunk(
  "integrations/setDatadogAPIKeyThunk",
  async (req: FormCbDTO<SetIntegrationReqProps>, { getState }) => {
    const accountId = selectCurrentAccountId(getState() as RootState);
    try {
      const { onSuccess, formData } = req;
      const payload: SetIntegrationReqProps = {
        ...formData,
        datadog_api_key: formData.datadog_api_key.trim(),
      };
      const promise = API.integrations.setDatadog(accountId, payload);
      setIntegrationAPIKeyToast(promise);
      const { data } = await promise;

      if (onSuccess) {
        onSuccess();
      }

      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
);

export const getIntegrationsThunk = createAsyncThunk(
  "integrations/getIntegrationsThunk",
  async (_, { dispatch, getState }) => {
    const accountId = selectCurrentAccountId(getState() as RootState);
    if (!accountId) {
      return Promise.reject("No account selected");
    }
    dispatch(setIsFetchingIntegrations(true));

    try {
      const { data } = await API.integrations.get(accountId);
      return data;
    } catch (err) {
      return Promise.reject(err);
    }
  },
);
