import { Add, ContentCopy, Sync, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  InputLabel,
  TableCell,
  TableRow,
  TextField,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import copy from "copy-to-clipboard";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DeleteAPIKeyReq } from "../../api/settings/settings.types";
import { StyledActionButton, buttonClasses } from "../../components/button/button.styles";
import CardTitle from "../../components/card-title/card-title.component";
import ContentLayout from "../../components/content-layout/content-layout.component";
import ContentSection from "../../components/content-layout/content-section.component";
import PromptDialog from "../../components/dialogs/prompt-dialog.component";
import Table from "../../components/table/table.component";
import config from "../../config";
import { ellipsisStyles } from "../../constants/styles";
import { setToast } from "../../slices/app";
import { TAPIKey, setShowAPISecret } from "../../slices/settings";
import { useDispatch, useSelector } from "../../store";
import { deleteAPIKeyThunk, getAPIKeysThunk } from "../../thunks/api-keys.thunk";
import { hasStripeCardThunk } from "../../thunks/billing.thunk";
import { generateRandomKey } from "../../utils";

const tableHeaders = [
  { id: 0, title: "Name" },
  { id: 1, title: "Secret Key" },
];
const SECRET_KEY_LENGTH = 40;
const asteriskedSecretKey: string = [...Array(SECRET_KEY_LENGTH)].map((_) => "*").join("");

const APIKeysPage = (): JSX.Element => {
  const dispatch = useDispatch();
  const btnClasses = buttonClasses();
  const navigate = useNavigate();
  const { keyList, isFetching } = useSelector((state) => state.settings.apiKeys);
  const { isGuest } = useSelector(({ user }) => user.data);

  const [openPrompt, setOpenPrompt] = useState(false);
  const [keyToDelete, setKeyToDelete] = useState(null);
  const [copyItems, setCopyItems] = useState<string[]>([]);
  const marqoEndpoint = "https://api.marqo.ai";

  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"), { noSsr: true });
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("sm"), { noSsr: true });

  // handlers

  const handleOnDelete = (payload: DeleteAPIKeyReq) => {
    setKeyToDelete(payload);
    setOpenPrompt(true);
  };

  const handleCopy = (value: string, itemId: string, label = "Secret key"): void => {
    copy(value);
    setCopyItems((prev) => [...prev, itemId]);
    const timer = setTimeout(() => {
      setCopyItems((prev) => prev.filter((el) => el !== itemId));
      clearTimeout(timer);
    }, 1000);

    dispatch(
      setToast({
        msg: `${label} has been copied to clipboard.`,
        type: "success",
        hash: generateRandomKey(6),
      }),
    );
  };

  const agreeHandler = () => {
    dispatch(deleteAPIKeyThunk(keyToDelete));
    setKeyToDelete(null);
    setOpenPrompt(false);
  };

  const disagreeHandler = () => {
    setKeyToDelete(null);
    setOpenPrompt(false);
  };

  const handleShowSecret = ({ secret, show }: TAPIKey) => {
    dispatch(setShowAPISecret({ secret, show: !show }));
  };

  useEffect(() => {
    dispatch(hasStripeCardThunk());
    // eslint-disable-next-line
  }, []);

  return (
    <ContentLayout pageTitle={"API Keys"}>
      <PromptDialog
        title={"Delete API Key?"}
        openDialog={openPrompt}
        agreeHandler={agreeHandler}
        disagreeHandler={disagreeHandler}
      />

      {/* SECTION: list of API KEYS */}

      <ContentSection id={"ApiKeyList-Section"}>
        {/* generate new api key */}

        <Grid container mb={2} alignItems={"center"} justifyContent={"space-between"} sx={mdUp ? {} : { gap: 2 }}>
          <Grid item>
            <CardTitle title={"API Keys"} noDivider rootProps={{ justifyContent: "center" }} />
          </Grid>

          <Grid
            container
            item
            xs={12}
            sm={6}
            gap={2}
            justifyContent={"flex-end"}
            sx={smUp ? {} : { justifyContent: "flex-start" }}
          >
            {/* action buttons */}
            <Grid item>
              <Button
                className={btnClasses.iconTextButton}
                onClick={() => navigate(config.authenticatedPaths.createApiKey)}
                color={"secondary"}
                variant={"contained"}
              >
                <Add />
                {mdUp && "New API Key"}
              </Button>
            </Grid>
            <Grid item>
              <Tooltip title="Refresh API key list">
                <Button
                  onClick={() => dispatch(getAPIKeysThunk())}
                  color={"secondary"}
                  variant={"contained"}
                  fullWidth={false}
                  disabled={isGuest}
                >
                  <Sync />
                </Button>
              </Tooltip>
            </Grid>
          </Grid>
        </Grid>

        <Divider />

        <Box sx={{ py: 3 }}>
          <Alert severity="info">
            <Typography>Keys you have generated that can be used to access your indexes.</Typography>
          </Alert>
        </Box>

        <Table
          tableHeaders={tableHeaders}
          inProgress={isFetching}
          isEmpty={!keyList.length}
          emptyListMsg={"No API Keys found."}
        >
          {keyList.map((_item: TAPIKey, key: number) => {
            return (
              <TableRow key={key} id={`ApiKey-${_item.name}`}>
                <TableCell sx={{ width: "auto", maxWidth: "4em" }}>
                  <Grid
                    container
                    flexDirection={"row"}
                    justifyContent={"space-between"}
                    alignItems={"center"}
                    sx={{
                      overflow: "hidden",
                    }}
                  >
                    <Grid container item xs={8}>
                      <Typography sx={{ ...ellipsisStyles }}>{_item.name}</Typography>
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell colSpan={1} sx={{ width: "auto", maxWidth: "10em", overflowX: "hidden" }}>
                  <Grid
                    container
                    flexDirection={mdUp ? "row" : "column"}
                    justifyContent={"space-between"}
                    sx={{
                      overflow: "hidden",
                    }}
                  >
                    <Grid item xs={8}>
                      <Typography sx={{ ...ellipsisStyles }}>
                        {!_item.show && asteriskedSecretKey}
                        {_item.show && _item.secret}
                      </Typography>
                    </Grid>

                    <Grid
                      container
                      item
                      flexDirection={"row"}
                      justifyContent={mdUp ? "flex-end" : "flex-start"}
                      alignItems={"center"}
                      gap={2}
                      xs={mdUp ? 4 : 12}
                    >
                      {/* visibility action buttons */}

                      <IconButton
                        size="small"
                        onClick={() => {
                          handleCopy(_item.secret, "secret");
                        }}
                      >
                        <ContentCopy />
                      </IconButton>

                      <IconButton
                        size="small"
                        onClick={() => {
                          handleShowSecret(_item);
                        }}
                      >
                        {!_item.show && <Visibility />}
                        {_item.show && <VisibilityOff />}
                      </IconButton>

                      {/* action buttons */}

                      <StyledActionButton
                        actiontype="error"
                        onClick={() => handleOnDelete({ secret: _item.secret, name: _item.name })}
                      >
                        Delete
                      </StyledActionButton>
                    </Grid>
                  </Grid>
                </TableCell>
              </TableRow>
            );
          })}
        </Table>
      </ContentSection>

      {/* Marqo Endpoint details */}

      <ContentSection id={"MarqoEndpointDetails-Section"}>
        <Grid container spacing={2} alignItems={"center"}>
          <Grid item sm={8} alignItems={"center"}>
            <CardTitle title={"Marqo Endpoint Details"} noDivider rootProps={{ justifyContent: "center" }} />
          </Grid>

          {/* fields */}

          <Grid item xs={12}>
            {/* url */}
            <Box mt={2}>
              <InputLabel>API Endpoint</InputLabel>
              <Box
                sx={{
                  mt: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <TextField value={marqoEndpoint} size="small" fullWidth sx={{ mr: 2 }} />
                <Tooltip title={"Copied"} open={copyItems.includes("url")} placement={"top"} arrow>
                  <Button onClick={() => handleCopy(marqoEndpoint, "url", "Marqo endpoint URL")}>Copy</Button>
                </Tooltip>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </ContentSection>
    </ContentLayout>
  );
};

export default APIKeysPage;
