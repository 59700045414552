import { alpha } from "@mui/material";
import { hexToRgb } from "@mui/system";

const neutral = {
  100: "#FFFFFF",
  150: "#FAFAFA",
  200: "#E5E7EB",
  250: "#D9D9D9",
  300: "#D1D5DB",
  400: "#9CA3AF",
  500: "#6B7280",
  600: "#4B5563",
  700: "#30324A", // "#374151",
  800: "#242536",
  900: "#1A1B28",
  950: "#0D0F35",
};

export const getNeutralRgb = (weight: string) => hexToRgb(neutral[weight as unknown as keyof typeof neutral]);

const divider = "#2D3748";

const primary = {
  main: "#00FFAA",
  light: "#909BEF", //#47A07B
  dark: "#513EE9",
  contrastText: neutral[900],
};

const secondary = {
  main: "#513EE9",
  light: "#3FC79A",
  dark: neutral[900], // "#0B815A",
  contrastText: neutral[100],
};

const success = {
  light: "#43C6B7",
  main: "rgba(0, 255, 170, 0.6)",
  dark: "#0E8074",
  contrastText: neutral[900],
};

const info = {
  main: "#2196F3",
  light: "#64B6F7",
  dark: "#0B79D0",
  contrastText: neutral[900],
};

const warning = {
  main: "#E3B166",
  light: "#E3B166",
  dark: "#B27B16",
  contrastText: neutral[900],
};

const error = {
  main: "#D14343",
  light: "#DA6868",
  dark: "#690822", // #922E2E
  contrastText: neutral[150],
};

const text = {
  primary: neutral[100],
  secondary: "#A0AEC0",
  disabled: "rgba(255, 255, 255, 0.48)",
};

const background = {
  default: neutral[800],
  toolbar: neutral[900],
  // card: neutral[700],
  contentCard: neutral[700],
  authCard: neutral[900],
  subSectionCard: neutral[900],
  warning: "rgba(227,177,102, 0.2)",
  error: `${alpha(hexToRgb(error.main), 0.2)}`,
};

export const darkThemeOptions = {
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          backgroundColor: neutral[900],
        },
        noOptions: {
          backgroundColor: neutral[900],
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: neutral[500],
          color: neutral[100],
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        li: {
          "&:last-child": {
            color: text.primary,
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        text: {
          color: neutral[100],
        },
      },
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          background: neutral[900],
        },
        grouped: {
          borderColor: "transparent",
          color: neutral[100],

          // "&.MuiButton-contained": {
          //   backgroundColor: secondary.main,
          // },
        },
      },
    },
    MuiCalendarPicker: {
      styleOverrides: {
        root: {
          background: neutral[900],
          "& .MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.MuiPickersDay-today": {
            background: neutral[900],
            color: secondary.contrastText,
            borderColor: secondary.main,
          },
          "& .MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin.Mui-selected": {
            background: secondary.main,
            color: secondary.contrastText,
          },
          "& .MuiButtonBase-root.Mui-disabled.MuiPickersDay-root.MuiPickersDay-dayWithMargin": {
            background: neutral[800],
          },
          "& .MuiButtonBase-root.MuiPickersDay-root.MuiPickersDay-dayWithMargin": {
            background: neutral[700],
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-filledDefault": {
            backgroundColor: neutral[800],
            "& .MuiChip-deleteIcon": {
              color: neutral[500],
            },
          },
          "&.MuiChip-outlinedDefault": {
            borderColor: neutral[700],
            "& .MuiChip-deleteIcon": {
              color: neutral[700],
            },
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          background: neutral[900],
          boxShadow: "0 0 10px 5px #08080a",
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        root: {
          "& .MuiSvgIcon-root": {
            fill: neutral[150],
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:hover svg": {
            fill: primary.main,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            opacity: 1,
            color: text.secondary,
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: neutral[100],
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "rgba(255, 255, 255, 0.5)",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          "& .MuiInputAdornment-root": {
            color: neutral[150],
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          // borderColor: divider,
          borderStyle: "solid",
          borderWidth: 1,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          // borderColor: divider,
          borderStyle: "solid",
          borderWidth: 1,
          background: background.authCard,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: neutral[700],
        },
        track: {
          backgroundColor: neutral[500],
          opacity: 1,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: neutral[800],
          ".MuiTableCell-root": {
            color: neutral[100],
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          "& button.Mui-selected": {
            color: neutral[100],
          },
        },
        indicator: {
          backgroundColor: "transparent",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          background: neutral[800],
        },
      },
    },
  },
  palette: {
    action: {
      active: neutral[400],
      hover: "rgba(255, 255, 255, 0.04)",
      selected: "rgba(255, 255, 255, 0.08)",
      disabledBackground: "rgba(255, 255, 255, 0.12)",
      disabled: "rgba(255, 255, 255, 0.5)",
    },
    background,
    divider,
    error,
    info,
    mode: "dark",
    neutral,
    primary,
    secondary,
    success,
    text,
    warning,
  },
  shadows: [
    "none",
    "0px 1px 2px rgba(0, 0, 0, 0.24)",
    "0px 1px 2px rgba(0, 0, 0, 0.24)",
    "0px 1px 4px rgba(0, 0, 0, 0.24)",
    "0px 1px 5px rgba(0, 0, 0, 0.24)",
    "0px 1px 6px rgba(0, 0, 0, 0.24)",
    "0px 2px 6px rgba(0, 0, 0, 0.24)",
    "0px 3px 6px rgba(0, 0, 0, 0.24)",
    "0px 4px 6px rgba(0, 0, 0, 0.24)",
    "0px 5px 12px rgba(0, 0, 0, 0.24)",
    "0px 5px 14px rgba(0, 0, 0, 0.24)",
    "0px 5px 15px rgba(0, 0, 0, 0.24)",
    "0px 6px 15px rgba(0, 0, 0, 0.24)",
    "0px 7px 15px rgba(0, 0, 0, 0.24)",
    "0px 8px 15px rgba(0, 0, 0, 0.24)",
    "0px 9px 15px rgba(0, 0, 0, 0.24)",
    "0px 10px 15px rgba(0, 0, 0, 0.24)",
    "0px 12px 22px -8px rgba(0, 0, 0, 0.24)",
    "0px 13px 22px -8px rgba(0, 0, 0, 0.24)",
    "0px 14px 24px -8px rgba(0, 0, 0, 0.24)",
    "0px 20px 25px rgba(0, 0, 0, 0.24)",
    "0px 25px 50px rgba(0, 0, 0, 0.24)",
    "0px 25px 50px rgba(0, 0, 0, 0.24)",
    "0px 25px 50px rgba(0, 0, 0, 0.24)",
    "0px 25px 50px rgba(0, 0, 0, 0.24)",
  ],
};
