import { Box, Divider, Typography } from "@mui/material";
import React from "react";
import { CardTitleProps } from "./card-title.types";

const CardTitle: React.FC<CardTitleProps> = ({
  title,
  rootProps,
  typographyProps,
  dividerProps,
  noDivider,
}): JSX.Element => {
  return (
    <Box display={"flex"} flexDirection={"column"} width={"100%"} {...rootProps}>
      <Typography variant={"h5"} {...typographyProps}>
        {title}
      </Typography>
      {!noDivider && <Divider sx={{ mt: 1 }} {...dividerProps} />}
    </Box>
  );
};

export default CardTitle;
