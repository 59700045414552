import { ContentCopy } from "@mui/icons-material";
import { Grid, IconButton, Typography } from "@mui/material";
import classNames from "classnames";
import copy from "copy-to-clipboard";
import { Highlight, themes as prismThemes } from "prism-react-renderer";
import React from "react";
import { WrapperClasses } from "../../constants/styles";
import { setToast } from "../../slices/app";
import { useDispatch } from "../../store";
import { generateRandomKey } from "../../utils";

type Props = {
  snippet: string;
  language: string;
  terminal: string;
  dataTestIdPrefix: string;
};

const CodeSnippetBlock: React.FC<Props> = ({ snippet, language, terminal, dataTestIdPrefix }) => {
  const dispatch = useDispatch();
  const wrapperClasses = WrapperClasses();

  const cleanSnippet = (rawSnippet: string) => {
    const commentRegex = /^\s*(\/\/|\/\*|#)/;
    return rawSnippet
      .split("\n")
      .filter((_line) => {
        return !commentRegex.test(_line);
      })
      .join("\n");
  };

  const handleSimpleCopy = (valueToCopy: string, label: string) => {
    copy(valueToCopy);
    dispatch(
      setToast({
        msg: `${label} has been copied to clipboard.`,
        type: "success",
        hash: generateRandomKey(6),
      }),
    );
  };

  return (
    <Grid container item xs={12}>
      <Grid container item xs={12} className={"terminal-wrapper"}>
        <Grid container item px={5} py={2} className={"terminal-header"}>
          <Typography sx={{ fontSize: "0.8rem" }}>{terminal}</Typography>
        </Grid>
        <Grid item className={classNames("highlight-wrapper", wrapperClasses.leanSnippetScrollbar)}>
          <Highlight theme={prismThemes.vsDark} code={snippet} language={language}>
            {({ className, style, tokens, getLineProps, getTokenProps }) => (
              <pre style={style} data-testid={dataTestIdPrefix + "-code"}>
                {tokens.map((line, i) => (
                  <div key={i} {...getLineProps({ line })}>
                    {line.map((token, key) => (
                      <span key={key} {...getTokenProps({ token })} />
                    ))}
                  </div>
                ))}
              </pre>
            )}
          </Highlight>
        </Grid>
      </Grid>

      {snippet !== "Unavailable" && (
        <span style={{ position: "relative" }}>
          <span style={{ position: "absolute", right: "40px", top: "80px" }}>
            <IconButton
              size="small"
              data-testid={dataTestIdPrefix + "-copy-button"}
              style={{ padding: 0 }}
              onClick={() => handleSimpleCopy(cleanSnippet(snippet), "Snippet")}
            >
              <ContentCopy fontSize={"small"} />
            </IconButton>
          </span>
        </span>
      )}
    </Grid>
  );
};

export default CodeSnippetBlock;
