import { Box, Button, CircularProgress, Theme, Typography, useMediaQuery } from "@mui/material";
import { FormikProps } from "formik";
import React, { Fragment } from "react";
import { useAuth } from "../../hooks/use-auth";
import { hasEmpty } from "../../utils/equality";
import ga4EventsLogger from "../../utils/google-analytics/events/logger";
import { buttonClasses } from "../button/button.styles";
import Form from "../form/form.component";
import { FormConfigType } from "../form/form.types";
import { OtherAuthLink } from "../page-parts/auth/sign-up-link.component";
import { privacyPolicy, termsAndConditions } from "../page-parts/quick-links/quick-links";
import { FormStep } from "./auth.enums";
import { AuthBaseClasses } from "./auth.styles";

type Props = {
  formik: FormikProps<any>;
  currentFormConfig: FormConfigType;
  currentStep: FormStep;
  nextHandler: () => void;
  formBtnText: string;
  formSubmittingText?: string;
  showOtherAuthLink?: boolean;
};

export const JWTRegister: React.FC<Props> = (props) => {
  const { tempRegisterFormData, setRegisterFormData } = useAuth();
  const { formik, currentFormConfig, currentStep, formBtnText, formSubmittingText } = props;
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("md"), { noSsr: true });
  // styles
  const classes = AuthBaseClasses();
  const buttonStyles = buttonClasses();

  const hasErrors = Object.values(formik.errors).length > 0;
  const { submit: _submit, token: _cardToken, ...formikValues } = formik.values;
  const isSubmitting = formik.isSubmitting;
  const isTouched = Object.keys(formik.touched).length > 0;

  const getFormikFields = () => {
    if (currentStep === FormStep.SELECT_AUTH_METHOD) {
      return { email: formikValues.email, password: formikValues.password };
    } else if (currentStep === FormStep.CC_INFO) {
      return { ...formikValues, token: _cardToken };
    } else if (currentStep === FormStep.FILL_ALL_SIGNUP_VIA_EMAIL_FIELDS) {
      return { name: formikValues.name, country: formikValues.country, acceptTerms: formikValues.acceptTerms };
    } else if (currentStep === FormStep.FILL_ALL_SIGNUP_VIA_SSO_FIELDS) {
      return { country: formikValues.country, acceptTerms: formikValues.acceptTerms };
    } else if (currentStep === FormStep.USER_BACKGROUND) {
      return {
        industry: formikValues.industry,
        useCase: formikValues.useCase,
        intentionOfUse: formikValues.intentionOfUse,
      };
    } else {
      return formikValues;
    }
  };

  const handleNextBtn = () => {
    if (!hasErrors && !hasEmpty(getFormikFields())) {
      if (currentStep === FormStep.FILL_ALL_SIGNUP_VIA_EMAIL_FIELDS) {
        ga4EventsLogger.logSignupView(formik.values.email, formik.values.organization);
      }
      setRegisterFormData({ ...tempRegisterFormData, token: formik.values.token, formErrors: [] });
      props?.nextHandler();
    }
  };

  const isNextBtnEnabled = () => {
    const formikFields = getFormikFields();
    return !isSubmitting && !hasErrors && hasEmpty(formikFields) !== isTouched;
  };

  const handleOnKeyDown = (e: React.KeyboardEvent<HTMLFormElement>) => {
    if (e.key === "Enter") {
      handleNextBtn();
    }
  };

  const buttonContent =
    isSubmitting && formSubmittingText ? (
      <div>
        {formSubmittingText}
        <span style={{ textAlign: "center", marginLeft: "1em" }}>
          <CircularProgress size="1em" color="inherit" />
        </span>
      </div>
    ) : (
      <div>{formBtnText}</div>
    );

  return (
    <Fragment>
      <Form spacing={2} formik={formik} formProps={{ onKeyDown: handleOnKeyDown }} formConfig={currentFormConfig}>
        <Box className={classes.customFormElementsWrapper}>
          <Button
            disabled={!isNextBtnEnabled()}
            fullWidth
            size="large"
            variant="contained"
            color="secondary"
            className={buttonStyles.authBtn}
            onClick={handleNextBtn}
          >
            {buttonContent}
          </Button>
        </Box>
      </Form>

      {props.showOtherAuthLink && <OtherAuthLink toSignUp={false} />}

      <Box className={classes.authTCWrapper}>
        <Typography sx={{ color: "neutral.950", textAlign: "center", fontSize: "0.875rem" }}>
          By submitting, you agree to our {termsAndConditions} and {privacyPolicy}.
        </Typography>
      </Box>
    </Fragment>
  );
};
