export enum IndexWorkflowsStorageMapping {
  BASIC = "marqo.basic",
  BALANCED = "marqo.balanced",
  PERFORMANCE = "marqo.performance",
}

export enum IndexWorkflowsInferenceMapping {
  "CPU.SMALL" = "marqo.CPU.small",
  CPU = "marqo.CPU.large",
  GPU = "marqo.GPU",
}

export const CPU_SMALL = IndexWorkflowsInferenceMapping["CPU.SMALL"];

export enum IndexStatus {
  CREATING = "Creating",
  CREATE_SCHEDULED = "Create scheduled",
  DELETE_SCHEDULED = "Delete scheduled",
  DELETING = "Deleting",
  MODIFY_SCHEDULED = "Modify scheduled",
  MODIFYING = "Modifying",
  READY = "Ready",
  DELETED = "Deleted",
  ROLLBACK_SCHEDULED = "Creating",
  ROLLING_BACK = "Creating",
  FAILED = "Failed",
}

export enum FreeMemInGb {
  "marqo.basic" = 3.8,
  "marqo.balanced" = 52.7,
  "marqo.performance" = 52.7,
}

export enum MarqoVersion {
  V1 = "1.X",
  V2 = "2.X",
}

export enum IndexType {
  UNSTRUCTURED = "unstructured",
  STRUCTURED = "structured",
}

export enum IndexingMode {
  TEXT = "text",
  IMAGE = "image",
  AUDIO_VIDEO = "audio-video",
}

export enum ModelModality {
  TEXT = "text",
  IMAGE = "image",
  AUDIO = "audio",
  VIDEO = "video",
}

// default models dimension if not provided
export const BASE_DIMENSION = 768;
