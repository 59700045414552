import { ChartItemData } from "../../../../chart/chart.types";
import { RangeItemType, RangeSelectHandler } from "../../../../range-buttons/range-buttons.types";

export type ChartModalProps = {
  indexName: string;
  operation: string;
  chartData: ChartItemData;
  onClose: () => void;
  onRangeButtonClick?: RangeSelectHandler;
  selectedRange: RangeItemType["value"];
  isFetching: boolean;
};

export enum StatisticType {
  sampleCount = "SampleCount",
  average = "Average",
  sum = "Sum",
  min = "Minimum",
  max = "Maximum",
}
