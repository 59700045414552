import axios, { AxiosPromise } from "axios";
import { IndexItem } from "../../components/page-parts/indexes/indexes.types";
import config from "../../config";
import { endpoints } from "../../config/common";
import { AccountId } from "../api.types";
import {
  CreateIndexReq,
  GetAllIndexesStatsReqType,
  GetAllIndicesRes,
  GetIndexStatsRes,
  GetModelsResponse,
  GetV1IndexStatsRes,
  IndexWorkflowsResponse,
  ModifyIndexReq,
  ReqGetIndexMetrics,
  ReqGetIndexMetricsManyType,
  ResGetIndexMetrics,
} from "./indexes.types";

const createIndex = (
  accountId: AccountId,
  payload: CreateIndexReq,
  endpoint: string,
): AxiosPromise<IndexWorkflowsResponse> => {
  const { route, ...formData } = payload;
  return axios({
    method: "POST",
    baseURL: config.baseURL,
    url: `${endpoint}/${route}`,
    params: { accountId },
    data: formData,
  });
};

const modifyIndex = (
  accountId: AccountId,
  payload: ModifyIndexReq,
  endpoint: string,
): AxiosPromise<IndexWorkflowsResponse> => {
  const { route, ...formData } = payload;
  return axios({
    method: "PUT",
    baseURL: config.baseURL,
    url: `${endpoint}/${route}`,
    params: { accountId },
    data: formData,
  });
};

const getAllIndices = (accountId: AccountId): AxiosPromise<GetAllIndicesRes> => {
  return axios({
    method: "GET",
    baseURL: config.baseURL,
    url: `${config.endpoints.v2IndexWorkflows}`,
    params: { accountId },
  });
};

const getIndexStats = (accountId: AccountId, indexName: string): AxiosPromise<GetIndexStatsRes> => {
  return axios({
    method: "GET",
    baseURL: config.baseURL,
    url: `${config.endpoints.v2IndexWorkflows}/${indexName}/stats`,
    params: { accountId },
  });
};

const getV1IndexStats = (accountId: AccountId, indexName: string): AxiosPromise<GetV1IndexStatsRes> => {
  return axios({
    method: "GET",
    baseURL: config.baseURL,
    url: `${config.endpoints.v1IndexWorkflows}/${indexName}/stats`,
    params: { accountId },
  });
};

const getIndexMetrics = (accountId: AccountId, queryParams: ReqGetIndexMetrics): AxiosPromise<ResGetIndexMetrics> => {
  if (["TargetResponseTime", "RequestCount", "4XX", "5XX"].includes(queryParams.operation)) {
    return axios({
      method: "GET",
      baseURL: config.baseURL,
      url: `${config.endpoints.rp_metrics}`,
      params: { accountId, ...queryParams },
    });
  }

  return axios({
    method: "GET",
    baseURL: config.baseURL,
    url: `${config.endpoints.index_metrics}`,
    params: { accountId, ...queryParams },
  });
};

const getIndexMetricsMany = (accountId: AccountId, paramsGroup: ReqGetIndexMetricsManyType) => {
  return Promise.all(paramsGroup.map((params) => getIndexMetrics(accountId, params)));
};

const getAllIndexesStats = (accountId: AccountId, indexNamesList: GetAllIndexesStatsReqType) => {
  const requests: AxiosPromise<GetIndexStatsRes | GetV1IndexStatsRes>[] = indexNamesList.map(({ indexName, isV2 }) => {
    return (isV2 ? getIndexStats : getV1IndexStats)(accountId, indexName);
  });
  return Promise.all(requests);
};

const deleteIndex = (accountId: AccountId, indexItem: IndexItem, endpoint: string): AxiosPromise<any> => {
  return axios({
    method: "delete",
    baseURL: config.baseURL,
    url: `${endpoint}/${indexItem.indexName}`,
    params: { accountId },
  });
};

const getModels = (accountId: AccountId): AxiosPromise<GetModelsResponse> => {
  return axios({
    method: "GET",
    baseURL: config.baseURL,
    url: endpoints.getModelsEndpoint,
    params: { accountId },
  });
};

const Indexes = {
  create: createIndex,
  modify: modifyIndex,
  delete: deleteIndex,
  get: {
    all: getAllIndices,
    indexStats: getIndexStats,
    v1IndexStats: getV1IndexStats,
    allIndexesStats: getAllIndexesStats,
    metrics: getIndexMetrics,
    metricsMany: getIndexMetricsMany,
    models: getModels,
  },
};

export default Indexes;
