import prettyBytes from "pretty-bytes";
import {
  IndexItem,
  IndexStatsType,
  StatParamFormatter,
  StatsParamUtilProps,
} from "../../components/page-parts/indexes/indexes.types";
import { MARQO_V1_MINOR_VERSION } from "../../constants";
import { getIndexStatusLabel } from "../../utils/indices";
import { plural } from "../formatters";
import { toMonthDYr } from "../time-formatter";
import { IndexStatus, IndexWorkflowsInferenceMapping, IndexWorkflowsStorageMapping } from "./../../constants/enums";
import IndexSettingsUtils from "./index-creation-utils";

type GridConfig = {
  [key: string]: {
    title: string;
    text: string;
    sizes?: number;
    copyTitle?: string;
  };
};

const toLocalStringFormatter = (val: number): string => {
  return val.toLocaleString("en-US");
};

const bytesFormatter = (val: number): string => {
  return prettyBytes(val);
};

const indexPercentageFormatter = (val: number, version: string): string => {
  if (/^2/.test(version)) {
    val = val * 100;
  }

  if (val > 0 && val < 1) {
    return "< 1 %";
  } else if (val < 0) {
    return "-";
  }
  return `${Math.round(val)} %`;
};

const statsParams: StatsParamUtilProps = {
  dataSize: {
    displayName: "Data Size",
    formatter: bytesFormatter,
  },
  docCount: {
    displayName: "Document Count",
    formatter: toLocalStringFormatter,
  },
  vectorCount: {
    displayName: "Vector Count",
    formatter: toLocalStringFormatter,
  },
  indexPercentage: {
    displayName: "Index Disk %",
    formatter: indexPercentageFormatter,
  },
};

const getIndexStatParamValue = (indexObj: IndexItem, statParam: string, formatter: StatParamFormatter): string => {
  if (!!indexObj.stats && ![null, undefined].includes(indexObj.stats[statParam as keyof IndexStatsType])) {
    if (statParam === "indexPercentage") {
      return formatter(indexObj.stats["indexPercentage"], indexObj?.marqoVersion);
    }
    return formatter(indexObj.stats[statParam as keyof IndexStatsType]);
  }

  return "-";
};

const indexSummaryGridConfig = (indexObj: IndexItem): GridConfig => {
  const { indexName, marqoEndpoint, marqoVersion } = indexObj;

  return {
    indexName: {
      title: "Index name",
      text: indexName,
      copyTitle: "Index name",
    },
    endpoint: {
      title: "Endpoint",
      text: marqoEndpoint,
      copyTitle: "Endpoint",
    },
    dateCreated: {
      title: "Date created (UTC)",
      text: !indexObj.Created ? "-" : toMonthDYr(indexObj.Created),
    },
    marqoVersion: {
      title: "Marqo version",
      text: marqoVersion || MARQO_V1_MINOR_VERSION,
    },
  };
};

const indexScalingAndModelGridConfig = (indexObj: IndexItem): GridConfig => {
  const { numberOfInferences, numberOfShards, storageClass, numberOfReplicas, inferenceType } = indexObj;

  const model = IndexSettingsUtils.getIndexSettingsModel(indexObj);

  return {
    inferencePods: {
      title: "Inference pods",
      text: !indexObj.Created
        ? "-"
        : `${numberOfInferences} ${
            IndexWorkflowsInferenceMapping[inferenceType as keyof typeof IndexWorkflowsInferenceMapping]
          }`,
    },
    storageShards: {
      title: "Storage shards",
      text: !indexObj.Created
        ? "-"
        : `${numberOfShards} ${
            IndexWorkflowsStorageMapping[storageClass as keyof typeof IndexWorkflowsStorageMapping]
          }, ${numberOfReplicas} replica${plural(numberOfReplicas)}`,
    },
    model: {
      title: "Model",
      text: model,
      copyTitle: model,
    },
    indexType: {
      title: "Index type",
      text: IndexSettingsUtils.getIndexType(indexObj),
    },
  };
};

const indexStatsGridConfig = ({ stats, marqoVersion }: IndexItem): GridConfig => {
  return {
    dataSize: {
      title: "Disk usage per shard",
      text: !!stats ? IndexDetailsUtils.bytesFormatter(stats.dataSize) : "-",
    },
    indexPercentage: {
      title: "Index disk %",
      text: !!stats ? IndexDetailsUtils.indexPercentageFormatter(stats.indexPercentage, marqoVersion) : "-",
    },
    vectorCount: {
      title: "Vector count",
      text: !!stats ? IndexDetailsUtils.toLocalStringFormatter(stats.vectorCount) : "-",
    },
    docCount: {
      title: "Document count",
      text: !!stats ? IndexDetailsUtils.toLocalStringFormatter(stats.docCount) : "-",
    },
  };
};

const getIndexSettings = (indexObj: IndexItem): string => {
  try {
    if (!IndexSettingsUtils.isV2Index(indexObj)) {
      if (!indexObj.index_defaults) throw new Error("Missing index defaults");
      return JSON.stringify(indexObj.index_defaults, null, 2);
    }

    const modelProperties = indexObj?.modelProperties ? { modelProperties: indexObj.modelProperties } : {};
    const settings = {
      type: indexObj?.type,
      vectorNumericType: indexObj?.vectorNumericType,
      treatUrlsAndPointersAsImages: indexObj?.treatUrlsAndPointersAsImages,
      treatUrlsAndPointersAsMedia: indexObj?.treatUrlsAndPointersAsMedia,
      model: indexObj?.model,
      ...modelProperties,
      normalizeEmbeddings: indexObj?.normalizeEmbeddings,
      textPreprocessing: indexObj?.textPreprocessing,
      imagePreprocessing: indexObj?.imagePreprocessing,
      annParameters: indexObj?.annParameters,
      filterStringMaxLength: indexObj?.filterStringMaxLength,
      tensorFields: indexObj?.tensorFields,
      allFields: indexObj?.allFields,
    };
    return JSON.stringify(settings, null, 2);
  } catch (e) {
    return "Unavailable";
  }
};

const showCodeSnippets = (fetchingInProgress: boolean, indexObj: IndexItem) => {
  return !fetchingInProgress && !!indexObj.Created && getIndexStatusLabel(indexObj.indexStatus) !== IndexStatus.FAILED;
};

const showStatusCircle = (fetchingInProgress: boolean, indexObj: IndexItem) => {
  return !fetchingInProgress && ["Ready"].includes(getIndexStatusLabel(indexObj.indexStatus));
};

const showStatusInfo = (fetchingInProgress: boolean, indexObj: IndexItem) => {
  return (
    !fetchingInProgress &&
    ["Failed", "Modifying", "Deleting", "Creating"].includes(getIndexStatusLabel(indexObj.indexStatus))
  );
};

const countdownAction = (fetchingInProgress: boolean, indexObj: IndexItem) => {
  return (
    !fetchingInProgress && ["Creating", "Modifying", "Deleting"].includes(getIndexStatusLabel(indexObj.indexStatus))
  );
};

const IndexDetailsUtils = {
  statsParams,
  toLocalStringFormatter,
  getIndexStatParamValue,
  bytesFormatter,
  indexPercentageFormatter,
  indexSummaryGridConfig,
  indexScalingAndModelGridConfig,
  indexStatsGridConfig,
  showCodeSnippets,
  getIndexSettings,
  showStatusCircle,
  countdownAction,
  showStatusInfo,
};
export default IndexDetailsUtils;
