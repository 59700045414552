import React from "react";
import { Navigate } from "react-router";
import { SplashScreen } from "../components/splash-screen/splash-screen.component";
import { useAuth } from "../hooks/use-auth";
import { useSelector } from "../store";
import { selectActiveAccounts, selectCurrentAccountId } from "../store/selectors";
import SelectAccount from "./accounts/account-select.page";

const MainPage: React.FC = (): JSX.Element => {
  const { isAuthenticated, authChecking } = useAuth();
  const activeAccounts = useSelector(selectActiveAccounts);
  const currentAccountId = useSelector(selectCurrentAccountId);
  if (authChecking) {
    return <SplashScreen />;
  }
  if (isAuthenticated) {
    if (activeAccounts.length && !currentAccountId) {
      return <SelectAccount />;
    }
    return <Navigate to={"/indexes"} />;
  }
  return <Navigate to={"/authentication/login"} />;
};
export default MainPage;
