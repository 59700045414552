import { Elements as StripeElements } from "@stripe/react-stripe-js";
import { StripeError, Token } from "@stripe/stripe-js";
import classNames from "classnames";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { stripeServicePromise } from "../../api/stripe/stripe.api";
import { AuthBaseClasses } from "../../components/authentication/auth.styles";
import ContentLayout from "../../components/content-layout/content-layout.component";
import StripeBillingDetails from "../../components/page-parts/billing/stripe/stripe-billing-details.component";
import { stripeAppearance as appearance } from "../../components/page-parts/billing/stripe/stripe.styles";
import Config from "../../config";
import { CreateIndexFormProps } from "../../form-configs/indexes/types";
import { clearToCreate } from "../../slices/indexes/indexes-main";
import { useDispatch, useSelector } from "../../store";
import { selectIndexesStatsReqList } from "../../store/selectors";
import { createAccount } from "../../thunks/account.thunk";
import { createIndex, getAllIndexesStatsThunk } from "../../thunks/indexes.thunk";
import { getAccountLimitsThunk } from "../../thunks/user.thunk";
import ga4EventsLogger from "../../utils/google-analytics/events/logger";

export default function GuestBillingPage() {
  const { billingInfo } = useSelector(({ billing }) => billing.stripeIntegration);
  const { toCreate } = useSelector(({ indices }) => indices.common);
  const indexNamesList = useSelector(selectIndexesStatsReqList);
  const dispatch = useDispatch();

  const classes = AuthBaseClasses();
  const navigate = useNavigate();

  const { email, organization } = useSelector(({ user }) => user.data);

  // Creates an index if the user tried to create one before entering billing details.
  const handlePendingIndexCreation = () => {
    if (!toCreate) {
      return;
    }
    dispatch(
      createIndex({
        formData: toCreate as CreateIndexFormProps,
        onSuccess: () => {
          navigate(`/indexes/${toCreate["indexName"]}`);
          dispatch(getAllIndexesStatsThunk(indexNamesList));
        },
      }),
    );
  };

  // Clear pending index creation details when the user navigates away from this page.
  useEffect(() => {
    return () => {
      dispatch(clearToCreate());
    };
  }, []);

  const submitHandler = async (token: Token) => {
    return new Promise<any>((resolve, reject) => {
      function onComplete() {
        resolve(null);
        ga4EventsLogger.logCcSignupPageSuccessfulSubmit(email, organization);
        // TODO(oliver): Integrate into account creation flow.
        dispatch(getAccountLimitsThunk());
        handlePendingIndexCreation();
        navigate(Config.authenticatedPaths.indexes);
      }

      function onError() {
        reject();
        ga4EventsLogger.logGuestBillingPageViewFailedSubmit(email, organization);
      }

      dispatch(createAccount({ token: token.id }))
        .then(onComplete)
        .catch(onError);
    });
  };

  const submitErrorHandler = (tokenError: StripeError) => {
    ga4EventsLogger.logGuestBillingPageViewFailedSubmit(email, organization);
  };

  useEffect(() => {
    ga4EventsLogger.logGuestBillingPageView(email, organization);
  }, []);

  return (
    <ContentLayout pageTitle={"Billing"}>
      <div
        className={classNames(classes.authForm, classes.stripeBillingCardForm)}
        style={{ backgroundColor: "white", width: "400px", borderRadius: "0.5em", margin: "auto" }}
      >
        <StripeElements
          stripe={stripeServicePromise}
          options={{
            fonts: [
              {
                cssSrc:
                  "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;1,300;1,400&display=swap",
              },
            ],
            appearance,
            loader: "always",
            ...(!!billingInfo.clientSecret && { clientSecret: billingInfo.clientSecret }),
          }}
        >
          <StripeBillingDetails
            submitHandler={submitHandler}
            submitErrorHandler={submitErrorHandler}
            submittingMessage="Creating your account, this might take a few seconds..."
            showTermsConditions={false}
            showSkipButton={false}
            showBackButton={false}
          />
        </StripeElements>
      </div>
    </ContentLayout>
  );
}
