import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getNeutralRgb } from "../../theme/dark-theme-options";

export const LinkClasses = makeStyles((_: Theme) => ({
  leanLink: {
    color: "neutral.950",
    textDecoration: "none",
    "& span": {
      fontWeight: "bold",
      color: getNeutralRgb("800"),

      "&.light": {
        color: getNeutralRgb("200"),
      },
    },
  },
}));
