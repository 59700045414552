import { ArrowDropDownRounded, ArrowDropUpRounded, InfoRounded } from "@mui/icons-material";
import {
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  TextFieldProps,
  Theme,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { ClassNameMap } from "@mui/styles";
import classNames from "classnames";
import React, { Fragment, useEffect, useState } from "react";
import * as Yup from "yup";
import { ellipsisStyles } from "../../constants/styles";
import { buttonClasses } from "../button/button.styles";
import MultilineField from "../form-fields/multiline-field";
import NumberField, { ControlNumberField } from "../form-fields/number-field";
import PasswordField from "../form-fields/password-field";
import RadioGroupField from "../form-fields/radio-group-field";
import Select, { Option, RadioGroupFieldProps } from "../form-fields/select-field";
import StripeCardField from "../form-fields/stripe-card-field";
import SelectOptionCard from "../page-parts/indexes/select-card.component";
import { formClasses } from "./form.styles";
import {
  FieldConfigType,
  FieldGroupVisibilitiesType,
  FormConfigType,
  FormProps,
  GetFormConfigType,
  GroupFieldType,
} from "./form.types";

const customFields = ["multi-select", "json-field", "card-select", "radio-group", "checkbox", "stripe"];

export const getFormConfig = <T,>(formConfig: FormConfigType<T>): GetFormConfigType<T> => {
  const yupObject: {} = Object.entries(formConfig.fields).reduce<{}>((prev, cur) => {
    const [key, values] = cur;
    const fieldData: FieldConfigType = values;
    if (fieldData.validation) {
      return { ...prev, [key]: fieldData.validation.validate };
    }
    return prev;
  }, {});

  const initialValues = Object.entries(formConfig.fields).reduce((prev, cur) => {
    const [key, value] = cur;
    const fieldData: FieldConfigType = value;
    return { ...prev, [key]: fieldData.initialValue || "" };
  }, {});

  return {
    initialValues: initialValues as T,
    validationSchema: Yup.object(yupObject),
  };
};

export const renderInputStartAdornment = (icon: React.ReactNode) => {
  return {
    InputProps: {
      startAdornment: <InputAdornment position={"start"}>{icon}</InputAdornment>,
    },
  };
};

export const FormField = (
  props: TextFieldProps & {
    options: { id: number | string; value: string; title: string | number }[];
  },
) => {
  switch (props.type as FieldConfigType["type"]) {
    case "select":
      return <Select {...props} options={props.options} />;
    case "password":
      return <PasswordField {...props} />;
    case "number-field":
      return <NumberField {...props} />;
    case "number":
      return <ControlNumberField {...props} />;
    default:
      return <TextField {...props} />;
  }
};

const Form: React.FC<FormProps> = (props) => {
  const UNCATEGORIZED_GROUP_NAME = "uncategorized";
  const btnClasses = buttonClasses();
  const formComponentClasses = formClasses();
  // states
  const [fieldGroupVisibilities, setFieldGroupVisibilities] = useState<FieldGroupVisibilitiesType>({});
  const {
    formik,
    formConfig,
    formProps,
    spacing,
    children,
    disabledFields: disabledFiedls,
    wrapChildren = false,
  } = props;
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"), { noSsr: true });

  function getUniqueGroupNames(entries: FormConfigType["fields"]): string[] {
    // : string[]
    const groupNames: string[] = Object.values(entries)
      .filter((_field) => !!_field?.groupName)
      .map((_field) => _field.groupName);
    return [...Array.from(new Set(groupNames))];
  }

  function getGroupedFields(entries: FormConfigType["fields"]): any {
    const groupedFields: { [groupKey: string]: any } = {};

    // group the uncategorized fields together first
    // includes hidden fields that are uncategorized
    groupedFields[UNCATEGORIZED_GROUP_NAME] = Object.entries(entries).reduce(
      (uncategorizedFields: GroupFieldType, [name, config]) => {
        if (!config?.groupName) {
          uncategorizedFields[name] = config;
        }
        return uncategorizedFields;
      },
      {},
    );

    getUniqueGroupNames(entries).forEach((groupName) => {
      groupedFields[groupName] = Object.entries(entries).reduce((categorizedFields: GroupFieldType, [name, config]) => {
        if (config?.groupName === groupName) {
          categorizedFields[name] = config;
        }
        return categorizedFields;
      }, {});
    });

    return groupedFields;
  }

  function handleFieldGroupVisibilityToggle(groupName: string): void {
    const visibilities = { ...fieldGroupVisibilities };
    visibilities[groupName] = !visibilities[groupName];
    setFieldGroupVisibilities(visibilities);
  }

  useEffect(() => {
    const groupedFields = getGroupedFields(formConfig?.fields);
    // initialize visibility states
    setFieldGroupVisibilities(
      Object.keys(groupedFields).reduce((resultingDict: FieldGroupVisibilitiesType, groupName) => {
        resultingDict[groupName] = groupName === UNCATEGORIZED_GROUP_NAME;
        return resultingDict;
      }, {}),
    );
  }, []);

  return (
    <form
      {...formProps}
      noValidate
      onSubmit={formik.handleSubmit}
      style={{ width: "100%", display: "flex", flexDirection: "column" }}
    >
      {formConfig.description && <Typography sx={{ mb: 4 }}>{formConfig.description}</Typography>}
      <Grid container spacing={spacing} className="formFieldsWrapper">
        {/* display fields by group name */}
        {/* potential change in next iterations: add overriding params */}

        {Object.entries(getGroupedFields(formConfig?.fields)).map(([groupName, groupFields], groupKey) => {
          return (
            <Fragment key={`groupKey-${groupKey}`}>
              {/* show / hide group toggle button */}

              {!!groupName && groupName !== UNCATEGORIZED_GROUP_NAME && (
                <Grid container item>
                  <Button
                    className={btnClasses.leanTextButton}
                    onClick={() => handleFieldGroupVisibilityToggle(groupName)}
                  >
                    {fieldGroupVisibilities[groupName] ? (
                      <Fragment>
                        Hide {groupName.toLowerCase()}
                        <ArrowDropDownRounded fontSize={"small"} />
                      </Fragment>
                    ) : (
                      <Fragment>
                        Show {groupName.toLowerCase()}
                        <ArrowDropUpRounded fontSize={"small"} />
                      </Fragment>
                    )}
                  </Button>
                </Grid>
              )}

              {/* render grouped fields */}

              {Object.entries(groupFields).map(([name, config], groupFieldKey) => {
                const { sizes, ...props } = config;

                const hideField = typeof config.hidden === "function" ? config.hidden(formik.values) : config.hidden;
                const showLabel = formConfig?.formInputMode !== "light-zen" || config.showLabel;

                const styles: ClassNameMap = config.styles ? config.styles() : {};

                return (
                  !hideField &&
                  fieldGroupVisibilities[groupName] && (
                    <Fragment key={`groupFieldKey-${groupFieldKey}`}>
                      <Grid item {...sizes} key={name} className={classNames(styles.container)}>
                        {showLabel && (
                          <Grid
                            container
                            flexDirection={"row"}
                            justifyContent={
                              config.type === "json-field" || (lgUp && config?.tooltipJustifyEndOnLgUp)
                                ? "space-between"
                                : "start"
                            }
                            alignItems={"center"}
                            columnGap={1}
                            mb={1}
                          >
                            <Grid
                              item
                              {...config?.labelSizing}
                              sx={{ maxWidth: "80%" }}
                              className={classNames(styles.label)}
                            >
                              <InputLabel htmlFor={name} sx={ellipsisStyles}>
                                {props.label}
                              </InputLabel>
                            </Grid>

                            {config?.tooltipTitle && (
                              <Grid
                                item
                                {...config?.tooltipTitleSizing}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: lgUp && config?.tooltipJustifyEndOnLgUp ? "flex-end" : "flex-start",
                                }}
                              >
                                <Tooltip
                                  data-testid={`tooltip-${name}`}
                                  title={config.tooltipTitle}
                                  placement="bottom-start"
                                >
                                  <InfoRounded sx={{ color: "neutral.400" }} />
                                </Tooltip>
                              </Grid>
                            )}

                            {config.type === "json-field" && config?.jsonFieldActionBarComponent && (
                              <config.jsonFieldActionBarComponent
                                name={name}
                                formik={formik}
                                {...config?.jsonFieldComponentProps}
                              />
                            )}
                          </Grid>
                        )}

                        {config.type === "card-select" && (
                          <Grid container flexDirection={"row"} spacing={2} height={"100%"}>
                            {config.options.map((_cardProps: Option, cardOptionKey: number) => {
                              const { value, title, description, subDescription, imageUrl } = _cardProps as Option;
                              return (
                                <SelectOptionCard
                                  key={`cardOptionKey-${cardOptionKey}`}
                                  name={name}
                                  selectedValue={formik.values[name]}
                                  isDisabled={!!props.componentProps?.disabled}
                                  disableToastMsg={config?.disableToastMsg}
                                  onClickFn={async (selectedValue) => {
                                    if (!config?.componentProps?.disabled) {
                                      await formik.setFieldValue(name, selectedValue, true);
                                      config?.componentProps?.onChange?.(selectedValue, formik);
                                      formik.setFieldTouched(name, true);
                                    }
                                  }}
                                  sizes={config.componentProps?.cardSizes ?? {}}
                                  value={value}
                                  title={title}
                                  imageUrl={imageUrl}
                                  description={description}
                                  subDescription={subDescription}
                                  styles={config.styles}
                                />
                              );
                            })}
                          </Grid>
                        )}

                        {config.type === "radio-group" && (
                          <RadioGroupField
                            disabled={props.componentProps?.disabled}
                            options={config.options as RadioGroupFieldProps["options"]}
                            value={formik.values[name]}
                            onChange={async (_, value) => {
                              await formik.setFieldValue(name, value, true);
                              formik.setFieldTouched(name, true);
                            }}
                          />
                        )}

                        {config.type === "checkbox" && (
                          <FormGroup className={formComponentClasses.checkboxLabel}>
                            <FormControlLabel
                              {...config.componentProps}
                              label={config.label}
                              control={<Checkbox role="checkbox" checked={!!formik.values[name]} />}
                              disabled={props.componentProps?.disabled}
                              onChange={async (_, value) => {
                                await formik.setFieldValue(name, value, true);
                                formik.setFieldTouched(name, true);
                              }}
                            />
                          </FormGroup>
                        )}

                        {config.type === "multi-select" && (
                          <Autocomplete
                            multiple
                            fullWidth
                            filterSelectedOptions
                            disableCloseOnSelect
                            limitTags={2}
                            ChipProps={{ color: "secondary" }}
                            value={formik.values[name]}
                            options={config.options || []}
                            getOptionLabel={(option: Option) => option.title as string}
                            isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                            defaultValue={config.initialValue}
                            onChange={async (_, value) => {
                              await formik.setFieldValue(name, value, true);
                              formik.setFieldTouched(name, true);
                            }}
                            renderInput={(params) => {
                              return (
                                <TextField
                                  {...params}
                                  error={formik.touched[name] && Boolean(formik.errors[name])}
                                  helperText={formik.touched[name] && formik.errors[name]}
                                />
                              );
                            }}
                          />
                        )}

                        {config.type === "json-field" && (
                          <MultilineField
                            fullWidth
                            disabled={disabledFiedls?.includes(name)}
                            id={name}
                            name={name}
                            value={formik.values[name]}
                            type={props.type}
                            error={formik.touched[name] && Boolean(formik.errors[name])}
                            helperText={formik.touched[name] && formik.errors[name]}
                            onChange={
                              config.transformValue
                                ? (e) => formik.setFieldValue(name, config.transformValue(e))
                                : (e) => {
                                    formik.setFieldTouched(name, true);
                                    formik.handleChange(e);
                                  }
                            }
                            {...props.componentProps}
                          />
                        )}

                        {config.type === "stripe" && (
                          <StripeCardField
                            id={name}
                            name={name}
                            type={props.type}
                            error={formik.errors[name]}
                            dirty={formik.touched[name]}
                            onChange={(e) => {
                              formik.setFieldTouched(name, true);
                              const value = config.transformValue ? config.transformValue(e) : e;
                              formik.setFieldValue(name, value, true);
                            }}
                            onError={(error) => formik.setFieldError(name, error)}
                            {...props.componentProps}
                          />
                        )}

                        {!customFields.includes(config.type) && (
                          <FormField
                            fullWidth
                            disabled={disabledFiedls?.includes(name)}
                            {...props.componentProps}
                            id={name}
                            name={name}
                            value={formik.values[name]}
                            onBlur={formik.handleBlur}
                            onChange={
                              config.transformValue
                                ? (e) => {
                                    formik.setFieldTouched(name, true);
                                    formik.setFieldValue(name, config.transformValue(e));
                                  }
                                : (e) => {
                                    formik.setFieldTouched(name, true);
                                    formik.handleChange(e);
                                  }
                            }
                            type={props.type}
                            error={formik.touched[name] && Boolean(formik.errors[name])}
                            helperText={
                              formik.touched[name] &&
                              formik.errors[name] && (
                                <Fragment>
                                  {formik.errors[name]}
                                  {!!config?.extendedErrorMsg && (
                                    <span className={formComponentClasses.extendedErrorWrapper}>
                                      {config.extendedErrorMsg}
                                    </span>
                                  )}
                                </Fragment>
                              )
                            }
                            options={props.options}
                          />
                        )}
                      </Grid>
                    </Fragment>
                  )
                );
              })}
            </Fragment>
          );
        })}

        {/* form errors */}

        {formik.errors.submit && (
          <Grid item xs={12}>
            <Alert severity="error">{formik.errors.submit}</Alert>
          </Grid>
        )}
      </Grid>

      {/* render children */}

      {wrapChildren ? (
        <Grid container justifyContent={"flex-end"} mt={4} gap={2}>
          {children}
        </Grid>
      ) : (
        children
      )}
    </form>
  );
};
export default Form;
