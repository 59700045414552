import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Appearance } from "@stripe/stripe-js";
import { getNeutralRgb } from "../../../../theme/dark-theme-options";

export const stripeFontsSrc =
  "https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap";

export const stripeClasses = makeStyles((_: Theme) => ({
  stripeCustomElementStyles: {
    "& .MuiInputBase-input": {
      fontSize: "0.875rem",
    },
    "& .StripeElement": {
      padding: "15px 20px",
      borderRadius: "7px",
      border: `1px solid ${getNeutralRgb("300")}`,
    },
  },
}));

export const TABLE_BORDER_TOP_STYLE = "1px solid #6B7280";
export const stripeAppearance = {
  theme: "stripe",
  loader: "always",
  variables: {
    fontWeightNormal: "500",
    borderRadius: "8px",
    colorBackground: "#FAFAFA",
    colorPrimary: "#0D0F35",
    colorPrimaryText: "#1A1B25",
    colorText: "#0D0F35",
    colorTextSecondary: "#0D0F35",
    colorIconTab: "#0D0F35",
    colorLogo: "dark",
    colorIcon: "#0D0F35",
    border: `1px solid ${getNeutralRgb("300")}`,
  },
  rules: {
    ".Input, .Block": {
      border: `1px solid ${getNeutralRgb("300")}`,
    },
  },
} as Appearance;
