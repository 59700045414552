import axios, { AxiosPromise } from "axios";
import config from "../../config";
import { AddMemberForm } from "../../form-configs/members.forms";
import FD from "../../utils/object-to-form-data";
import { AccountId, TBaseRes } from "../api.types";
import {
  AcceptInviteReq,
  CreateAPIKeyReq,
  CreateAPIKeyRes,
  DeleteAPIKeyReq,
  GetAllAPIKeysRes,
  InvitationMethodRes,
  TAcceptInviteRes,
  TAddTeamMemberRes,
  TGetAllTeamMembersRes,
} from "./settings.types";

/**
 * API KEYS
 */

const createAPIKey = (accountId: string, payload: CreateAPIKeyReq): AxiosPromise<CreateAPIKeyRes> => {
  const { key_name } = payload;
  return axios({
    method: "POST",
    baseURL: config.baseURL,
    url: config.endpoints.apiKeys,
    data: { key_name },
    params: { accountId },
  });
};

const getAPIKeys = (accountId: AccountId): AxiosPromise<GetAllAPIKeysRes> => {
  return axios({
    method: "GET",
    baseURL: config.baseURL,
    url: config.endpoints.apiKeys,
    params: { accountId },
  });
};

const deleteAPIKey = (accountId: string, payload: DeleteAPIKeyReq): AxiosPromise<any> => {
  const { name, secret } = payload;
  return axios({
    method: "DELETE",
    baseURL: config.baseURL,
    url: `${config.endpoints.deleteAPIKey}`,
    data: { key_name: name, key: secret },
    params: { accountId },
  });
};

/**
 * team members
 */

const addMember = (accountId: AccountId, formData: AddMemberForm): AxiosPromise<TAddTeamMemberRes> => {
  const { email, invitationMethod } = formData;
  const payload = new FD({
    email,
    invitation_method: invitationMethod,
  }).get();
  return axios({
    method: "POST",
    baseURL: config.baseURL,
    url: config.endpoints.members,
    params: { accountId },
    data: payload,
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const getTeamMembers = (accountId: AccountId): AxiosPromise<TGetAllTeamMembersRes> => {
  return axios({
    method: "GET",
    baseURL: config.baseURL,
    url: config.endpoints.members,
    params: { accountId },
  });
};
const resendInvite = (accountId: AccountId, email: string): AxiosPromise<TBaseRes> => {
  const payload = new FD({ email }).get();
  return axios({
    method: "POST",
    baseURL: config.baseURL,
    url: config.endpoints.resendInvite,
    params: { accountId },
    data: payload,
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const acceptInvite = (accountId: AccountId, formData: AcceptInviteReq): AxiosPromise<TAcceptInviteRes> => {
  const payload = new FD(formData).get();
  return axios({
    method: "POST",
    baseURL: config.baseURL,
    url: config.endpoints.acceptInvite,
    params: { accountId },
    data: payload,
    headers: { "Content-Type": "multipart/form-data" },
  });
};
const getInvitationMethod = (accountId: AccountId, email: string): AxiosPromise<InvitationMethodRes> => {
  return axios({
    method: "GET",
    baseURL: config.baseURL,
    url: `${config.endpoints.invitationMethod}/${email}/`,
    params: { accountId },
  });
};

const updateMember = (accountId: AccountId, email: string, data: { status?: string }): AxiosPromise<TBaseRes> => {
  return axios({
    method: "PUT",
    baseURL: config.baseURL,
    url: `${config.endpoints.members}/${email}/`,
    data,
    params: { accountId },
  });
};
const removeMember = (accountId: AccountId, email: string): AxiosPromise<unknown> => {
  return axios({
    method: "DELETE",
    baseURL: config.baseURL,
    url: `${config.endpoints.members}/${email}/`,
    params: { accountId },
  });
};

/**
 * exports
 */

const Settings = {
  apiKeys: {
    create: createAPIKey,
    get: getAPIKeys,
    delete: deleteAPIKey,
  },
  teamMember: {
    add: addMember,
    get: getTeamMembers,
    resendInvite: resendInvite,
    acceptInvite: acceptInvite,
    update: updateMember,
    remove: removeMember,
    getInvitationMethod: getInvitationMethod,
  },
};
export default Settings;
