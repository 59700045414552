import { SxProps, Theme } from "@mui/material";

export const cancelButtonStyles: SxProps<Theme> = {
  backgroundColor: "#f0f0f0",
  color: "#000000",
  "&:hover": {
    backgroundColor: "#e0e0e0",
  },
};

export const getReleaseButtonStyles = (theme: Theme): SxProps<Theme> => ({
  backgroundColor: `${theme.palette.secondary.main}`,
  color: `${theme.palette.text.primary}`,
});
