import { Grid, Tooltip, Typography } from "@mui/material";
import { ellipsisStyles } from "../../../constants/styles";
import CopyButton from "../../copy-button/copy-button.component";

type Props = {
  label: string;
  value: string | null | undefined | number | React.ReactNode;
  sizes: { [key: string]: number };
  withCopy?: boolean;
};

const FLAGGED_EMPTY_VALUES = ["-", "Unavailable"];

export const DetailGridDisplay = ({ label, value, sizes, withCopy }: Props) => {
  const isCopiable =
    value && ["string", "number"].includes(typeof value) && !FLAGGED_EMPTY_VALUES.includes(value as string);

  return (
    <Grid container flexDirection={"column"} item {...sizes}>
      <Typography variant={"subtitle2"} color={"neutral.200"} fontWeight={600}>
        {label}
      </Typography>

      {isCopiable ? (
        <Grid container item alignItems={"center"} gap={1}>
          <Tooltip title={value}>
            <Grid item xs={withCopy ? 9 : 12} pr={1}>
              <Typography variant={"subtitle2"} color={"neutral.400"} sx={ellipsisStyles}>
                {value}
              </Typography>
            </Grid>
          </Tooltip>

          {withCopy && (
            <Grid item xs={2} sx={{ display: "flex" }}>
              <CopyButton text={value.toString()} label={label} />
            </Grid>
          )}
        </Grid>
      ) : (
        <Typography variant={"subtitle2"} color={"neutral.400"}>
          -
        </Typography>
      )}
    </Grid>
  );
};
