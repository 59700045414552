import { Link } from "@mui/material";
import React, { Fragment } from "react";
import QuickLinks from "../../../constants/links";
import MailToSupportLink from "../../mail-to-support-link/mail-to-support-link.component";

export const TooltipTitleLink = (link: string, text = "here") => (
  <Link
    href={link}
    target={"_blank"}
    rel="noopener"
    sx={{
      textDecoration: "none",
      display: "contents",
      color: "primary.main",
    }}
  >
    {text}
  </Link>
);

export const indexingModeTooltipTitle = (
  <Fragment>
    Indexing mode will set a default text or multi-modal model. The full list of models can be found{" "}
    {TooltipTitleLink(QuickLinks.supported_models)} and changed in the model drop down below.
  </Fragment>
);

export const modelSelectionTooltipTitle = (
  <Fragment>See more information about models {TooltipTitleLink(QuickLinks.supported_models)}.</Fragment>
);

export const indexTypeTooltipTitle = (
  <Fragment>
    Unstructured indexes are more flexible. On the other hand,
    <span style={{ padding: "0 4px" }}>
      {TooltipTitleLink(QuickLinks.create_structured_index, "structured indexes")}
    </span>
    can be more performant but require setting a schema.
  </Fragment>
);

export const shardsTooltipTitle = (
  <Fragment>
    Adding more shards increases the number of vectors your index can hold. Read more about index sizing{" "}
    {TooltipTitleLink(QuickLinks.sizing_index)}.
  </Fragment>
);

export const replicasTooltipTitle = (
  <Fragment>
    Adding more storage replicas allows you to serve more requests. Having one or more replica enables high
    availability.
  </Fragment>
);

export const inferencePodsTooltipTitle = (
  <Fragment>
    Inference pods run the machine learning models for your cluster. Adding more inference pods allows you to serve more
    requests. For high availability, we recommend at least 2 inference pods.
  </Fragment>
);

export const getAccountLimitReachedMsg = (fieldName: string, shardLimit: number) => (
  <Fragment>
    Your current account limit is {shardLimit} {fieldName}
    {shardLimit > 1 ? "s" : ""}, click <MailToSupportLink title={"here"} /> to request limit increase.
  </Fragment>
);
