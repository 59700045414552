import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import API from "../api";
import { setBillingInfo, setBillingInfoIsFetching } from "../slices/billing/stripe-integration";
import { RootState } from "../store";
import { selectCurrentAccountId } from "../store/selectors";

export const hasStripeCardThunk = createAsyncThunk("billing/hasStripeCard", async (_, { dispatch, getState }) => {
  const accountId = selectCurrentAccountId(getState() as RootState);
  dispatch(setBillingInfoIsFetching(true));
  try {
    const { data } = await API.billing.get.billingInfo(accountId);
    dispatch(setBillingInfoIsFetching(false));
    return data;
  } catch (e) {
    const err = e as AxiosError;
    const intent = err?.response?.data?.intent || "";
    const has_card = err?.response?.data?.has_card || null;

    dispatch(setBillingInfo({ intent, has_card, isFetching: false }));
    return Promise.reject(e);
  }
});
