import { Theme } from "@mui/material";
import { createTheme as createMuiTheme, responsiveFontSizes } from "@mui/material/styles";
import { baseThemeOptions } from "./base-theme-options";
import { darkThemeOptions } from "./dark-theme-options";
import { lightThemeOptions } from "./light-theme-options";

export type CreateThemeParams = {
  mode: "dark" | "light";
  direction: string;
  responsiveFontSizes: boolean;
};

export type CreateThemeType = (config: CreateThemeParams) => Theme;

export const createTheme: CreateThemeType = (config) => {
  let theme = createMuiTheme(baseThemeOptions, config.mode === "dark" ? darkThemeOptions : lightThemeOptions, {
    direction: config.direction,
  });

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
