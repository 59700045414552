import { ContentCopy } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import copy from "copy-to-clipboard";
import { setToast } from "../../slices/app";
import { useDispatch } from "../../store";
import { generateRandomKey } from "../../utils";

type Props = {
  text: string;
  label: string;
};

const CopyButton = ({ text, label }: Props) => {
  const dispatch = useDispatch();

  const handleSimpleCopy = (valueToCopy: string, label: string) => {
    copy(valueToCopy);
    dispatch(
      setToast({
        msg: `${label} has been copied to clipboard.`,
        type: "success",
        hash: generateRandomKey(6),
      }),
    );
  };

  return (
    <IconButton
      size="small"
      style={{ padding: 0 }}
      onClick={(e) => {
        e.stopPropagation();
        handleSimpleCopy(text, label);
      }}
    >
      <ContentCopy fontSize={"small"} />
    </IconButton>
  );
};

export default CopyButton;
