import axios, { AxiosPromise } from "axios";
import config from "../../config";
import { INDUSTRY, USE_CASE } from "../../constants/validation";
import { CodeExchangeIntent } from "../../contexts/jwt-context";
import FD from "../../utils/object-to-form-data";
import CookieStorage from "../../utils/services/storage";
import { AccountId, TBaseRes } from "../api.types";
import {
  ChangePasswordReq,
  CloudProviderForm,
  ContinueWithSSORes,
  ForgotPasswordReq,
  ResetPasswordReq,
  SigninReq,
  SigninRes,
  SigninViaSsoReq,
  SignupByEmailForm,
  SsoSignupForm,
  UserBackgroundForm,
} from "./auth.types";

//Separated auth instance for non-authorized api-calls, headers will not connect
const authInstance = axios.create({
  baseURL: config.baseURL,
});

/**
 * Log in user by email
 * @param {{email: string, password: string}} params
 * @param {AccountId} accountId The ID of the account that the user last selected. This should be
 * returned in the response's `account_id` field if the user is a member of the account.
 * @returns {AxiosPromise}
 */
const signin = (params: SigninReq): AxiosPromise<SigninRes> => {
  const fd = new FD(params).get();
  return authInstance({
    method: "POST",
    url: config.endpoints.signin,
    data: fd,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
/**
 * Log in user via SSO.
 * @param params params
 * @param {AccountId} accountId The ID of the account that the user last selected. This should be
 * returned in the response's `account_id` field if the user is a member of the account.
 * @returns
 */
const signinViaSSO = (params: SigninViaSsoReq) => {
  const fd = new FD(params).get();
  return authInstance({
    method: "POST",
    url: config.endpoints.signin,
    data: fd,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const addUtmInformation = (jsonPayload: object) => {
  const utmSource = new CookieStorage("mq_utm_source").get();
  const utmTerm = new CookieStorage("mq_utm_term").get();
  const utmMedium = new CookieStorage("mq_utm_medium").get();
  const utmCampaign = new CookieStorage("mq_utm_campaign").get();
  const utmContent = new CookieStorage("mq_utm_content").get();

  return {
    ...jsonPayload,
    utm_source: utmSource,
    utm_term: utmTerm,
    utm_medium: utmMedium,
    utm_campaign: utmCampaign,
    utm_content: utmContent,
  };
};

function addUserBackgroundForm<T extends UserBackgroundForm>(form: T) {
  const { industry, useCase, otherUseCases, otherIndustries, intentionOfUse, ...rest } = form;

  const consolidatedIndustry = industry === INDUSTRY.OTHER ? otherIndustries : industry;

  const consolidatedUseCase = useCase === USE_CASE.OTHER ? otherUseCases : useCase;

  return {
    industry: consolidatedIndustry,
    use_case: consolidatedUseCase,
    intention_of_use: intentionOfUse,
    ...rest,
  };
}

const addCloudProviderInfo = (jsonPayload: { [key: string]: any }) => {
  const { cloudProvider, isCloudSelectorEnabled, ...rest } = jsonPayload;

  return {
    cloud_provider: cloudProvider,
    is_cloud_selector_enabled: isCloudSelectorEnabled,
    ...rest,
  };
};

const parseAndAddPayloadInfo = (payload: SignupByEmailForm | SsoSignupForm) =>
  addCloudProviderInfo(addUtmInformation(addUserBackgroundForm(payload)));

const preSignup = (formData: SignupByEmailForm | SsoSignupForm): AxiosPromise => {
  const { submit, ...requestPayload } = formData;

  const payload = new FD(addUtmInformation(addUserBackgroundForm(requestPayload))).get();

  return authInstance({
    method: "POST",
    url: config.endpoints.preSignup,
    data: payload,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const registerByEmail = (formData: SignupByEmailForm): AxiosPromise => {
  const { submit, ...requestPayload } = formData;
  const payload = new FD(parseAndAddPayloadInfo(requestPayload)).get();

  return authInstance({
    method: "POST",
    url: config.endpoints.signup,
    data: payload,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const registerViaSSO = (formData: SsoSignupForm): AxiosPromise => {
  const { submit, ...requestPayload } = formData;
  const payload = new FD(parseAndAddPayloadInfo(requestPayload)).get();

  return authInstance({
    method: "POST",
    url: config.endpoints.signup,
    data: payload,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

/** TODO://API CALL
 * User logout
 * @return {Promise<unknown>}
 */
const logOut = () => {
  return axios({
    method: "POST",
    baseURL: config.baseURL,
    url: config.endpoints.logout,
  });
};

const forgotPassword = (params: ForgotPasswordReq) => {
  return axios({
    method: "POST",
    baseURL: config.baseURL,
    url: config.endpoints.forgotPassword,
    data: new FD(params).get(),
  });
};

const resetPassword = (params: ResetPasswordReq): AxiosPromise<TBaseRes> => {
  return authInstance({
    method: "POST",
    url: config.endpoints.resetPassword,
    data: new FD(params).get(),
  });
};

const changePassword = (params: ChangePasswordReq) => {
  return axios({
    method: "POST",
    baseURL: config.baseURL,
    url: config.endpoints.changePassword,
    data: new FD(params).get(),
  });
};

const confirmSignup = (email: string, verification_code: string) => {
  return axios({
    method: "POST",
    baseURL: config.baseURL,
    url: config.endpoints.confirmSignup,
    data: new FD({
      email,
      verification_code,
    }).get(),
  });
};

const resendConfirmationCode = (email: string) => {
  return axios({
    method: "POST",
    baseURL: config.baseURL,
    url: config.endpoints.resendConfirmation,
    data: new FD({
      email,
    }).get(),
  });
};

const getSocialUserData = (token: string) => {
  return axios({
    method: "GET",
    baseURL: config.baseURL,
    url: `${config.endpoints.getSocialUserData}/${token}`,
  });
};

const continueWithSocialAuth = (credential: string, intent: CodeExchangeIntent): AxiosPromise<ContinueWithSSORes> => {
  return axios({
    method: "POST",
    baseURL: config.baseURL,
    url: config.endpoints.ssoAuth,
    data: new FD({ credential, intent }).get(),
  });
};

export default {
  login: {
    signin,
    viaSSO: signinViaSSO,
  },
  social: {
    auth: continueWithSocialAuth,
    userData: getSocialUserData,
  },
  register: {
    preSignup,
    byEmail: registerByEmail,
    viaSSO: registerViaSSO,
    confirm: confirmSignup,
    resendConfirmation: resendConfirmationCode,
  },
  password: {
    forgot: forgotPassword,
    reset: resetPassword,
    change: changePassword,
  },
  logOut,
};
