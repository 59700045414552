import { Theme, useMediaQuery } from "@mui/material";
import classNames from "classnames";
import React, { Fragment } from "react";
import { ReactComponent as MarqoVectorLogo } from "../../assets/marqo-logo.svg";
import { LogoClasses } from "./logo.styles";

export enum LogoVariant {
  ALL_MIDNIGHT_BLUE = "ALL_MIDNIGHT_BLUE", // Default
  NEON_GREEN_WHITE_TEXT = "NEON_GREEN_WHITE_TEXT",
}

type Props = {
  variant: LogoVariant;
  height?: number;
};

const MarqoLogo = ({ variant, ...props }: Props) => {
  // Midnight blue by default
  const logoClasses = LogoClasses();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up("lg"), { noSsr: true });
  const getClassName = () => {
    let variantClass = "";
    if (variant === LogoVariant.NEON_GREEN_WHITE_TEXT) {
      variantClass = logoClasses.neonGreenHippoWhiteText;
    }
    return classNames([logoClasses.logo, variantClass]);
  };

  const getWidth = () => {
    if (lgUp) return 250;
    return 200;
  };

  return (
    <Fragment>
      {!!props?.height && <MarqoVectorLogo height={props.height} className={getClassName()} />}
      {!props?.height && <MarqoVectorLogo width={getWidth()} className={getClassName()} />}
    </Fragment>
  );
};

export default MarqoLogo;
