import * as Yup from "yup";
import { ModelInfo } from "../../api/indexes/indexes.types";
import { FormConfigType } from "../../components/form/form.types";
import IndexDefaultsActionBarComponent from "../../components/page-parts/indexes/index-defaults-action-bar.component";
import {
  getAccountLimitReachedMsg,
  indexTypeTooltipTitle,
  indexingModeTooltipTitle,
  inferencePodsTooltipTitle,
  modelSelectionTooltipTitle,
  replicasTooltipTitle,
  shardsTooltipTitle,
} from "../../components/page-parts/indexes/index-form-tooltips";
import { DISALLOW_GT2_GPU_CPU_LARGE_BASIC_MSG } from "../../constants";
import { DEFAULT_AUDIO_VIDEO_MODEL, DEFAULT_IMAGE_MODEL, DEFAULT_TEXT_BASED_MODEL } from "../../constants/common";
import { IndexingMode, MarqoVersion } from "../../constants/enums";
import { getSizes } from "../../constants/sizes";
import { constructRequiredNumberValidation } from "../../constants/validation";
import { InferencePrices, StoragePrices } from "../../slices/billing/pricing";
import { AccountLimits } from "../../utils/indices";
import { FormValuesExtended } from "../types";
import { advancedDetailsFieldGroup, commonInputPropValues, defaultCardSizes, numberInputSizingProps } from "./common";
import {
  INDEXING_MODE_OPTIONS,
  INDEX_TYPE_OPTIONS,
  getInferencePodTypeOptions,
  getMarqoVersionOptions,
  getModelOptions,
  getStorageShardTypeOptions,
} from "./options";
import { CreateIndexFormProps } from "./types";
import IndexFormValidators from "./validators";

type CreateIndexFormConfigProps = {
  withV1Indexes: boolean;
  withAudioVideoIndexingMode: boolean;
  disableReplica: boolean;
  accountLimits: AccountLimits;
  inferencePrices: InferencePrices;
  storagePrices: StoragePrices;
  indexingMode: IndexingMode;
  modelList: ModelInfo[];
  version: MarqoVersion;
  classes: any;
};

const getDefaultModel = (indexingMode: IndexingMode): string => {
  if (indexingMode === IndexingMode.TEXT) {
    return DEFAULT_TEXT_BASED_MODEL;
  } else if (indexingMode === IndexingMode.AUDIO_VIDEO) {
    return DEFAULT_AUDIO_VIDEO_MODEL;
  } else {
    return DEFAULT_IMAGE_MODEL;
  }
};

const marqoV2AdvancedFields: FormConfigType["fields"] = {
  indexType: {
    label: "Index type",
    type: "radio-group",
    initialValue: INDEX_TYPE_OPTIONS[0]["value"],
    options: INDEX_TYPE_OPTIONS,
    componentProps: {
      fullWidth: true,
    },
    sizes: { xs: 12 },
    validation: {
      rules: {},
      validate: Yup.string().required("Indexing type is required"),
    },
    ...advancedDetailsFieldGroup,
    tooltipTitle: indexTypeTooltipTitle,
  },
};

export const createIndexFormConfig = ({
  withV1Indexes,
  withAudioVideoIndexingMode,
  disableReplica,
  accountLimits,
  inferencePrices,
  storagePrices,
  indexingMode,
  modelList,
  version,
  classes,
}: CreateIndexFormConfigProps): FormConfigType => {
  const modelOptions = getModelOptions(indexingMode, modelList, version, classes);
  return {
    fields: {
      marqoVersion: {
        label: "Marqo version",
        type: "select",
        hidden: !withV1Indexes,
        options: getMarqoVersionOptions(withV1Indexes),
        componentProps: {
          fullWidth: true,
        },
        sizes: getSizes(12, 9, 6, 4, 4),
        initialValue: MarqoVersion.V2,
      },
      indexName: {
        label: "Index name",
        componentProps: {
          fullWidth: !withV1Indexes,
        },
        sizes: withV1Indexes ? getSizes(12, 12, 6, 6, 8) : getSizes(12, 12, 6, 4, 3),
        transformValue: (e) => {
          const { value } = e.target;
          return value.replace(" ", version === MarqoVersion.V2 ? "_" : "-").toLowerCase();
        },
        validation: {
          rules: {
            onTouch: true,
            onBlur: true,
          },
          validate: Yup.string()
            .min(
              accountLimits.indexNameLength.min,
              `Index name must be at least ${accountLimits.indexNameLength.min} characters.`,
            )
            .max(
              accountLimits.indexNameLength.max,
              `Index name must be at most ${accountLimits.indexNameLength.max} characters.`,
            )
            .test("alphanumeric-lowercase-dash-hyphen-only", "Index name is not valid", (val) =>
              IndexFormValidators.indexName.validator(val, version),
            )
            .required("Index name is required"),
        },
      },
      indexingMode: {
        label: "Indexing mode",
        type: "radio-group",
        initialValue: INDEXING_MODE_OPTIONS[0]["value"],
        options: INDEXING_MODE_OPTIONS.filter(
          (option) => withAudioVideoIndexingMode || option.value !== IndexingMode.AUDIO_VIDEO,
        ),
        componentProps: {
          fullWidth: false,
        },
        sizes: withV1Indexes ? getSizes(12, 9, 6, 4, 4) : getSizes(12, 9, 6, 4, 3),
        validation: {
          rules: { onTouch: false, onBlur: false },
          validate: Yup.string().required("Indexing mode is required"),
        },
        tooltipTitle: indexingModeTooltipTitle,
      },
      model: {
        label: "Model",
        type: "select",
        options: modelOptions,
        componentProps: {
          fullWidth: true,
        },
        sizes: withV1Indexes ? getSizes(12, 12, 6, 4, 4) : getSizes(12, 12, 6, 4, 3),
        initialValue: getDefaultModel(indexingMode),
        tooltipTitle: modelSelectionTooltipTitle,
      },
      inferencePodType: {
        label: "Inference pod type",
        type: "card-select",
        options: getInferencePodTypeOptions(inferencePrices),
        initialValue: getInferencePodTypeOptions(inferencePrices)[0].value, // marqo.small
        ...defaultCardSizes,
        validation: {
          rules: {},
          validate: Yup.string()
            .test("selected-option", "Option selected is not valid", (val) =>
              getInferencePodTypeOptions(inferencePrices)
                .map(({ value }) => value)
                .includes(val),
            )
            .required("Required"),
        },
      },
      storageShardType: {
        label: "Storage shard type",
        type: "card-select",
        options: getStorageShardTypeOptions(storagePrices),
        initialValue: getStorageShardTypeOptions(storagePrices)[0].value, // marqo.basic
        ...defaultCardSizes,
        validation: {
          rules: {},
          validate: Yup.string()
            .test("selected-option", "Option selected is not valid", (val) =>
              getStorageShardTypeOptions(storagePrices)
                .map(({ value }) => value)
                .includes(val),
            )
            .test("storage-config", DISALLOW_GT2_GPU_CPU_LARGE_BASIC_MSG, (val, formValues) =>
              IndexFormValidators.storageShardType.validator(
                val,
                (formValues as FormValuesExtended<CreateIndexFormProps>).from[0].value,
              ),
            )
            .required("Required"),
        },
      },
      numberOfShards: {
        label: "Number of shards",
        initialValue: 1,
        validation: {
          rules: {},
          validate: constructRequiredNumberValidation(
            "Number of shards",
            accountLimits.shard.min,
            accountLimits.shard.max,
          ),
        },
        ...commonInputPropValues(1),
        extendedErrorMsg: getAccountLimitReachedMsg("shard", accountLimits.shard.max),
        tooltipTitle: shardsTooltipTitle,
        ...numberInputSizingProps,
      },
      numberOfReplicas: {
        label: "Number of replicas",
        initialValue: "0",
        validation: {
          rules: {},
          validate: constructRequiredNumberValidation(
            "Number of replicas",
            accountLimits.replica.min,
            accountLimits.replica.max,
          ),
        },
        ...commonInputPropValues(0, disableReplica),
        extendedErrorMsg: getAccountLimitReachedMsg("replica", accountLimits.replica.max),
        tooltipTitle: replicasTooltipTitle,
        ...numberInputSizingProps,
      },
      numberOfInferencePods: {
        label: "Number of inference pods",
        initialValue: 1,
        validation: {
          rules: {},
          validate: constructRequiredNumberValidation(
            "Number of inference pods",
            accountLimits.inference.min,
            accountLimits.inference.max,
          ),
        },
        ...commonInputPropValues(1),
        extendedErrorMsg: getAccountLimitReachedMsg("inference pod", accountLimits.inference.max),
        tooltipTitle: inferencePodsTooltipTitle,
        ...numberInputSizingProps,
      },
      ...(version === MarqoVersion.V2 ? marqoV2AdvancedFields : {}),
      indexDefaults: {
        label: version === MarqoVersion.V2 ? "Index settings" : "Index defaults",
        type: "json-field",
        initialValue: "{}",
        componentProps: {
          fullWidth: false,
        },
        sizes: getSizes(12, 12, 12, 12, 9),
        validation: {
          rules: { onBlur: true },
          validate: Yup.string()
            .test("is-valid-json", "Invalid json object.", IndexFormValidators.indexDefaults.validJSON)
            .test("is-model-defined", "Please define the model.", (val) =>
              IndexFormValidators.indexDefaults.isModelDefined(val),
            )
            .test("is-model-props-defined", IndexFormValidators.indexDefaults.getModelPropsDefinedMsg(version), (val) =>
              IndexFormValidators.indexDefaults.isModelPropsDefined(val, version, modelList),
            ),
        },
        ...advancedDetailsFieldGroup,
        jsonFieldActionBarComponent: IndexDefaultsActionBarComponent,
        jsonFieldComponentProps: {},
      },
      // action btn
      submit: {
        hidden: true,
        initialValue: false,
      },
    },
  };
};
