import _ from "lodash";
import moment from "moment";
import mtz from "moment-timezone";
import { MetricItemType } from "../../components/page-parts/indexes/indexes.types";
import { CHART_LABEL_FORMAT } from "../../constants/chart";
import { GetIndexMetricsType } from "./metrics.types";

type MetricItemMetricsOnlyType = Omit<MetricItemType, "Unit" | "Timestamp">;

const formatTime = (formatString: string, dates: string[]): string[] =>
  dates.map((date) => {
    const d = mtz(date).utc().toISOString();
    return moment(d).format(formatString);
  });

export const getIndexMetricsData: GetIndexMetricsType = (metricItems) => {
  const sortedMetrics = [...metricItems].sort((a, b) => {
    const dateA: Date = new Date(a.Timestamp);
    const dateB: Date = new Date(b.Timestamp);
    return dateA.getTime() - dateB.getTime();
  });

  const dates = sortedMetrics.map((item) => item.Timestamp);

  const result = sortedMetrics.reduce<Record<string, number[]>>((prev, cur) => {
    const data = { ...prev };
    const { Timestamp } = cur;
    const metricItem: MetricItemMetricsOnlyType = _.omit<MetricItemMetricsOnlyType>(cur, ["Unit", "Timestamp"]);

    Object.entries(metricItem).forEach((el) => {
      const itemIndex = dates.findIndex((date) => date === Timestamp);
      const [operation, value] = el;

      if (!data[operation]) {
        data[operation] = _.times(dates.length, _.constant(null));
      }
      data[operation][itemIndex] = !_.isNull(value) ? Math.trunc(value) : null;
    });
    return data;
  }, {});

  const data = Object.entries(result).map(([name, data]) => ({
    label: name,
    data,
  }));
  const categories = formatTime(CHART_LABEL_FORMAT, dates);

  return { labels: categories, datasets: data };
};
