import { Box, TableBody, TableCell, TableRow } from "@mui/material";
import React from "react";
import { useStyles } from "./table-fetching.styles";
import { TableFetchingProps } from "./table-fetching.types";

const TableFetching: React.FC<TableFetchingProps> = (props): JSX.Element => {
  const { columns, linesCount } = props;
  const classes = useStyles();
  const getRows = () => {
    const rows = [];
    let i = 0;
    while (i < linesCount) {
      rows.push(i);
      i++;
    }
    return rows;
  };
  return (
    <TableBody className={"TableFetching-body"}>
      {getRows().map((_, index) => (
        <TableRow key={index}>
          {columns.map((_, index) => (
            <TableCell key={index}>
              <Box className={classes.blink} />
              <Box className={classes.blink} width={"30%"} />
              <Box className={classes.blink} width={"50%"} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </TableBody>
  );
};
export default TableFetching;
