import { Box } from "@mui/material";
import React from "react";
import { Logo } from "../logo/logo";
import { bounce } from "./splash-screen.styles";
import { SplashScreenProps } from "./splash-screen.types";

export const SplashScreen: React.FC<SplashScreenProps> = (props): JSX.Element => {
  const { open } = props;
  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: "neutral.900",
        display: open ? "flex" : "none",
        flexDirection: "column",
        height: "100vh",
        justifyContent: "center",
        left: 0,
        p: 3,
        position: "fixed",
        top: 0,
        width: "100vw",
        zIndex: 2000,
      }}
    >
      <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <Logo size={"lg"} />
      </Box>
    </Box>
  );
};
