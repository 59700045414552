import { MoreVertRounded } from "@mui/icons-material";
import { Box, Grid, IconButton } from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { MarqtuneDataset } from "../../api/marqtune/types";
import CardTitle from "../../components/card-title/card-title.component";
import ContentLayout from "../../components/content-layout/content-layout.component";
import ContentSection from "../../components/content-layout/content-section.component";
import DatasetSchemaTable from "../../components/dataset-schema-table/dataset-schema-table.component";
import FetchingDisplay from "../../components/fetching-display/fetching-display.component";
import { DetailGridDisplay } from "../../components/page-parts/marqtune/detail-grid-display.component";
import LogsViewer from "../../components/page-parts/marqtune/logs-viewer/logs-viewer.component";
import { MarqtuneDatasetActionPrompt } from "../../components/page-parts/marqtune/prompts.component";
import {
  InProgressStatusDisplay,
  ReadyStatusDisplay,
} from "../../components/page-parts/marqtune/status-progress.component";
import { DetailsConf } from "../../components/page-parts/marqtune/types";
import { getDisplayableStatus, isFinished, isReady } from "../../components/page-parts/marqtune/utils";
import config from "../../config";
import { useBreadcrumbs } from "../../hooks/use-breadcrumbs";
import { useDispatch, useSelector } from "../../store";
import { selectValidDatasetByDatasetId } from "../../store/selectors";
import {
  deleteMarqtuneDatasetThunk,
  downloadMarqtuneDatasetLogsThunk,
  listMarqtuneDatasetLogsThunk,
  listMarqtuneDatasetsThunk,
} from "../../thunks/marqtune.thunk";

const initialBreadcrumbs = [{ title: "Marqtune", link: "/marqtune/" }];
const datasetDetailsConfig = (datasetDetails: MarqtuneDataset): DetailsConf[] => {
  const sizes = { xs: 12, sm: 6, md: datasetDetails?.failureReason ? 4 : 3, xl: 2 };
  return [
    {
      id: {
        label: "Dataset ID",
        value: datasetDetails.datasetId,
        withCopy: true,
        sizes,
      },
      name: {
        label: "Dataset name",
        value: datasetDetails.datasetName,
        sizes,
      },
      datasetType: {
        label: "Dataset type",
        value: _.capitalize(datasetDetails.datasetType),
        withCopy: true,
        sizes,
      },
      status: {
        label: "Status",
        value: getDisplayableStatus(datasetDetails),
        sizes,
      },
      ...(datasetDetails?.failureReason
        ? {
            failureReason: {
              label: "Failure reason",
              value: datasetDetails.failureReason,
              sizes,
            },
          }
        : {}),
    },
  ];
};

export const NO_DATASET_SCHEMA_CONFIG = { "-": "-" };

export const datasetSchemaTableConfig = (dataset: MarqtuneDataset) => dataset?.dataSchema ?? NO_DATASET_SCHEMA_CONFIG;

const MarqtuneDatasetDetailsPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { datasetId } = useParams();
  const { setBreadcrumbs, clearBreadcrumbs } = useBreadcrumbs();
  const { isFetching } = useSelector(({ marqtune }) => marqtune.datasets);
  const datasetDetails = useSelector(selectValidDatasetByDatasetId(datasetId));
  const [actionsMenuAnchor, setActionsMenuAnchor] = useState(null);
  const matchingDatasetId = datasetDetails?.datasetId;
  const pageTitle = datasetDetails?.datasetName || "-";
  const isResourceReady = isReady(datasetDetails);

  const handleDatasetActions = (e: React.MouseEvent) => {
    setActionsMenuAnchor(e.currentTarget);
  };

  const handleActionsMenuClose = () => {
    setActionsMenuAnchor(null);
  };

  const agreeHandler = () => {
    dispatch(deleteMarqtuneDatasetThunk(matchingDatasetId));
    handleActionsMenuClose();
  };

  useEffect(() => {
    dispatch(listMarqtuneDatasetsThunk());
    setBreadcrumbs(initialBreadcrumbs);
    return () => clearBreadcrumbs();
  }, []);

  useEffect(() => {
    if (!matchingDatasetId && !isFetching) {
      navigate(config.authenticatedPaths.marqtune.root);
      return;
    }
  }, [matchingDatasetId, isFetching]);

  return (
    <ContentLayout headTitle={pageTitle} pageTitle={pageTitle}>
      {isFetching || !matchingDatasetId ? (
        <FetchingDisplay />
      ) : (
        <ContentSection id={"MarqtuneDatasetDetails-Summary"} mb={3}>
          <Grid container flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"} mb={2}>
            <Grid item>
              <Grid container item gap={2}>
                <Grid item>
                  <CardTitle title={datasetDetails.datasetName} noDivider typographyProps={{ variant: "h6" }} />
                </Grid>

                {isResourceReady ? (
                  <ReadyStatusDisplay status={datasetDetails.status} />
                ) : (
                  <InProgressStatusDisplay timeLeft={null} status={datasetDetails.secondaryStatus} />
                )}
              </Grid>
            </Grid>
            <Grid item>
              <IconButton onClick={handleDatasetActions}>
                <MoreVertRounded />
              </IconButton>
            </Grid>
          </Grid>

          <MarqtuneDatasetActionPrompt
            resourceName={datasetDetails.datasetName}
            resourceId={matchingDatasetId}
            actionsMenuAnchor={actionsMenuAnchor}
            agreeHandler={agreeHandler}
            disagreeHandler={handleActionsMenuClose}
          />

          <Grid container flexDirection={"row"} spacing={1} mt={2}>
            {datasetDetailsConfig(datasetDetails).map((detailGroup, groupIndex) => {
              return (
                <Grid container item key={`dataset-details-group-${groupIndex}`} xs={12} spacing={1}>
                  {Object.entries(detailGroup).map(([param, restOfConfig], index) => (
                    <DetailGridDisplay key={`dataset-details-grid-${restOfConfig.label}`} {...restOfConfig} />
                  ))}
                </Grid>
              );
            })}
          </Grid>

          {isResourceReady && <DatasetSchemaTable schemaConfig={() => datasetSchemaTableConfig(datasetDetails)} />}

          <Box mt={3}>
            <LogsViewer
              isResourceTaskFinished={isFinished(datasetDetails)}
              sectionId={"DatasetDetails"}
              resourceId={matchingDatasetId}
              dispatchIntervalInSec={30}
              dispatchAction={listMarqtuneDatasetLogsThunk}
              downloadLogsDispatchAction={downloadMarqtuneDatasetLogsThunk}
              resourceLogs={datasetDetails?.resourceLogs}
            />
          </Box>
        </ContentSection>
      )}
    </ContentLayout>
  );
};

export default MarqtuneDatasetDetailsPage;
