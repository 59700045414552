import { createSlice } from "@reduxjs/toolkit";
import { IndexItemRes, ModelInfo } from "../../api/indexes/indexes.types";
import { MarqtuneModel } from "../../api/marqtune/types";
import {
  IndexItem,
  IndexOverviewPropType,
  IndexOverviewTabValueType,
} from "../../components/page-parts/indexes/indexes.types";
import { CreateIndexFormProps } from "../../form-configs/indexes/types";
import { getAllIndices, getModelsThunk } from "../../thunks/indexes.thunk";
import { listMarqtuneModelsThunk } from "../../thunks/marqtune.thunk";
import IndexStatsUtils from "../../utils/indices/index-stats-utils";

export type IndexesMainState = {
  list: IndexItem[];
  overview: {
    currentTab: IndexOverviewTabValueType;
    indexData: IndexOverviewPropType[];
  };
  tableConfig: {
    itemsPerPage: number;
    currentPage: number;
  };
  fetching: {
    isDeleting: boolean;
    inProgress: boolean;
    error: boolean | string | any;
  };
  opensourceModels: ModelInfo[];
  marqtunedModels: MarqtuneModel[];
  models: ModelInfo[];
  /** Records the details of the index to create if billing details are required first. */
  toCreate?: CreateIndexFormProps;
};

export const indexesMainInitialState: IndexesMainState = {
  list: [],
  overview: {
    currentTab: "overview",
    indexData: null,
  },
  tableConfig: {
    itemsPerPage: 10,
    currentPage: 0,
  },
  fetching: {
    isDeleting: false,
    inProgress: false,
    error: false,
  },
  opensourceModels: [],
  marqtunedModels: [],
  models: [],
};

const getIndexFields = (item: IndexItemRes, stats: IndexItem["stats"] = null): IndexItem => ({
  indexName: item.indexName,
  indexStatus: item.indexStatus,
  Created: item.Created,
  marqoEndpoint: item.marqoEndpoint,
  errorMsg: item.errorMsg,
  marqoVersion: item?.marqoVersion,
  workflowVersion: item?.workflowVersion,
  // storage
  storageClass: item.storageClass,
  numberOfShards: item.numberOfShards,
  numberOfReplicas: item.numberOfReplicas,
  // inference
  inferenceType: item.inferenceType,
  numberOfInferences: item.numberOfInferences,
  // stats
  stats,
  isFetchingStats: false,
  // v1 index defaults
  index_defaults: item?.index_defaults,
  // v2 unpacked index defaults
  type: item?.type,
  vectorNumericType: item?.vectorNumericType,
  treatUrlsAndPointersAsImages: item?.treatUrlsAndPointersAsImages,
  treatUrlsAndPointersAsMedia: item?.treatUrlsAndPointersAsMedia,
  model: item?.model,
  modelProperties: item?.modelProperties,
  normalizeEmbeddings: item?.normalizeEmbeddings,
  textPreprocessing: item?.textPreprocessing,
  imagePreprocessing: item?.imagePreprocessing,
  annParameters: item?.annParameters,
  allFields: item?.allFields,
  tensorFields: item?.tensorFields,
});

export const formatIndexes = (results: IndexItemRes[]): IndexItem[] => results.map((item) => getIndexFields(item));

const formatIndexesForPolling = (results: IndexItemRes[], stateList: IndexItem[]): IndexItem[] =>
  results.map((item) => {
    const itemStats: IndexItem["stats"] =
      stateList.find(({ indexName }) => indexName === item.indexName)?.stats || null;
    return getIndexFields(item, itemStats);
  });

const indexesMainSlice = createSlice({
  name: "indexesMain",
  initialState: indexesMainInitialState,
  reducers: {
    setIndexData: (state, action) => {
      state.overview.indexData = action.payload;
    },
    setCurrentPage: (state, action) => {
      state.tableConfig.currentPage = action.payload;
    },
    clearIndicesState: (state) => {
      state.list = [...indexesMainInitialState.list];
      state.fetching = { ...indexesMainInitialState.fetching };
      state.tableConfig = { ...indexesMainInitialState.tableConfig };
    },
    setChangeTab: (state, action) => {
      state.overview.currentTab = action.payload;
    },
    setIsDeleting: (state, action) => {
      state.fetching.isDeleting = action.payload;
    },
    setIndexStats: (state, { payload }) => {
      const indexList = [...state.list];
      const updatedIndexList: IndexItem[] = IndexStatsUtils.getUpdatedIndexStatsList(payload, indexList);
      state.list = updatedIndexList;
    },
    resetIndexStats: (state, { payload }) => {
      const indexList = [...state.list];
      indexList.forEach((index) => {
        if (index.indexName === payload) {
          index.stats = null;
          index.isFetchingStats = false;
        }
      });
      state.list = indexList;
    },
    resetAllIndexesStats: (state) => {
      const indexList = [...state.list];
      indexList.forEach((index) => {
        index.stats = null;
        index.isFetchingStats = false;
      });
      state.list = indexList;
    },
    setIsFetchingIndexStats: (state, { payload }) => {
      const indexList = [...state.list];
      const { indexName, isFetchingStats } = payload;
      indexList.forEach((index) => {
        if (index.indexName === indexName) {
          index.isFetchingStats = isFetchingStats;
        }
      });
      state.list = indexList;
    },
    setToCreate: (state, { payload }) => {
      state.toCreate = payload;
    },
    clearToCreate: (state) => {
      state.toCreate = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllIndices.pending, (state, action) => {
        const isForPollingOnly = !!action.meta.arg?.forPollingOnly;
        state.fetching = { ...state.fetching, inProgress: !isForPollingOnly };
      })
      .addCase(getAllIndices.fulfilled, (state, action) => {
        const results = action.payload?.results || [];
        const forPollingOnly = !!action.meta.arg?.forPollingOnly;

        state.list = forPollingOnly ? formatIndexesForPolling(results, state.list) : formatIndexes(results);
        state.fetching = { error: false, isDeleting: false, inProgress: false };
      })
      .addCase(getAllIndices.rejected, (state, action) => {
        state.fetching = {
          isDeleting: false,
          inProgress: false,
          error: action.payload,
        };
      })
      .addCase(getModelsThunk.fulfilled, (state, action) => {
        const models = action.payload?.models || [];
        state.opensourceModels = models.map((model) => ({
          ...model,
          type: "opensource",
        }));

        // Merge opensource models and enhanced marqtuned models into state.models
        state.models = [...state.opensourceModels, ...convertMarqtuneModels(state.marqtunedModels)];
      })
      .addCase(listMarqtuneModelsThunk.fulfilled, (state, action) => {
        state.marqtunedModels = action.payload || [];

        // Merge opensource models and enhanced marqtuned models into state.models
        state.models = [...state.opensourceModels, ...convertMarqtuneModels(state.marqtunedModels)];
      });
  },
});

const convertMarqtuneModels = (marqtuneModels: MarqtuneModel[] = []): ModelInfo[] =>
  marqtuneModels
    .filter((marqtuneModel) => marqtuneModel.releasedCheckpoints?.length > 0)
    .flatMap((marqtuneModel) =>
      marqtuneModel.releasedCheckpoints.map((checkpoint) => ({
        name: `marqtune/${marqtuneModel.modelName}/${checkpoint}`,
        type: "marqtuned",
        modality: marqtuneModel.modality || [],
        model_dimension: marqtuneModel.dimension,
        show: true,
      })),
    );

export const {
  clearIndicesState,
  setCurrentPage,
  setChangeTab,
  setIndexData,
  setIsDeleting,
  setIndexStats,
  setIsFetchingIndexStats,
  setToCreate,
  clearToCreate,
  resetIndexStats,
  resetAllIndexesStats,
} = indexesMainSlice.actions;

export default indexesMainSlice.reducer;
