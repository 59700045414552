import { Fragment, useEffect } from "react";
import { INDEX_PROCESS_AVE_DURATIONS } from "../../../../constants/common";
import useCountdown from "../../../../hooks/use-countdown";
import { useDispatch } from "../../../../store";
import { getAllIndices } from "../../../../thunks/indexes.thunk";
import { plural } from "../../../../utils/formatters";

const ACTION_INTERVAL_IN_MS = 30000;
export const INDEX_COUNTDOWN_DELAY_IN_MS = 75000;

type Props = {
  timeOfOperation: string;
  version: string;
  showCountdown: boolean;
};

const averageDuration = (version: string) => {
  if (/^2/.test(version)) {
    return INDEX_PROCESS_AVE_DURATIONS.v2_index_creation;
  }
  return INDEX_PROCESS_AVE_DURATIONS.v1_index_creation;
};

const IndexCountdown: React.FC<React.PropsWithChildren<Props>> = ({ timeOfOperation, version, showCountdown }) => {
  const dispatch = useDispatch();
  const countdownDurationInMin = averageDuration(version);

  const countdown = useCountdown({
    timeOfOperation,
    countdownDurationInMin,
    countdownDelayInMs: INDEX_COUNTDOWN_DELAY_IN_MS,
  });

  const getCountDownMsg = () => {
    if (countdown < 1) {
      return "Almost done";
    } else {
      return `About ${countdown} minute${plural(countdown)} remaining...`;
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timer;

    if (countdown <= 1) {
      interval = setInterval(() => {
        dispatch(getAllIndices({ forPollingOnly: true }));
      }, ACTION_INTERVAL_IN_MS);
    }

    return () => clearInterval(interval);
  }, [countdown]);

  return <Fragment>{showCountdown && getCountDownMsg()}</Fragment>;
};

export default IndexCountdown;
