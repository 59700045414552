import { createSlice } from "@reduxjs/toolkit";
import { ChartItemData } from "../../components/chart/chart.types";
import { StatisticType } from "../../components/page-parts/indexes/index-overview/chart-modal/chart-modal.types";
import { MetricOperationTypes } from "../../components/page-parts/indexes/indexes.types";
import { RangeItemType } from "../../components/range-buttons/range-buttons.types";
import { getFullIndexMetrics } from "../../thunks/indexes.thunk";
import { getIndexMetricsData } from "../../utils/indices/metrics-data-convert";

type InitialFullMetricsStateType = {
  isFetching: boolean;
  isOpen: boolean;
  statistics: StatisticType[];
  operation: MetricOperationTypes | null;
  selectedRange: RangeItemType["value"];
  data: ChartItemData | null;
};

export const fullIndexMetricsInitialState: InitialFullMetricsStateType = {
  isFetching: false,
  isOpen: false,
  data: null,
  selectedRange: "3h",
  operation: null,
  statistics: [
    StatisticType.min,
    StatisticType.max,
    StatisticType.average,
    StatisticType.sampleCount,
    StatisticType.sum,
  ],
};

const indexMetricsFull = createSlice({
  name: "indexMetricsCommon",
  initialState: fullIndexMetricsInitialState,
  reducers: {
    setChartFullOpen: (state) => {
      state.isOpen = true;
    },
    setChartFullClose: (state) => {
      state.data = null;
      state.operation = null;
      state.isOpen = false;
    },
    setChartFullIsFetching: (state, action) => {
      state.isFetching = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFullIndexMetrics.fulfilled, (state, action) => {
      const { data: responseData, operation, selectedRange } = action.payload;
      const { datasets, labels } = getIndexMetricsData(responseData.datapoints);
      state.operation = operation;
      state.selectedRange = selectedRange;
      state.data = {
        id: operation,
        labels,
        datasets,
      };
    });
  },
});
export const { setChartFullClose, setChartFullIsFetching, setChartFullOpen } = indexMetricsFull.actions;

export default indexMetricsFull.reducer;
