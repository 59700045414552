import Cookie from "js-cookie";

/**
 * A simple interface for storing and retrieving data in/from cookies.
 */
interface Storage<T> {
  get(): T | null;
  set(token: T): T;
  remove(): boolean;
}

export default class CookieStorage implements Storage<string> {
  constructor(private key: string) {}

  get(): string | null {
    const storedToken = Cookie.get(this.key);
    return storedToken ? storedToken : null;
  }

  set(token: string): string {
    Cookie.set(this.key, token); // add expiry and or path
    return token;
  }

  remove(): boolean {
    Cookie.remove(this.key);
    return !this.get();
  }
}

export class JSONCookieStorage<T = unknown> implements Storage<T> {
  constructor(private key: string) {}

  get(): T | null {
    const storedToken = Cookie.get(this.key);
    return storedToken ? JSON.parse(storedToken) : null;
  }

  set(token: T): T {
    Cookie.set(this.key, JSON.stringify(token)); // add expiry and or path
    return token;
  }

  remove(): boolean {
    Cookie.remove(this.key);
    return !this.get();
  }
}
