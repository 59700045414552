import AddIcon from "@mui/icons-material/Add";
import { Box, Button, List, ListItem, ListProps, ListSubheader, Theme, useTheme } from "@mui/material";
import { SxProps } from "@mui/system";
import { isEmpty } from "lodash";
import React from "react";
import { useLocation, useNavigate } from "react-router";
import Config from "../../config";
import { useSelector } from "../../store";
import { selectMustCreateOrSelectAccount } from "../../store/selectors";
import AccountSelector from "../account-selector/account-selector.component";
import { DashboardItem, DashboardSidebarSectionProps } from "./dashboard-sidebar.types";

const getSubheaderStyles = (): SxProps => ({
  color: "neutral.500",
  fontSize: "0.75rem",
  fontWeight: 700,
  lineHeight: 2.5,
  ml: 4,
  textTransform: "uppercase",
});

const getListItemButtonStyles = (theme: Theme, isActiveLink: boolean, disabled?: boolean): SxProps => ({
  color: isActiveLink ? "primary.main" : "neutral.100",
  justifyContent: "flex-start",
  pr: 3,
  textAlign: "left",
  textTransform: "none",
  width: "100%",
  "&:hover": {
    backgroundColor: "rgba(255,255,255, 0.08)",
  },
  "& .MuiButton-startIcon": {
    color: isActiveLink ? "primary.main" : disabled ? theme.palette.action.disabled : "neutral.100",
  },
  "& .MuiButton-endIcon": {
    color: "neutral.100",
  },
});

type DashboardSidebarPropsListProps = {
  onClick: (path: string) => () => void;
  checkIsLinkActive: (path: string) => boolean;
  items: DashboardItem[];
  ListProps: ListProps;
  sectionTitle?: string;
};
const DashboardSidebarList: React.FC<DashboardSidebarPropsListProps> = (props): JSX.Element => {
  const { sectionTitle, checkIsLinkActive, onClick, items, ListProps } = props;
  const { withBilling } = useSelector(({ user }) => user.data);
  const { accountDetails } = useSelector(({ accounts }) => accounts);
  const theme = useTheme();
  const disableSidebar = useSelector(selectMustCreateOrSelectAccount);

  function getFilteredItems() {
    return items.filter((section) => {
      if (section.path === Config.authenticatedPaths.billing) {
        return withBilling && !isEmpty(accountDetails);
      }
      return true;
    });
  }

  const filteredItems = getFilteredItems();

  return (
    <List
      subheader={
        sectionTitle && (
          <ListSubheader disableGutters disableSticky sx={getSubheaderStyles()}>
            {sectionTitle}
          </ListSubheader>
        )
      }
      {...ListProps}
    >
      {!isEmpty(accountDetails) && (
        <ListItem key="account" sx={{ display: "flex", flexDirection: "column" }}>
          <AccountSelector />
        </ListItem>
      )}
      {isEmpty(accountDetails) && (
        <ListItem key="account" sx={{ display: "flex", flexDirection: "column" }}>
          <Button
            disableRipple
            startIcon={<AddIcon />}
            onClick={onClick(Config.authenticatedPaths.createAccount)}
            sx={{
              ...getListItemButtonStyles(theme, checkIsLinkActive(Config.authenticatedPaths.createAccount)),
              backgroundColor: "rgba(100,100,255, 0.2)",
            }}
          >
            <Box sx={{ flexGrow: 1 }}>Add Billing Details</Box>
          </Button>
        </ListItem>
      )}
      {filteredItems.map((item) => (
        <ListItem key={item.path} sx={{ display: "flex", flexDirection: "column" }}>
          <Button
            disableRipple
            startIcon={item.icon}
            onClick={onClick(item.path)}
            sx={getListItemButtonStyles(theme, checkIsLinkActive(item.path), disableSidebar)}
            disabled={disableSidebar}
          >
            <Box sx={{ flexGrow: 1 }}>{item.title}</Box>
          </Button>
          {item.nestedPaths && (
            <DashboardSidebarList
              onClick={onClick}
              checkIsLinkActive={checkIsLinkActive}
              items={item.nestedPaths}
              ListProps={{
                sx: {
                  mt: 0,
                  width: "100%",
                  py: 0,
                  "& li:last-child": { paddingBottom: 0 },
                },
              }}
            />
          )}
        </ListItem>
      ))}
    </List>
  );
};

export const DashboardSidebarSection: React.FC<DashboardSidebarSectionProps> = (props): JSX.Element => {
  const { items, path, title, ...other } = props;
  const location = useLocation();
  const navigate = useNavigate();

  const handleClick =
    (itemPath: string): (() => void) =>
    () => {
      navigate(itemPath);
    };

  const isActiveLink = (itemPath: string) => {
    return itemPath === location.pathname;
  };

  return (
    <DashboardSidebarList
      sectionTitle={title}
      onClick={handleClick}
      checkIsLinkActive={isActiveLink}
      items={items}
      ListProps={other}
    />
  );
};
