import { Box, Grid, InputLabel, Tab, Tabs } from "@mui/material";
import { Fragment, useState } from "react";
import { MarqtuneDataset } from "../../../api/marqtune/types";
import Config from "../../../config";
import { WrapperClasses } from "../../../constants/styles";
import { NO_DATASET_SCHEMA_CONFIG, datasetSchemaTableConfig } from "../../../pages/marqtune/dataset-details.page";
import { useSelector } from "../../../store";
import { selectDatasetByDatasetId, selectFirstApiKeySecret } from "../../../store/selectors";
import CodeSnippetBlock from "../../code-snippet-block/code-snippet-block.component";
import { CodeSnippetsWrapperStyles } from "../../code-snippets-wrapper/code-snippets-wrapper.styles";
import DatasetSchemaTable from "../../dataset-schema-table/dataset-schema-table.component";
import { FormField } from "../../form/form.component";
import { formClasses } from "../../form/form.styles";
import TabPanel, { tabPanelControlProps } from "../../tab-panel/tab-panel.component";
import MarqtuneSnippets from "./snippets";

const getDatasetOptions = (datasets: MarqtuneDataset[]) => {
  return datasets.map(({ datasetId, datasetName }) => {
    return {
      id: datasetId,
      title: datasetName,
      value: datasetId,
    };
  });
};

const OPERATIONS = ["Train model"];

type Props = {
  datasets: MarqtuneDataset[];
};

type CodeWrapperProps = {
  trainingDatasetId: string;
};

type Tools = {
  [language: string]: {
    displayName: string;
    terminal: string;
  };
};

const tools: Tools = {
  python: {
    displayName: "Python3",
    terminal: "Python3",
  },
  // TODO: Add support for cURL
  // "bash": {
  //   displayName: "cURL",
  //   terminal: "bash",
  // },
};

const CodeWrapper = ({ trainingDatasetId }: CodeWrapperProps) => {
  const apiKey = useSelector(selectFirstApiKeySecret);
  const [activeOpsTab, setActiveOpsTab] = useState(OPERATIONS[0]);
  const [activeToolTab, setActiveToolTab] = useState(Object.keys(tools)[0]);
  const [snippetTerminal, setSnippetTerminal] = useState(Object.values(tools)[0].terminal);
  const classes = CodeSnippetsWrapperStyles();
  const wrapperClasses = WrapperClasses();

  const snippet: string = MarqtuneSnippets.python.train_model({
    marqtuneClientUrl: Config.marqtuneBaseURL,
    apiKey: apiKey || "",
    trainingDatasetId,
  });

  // handlers
  const handleOpsChange = (_: React.SyntheticEvent, value: string) => {
    setActiveOpsTab(value);
  };

  const handleToolChange = (_: React.SyntheticEvent, value: string) => {
    setActiveToolTab(value);
    setSnippetTerminal(tools[value as keyof typeof tools].terminal);
  };

  return (
    <Fragment>
      <Grid container item xs={12} className={classes.codeSnippetsWrapper}>
        <Grid container className="tab-wrapper" flexDirection={"column"} mb={4}>
          <Grid item className={"sub-header"} px={2}>
            <Tabs value={activeOpsTab} onChange={handleOpsChange} className={wrapperClasses.leanSnippetScrollbar}>
              {OPERATIONS.map((displayName) => (
                <Tab
                  key={displayName}
                  label={displayName}
                  value={displayName}
                  sx={{
                    fontWeight: 500,
                    fontSize: "0.8rem",
                  }}
                />
              ))}
            </Tabs>
          </Grid>

          <Grid className={"sub-header"} item px={2}>
            <Tabs value={activeToolTab} onChange={handleToolChange}>
              {Object.entries(tools).map(([language, { displayName }]) => (
                <Tab
                  key={language}
                  label={displayName}
                  value={language}
                  sx={{
                    fontWeight: 500,
                    fontSize: "0.8rem",
                  }}
                />
              ))}
            </Tabs>
          </Grid>
        </Grid>

        <Grid container>
          {activeToolTab === "python" && (
            <Grid container item justifyContent={"center"} mb={4} px={0}>
              <CodeSnippetBlock
                dataTestIdPrefix={"train-model-py-setup"}
                snippet={MarqtuneSnippets.setup}
                language={"bash"}
                terminal={"Bash"}
              />
            </Grid>
          )}

          {snippet && (
            <Grid container item justifyContent={"center"} px={0} mb={4}>
              <CodeSnippetBlock
                dataTestIdPrefix={"py-train-model"}
                snippet={snippet}
                language={activeToolTab}
                terminal={snippetTerminal}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Fragment>
  );
};

const TrainModelQuickStart = ({ datasets }: Props) => {
  const formComponentClasses = formClasses();
  const datasetOptions = getDatasetOptions(datasets);
  const [datasetSelected, setDatasetSelected] = useState(datasetOptions.length ? datasetOptions[0].value : "");
  const datasetDetails = useSelector(selectDatasetByDatasetId(datasetSelected));

  const [value, setValue] = useState(0);
  const idPrefix = "marqtune-create-task";

  const handleChange = (_: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Fragment>
      {/* SELECTOR */}

      <Box mb={2}>
        <form className={formComponentClasses.unwrappedForm}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={4} xl={4} flexDirection={"column"}>
              <Grid item mb={1}>
                <InputLabel htmlFor={"dataset-selector"}>Training Dataset</InputLabel>
              </Grid>
              <Grid item>
                <FormField
                  fullWidth
                  id={"dataset-selector"}
                  name={"dataset-selector"}
                  placeholder="Select Dataset"
                  value={datasetSelected}
                  onChange={(e) => {
                    setDatasetSelected(e.target.value);
                  }}
                  type={"select"}
                  disabled={!datasetOptions.length}
                  options={datasetOptions}
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Box>

      {/* DATASET SCHEMA */}

      <DatasetSchemaTable
        schemaConfig={() => (datasetDetails ? datasetSchemaTableConfig(datasetDetails) : NO_DATASET_SCHEMA_CONFIG)}
      />

      {/* QUICK START PANEL */}

      <Box mt={3}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={value} onChange={handleChange} aria-label="train-model-tabs">
            <Tab sx={{ fontSize: "0.8rem" }} label="TRAIN MODEL QUICK START" {...tabPanelControlProps(0, idPrefix)} />
          </Tabs>
        </Box>

        <TabPanel value={value} index={0} idPrefix={idPrefix}>
          <CodeWrapper trainingDatasetId={datasetSelected || ""} />
        </TabPanel>
      </Box>
    </Fragment>
  );
};

export default TrainModelQuickStart;
