import { Box, Typography } from "@mui/material";
import QuickLinks from "../../../constants/links";
import { StyledNavLink } from "../../authentication/auth.styles";
import { LeanLink } from "../../lean-link/lean-link.component";

export const termsAndConditions = <LeanLink label={"terms and conditions"} href={QuickLinks.terms_and_condition} />;
export const privacyPolicy = <LeanLink label={"privacy policy"} href={QuickLinks.privacy_policy} />;
export const pricingCalculator = <LeanLink href={QuickLinks.pricing} label="pricing calculator" />;

export const AlreadyHaveAnAccountQuickLink = (
  <Box mb={2}>
    <StyledNavLink to={"/authentication/login"}>
      <Typography className={"redirect-link"} sx={{ marginTop: -3, fontSize: "0.875rem" }}>
        Already have an account?
      </Typography>
    </StyledNavLink>
  </Box>
);

export const MarqtuneEvaluationGuideQuickLink = (
  <LeanLink label={"Marqtune Evaluation Guide"} href={QuickLinks.marqtune_evaluation_guide} spanClass="light" />
);

export const SettingMarqtuneEvaluationSchemaParamQuickLink = (
  <LeanLink
    label={"set the evaluation schema parameter"}
    href={QuickLinks.setting_marqtune_evaluation_schema_param}
    spanClass="light"
  />
);

export const MarqtuneDataGuideQuickLink = (
  <LeanLink label={"Marqtune data guide"} href={QuickLinks.marqtune_data_guide} spanClass="light" />
);

export const MarqtuneTrainingDatasetQuickLink = (
  <LeanLink label={"training dataset"} href={QuickLinks.marqtune_example_training_dataset} spanClass="light" />
);

export const MarqtuneEvaluationDatasetQuickLink = (
  <LeanLink label={"evaluation dataset"} href={QuickLinks.marqtune_example_eval_dataset} spanClass="light" />
);

export const CreatedAMarqtuneTrainingDatasetQuickLink = (
  <LeanLink
    label={"created a Marqtune Training Dataset"}
    href={QuickLinks.created_a_marqtune_training_dataset}
    spanClass="light"
  />
);

export const CreatedAMarqtuneEvaluationDatasetQuickLink = (
  <LeanLink
    label={"created a Marqtune Evaluation Dataset"}
    href={QuickLinks.created_a_marqtune_evaluation_dataset}
    spanClass="light"
  />
);

export const FineTuningGuideQuickLink = (
  <LeanLink label={"training guide"} href={QuickLinks.fine_tuning_guide} spanClass="light" />
);

export const FineTuningChargesPerMinQuickLink = (
  <LeanLink label={"charged per minute"} href={QuickLinks.fine_tuning_charges_per_min} spanClass="light" />
);

export const MarqtuneDocumentationQuickLink = (
  <LeanLink label={"Marqtune Documentation"} href={QuickLinks.marqtune_documentation} spanClass="light" />
);
