import { MarqtuneWorkflowAction } from "../../../api/marqtune/types";

export const MARQTUNE_READY_STATUSES = ["READY", "COMPLETED", "FINISHED"];
export const MARQTUNE_FAILED_STATUSES = ["FAILED", "STOPPED", "DELETED", "TERMINATED", "MAXTRAININGTIMEEXCEEDED"];
export const MARQTUNE_IN_PROGRESS_STATUSES = ["CREATING", "INITIALIZING", "RUNNING", "UPLOADING", "PROVISIONING"];
export const MARQTUNE_FINISHED_STATUSES = [...MARQTUNE_READY_STATUSES, ...MARQTUNE_FAILED_STATUSES];

export enum TableRowActions {
  DELETE = "Delete",
  CANCEL = "Cancel",
}

export type PromptConfigState = {
  openPrompt: boolean;
  title: string;
  content: React.ReactNode;
  agreeHandler: () => void;
  agreeLabel: string;
  resourceName: string;
  resourceId: string;
  action: MarqtuneWorkflowAction | null;
};

export type DetailsConf = {
  [key: string]: {
    label: string;
    value: JSX.Element | string | number;
    withCopy?: boolean;
    sizes: { [key: string]: number };
  };
};
